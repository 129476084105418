import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IClinicNotificationConfig, IClinicNotificationConfigResponse } from "clinicNotifications-types";

enum TagTypes {
  CLINIC_NOTIFICATIONS = "CLINIC_NOTIFICATIONS",
}

export const clinicNotificationsApi = createApi({
  reducerPath: "clinicNotificationsApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, includeClinicId: true, isTokenRequired: true }),
  tagTypes: [TagTypes.CLINIC_NOTIFICATIONS],
  endpoints: (builder) => ({
    getAllNotificationConfig: builder.query<IClinicNotificationConfigResponse, unknown>({
      query: () => ({ url: `settings/notification-config`, method: "GET" }),
      providesTags: (result) => (result ? [{ type: TagTypes.CLINIC_NOTIFICATIONS }] : [{ type: TagTypes.CLINIC_NOTIFICATIONS }]),
    }),
    updateNotificationConfig: builder.mutation<void, { notificationType: string; data: Partial<IClinicNotificationConfig> }>({
      query: ({ notificationType, data }) => ({
        url: `settings/notification-config/${notificationType}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: () => [{ type: TagTypes.CLINIC_NOTIFICATIONS }],
    }),
  }),
});

export const { useGetAllNotificationConfigQuery, useUpdateNotificationConfigMutation } = clinicNotificationsApi;

export const clinicNotificationsApiReducer = {
  [clinicNotificationsApi.reducerPath]: clinicNotificationsApi.reducer,
};
