import { MinimumBookingLeadTime, MaximumBookingLeadTime } from "@utils/enums";
import * as Yup from "yup";

export const reservationSettingsValidationSchema = Yup.object().shape({
  autoAcceptReservation: Yup.boolean().required("reservations.settings.validation.autoAcceptReservation.required"),
  breakBetweenAppointments: Yup.number()
    .required("reservations.settings.validation.breakBetweenAppointments.required")
    .min(0, "reservations.settings.validation.breakBetweenAppointments.min"),
  minimumBookingLeadTime: Yup.mixed()
    .oneOf(Object.values(MinimumBookingLeadTime), "reservations.settings.validation.minimumBookingLeadTime.invalid")
    .required("reservations.settings.validation.minimumBookingLeadTime.required"),
  maximumBookingLeadTime: Yup.mixed()
    .oneOf(Object.values(MaximumBookingLeadTime), "reservations.settings.validation.maximumBookingLeadTime.invalid")
    .required("reservations.settings.validation.maximumBookingLeadTime.required"),
});
