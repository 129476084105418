import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IInvoice, IInvoiceReport, IInvoiceStats } from "invoice-types";
import { InvoiceStatus } from "@utils/enums";

export enum InvoiceTagTypes {
  INVOICES = "INVOICES",
}

export const invoiceApi = createApi({
  reducerPath: "invoiceApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [InvoiceTagTypes.INVOICES],
  endpoints: (builder) => ({
    generateInvoiceData: builder.query<IInvoiceReport, { patientId: string; start: string; from: string }>({
      query: ({ patientId, start, from }) => ({
        url: `patients/${patientId}/generate-invoice`,
        method: "GET",
        params: { start, from },
      }),
      providesTags: [InvoiceTagTypes.INVOICES],
    }),
    getInvoiceStats: builder.query<IInvoiceStats, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        url: `invoice/invoice-stats`,
        method: "GET",
        params: { startDate, endDate },
      }),
      providesTags: [InvoiceTagTypes.INVOICES],
    }),
    suggestInvoiceNumber: builder.query<{ suggestion: string }, void>({
      query: () => ({
        url: `invoice/suggest-invoice-number`,
        method: "GET",
      }),
    }),
    getInvoiceById: builder.query<IInvoice, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `invoice/${invoiceId}`,
        method: "GET",
      }),
      providesTags: [InvoiceTagTypes.INVOICES],
    }),
    getInvoices: builder.query<
      IInvoice[],
      { startDate?: string; endDate?: string; patientId?: string; status?: InvoiceStatus[]; invoiceNumber?: string; invoiceId?: string }
    >({
      query: (queryParams) => ({
        url: `invoice`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [InvoiceTagTypes.INVOICES],
    }),
    addInvoice: builder.mutation<IInvoice, Partial<IInvoice>>({
      query: (invoiceData) => ({
        url: `invoice`,
        method: "POST",
        data: invoiceData,
      }),
      invalidatesTags: [InvoiceTagTypes.INVOICES],
    }),
    deleteInvoice: builder.mutation<IInvoice, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `invoice/${invoiceId}`,
        method: "DELETE",
      }),
      invalidatesTags: [InvoiceTagTypes.INVOICES],
    }),
    toggleInvoiceStatus: builder.mutation<IInvoice, { invoiceId: string; status: InvoiceStatus }>({
      query: ({ invoiceId, status }) => ({
        url: `invoice/${invoiceId}/toggle-status`,
        method: "PATCH",
        data: { status },
      }),
      invalidatesTags: [InvoiceTagTypes.INVOICES],
    }),
  }),
});

export const {
  useGenerateInvoiceDataQuery,
  useSuggestInvoiceNumberQuery,
  useGetInvoiceStatsQuery,
  useGetInvoicesQuery,
  useGetInvoiceByIdQuery,
  useAddInvoiceMutation,
  useDeleteInvoiceMutation,
  useToggleInvoiceStatusMutation,
  useLazySuggestInvoiceNumberQuery,
} = invoiceApi;

export const invoiceApiReducer = {
  [invoiceApi.reducerPath]: invoiceApi.reducer,
};
