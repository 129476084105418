import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IHomework } from "patients-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";

enum TagTypes {
  HOMEWORKS = "HOMEWORKS",
}

export const homeworksApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "homeworksApi",
  tagTypes: [TagTypes.HOMEWORKS],
  endpoints: (build) => ({
    getHomeworksByPatient: build.query<IHomework[], string>({
      query: (patientId) => ({ method: "GET", url: `patients/${patientId}/homeworks` }),
      providesTags: (result, error, id) => [{ type: TagTypes.HOMEWORKS, id }],
    }),
    addPatientHomework: build.mutation<IHomework, { patientId: string; homework: Partial<IHomework> }>({
      query: ({ patientId, homework }) => ({
        method: "POST",
        url: `patients/${patientId}/homeworks`,
        data: homework,
      }),
      invalidatesTags: (result, error, { patientId }) => [{ type: TagTypes.HOMEWORKS, id: patientId }],
    }),
    editHomework: build.mutation<IHomework, { patientId: string; homeworkId: string; data: Partial<IHomework> }>({
      query: ({ patientId, homeworkId, data }) => ({
        method: "PATCH",
        url: `patients/${patientId}/homeworks/${homeworkId}`,
        data,
      }),
      invalidatesTags: [TagTypes.HOMEWORKS],
    }),
    deleteHomework: build.mutation<void, { patientId: string; homeworkId: string }>({
      query: ({ patientId, homeworkId }) => ({
        method: "DELETE",
        url: `patients/${patientId}/homeworks/${homeworkId}`,
      }),
      invalidatesTags: [TagTypes.HOMEWORKS],
    }),
    togglePatientHomeworkStatus: build.mutation<IHomework, { patientId: string; homeworkId: string }>({
      query: ({ patientId, homeworkId }) => ({
        method: "POST",
        url: `patients/${patientId}/homeworks/${homeworkId}/toggle-status`,
      }),
      invalidatesTags: [TagTypes.HOMEWORKS],
    }),
    deleteHomeworkFile: build.mutation<void, { patientId: string; homeworkId: string; fileId: string }>({
      query: ({ patientId, homeworkId, fileId }) => ({
        method: "DELETE",
        url: `patients/${patientId}/homeworks/${homeworkId}/file/${fileId}`,
      }),
      invalidatesTags: [TagTypes.HOMEWORKS],
    }),
  }),
});

export const {
  useGetHomeworksByPatientQuery,
  useAddPatientHomeworkMutation,
  useEditHomeworkMutation,
  useDeleteHomeworkMutation,
  useTogglePatientHomeworkStatusMutation,
  useDeleteHomeworkFileMutation,
} = homeworksApi;

export const homeworksApiReducer = {
  [homeworksApi.reducerPath]: homeworksApi.reducer,
};
