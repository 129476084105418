import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { IEmployee } from "employees-types";
import { Field, FieldProps, Formik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { assignEmployeeToPatientValidationSchema } from "src/validations/patientValidation";

interface IAssignEmployeeToPatientModalProps {
  employees: IEmployee[];
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: ({ employeeBodyId }: { employeeBodyId: string }) => void;
}

const AssignEmployeeToPatientModal: React.FC<IAssignEmployeeToPatientModalProps> = ({ employees, open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  if (!employees) return null;

  const initialValues = {
    employeeBodyId: null,
  } as { employeeBodyId: string };

  const employeesData = useMemo(
    () => employees?.map((employee) => ({ label: `${employee.firstname} ${employee.lastname}`, id: employee._id })),
    [employees]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={assignEmployeeToPatientValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("patient_details.assign_employee_to_patient_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("patient_details.assign_employee_to_patient_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="employeeBodyId">
                    {({ form }: FieldProps) => (
                      <Autocomplete
                        noOptionsText={t("no_options")}
                        options={employeesData}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => {
                          form.setFieldValue("employeeBodyId", value ? value.id : null);
                        }}
                        renderOption={(props, option) => (
                          <ListItem {...props}>
                            <ListItemText primary={option.label} />
                          </ListItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("roles.employee")}
                            helperText={
                              touched.employeeBodyId && errors.employeeBodyId
                                ? t(errors.employeeBodyId.toString())
                                : t("employee.assign_to_patient_modal.choose_patient_helper_text")
                            }
                            error={touched.employeeBodyId && !!errors.employeeBodyId}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AssignEmployeeToPatientModal;
