import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Stack, Link, Avatar } from "@mui/material";
import React, { useMemo } from "react";
import { IAppointmentTypeOnlineReservation } from "appointmentTypes.types";
import { t } from "i18next";
import { IHourSetForOnlineReservation } from "reservation-types";
import { formatDuration, formatCost } from "@utils/typographyUtils";
import { formatDate } from "@utils/timeUtils";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import { IFile } from "patients-types";

interface ReservationDetailsProps {
  reservationDetails: {
    firstName: string;
    lastName: string;
    phoneNumber: number;
    email: string;
  };
  selectedEmployee: string;
  clinicInfo: { name: string; files: IFile[] } | undefined;
  selectedAppointment: IAppointmentTypeOnlineReservation;
  selectedHourForOnlineReservation: IHourSetForOnlineReservation;
}

const FinalConfirmation: React.FC<ReservationDetailsProps> = ({
  clinicInfo,
  reservationDetails,
  selectedAppointment,
  selectedEmployee,
  selectedHourForOnlineReservation,
}) => {
  const chosenEmployee = useMemo(() => {
    return selectedAppointment.assignedEmployees.find((employee) => employee._id === selectedEmployee);
  }, [selectedAppointment, selectedEmployee]);

  if (!reservationDetails || !selectedAppointment || !selectedEmployee || !selectedHourForOnlineReservation) {
    return null;
  }

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      sx={{ maxWidth: 600, margin: "0 auto", p: 3, textAlign: "center" }}
    >
      <motion.div initial={{ scale: 0.8 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
        <CheckCircleRoundedIcon color="success" sx={{ fontSize: 60 }} />
      </motion.div>

      <Typography
        component={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        variant="h5"
        fontWeight="bold"
        sx={{ mt: 2 }}
      >
        {t("final_confirmation.confirmation")}
      </Typography>

      <Typography
        component={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        variant="body1"
        sx={{ mt: 1, mb: 3 }}
      >
        {t("final_confirmation.confirmation_text")} <strong>{reservationDetails.email}</strong>
      </Typography>

      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        sx={{ border: "1px solid", borderColor: "grey.300", borderRadius: 2, p: 2, textAlign: "left" }}
      >
        <Typography variant="h6" fontWeight="bold">
          {selectedAppointment.name}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={motion.div}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          <Avatar src={clinicInfo?.files?.[0]?.url} sx={{ width: 20, height: 20 }} variant="rounded" />
          <Typography variant="body2" color="text.secondary">
            {clinicInfo?.name}
          </Typography>
        </Stack>

        <List component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ staggerChildren: 0.1, delayChildren: 0.8 }}>
          {[
            {
              icon: <AccessTimeRoundedIcon />,
              primary: t("final_confirmation.duration"),
              secondary: formatDuration(selectedAppointment.pricing.duration),
            },
            {
              icon: <LocalAtmRoundedIcon />,
              primary: t("final_confirmation.cost"),
              secondary: formatCost(selectedAppointment.pricing.cost),
            },
            {
              icon: <CalendarMonthRoundedIcon />,
              primary: t("final_confirmation.date"),
              secondary: `${formatDate(new Date(selectedHourForOnlineReservation.start), "HH:mm")} - ${formatDate(
                new Date(selectedHourForOnlineReservation.end),
                "HH:mm"
              )}, ${formatDate(new Date(selectedHourForOnlineReservation.start), "EEEE, dd MMMM yyyy")}`,
            },
            {
              icon: <BadgeRoundedIcon />,
              primary: t("final_confirmation.specialist"),
              secondary: `${chosenEmployee.firstname} ${chosenEmployee.lastname}`,
            },
          ].map((item, index) => (
            <ListItem
              key={index}
              component={motion.div}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              disablePadding
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" fontWeight="600">
                    {item.primary}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {item.secondary}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
        style={{ alignSelf: "center" }}
      >
        <Stack sx={{ mt: 5 }} alignItems="center">
          <Link to="https://www.logopedaplus.com/" component={RouterLink} sx={{ display: "contents" }}>
            <img src="/assets/images/logo/primary.png" alt="Logo" style={{ width: 100 }} />
          </Link>
          <Typography variant="caption" color="text.secondary" align="center">
            © {t("final_confirmation.all_rights_reserved")}
          </Typography>
        </Stack>
      </motion.div>
    </Box>
  );
};

export default FinalConfirmation;
