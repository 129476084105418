import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomDialog from "../CustomDialog";
import { Form } from "@theme/styled-components/StyledForm";

interface IDeletePatientModalProps {
  open: boolean;
  loading: boolean;
  patientLastName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeletePatientModal: React.FC<IDeletePatientModalProps> = ({ open, loading, patientLastName, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    patientLastName: "",
  };

  const validationSchema = Yup.object({
    patientLastName: Yup.string()
      .required(t("patient.delete_modal.lastname_required"))
      .oneOf([patientLastName], t("patient.delete_modal.lastname_must_match")),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, values }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("patient.delete_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("patient.delete_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="patientLastName">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("patient.delete_modal.lastname")}
                        helperText={
                          touched.patientLastName && errors.patientLastName
                            ? t(errors.patientLastName.toString())
                            : t("personal_data.lastname_helper")
                        }
                        error={touched.patientLastName && !!errors.patientLastName}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                disabled={values.patientLastName !== patientLastName}
                color="secondary"
              >
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default DeletePatientModal;
