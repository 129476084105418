import { motion } from "framer-motion";
import RBAC from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions, RBACRoles } from "@auth/rbac-rules";
import InfoCard from "@components/InfoCard/InfoCard";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { useModal } from "@hooks/useModal";
import useResponsive from "@hooks/useResponsive";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import { Avatar, Box, Button, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { logOut } from "@store/features/authSlice";
import { setClinicId } from "@store/features/clinicSlice";
import { useAddClinicMutation } from "@store/services/clinicsService";
import { ServiceName, resetApiState } from "@store/utils/resetApiState";
import { palette } from "@theme/palette";
import { Background } from "@theme/styled-components/Background";
import { AppRoutes } from "@utils/enums";
import { IClinic } from "clinics-types";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddClinicModal from "./modals/AddClinicModal";

const StyledContainer = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  maxWidth: "100%",
  paddingLeft: 6,
  paddingRight: 6,
}));

const ScrollableContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  overflowX: "auto",
  maxWidth: "100vw",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
});

interface IClinicComponentProps {
  id?: string;
  name?: string;
  logo?: string;
  addClinicMode?: boolean;
  onItemClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface IClinicSelectionProps {
  clinics: IClinic[];
  isClinicsLoading: boolean;
  isClinicsInitialized: boolean;
}

const ClinicSelection: React.FC<IClinicSelectionProps> = ({ clinics, isClinicsLoading, isClinicsInitialized }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const isDesktop = useResponsive("up", "sm");

  const [isAddClinicModalOpen, toggleAddClinicModal] = useModal();

  const [addClinic, { isLoading }] = useAddClinicMutation();

  const handleAddClinic = useCallback(
    async (clinicData: Partial<IClinic>) => {
      try {
        await addClinic(clinicData);
      } finally {
        toggleAddClinicModal();
        navigate(AppRoutes.MyClinic);
      }
    },
    [addClinic, toggleAddClinicModal]
  );

  const handleSelectClinic = useCallback(
    async (clinicId: string) => {
      await dispatch(setClinicId(clinicId));
      await resetApiState(dispatch, [ServiceName.AUTH, ServiceName.CLINICS]);
      await navigate(AppRoutes.Dashboard);
    },
    [dispatch, navigate]
  );

  const handleLogout = useCallback(async () => {
    await dispatch(logOut());
    await resetApiState(dispatch);
    await navigate(AppRoutes.Login);
  }, [dispatch, navigate]);

  return (
    <>
      <Background />
      <Box
        sx={{
          position: "absolute",
          top: { xs: 24, sm: 40 },
          left: { xs: 24, sm: 40 },
        }}
      >
        <Button startIcon={<MeetingRoomRoundedIcon />} onClick={handleLogout} size="large" color="secondary">
          {t("logout")}
        </Button>
      </Box>

      <StyledContainer>
        {isClinicsLoading && !isClinicsInitialized ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : userRole === RBACRoles.CLIENT && clinics.length === 0 ? (
          <Stack justifyContent="center" alignItems="center">
            <InfoCard
              title={t("clinic_selection.info_card.title")}
              secondTitle={t("clinic_selection.info_card.subtitle")}
              additionalText={t("clinic_selection.info_card.content_text")}
              additionalText2={t("clinic_selection.info_card.helper_text")}
              imageUrl="/assets/images/illustrations/illustration_hand.png"
              width={isDesktop ? "60%" : "100%"}
            />
          </Stack>
        ) : (
          <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Typography variant="h3" sx={{ mb: 1, textAlign: "center" }}>
                {t("clinic_selection.select_your_clinic")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <SeverityPill>{t("clinic_selection.pick_the_clinic")}</SeverityPill>
              </Box>
            </motion.div>
            <ScrollableContainer>
              <Fade in={!!clinics} {...(!clinics ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>
                <Stack direction="row" gap={isDesktop && 2} sx={{ overflowY: "hidden" }}>
                  {clinics.map((clinic) => (
                    <ClinicComponent
                      key={clinic._id}
                      id={clinic._id}
                      name={clinic.name}
                      logo={clinic?.files[0]?.url}
                      onItemClick={() => handleSelectClinic(clinic._id)}
                    />
                  ))}
                  <RBAC
                    action={RBACActions.ACTION_ADD_CLINIC}
                    yes={() => (
                      <ClinicComponent
                        id="add-clinic-button"
                        name={t("clinic_selection.add_new_clinic")}
                        onItemClick={toggleAddClinicModal}
                        addClinicMode
                      />
                    )}
                  />
                </Stack>
              </Fade>
            </ScrollableContainer>
          </>
        )}
      </StyledContainer>
      <AddClinicModal open={isAddClinicModalOpen} onClose={toggleAddClinicModal} onSubmit={handleAddClinic} loading={isLoading} />
    </>
  );
};

export default ClinicSelection;

const ClinicComponent: React.FC<IClinicComponentProps> = ({ id, name, logo, onItemClick, addClinicMode }) => {
  const isDesktop = useResponsive("up", "lg");

  const avatarSize = isDesktop ? 130 : 100;
  const iconSize = isDesktop ? 90 : 60;
  const commonAvatarProps = {
    sx: { width: avatarSize, height: avatarSize, mb: 1, borderRadius: 3 },
    variant: "rounded" as Partial<"square" | "rounded" | "circular">,
  };

  const renderAvatar = () => {
    if (addClinicMode) {
      return (
        <Avatar {...commonAvatarProps} src={logo} sx={{ ...commonAvatarProps.sx, bgcolor: palette.grey[800] }}>
          <AddRoundedIcon sx={{ fontSize: iconSize }} />
        </Avatar>
      );
    }

    if (logo) {
      return <Avatar {...commonAvatarProps} src={logo} />;
    }

    return (
      <Avatar {...commonAvatarProps}>
        <HomeWorkRoundedIcon sx={{ fontSize: iconSize }} />
      </Avatar>
    );
  };

  return (
    <motion.div
      key={id}
      onClick={onItemClick}
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        sx={{
          padding: "20px 20px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {renderAvatar()}
        <Typography variant="subtitle1" align="center">
          {name}
        </Typography>
      </Stack>
    </motion.div>
  );
};
