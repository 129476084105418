import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { addEditAppointmentTypeValidationSchema } from "src/validations/appointmentTypesValidation";
import { Form } from "@theme/styled-components/StyledForm";

interface IEditAppointmentTypeModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: IAppointmentType) => void;
  initialValues: Partial<IAppointmentType>;
}

interface IAppointmentType {
  name: string;
  pricing: { duration: number; cost: number; isReservationEnabled: boolean }[];
}

const EditAppointmentTypeModal: React.FC<IEditAppointmentTypeModalProps> = ({ open, loading, onClose, onSubmit, initialValues }) => {
  const { t } = useTranslation();

  if (!initialValues) return null;

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={addEditAppointmentTypeValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, values, setFieldValue }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("appointment_types.edit_modal.title")} </DialogTitle>
          <Form>
            <DialogContent>
              <DialogContentText>{t("appointment_types.edit_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label={t("appointment_types.edit_modal.name")}
                    value={values.name}
                    onChange={(event) => setFieldValue("name", event.target.value)}
                    helperText={touched.name && errors.name ? t(errors.name as string) : t("appointment_types.edit_modal.name_helper_text")}
                    error={touched.name && !!errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="pricing"
                    render={(arrayHelpers) => (
                      <>
                        {values.pricing.map((price, index) => {
                          const isInitial = index < (initialValues.pricing?.length || 0);

                          return (
                            <Stack direction="row" alignItems="center" key={index} spacing={2} sx={{ mb: 2 }}>
                              <TextField
                                fullWidth
                                name={`pricing.${index}.duration`}
                                label={t("appointment_types.edit_modal.duration")}
                                value={price.duration}
                                inputProps={{
                                  min: 0,
                                  max: 480,
                                }}
                                onChange={(event) => {
                                  setFieldValue(`pricing.${index}.duration`, Number(event.target.value));
                                }}
                                type="number"
                                helperText={
                                  // @ts-expect-error
                                  touched.pricing?.[index]?.duration && errors.pricing?.[index]?.duration
                                    ? // @ts-expect-error
                                      t(errors.pricing[index].duration.toString())
                                    : t("appointment_types.validation.duration_helper_text")
                                }
                                // @ts-expect-error
                                error={touched.pricing?.[index]?.duration && Boolean(errors.pricing?.[index]?.duration)}
                              />
                              <TextField
                                fullWidth
                                name={`pricing.${index}.cost`}
                                label={t("appointment_types.cost")}
                                value={price.cost}
                                onChange={(event) => {
                                  setFieldValue(`pricing.${index}.cost`, Number(event.target.value));
                                }}
                                inputProps={{
                                  min: 0,
                                  max: 10000,
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                                }}
                                type="number"
                                helperText={
                                  // @ts-expect-error
                                  touched.pricing?.[index]?.cost && errors.pricing?.[index]?.cost
                                    ? // @ts-expect-error
                                      t(errors.pricing[index].cost.toString())
                                    : t("appointment_types.validation.cost_helper_text")
                                }
                                // @ts-expect-error
                                error={touched.pricing?.[index]?.cost && Boolean(errors.pricing?.[index]?.cost)}
                              />

                              {!isInitial && (
                                <Grid item xs={1}>
                                  <IconButton onClick={() => arrayHelpers.remove(index)} sx={{ mt: -2.5 }}>
                                    <ClearRoundedIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Stack>
                          );
                        })}
                        <Button
                          startIcon={<AddRoundedIcon />}
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={() => arrayHelpers.push({ duration: null, cost: null })}
                        >
                          {t("appointment_types.add_new_price")}
                        </Button>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default EditAppointmentTypeModal;
