import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { IEmployee } from "employees-types";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { editEmployeeValidationSchema } from "src/validations/employeeValidation";

interface IEditEmployeeModalProps {
  open: boolean;
  initialValues: Partial<IEmployee>;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IEmployee>) => void;
}

const EditEmployeeModal: React.FC<IEditEmployeeModalProps> = ({ open, loading, initialValues, onClose, onSubmit }) => {
  if (!initialValues) return null;

  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validationSchema={editEmployeeValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("employee.edit_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("employee.edit_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="firstname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.firstname")}
                        helperText={
                          touched.firstname && errors.firstname ? t(errors.firstname.toString()) : t("employee.edit_modal.firstname_helper_text")
                        }
                        error={touched.firstname && !!errors.firstname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.lastname")}
                        helperText={
                          touched.lastname && errors.lastname ? t(errors.lastname.toString()) : t("employee.edit_modal.lastname_helper_text")
                        }
                        error={touched.lastname && !!errors.lastname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="phoneNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.phone_number")}
                        helperText={
                          touched.phoneNumber && errors.phoneNumber
                            ? t(errors.phoneNumber.toString())
                            : t("employee.edit_modal.phone_number_helper_text")
                        }
                        error={touched.phoneNumber && !!errors.phoneNumber}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default EditEmployeeModal;
