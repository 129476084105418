import React, { useState, useCallback } from "react";
import { Grid, Skeleton } from "@mui/material";
import { getMonth, getYear } from "date-fns";
import { withSnackbar, SnackbarProps } from "@hoc/AlertPopover";
import { useGetEmployeesReportsQuery } from "@store/services/reportsService";
import { AppointmentStatus, SortBy, SortOrder } from "@utils/enums";
import ReportsFilter from "@components/ReportsFilter/ReportsFilter";
import EmployeeIncomeChart from "./fragments/EmployeeIncomeChart";

const EmployeeCharts: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.TOTAL_INCOME);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);

  const handleMonthChange = useCallback((newDate: Date | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  }, []);

  const {
    data: employeesReports,
    isLoading,
    isFetching,
  } = useGetEmployeesReportsQuery({
    month: getMonth(selectedDate),
    year: getYear(selectedDate),
    appointmentStatus: selectedStatuses,
    sortBy,
    sortOrder,
  });

  const loading = isLoading || isFetching;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReportsFilter
          fields={{ date: true, status: true, sort: true }}
          selectedDate={selectedDate}
          handleMonthChange={handleMonthChange}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Grid>
      <Grid item xs={12}>
        {loading ? <Skeleton variant="rounded" width="100%" height={400} /> : <EmployeeIncomeChart reports={employeesReports} />}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(EmployeeCharts);
