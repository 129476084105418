import activityLogEn from "../locales/en/activity_log.json";
import appSidebarEn from "../locales/en/app_sidebar.json";
import appointmentTypesEn from "../locales/en/appointment_types.json";
import appointmentsEn from "../locales/en/appointments.json";
import authEn from "../locales/en/auth.json";
import changePasswordEn from "../locales/en/change_password.json";
import clientEn from "../locales/en/client.json";
import clinicSelectionEn from "../locales/en/clinic_selection.json";
import commonEn from "../locales/en/common.json";
import dashboardEn from "../locales/en/dashboard.json";
import diagnosisEn from "../locales/en/diagnosis.json";
import employeeEn from "../locales/en/employee.json";
import headerEn from "../locales/en/header.json";
import homeworkEn from "../locales/en/homework.json";
import invoiceDataEn from "../locales/en/invoice_data.json";
import invoiceEn from "../locales/en/invoice.json";
import myClinicEn from "../locales/en/my_clinic.json";
import patientDetailsEn from "../locales/en/patient_details.json";
import patientEn from "../locales/en/patient.json";
import reportsEn from "../locales/en/reports.json";
import resetPasswordEn from "../locales/en/reset_password.json";
import settingsEn from "../locales/en/settings.json";
import themeSettingsEn from "../locales/en/theme_settings.json";
import confirmRegistrationEn from "../locales/en/confirm_registration.json";
import signUpEn from "../locales/en/sign_up.json";
import clinicNotificationsConfigEn from "../locales/en/clinic_notifications.json";
import reservationsEn from "../locales/en/reservations.json";
import onlineReservationEn from "../locales/en/online_reservation.json";

import activityLogPl from "../locales/pl/activity_log.json";
import appSidebarPl from "../locales/pl/app_sidebar.json";
import appointmentTypesPl from "../locales/pl/appointment_types.json";
import appointmentsPl from "../locales/pl/appointments.json";
import authPl from "../locales/pl/auth.json";
import changePasswordPl from "../locales/pl/change_password.json";
import clientPl from "../locales/pl/client.json";
import clinicSelectionPl from "../locales/pl/clinic_selection.json";
import commonPl from "../locales/pl/common.json";
import dashboardPl from "../locales/pl/dashboard.json";
import diagnosisPl from "../locales/pl/diagnosis.json";
import employeePl from "../locales/pl/employee.json";
import headerPl from "../locales/pl/header.json";
import homeworkPl from "../locales/pl/homework.json";
import invoiceDataPl from "../locales/pl/invoice_data.json";
import invoicePl from "../locales/pl/invoice.json";
import myClinicPl from "../locales/pl/my_clinic.json";
import patientDetailsPl from "../locales/pl/patient_details.json";
import patientPl from "../locales/pl/patient.json";
import reportsPl from "../locales/pl/reports.json";
import resetPasswordPl from "../locales/pl/reset_password.json";
import settingsPl from "../locales/pl/settings.json";
import themeSettingsPl from "../locales/pl/theme_settings.json";
import confirmRegistrationPl from "../locales/pl/confirm_registration.json";
import signUpPl from "../locales/pl/sign_up.json";
import expensesPl from "../locales/pl/expenses.json";
import clinicNotificationsConfigPl from "../locales/pl/clinic_notifications.json";
import reservationsPl from "../locales/pl/reservations.json";
import onlineReservationPl from "../locales/pl/online_reservation.json";

const translations = {
  en: {
    translation: {
      ...onlineReservationEn,
      ...activityLogEn,
      ...appSidebarEn,
      ...appointmentTypesEn,
      ...appointmentsEn,
      ...authEn,
      ...changePasswordEn,
      ...clientEn,
      ...clinicSelectionEn,
      ...commonEn,
      ...dashboardEn,
      ...diagnosisEn,
      ...employeeEn,
      ...headerEn,
      ...homeworkEn,
      ...invoiceDataEn,
      ...invoiceEn,
      ...myClinicEn,
      ...patientDetailsEn,
      ...patientEn,
      ...reportsEn,
      ...resetPasswordEn,
      ...settingsEn,
      ...themeSettingsEn,
      ...confirmRegistrationEn,
      ...signUpEn,
      ...clinicNotificationsConfigEn,
      ...reservationsEn,
    },
  },
  pl: {
    translation: {
      ...onlineReservationPl,
      ...reservationsPl,
      ...activityLogPl,
      ...appSidebarPl,
      ...appointmentTypesPl,
      ...appointmentsPl,
      ...authPl,
      ...changePasswordPl,
      ...clientPl,
      ...clinicSelectionPl,
      ...commonPl,
      ...dashboardPl,
      ...diagnosisPl,
      ...employeePl,
      ...headerPl,
      ...homeworkPl,
      ...invoiceDataPl,
      ...invoicePl,
      ...myClinicPl,
      ...patientDetailsPl,
      ...patientPl,
      ...reportsPl,
      ...resetPasswordPl,
      ...settingsPl,
      ...themeSettingsPl,
      ...confirmRegistrationPl,
      ...signUpPl,
      ...expensesPl,
      ...clinicNotificationsConfigPl,
    },
  },
};

export default translations;
