import { Card, CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IReport } from "reports-types";
import { calculateDynamicChartHeight, getCommonOptions } from "../utils/chartUtils";

interface IAppointmentTypeIncomeBarChartProps {
  reports: IReport;
}

const AppointmentTypeIncomeBarChart: React.FC<IAppointmentTypeIncomeBarChartProps> = ({ reports }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!reports.incomeByType) return null;

  const chartHeight = calculateDynamicChartHeight({ itemCount: reports.incomeByType.length });

  const series = [
    {
      name: t("reports.total_income"),
      data: reports.incomeByType.map((item) => item.totalIncome),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    ...getCommonOptions(theme),
    xaxis: {
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: theme.typography.body2.fontSize as string,
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightRegular as string,
        },
      },
      categories: reports.incomeByType.map((item) => item.typeName),
    },
  };

  return (
    <Card>
      <CardHeader title={t("reports.appointmentsType.barChart.title")} subheader={t("reports.appointmentsType.barChart.subtitle")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent>
        <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
      </CardContent>
    </Card>
  );
};

export default AppointmentTypeIncomeBarChart;
