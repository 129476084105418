import { ColorType, SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { ReservationStatus } from "@utils/enums";
import { getColorForReservationStatus, getReservationStatusText } from "@utils/utils";
import React from "react";
import { IReservationStats } from "reservation-types";

interface IReservationStatusFilterProps {
  selectedTab: number;
  reservationStats: IReservationStats[];
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface ITabData {
  label: string;
  severityCount: number;
  value: number;
  color?: ColorType;
}

const ReservationStatusFilter: React.FC<IReservationStatusFilterProps> = ({ selectedTab, reservationStats, handleTabChange }) => {
  const tabData: ITabData[] = reservationStats.map((stat, index) => ({
    label: getReservationStatusText(stat.status),
    severityCount: stat.reservationCount,
    color: getColorForReservationStatus(stat.status as ReservationStatus),
    value: index,
  }));

  const renderTab = (tab: ITabData) => (
    <Tab
      key={tab.value}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{tab.label}</Typography>
          <SeverityPill color={tab.color}>{tab.severityCount}</SeverityPill>
        </Stack>
      }
      value={tab.value}
      title={tab.label}
    />
  );

  return (
    <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable">
      {tabData.map(renderTab)}
    </Tabs>
  );
};

export default ReservationStatusFilter;
