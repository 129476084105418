import Logo from "@components/Logo/Logo";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

interface ISplashScreen {
  title: string;
  subtitle: string;
  image?: string;
}

const SplashScreen: React.FC<ISplashScreen> = ({ title, subtitle, image }) => {
  return (
    <>
      <Logo />
      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            {title}
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>{subtitle}</Typography>

          {image ? (
            <Box component="img" src={image} sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }} />
          ) : (
            <Box sx={{ mx: "auto", my: { xs: 5, sm: 7 } }}>
              <CircularProgress />
            </Box>
          )}
        </StyledContent>
      </Container>
    </>
  );
};
export default SplashScreen;
