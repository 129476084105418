import AddPatientHomework from "@components/modals/Homeworks/AddPatientHomework";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Box, Button, Card, CardContent, CardHeader, Divider, List } from "@mui/material";
import NoDataCard from "@components/NoDataCard/NoDataCard";
import { useAddPatientHomeworkMutation } from "@store/services/homeworksService";
import { palette } from "@theme/palette";
import { IHomework } from "patients-types";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientHomeworkMenu from "./fragments/PatientHomeworkMenu";
import PatientHomeworkWidgetItem from "./fragments/PatientHomeworkWidgetItem";
import { RBACActions } from "@auth/rbac-rules";
import { useTranslation } from "react-i18next";
import RBAC from "@auth/RBAC/RBAC";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";

interface IPatientHomeworkWidgetProps extends SnackbarProps {
  patientId: string;
  homeworks: IHomework[];
  isActive: boolean;
  refetch: () => void;
}

const PatientHomeworkWidget: React.FC<IPatientHomeworkWidgetProps> = ({ patientId, homeworks, isActive, refetch, snackbarShowMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);
  const [isAddPatientHomeworkModalOpen, toggleAddPatientHomeworkModal] = useModal();

  const [addHomework, { isLoading: isAddHomeworkLoading }] = useAddPatientHomeworkMutation();

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleAddPatientHomework = useCallback(
    (homeworkData: Partial<IHomework>) => {
      addHomework({ patientId, homework: homeworkData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("homework.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("homework.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddPatientHomeworkModal();
          handleCloseMenu();
        });
    },
    [addHomework, snackbarShowMessage, toggleAddPatientHomeworkModal, handleCloseMenu, patientId]
  );

  if (!homeworks.length)
    return (
      <>
        <NoDataCard
          title={t("homework.widget.card_title")}
          description={t("homework.widget.card_description")}
          buttonText={t("homework.menu.add")}
          rbacAction={RBACActions.ACTION_ADD_HOMEWORK}
          onButtonClick={toggleAddPatientHomeworkModal}
          disableButton={!isActive}
        />
        <AddPatientHomework
          open={isAddPatientHomeworkModalOpen}
          onClose={toggleAddPatientHomeworkModal}
          onSubmit={handleAddPatientHomework}
          loading={isAddHomeworkLoading}
        />
      </>
    );

  return (
    <>
      <Card>
        <CardHeader
          title={t("homework.widget.title")}
          subheader={t("homework.widget.subheader")}
          action={
            <RBAC
              action={RBACActions.ACTION_ADD_HOMEWORK}
              yes={() => (
                <IconWithTooltip
                  label={t("homework.button.add_new")}
                  icon={<AddRoundedIcon />}
                  onClick={toggleAddPatientHomeworkModal}
                  locked={{ isLocked: !isActive }}
                />
              )}
            />
          }
        />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent>
          <List sx={{ width: "100%", my: -3, overflow: "auto", maxHeight: 500 }}>
            {homeworks?.map((homework, index) => (
              <React.Fragment key={homework._id}>
                <PatientHomeworkWidgetItem
                  {...{ patientId }}
                  {...{ homework }}
                  snackbarShowMessage={snackbarShowMessage}
                  refetch={refetch}
                  isActive={isActive}
                />
                {index !== homeworks.length - 1 && <Divider sx={{ borderStyle: "dashed" }} />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ px: 2, py: 1 }}>
          <Button
            endIcon={<EastRoundedIcon />}
            onClick={() => navigate(`/patients/${patientId}/homeworks`)}
            sx={{
              color: "text.primary",
              "&:hover": {
                backgroundColor: palette.grey[200],
                color: "text.primary",
              },
            }}
          >
            {t("homework.button.see_all")}
          </Button>
        </Box>
      </Card>
      <PatientHomeworkMenu {...{ anchorElementMenu }} {...{ handleCloseMenu }} />
      <AddPatientHomework
        open={isAddPatientHomeworkModalOpen}
        onClose={toggleAddPatientHomeworkModal}
        onSubmit={handleAddPatientHomework}
        loading={isAddHomeworkLoading}
      />
    </>
  );
};

export default PatientHomeworkWidget;
