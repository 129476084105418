import { Dispatch } from "@reduxjs/toolkit";
import { invoiceDataApi } from "@store/services/InvoiceDataService";
import { activityLogApi } from "@store/services/activityLogService";
import { appointmentStatusApi } from "@store/services/appointmentStatusService";
import { appointmentTypesApi } from "@store/services/appointmentTypesService";
import { appointmentsApi } from "@store/services/appointmentsService";
import { authApi } from "@store/services/authService";
import { clinicNotificationsApi } from "@store/services/clinicNotificationsService";
import { clinicsApi } from "@store/services/clinicsService";
import { diagnosisApi } from "@store/services/diagnosisService";
import { employeesApi } from "@store/services/employeesService";
import { expensesApi } from "@store/services/expensesService";
import { homeworksApi } from "@store/services/homeworksService";
import { invoiceApi } from "@store/services/invoiceService";
import { notificationsApi } from "@store/services/notificationsService";
import { patientsApi } from "@store/services/patientsService";
import { reportsApi } from "@store/services/reportsService";
import { reservationSettingsApi } from "@store/services/reservationSettingsService";
import { reservationApi } from "@store/services/reservationsService";
import { usersApi } from "@store/services/usersService";

export enum ServiceName {
  USERS = "users",
  AUTH = "auth",
  CLINICS = "clinics",
  EMPLOYEES = "employees",
  PATIENTS = "patients",
  APPOINTMENTS = "appointments",
  DIAGNOSIS = "diagnosis",
  HOMEWORKS = "homeworks",
  APPOINTMENT_TYPES = "appointmentTypes",
  NOTIFICATIONS = "notifications",
  ACTIVITY_LOG = "activityLog",
  REPORTS = "reports",
  INVOICE_DATA = "invoiceData",
  APPOINTMENT_STATUS = "appointmentsStatus",
  INVOICE = "invoice",
  EXPENSES = "expenses",
  CLINIC_NOTIFICATIONS = "clinicNotifications",
  RESERVATIONS = "reservations",
  RESERVATION_SETTINGS = "reservationSettings",
}

const apiResetFunctions = {
  [ServiceName.USERS]: usersApi.util.resetApiState,
  [ServiceName.AUTH]: authApi.util.resetApiState,
  [ServiceName.CLINICS]: clinicsApi.util.resetApiState,
  [ServiceName.EMPLOYEES]: employeesApi.util.resetApiState,
  [ServiceName.PATIENTS]: patientsApi.util.resetApiState,
  [ServiceName.APPOINTMENTS]: appointmentsApi.util.resetApiState,
  [ServiceName.DIAGNOSIS]: diagnosisApi.util.resetApiState,
  [ServiceName.HOMEWORKS]: homeworksApi.util.resetApiState,
  [ServiceName.APPOINTMENT_TYPES]: appointmentTypesApi.util.resetApiState,
  [ServiceName.NOTIFICATIONS]: notificationsApi.util.resetApiState,
  [ServiceName.ACTIVITY_LOG]: activityLogApi.util.resetApiState,
  [ServiceName.REPORTS]: reportsApi.util.resetApiState,
  [ServiceName.INVOICE_DATA]: invoiceDataApi.util.resetApiState,
  [ServiceName.APPOINTMENT_STATUS]: appointmentStatusApi.util.resetApiState,
  [ServiceName.INVOICE]: invoiceApi.util.resetApiState,
  [ServiceName.EXPENSES]: expensesApi.util.resetApiState,
  [ServiceName.CLINIC_NOTIFICATIONS]: clinicNotificationsApi.util.resetApiState,
  [ServiceName.RESERVATIONS]: reservationApi.util.resetApiState,
  [ServiceName.RESERVATION_SETTINGS]: reservationSettingsApi.util.resetApiState,
};

export const resetApiState = async (dispatch: Dispatch, excludeServices: ServiceName[] = []): Promise<void> => {
  const servicesToReset = Object.values(ServiceName).filter((service) => !excludeServices.includes(service));
  const resetPromises = servicesToReset.map((service) => dispatch(apiResetFunctions[service]()));

  await Promise.all(resetPromises);
};
