import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { AppointmentStatus, SortBy, SortOrder } from "@utils/enums";
import { IPatientAppointmentsByStatus, IPatientIncomeDetails, IReport } from "reports-types";

export enum TagTypes {
  REPORTS = "REPORTS",
  PATIENT_PRESENCE_REPORTS = "PATIENT_PRESENCE_REPORTS",
  PATIENT_APPOINTMENT_REPORTS = "PATIENT_APPOINTMENT_REPORTS",
}

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [TagTypes.REPORTS, TagTypes.PATIENT_PRESENCE_REPORTS, TagTypes.PATIENT_APPOINTMENT_REPORTS],
  endpoints: (builder) => ({
    getReports: builder.query<IReport, { month: number; appointmentStatus?: AppointmentStatus[]; sortBy?: SortBy; sortOrder?: SortOrder }>({
      query: (queryParams) => ({
        url: `reports`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.REPORTS],
    }),
    getPatientPresenceReports: builder.query<IPatientAppointmentsByStatus, { patientId: string; month: number }>({
      query: ({ patientId, ...queryParams }) => ({
        url: `reports/patient-presence/${patientId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_PRESENCE_REPORTS],
    }),
    getPatientAppointmentsReports: builder.query<
      IPatientIncomeDetails,
      { patientId: string; month: number; appointmentStatus?: AppointmentStatus[] }
    >({
      query: ({ patientId, ...queryParams }) => ({
        url: `reports/patient-appointments/${patientId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_APPOINTMENT_REPORTS],
    }),
  }),
});

export const { useGetReportsQuery, useGetPatientPresenceReportsQuery, useGetPatientAppointmentsReportsQuery } = reportsApi;

export const reportsApiReducer = {
  [reportsApi.reducerPath]: reportsApi.reducer,
};
