import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { formatDate } from "@utils/timeUtils";
import { IActivityLog } from "activityLog.types";
import { Field, FieldProps, Formik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { addEditActivityLogValidationSchema } from "src/validations/activityLogValidation";

interface IEditLogModalProps {
  open: boolean;
  loading: boolean;
  appointments: { label: string; date: Date; time: string; id: string }[];
  initialValues: Partial<IActivityLog> | null;
  onClose: () => void;
  onSubmit: (values: Partial<IActivityLog>) => void;
}

const EditLogModal: React.FC<IEditLogModalProps> = ({ open, loading, appointments, initialValues, onClose, onSubmit }) => {
  if (!initialValues) return null;
  const { t } = useTranslation();

  const values = useMemo(() => {
    return {
      ...initialValues,
      appointmentId: initialValues.appointmentId._id,
    };
  }, [initialValues]) as any;

  return (
    <Formik initialValues={values} validationSchema={addEditActivityLogValidationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ values, errors, touched, handleSubmit }) => {
        const currentAppointment = useMemo(() => {
          return appointments.find((appointment) => appointment.id === values.appointmentId);
        }, [appointments, initialValues, values]);

        return (
          <CustomDialog open={open} onClose={onClose}>
            <DialogTitle>{t("activity_log.edit.title")}</DialogTitle>
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>{t("activity_log.edit.content_text")}</DialogContentText>
                <Grid container sx={{ my: 1 }} spacing={2}>
                  <Grid item xs={12}>
                    <Field name="appointmentId">
                      {({ form }: FieldProps) => (
                        <Autocomplete
                          noOptionsText={t("no_options")}
                          value={currentAppointment}
                          options={appointments}
                          getOptionLabel={(option) => `${option.label} (${formatDate(option.date, "dd MMM yyyy")})`}
                          onChange={(event, value) => {
                            form.setFieldValue("appointmentId", value ? value.id : null);
                          }}
                          renderOption={(props, option) => (
                            <ListItem {...props}>
                              <ListItemText primary={`${option.label} (${formatDate(option.date, "dd MMM yyyy")})`} secondary={option.time} />
                            </ListItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label={t("appointments.label")}
                              helperText={
                                touched.appointmentId && errors.appointmentId
                                  ? t(errors.appointmentId.toString())
                                  : t("activity_log.choose_appointment_helper_text")
                              }
                              error={touched.appointmentId && !!errors.appointmentId}
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="description">
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          minRows={3}
                          fullWidth
                          multiline
                          label={t("activity_log.subject_of_appointment")}
                          helperText={
                            touched.description && errors.description
                              ? t(errors.description.toString())
                              : t("activity_log.subject_of_appointment_helper_text")
                          }
                          error={touched.description && !!errors.description}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined" color="secondary">
                  {t("cancel")}
                </Button>
                <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                  {t("submit")}
                </LoadingButton>
              </DialogActions>
            </Form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
};

export default EditLogModal;
