import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { INotification } from "notification.types";

enum TagTypes {
  NOTIFICATIONS = "NOTIFICATIONS",
}

export const notificationsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true }),
  reducerPath: "notificationsApi",
  tagTypes: [TagTypes.NOTIFICATIONS],
  endpoints: (build) => ({
    getUserNotifications: build.query<INotification[], string>({
      query: (userId) => ({ method: "GET", url: `notifications/${userId}/` }),
      providesTags: [TagTypes.NOTIFICATIONS],
    }),
    updateNotificationToken: build.mutation<void, { userId: string; notificationToken: string }>({
      query: ({ userId, notificationToken }) => ({
        method: "POST",
        url: `notifications/${userId}/add-token`,
        data: { notificationToken },
      }),
      invalidatesTags: [TagTypes.NOTIFICATIONS],
    }),
  }),
});

export const { useGetUserNotificationsQuery, useUpdateNotificationTokenMutation } = notificationsApi;

export const notificationsApiReducer = {
  [notificationsApi.reducerPath]: notificationsApi.reducer,
};
