import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";
import React, { useCallback, useRef } from "react";

interface IActionMenuProps {
  openMenu: boolean;
  menuItems: React.ReactNode;
  buttonLabel: string;
  fullWidth?: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionMenu: React.FC<IActionMenuProps> = ({ openMenu, menuItems, buttonLabel, fullWidth, setOpenMenu }) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = useCallback((event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  }, []);

  return (
    <>
      {/* @ts-expect-error: Missing ref interface in button mui */}
      <Button ref={anchorRef} color="secondary" variant="contained" onClick={handleToggle} endIcon={<ExpandMoreRoundedIcon />} fullWidth={fullWidth}>
        {buttonLabel}
      </Button>
      <Popper sx={{ zIndex: 1 }} open={openMenu} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem>{menuItems}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionMenu;
