import { RBACRoles } from "@auth/rbac-rules";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import CustomDialog from "@components/modals/CustomDialog";
import { IUserContextType, UserContext } from "@context/UserProvider";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { palette } from "@theme/palette";
import { Form } from "@theme/styled-components/StyledForm";
import { AppointmentStatus, AppRoutes, ReservationStatus } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointment } from "appointment.types";
import { IAppointmentType } from "appointmentTypes.types";
import { endOfMonth } from "date-fns";
import { IEmployee } from "employees-types";
import { Field, FieldProps, Formik } from "formik";
import { IPatient } from "patients-types";
import React, { useContext, useMemo, useState } from "react";
import { SlotInfo } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addEditAppointmentValidationSchema } from "src/validations/appointmentValidation";

interface IAddAppointmentModalProps {
  patients: IPatient[];
  employees: IEmployee[];
  event?: SlotInfo;
  open: boolean;
  loading: boolean;
  appointmentTypes: IAppointmentType[];
  onClose: () => void;
  onSubmit: (values: Partial<IAppointment>) => void;
}

const AddAppointmentModal: React.FC<IAddAppointmentModalProps> = ({
  patients,
  employees,
  event,
  open,
  loading,
  appointmentTypes,
  onClose,
  onSubmit,
}) => {
  if (!appointmentTypes) return null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const [selectedRepeatOption, setSelectedRepeatOption] = useState("false");

  const REPEAT_INTERVAL_OPTIONS = [{ value: 2 }, { value: 3 }, { value: 4 }];

  const initialValues = useMemo(() => {
    return {
      typeId: "",
      pricingId: "",
      description: "",
      startDate: event ? event.slots[0] : null,
      endDate: event ? event.slots[event.slots.length - 1] : null,
      patientId: null,
      repeatUntilDate: selectedRepeatOption === "false" ? null : endOfMonth(new Date()),
      repeatIntervalWeeks: selectedRepeatOption === "custom" ? REPEAT_INTERVAL_OPTIONS[0].value : 1,
      appointmentStatus: AppointmentStatus.PENDING,
      assignedEmployees: [],
      leadEmployee: userDetails.accountType === RBACRoles.EMPLOYEE ? userDetails._id : null,
      reservationStatus: ReservationStatus.NONE,
    };
  }, [event, selectedRepeatOption, userDetails]) as any;

  const patientsData = useMemo(
    () =>
      patients.map((patient) => ({
        label: `${patient?.firstname} ${patient?.lastname}`,
        additionalLabel: patient.assignedEmployees,
        id: patient?._id,
      })),
    [patients]
  );

  const employeesData = useMemo(() => {
    return employees.map((employee) => ({
      firstname: employee.firstname,
      lastname: employee.lastname,
      email: employee.email,
      id: employee._id,
    }));
  }, [employees]);

  const dialogTitle = useMemo(
    () =>
      `${t("appointments.button.add")} ${
        event?.start ? `${t("appointments.add_modal.add_appointment_on", { date: formatDate(event.start, "EEEE dd.MM.yyyy") })}` : ""
      }`,
    [event]
  );

  return (
    <Formik
      key={event?.start?.toISOString() || "new-appointment"}
      initialValues={initialValues}
      validationSchema={addEditAppointmentValidationSchema}
      onSubmit={(values) => {
        if (!values.patientId) {
          const isLeadEmployee = employees.some((employee) => employee._id === values.leadEmployee && employee.accountType === RBACRoles.EMPLOYEE);

          if (isLeadEmployee) {
            values.assignedEmployees = [values.leadEmployee];
          } else if (userDetails.accountType === RBACRoles.EMPLOYEE) {
            values.assignedEmployees = [userDetails._id];
          }
        }

        onSubmit(values);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("appointments.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="typeId">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth error={touched.typeId && !!errors.typeId}>
                        <InputLabel>{t("appointments.add_modal.type")}</InputLabel>
                        <Select
                          {...field}
                          onChange={(event) => {
                            const selectedTypeId = event.target.value;
                            if (selectedTypeId === "add-new-type") {
                              const redirectPath = AppRoutes.MyClinic.replace(":id", "1");
                              navigate(redirectPath);
                            } else {
                              const selectedType = appointmentTypes.find((type) => type._id === selectedTypeId);
                              form.setFieldValue("typeId", selectedTypeId);
                              const firstPricingId = selectedType && selectedType.pricing.length > 0 ? selectedType.pricing[0]._id : "";
                              form.setFieldValue("pricingId", firstPricingId);
                            }
                          }}
                          value={field.value}
                        >
                          {appointmentTypes.map((type) => (
                            <MenuItem value={type._id} key={type._id}>
                              <Stack direction="row" spacing={1}>
                                <Box>{type.name}</Box>
                                <SeverityPill color={type.isArchive ? "warning" : "success"}>
                                  {type.isArchive ? t("appointments.add_modal.is_archived") : t("appointments.add_modal.is_not_archived")}
                                </SeverityPill>
                              </Stack>
                            </MenuItem>
                          ))}
                          <MenuItem
                            value="add-new-type"
                            key="add-new-type"
                            sx={{ backgroundColor: palette.grey[200], mt: appointmentTypes.length && 1 }}
                          >
                            <Stack direction="row" spacing={1} alignItems="center">
                              <AddCircleRoundedIcon color="primary" />
                              <Typography variant="subtitle1">{t("appointments.add_modal.add_new_type")}</Typography>
                            </Stack>
                          </MenuItem>
                        </Select>
                        <FormHelperText>{touched.typeId && errors.typeId && t(errors.typeId.toString())}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                {values.typeId && (
                  <Grid item xs={12}>
                    <Field name="pricingId">
                      {({ field, form }: FieldProps) => (
                        <FormControl fullWidth error={touched.pricingId && !!errors.pricingId}>
                          <InputLabel>{t("appointments.add_modal.pricing")}</InputLabel>
                          <Select {...field} onChange={(event) => form.setFieldValue("pricingId", event.target.value)} value={field.value}>
                            {appointmentTypes
                              .find((type) => type._id === form.values.typeId)
                              ?.pricing.map((price, index) => (
                                <MenuItem value={price._id} key={index}>
                                  <Stack direction="row" spacing={1}>
                                    <Typography variant="subtitle2">{formatDuration(price.duration)}</Typography>
                                    <SeverityPill color="info">{formatCost(price.cost)}</SeverityPill>
                                  </Stack>
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText>{touched.pricingId && errors.pricingId && t(errors.pricingId.toString())}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field name="description">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={2}
                        fullWidth
                        multiline
                        label={t("appointments.add_modal.description")}
                        helperText={
                          touched.description && errors.description
                            ? t(errors.description.toString())
                            : t("appointments.add_modal.description_helper_text")
                        }
                        error={touched.description && !!errors.description}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate">
                    {({ field, form }: FieldProps) => (
                      <DateTimeField
                        field={field}
                        value={form.values.startDate}
                        onChange={(value) => form.setFieldValue("startDate", value)}
                        labelPrefix={t("appointments.add_modal.start_date_prefix")}
                        helperText={touched.startDate && t(errors.startDate?.toString())}
                        error={touched.startDate && !!errors.startDate}
                        pickerType={event ? "time" : "date-time"}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="endDate">
                    {({ field, form }: FieldProps) => (
                      <DateTimeField
                        field={field}
                        value={form.values.endDate}
                        onChange={(value) => form.setFieldValue("endDate", value)}
                        labelPrefix={t("appointments.add_modal.end_date_prefix")}
                        helperText={touched.endDate && t(errors.endDate?.toString())}
                        error={touched.endDate && !!errors.endDate}
                        pickerType={event ? "time" : "date-time"}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    color="primary"
                    fullWidth
                    exclusive
                    value={selectedRepeatOption}
                    onChange={(event, newValue) => {
                      const isCustom = newValue === "custom";
                      setSelectedRepeatOption(newValue);
                      setFieldValue("repeatIntervalWeeks", newValue === "true" ? 1 : isCustom ? REPEAT_INTERVAL_OPTIONS[0].value : 1);
                      setFieldValue("repeatUntilDate", newValue === "false" ? null : endOfMonth(new Date()));
                    }}
                  >
                    <ToggleButton value="false">{t("appointments.add_modal.one_time_meeting")}</ToggleButton>
                    <ToggleButton value="true">{t("appointments.add_modal.repeat_meeting")}</ToggleButton>
                    <ToggleButton value="custom">{t("appointments.add_modal.custom_interval")}</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {["true", "custom"].includes(selectedRepeatOption) && (
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      {selectedRepeatOption === "custom" && (
                        <Field name="repeatIntervalWeeks">
                          {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => (
                            <FormControl fullWidth error={touched.repeatIntervalWeeks && !!errors.repeatIntervalWeeks}>
                              <InputLabel>{t("appointments.add_modal.custom_interval")}</InputLabel>
                              <Select
                                {...field}
                                onChange={(event) => setFieldValue("repeatIntervalWeeks", Number(event.target.value))}
                                value={field.value}
                              >
                                {REPEAT_INTERVAL_OPTIONS.map((option) => (
                                  <MenuItem value={option.value} key={option.value}>
                                    {t("appointments.add_modal.interval", { interval: option.value })}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {touched.repeatIntervalWeeks && errors.repeatIntervalWeeks
                                  ? t(errors.repeatIntervalWeeks.toString())
                                  : t("appointments.add_modal.custom_interval_helper_text")}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      )}
                      <Field name="repeatUntilDate">
                        {({ field, form }: FieldProps) => (
                          <DateTimeField
                            field={field}
                            value={form.values.repeatUntilDate}
                            onChange={(value) => form.setFieldValue("repeatUntilDate", value)}
                            labelPrefix={t("appointments.add_modal.repeat_until_date_prefix")}
                            helperText={touched.repeatUntilDate && t(errors.repeatUntilDate?.toString())}
                            error={touched.repeatUntilDate && !!errors.repeatUntilDate}
                            pickerType="date"
                          />
                        )}
                      </Field>
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field name="patientId">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth error={touched.patientId && !!errors.patientId}>
                        <InputLabel>{t("roles.patient")}</InputLabel>
                        <Select
                          {...field}
                          onChange={(event) => {
                            const selectedPatientId = event.target.value;
                            form.setFieldValue("patientId", selectedPatientId);
                            const selectedPatient = patients.find((patient) => patient._id === selectedPatientId);
                            const assignedEmployeeIds = selectedPatient?.assignedEmployees?.map((employee) => employee._id) || [];
                            form.setFieldValue("assignedEmployees", assignedEmployeeIds);
                          }}
                          value={field.value}
                        >
                          <MenuItem value="">
                            <Typography color="text.secondary">{t("appointments.add_modal.no_patient_selected")}</Typography>
                          </MenuItem>
                          {patientsData.map((option) => (
                            <MenuItem value={option.id} key={option.id}>
                              <ListItem sx={{ p: 0 }}>
                                <ListItemText
                                  primary={<Typography variant="subtitle1">{option.label}</Typography>}
                                  secondary={
                                    <Typography variant="body2">
                                      {option.additionalLabel.length
                                        ? `${t("appointments.add_modal.therapists")} ${option.additionalLabel
                                            .map((employee) => `${employee.firstname} ${employee.lastname}`)
                                            .join(", ")}`
                                        : ""}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {touched.patientId && errors.patientId
                            ? t(errors.patientId.toString())
                            : t("appointments.add_modal.choose_patient_helper_text")}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </Grid>

                {userDetails.accountType !== RBACRoles.EMPLOYEE && (
                  <Grid item xs={12}>
                    <Field name="leadEmployee">
                      {({ field, form }: FieldProps) => {
                        let assignedEmployeesData = values.patientId
                          ? values.assignedEmployees.map((employeeId: string) => {
                              const employee = patients
                                .flatMap((patient) => patient.assignedEmployees)
                                .find((employee) => employee._id === employeeId);
                              return {
                                id: employee?._id,
                                firstname: employee?.firstname,
                                lastname: employee?.lastname,
                                email: employee?.email,
                              };
                            })
                          : employeesData;

                        if (userDetails.accountType === RBACRoles.OWNER) {
                          const isUserAlreadyInList = assignedEmployeesData.some((employee: any) => employee.id === userDetails._id);
                          if (!isUserAlreadyInList) {
                            assignedEmployeesData = [
                              ...assignedEmployeesData,
                              {
                                id: userDetails._id,
                                firstname: userDetails.firstname,
                                lastname: userDetails.lastname,
                                email: userDetails.email,
                                isCurrentUser: true,
                              },
                            ];
                          }
                        }

                        return (
                          <FormControl fullWidth error={touched.leadEmployee && !!errors.leadEmployee}>
                            <InputLabel>{t("appointments.add_modal.choose_lead_employee")}</InputLabel>
                            <Select
                              {...field}
                              onChange={(event) => {
                                const selectedLeadEmployeeId = event.target.value;
                                form.setFieldValue("leadEmployee", selectedLeadEmployeeId);
                              }}
                              value={form.values.leadEmployee || ""}
                            >
                              <MenuItem value="">
                                <Typography color="text.secondary">{t("appointments.add_modal.no_lead_employee_selected")}</Typography>
                              </MenuItem>
                              {assignedEmployeesData.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                  <UsernameAvatar
                                    avatar
                                    firstname={option.firstname}
                                    lastname={option.lastname}
                                    email={option.email}
                                    additionalInfo={
                                      option.isCurrentUser && <SeverityPill color="primary">{t("reservations.scheduler.my_account")}</SeverityPill>
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.leadEmployee && errors.leadEmployee
                                ? t(errors.leadEmployee.toString())
                                : t("appointments.add_modal.choose_lead_employee_helper_text")}
                            </FormHelperText>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddAppointmentModal;

interface DateTimeFieldProps {
  field: FieldProps["field"];
  value: any;
  onChange: (value: any) => void;
  labelPrefix: string;
  helperText: string;
  error: boolean;
  pickerType?: "time" | "date-time" | "date";
}

const DateTimeField: React.FC<DateTimeFieldProps> = ({ labelPrefix, field, value, onChange, helperText, error, pickerType }) => {
  const { t } = useTranslation();
  const helpertTextLabelPrefix = labelPrefix.toLowerCase();

  let label;
  let defaultHelperText;

  switch (pickerType) {
    case "time":
      label = `${labelPrefix} ${t("appointments.add_modal.hour_prefix")}`;
      defaultHelperText = `${t("appointments.add_modal.date_prefix_helper_text")} ${helpertTextLabelPrefix} ${t(
        "appointments.add_modal.hour_postfix_helper_text"
      )}`;
      break;
    case "date-time":
      label = `${labelPrefix} ${t("appointments.add_modal.hour_and_date_prefix")}`;
      defaultHelperText = `${t("appointments.add_modal.date_prefix_helper_text")} ${helpertTextLabelPrefix} ${t(
        "appointments.add_modal.date_and_hour_postfix_helper_text"
      )}`;
      break;
    case "date":
      label = `${labelPrefix}`;
      defaultHelperText = `${t("appointments.add_modal.repeat_until_date_prefix")}`;
      break;
    default:
      label = `${labelPrefix}`;
      defaultHelperText = `${t("appointments.add_modal.date_prefix_helper_text")} ${helpertTextLabelPrefix}`;
  }

  const commonProps = {
    ...field,
    label: label,
    value: value,
    onChange: onChange,
    slotProps: {
      textField: {
        fullWidth: true,
        helperText: helperText || defaultHelperText,
        error: error,
      },
    },
  };

  return (
    <>
      {pickerType === "time" && <TimePicker {...commonProps} ampm={false} minTime={new Date(0, 0, 0, 6, 0)} maxTime={new Date(0, 0, 0, 23, 59)} />}
      {pickerType === "date-time" && (
        <DateTimePicker {...commonProps} ampm={false} minTime={new Date(0, 0, 0, 6, 0)} maxTime={new Date(0, 0, 0, 23, 59)} />
      )}
      {pickerType === "date" && <DatePicker {...commonProps} />}
    </>
  );
};
