import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Form } from "@theme/styled-components/StyledForm";
import { endOfWeek, startOfWeek } from "date-fns";
import { Field, FieldProps, Formik } from "formik";
import { IPatient } from "patients-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { deleteManyAppointmentsValidationSchema } from "src/validations/appointmentValidation";

interface IDeleteManyAppointmentsModalProps {
  open: boolean;
  patients: IPatient[];
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<any>) => void;
}

const DeleteManyAppointmentsModal: React.FC<IDeleteManyAppointmentsModalProps> = ({ open, loading, patients, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    patientId: "",
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  };

  const patientsData = useMemo(() => patients.map((patient) => ({ label: `${patient.firstname} ${patient.lastname}`, id: patient._id })), [patients]);

  return (
    <Formik initialValues={initialValues} validationSchema={deleteManyAppointmentsValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, setFieldValue }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("appointments.delete_many_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("appointments.delete_many_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="patientId">
                    {({ form }: FieldProps) => (
                      <Autocomplete
                        noOptionsText={t("no_options")}
                        options={patientsData}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => {
                          form.setFieldValue("patientId", value ? value.id : null);
                        }}
                        renderOption={(props, option) => (
                          <ListItem {...props}>
                            <ListItemText primary={option.label} />
                          </ListItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label={t("roles.patient")}
                            helperText={
                              touched.patientId && errors.patientId
                                ? t(errors.patientId.toString())
                                : t("appointments.add_modal.choose_patient_helper_text")
                            }
                            error={touched.patientId && !!errors.patientId}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate">
                    {({ field }: FieldProps) => (
                      <DatePicker
                        label={"Data początkowa"}
                        value={field.value}
                        onChange={(value) => setFieldValue("startDate", value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: touched.startDate && errors.startDate ? errors.startDate.toString() : "Wybierz datę początkową",
                            error: touched.startDate && !!errors.startDate,
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="endDate">
                    {({ field }: FieldProps) => (
                      <DatePicker
                        label={"Data końcowa"}
                        value={field.value}
                        onChange={(value) => setFieldValue("endDate", value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: touched.endDate && errors.endDate ? errors.endDate.toString() : "Wybierz datę końcową",
                            error: touched.endDate && !!errors.endDate,
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={loading} color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default DeleteManyAppointmentsModal;
