import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(3),
  boxSizing: "border-box",
  maxWidth: 800,
  width: "100%",
  [theme.breakpoints.down(550)]: {
    width: "calc(100% - 32px)",
  },
}));
