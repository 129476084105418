import * as Yup from "yup";

export const addEditInvoiceDataValidationSchema = Yup.object({
  bankName: Yup.string().required("invoice_data.validation.bank_name"),
  bankAccountNumber: Yup.string()
    .matches(/^[0-9]{26}$/, "invoice_data.validation.bank_account_number_invalid")
    .required("invoice_data.validation.bank_account_number"),
  NIP: Yup.string()
    .matches(/^[0-9]{10}$/, "invoice_data.validation.NIP_invalid")
    .required("invoice_data.validation.NIP"),
  REGON: Yup.string()
    .matches(/^[0-9]{9}$|^[0-9]{14}$/, "invoice_data.validation.REGON_invalid")
    .required("invoice_data.validation.REGON"),
  address: Yup.string().required("invoice_data.validation.address"),
  postalCode: Yup.string()
    .matches(/^\d{2}-\d{3}$/, "invoice_data.validation.postal_code_invalid")
    .required("invoice_data.validation.postal_code"),
  city: Yup.string().required("invoice_data.validation.city"),
  paymentDayOfMonth: Yup.number()
    .integer("invoice_data.validation.payment_day_of_month_integer")
    .min(1, "invoice_data.validation.payment_day_of_month_range")
    .max(31, "invoice_data.validation.payment_day_of_month_range")
    .required("invoice_data.validation.payment_day_of_month"),
});
