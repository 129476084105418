import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { AppointmentStatus, SortBy, SortOrder } from "@utils/enums";
import {
  AppointmentReportsData,
  IEmployeeAppointmentReports,
  IEmployeesReports,
  IPatientIncomeDetails,
  IPatientReports,
  IPatientsMissedAppointments,
  IReport,
} from "reports-types";

export enum TagTypes {
  REPORTS = "REPORTS",
  PATIENT_REPORTS = "PATIENT_REPORTS",
  PATIENT_PRESENCE_REPORTS = "PATIENT_PRESENCE_REPORTS",
  PATIENT_APPOINTMENT_REPORTS = "PATIENT_APPOINTMENT_REPORTS",
  EMPLOYEE_PRESENCE_REPORTS = "EMPLOYEE_PRESENCE_REPORTS",
  EMPLOYEE_APPOINTMENT_REPORTS = "EMPLOYEE_APPOINTMENT_REPORTS",
  PATIENTS_MISSED_APPOINTMENTS = "PATIENTS_MISSED_APPOINTMENTS",
}

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [
    TagTypes.REPORTS,
    TagTypes.PATIENT_REPORTS,
    TagTypes.PATIENT_PRESENCE_REPORTS,
    TagTypes.PATIENT_APPOINTMENT_REPORTS,
    TagTypes.EMPLOYEE_PRESENCE_REPORTS,
    TagTypes.EMPLOYEE_APPOINTMENT_REPORTS,
    TagTypes.PATIENTS_MISSED_APPOINTMENTS,
  ],
  endpoints: (builder) => ({
    getReports: builder.query<
      IReport,
      { month: number; year: number; appointmentStatus?: AppointmentStatus[]; sortBy?: SortBy; sortOrder?: SortOrder }
    >({
      query: (queryParams) => ({
        url: `reports`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.REPORTS],
    }),
    getPatientsReports: builder.query<
      IPatientReports,
      { month: number; year: number; appointmentStatus?: AppointmentStatus[]; sortBy?: SortBy; sortOrder?: SortOrder }
    >({
      query: (queryParams) => ({
        url: `reports/patients`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_REPORTS],
    }),
    getEmployeesReports: builder.query<
      IEmployeesReports,
      { month: number; year: number; appointmentStatus?: AppointmentStatus[]; sortBy?: SortBy; sortOrder?: SortOrder }
    >({
      query: (queryParams) => ({
        url: `reports/employees`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_REPORTS],
    }),
    getPatientPresenceReports: builder.query<AppointmentReportsData[], { patientId: string; month: number; year: number }>({
      query: ({ patientId, ...queryParams }) => ({
        url: `reports/patient-presence/${patientId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_PRESENCE_REPORTS],
    }),
    getPatientAppointmentsReports: builder.query<
      IPatientIncomeDetails,
      { patientId: string; month: number; year: number; appointmentStatus?: AppointmentStatus[] }
    >({
      query: ({ patientId, ...queryParams }) => ({
        url: `reports/patient-appointments/${patientId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENT_APPOINTMENT_REPORTS],
    }),
    getEmployeePresenceReports: builder.query<
      AppointmentReportsData[],
      { leadEmployeeId: string; month: number; year: number; appointmentStatus?: AppointmentStatus[] }
    >({
      query: ({ leadEmployeeId, ...queryParams }) => ({
        url: `reports/employee-presence/${leadEmployeeId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.EMPLOYEE_PRESENCE_REPORTS],
    }),
    getEmployeeAppointmentsReports: builder.query<
      IEmployeeAppointmentReports,
      { leadEmployeeId: string; month: number; year: number; appointmentStatus?: AppointmentStatus[] }
    >({
      query: ({ leadEmployeeId, ...queryParams }) => ({
        url: `reports/employee-appointments/${leadEmployeeId}`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.EMPLOYEE_APPOINTMENT_REPORTS],
    }),
    getPatientsSortedByMissedAppointments: builder.query<IPatientsMissedAppointments[], { from: string; to: string }>({
      query: (queryParams) => ({
        url: `reports/missed-appointments`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENTS_MISSED_APPOINTMENTS],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetPatientsReportsQuery,
  useGetEmployeesReportsQuery,
  useGetPatientPresenceReportsQuery,
  useGetPatientAppointmentsReportsQuery,
  useGetEmployeePresenceReportsQuery,
  useGetEmployeeAppointmentsReportsQuery,
  useGetPatientsSortedByMissedAppointmentsQuery,
} = reportsApi;

export const reportsApiReducer = {
  [reportsApi.reducerPath]: reportsApi.reducer,
};
