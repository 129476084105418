import { RBACActions } from "@auth/rbac-rules";
import RBAC from "@auth/RBAC/RBAC";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import AddAppointmentModal from "@components/modals/Appointments/AddAppointmentModal";
import NoDataCard from "@components/NoDataCard/NoDataCard";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useAddAppointmentMutation } from "@store/services/appointmentsService";
import { palette } from "@theme/palette";
import { AppRoutes } from "@utils/enums";
import { IAppointment } from "appointment.types";
import { IAppointmentType } from "appointmentTypes.types";
import { isSameDay, parseISO } from "date-fns";
import { IPatient } from "patients-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UpcomingAppointmentsWidgetItem from "./fragments/UpcomingAppointmentsWidgetItem";

interface IUpcomingAppointmentsWidgetProps extends SnackbarProps {
  appointments: IAppointment[];
  appointmentTypes: IAppointmentType[];
  isActive?: boolean;
  isMonthScope?: boolean;
  patients?: IPatient[];
}

const UpcomingAppointmentsWidget: React.FC<IUpcomingAppointmentsWidgetProps> = ({
  patients,
  appointments,
  appointmentTypes,
  isActive = true,
  isMonthScope = false,
  snackbarShowMessage,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isAddAppointmentModalOpen, toggleAddAppointmentModal] = useModal();

  const [addAppointment, { isLoading: isAddAppointmentLoading }] = useAddAppointmentMutation();

  const handleAddAppointment = useCallback(
    (appointmentData: Partial<IAppointment>) => {
      if (!isSameDay(appointmentData.startDate, appointmentData.endDate)) {
        snackbarShowMessage(t("appointments.snackbar_message.date_error"), "error");
        return;
      }

      addAppointment(appointmentData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddAppointmentModal();
        });
    },
    [addAppointment, snackbarShowMessage, toggleAddAppointmentModal]
  );

  const sortedAppointments = useMemo(() => {
    return [...appointments].sort((a, b) => parseISO(a.startDate).getTime() - parseISO(b.startDate).getTime());
  }, [appointments]);

  if (!appointments.length)
    return (
      <>
        <NoDataCard
          title={t("patient_details.upcoming_appointments.no_data_title")}
          description={t("patient_details.upcoming_appointments.no_data_description")}
          buttonText={t("patient_details.upcoming_appointments.no_data_button_text")}
          rbacAction={RBACActions.ACTION_ADD_APPOINTMENT}
          onButtonClick={toggleAddAppointmentModal}
          disableButton={!isActive}
        />
        <AddAppointmentModal
          appointmentTypes={appointmentTypes}
          patients={patients}
          open={isAddAppointmentModalOpen}
          onClose={toggleAddAppointmentModal}
          onSubmit={handleAddAppointment}
          loading={isAddAppointmentLoading}
        />
      </>
    );

  return (
    <>
      <Card>
        <CardHeader
          title={t("patient_details.upcoming_appointments.card.header_title")}
          subheader={
            isMonthScope
              ? t("patient_details.upcoming_appointments.card.header_month_subheader")
              : t("patient_details.upcoming_appointments.card.header_day_subheader")
          }
          action={
            <RBAC
              action={RBACActions.ACTION_ADD_APPOINTMENT}
              yes={() => (
                <IconWithTooltip
                  label={t("patient_details.upcoming_appointments.card.action.add")}
                  icon={<AddRoundedIcon />}
                  onClick={toggleAddAppointmentModal}
                  locked={{ isLocked: !isActive }}
                />
              )}
            />
          }
        />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent>
          <Box sx={{ width: "100%", my: -3, overflow: "auto", maxHeight: 500 }}>
            {sortedAppointments.map((appointment) => (
              <UpcomingAppointmentsWidgetItem
                key={appointment._id}
                patients={patients}
                appointment={appointment}
                appointmentTypes={appointmentTypes}
                snackbarShowMessage={snackbarShowMessage}
              />
            ))}
          </Box>
        </CardContent>
        <RBAC
          action={RBACActions.MAIN_MENU_APPOINTMENTS}
          yes={() => (
            <>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Box sx={{ px: 2, py: 1 }}>
                <Button
                  endIcon={<EastRoundedIcon />}
                  onClick={() => navigate(AppRoutes.Appointments)}
                  sx={{
                    color: "text.primary",
                    "&:hover": {
                      backgroundColor: palette.grey[200],
                      color: "text.primary",
                    },
                  }}
                >
                  {t("see_all_button")}
                </Button>
              </Box>
            </>
          )}
        />
      </Card>
      <AddAppointmentModal
        appointmentTypes={appointmentTypes}
        patients={patients}
        open={isAddAppointmentModalOpen}
        onClose={toggleAddAppointmentModal}
        onSubmit={handleAddAppointment}
        loading={isAddAppointmentLoading}
      />
    </>
  );
};

export default UpcomingAppointmentsWidget;
