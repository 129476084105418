import { ThemeSettingsProvider, useThemeSettings } from "@context/ThemeSettingsProvider";
import { Theme } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import React, { useMemo } from "react";
import customShadows from "./customShadows";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides/ComponentsOverrides";
import { palette, primaryPalettes } from "./palette";
import shadows from "./shadows";
import defaultTypography from "./typography";

interface IThemeProvider {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<IThemeProvider> = ({ children }) => {
  const { settings } = useThemeSettings();

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette,
        primary: primaryPalettes[settings.primaryColor] || palette.primary,
      },
      shape: { borderRadius: 6 },
      typography: {
        ...defaultTypography,
        fontFamily: settings.primaryFont,
      },
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [settings]
  ) as Theme;

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ThemeSettingsProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </ThemeSettingsProvider>
);

export default AppThemeProvider;
