import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Tooltip } from "@mui/material";
import { PatientStatus as PatientStatusEnum } from "@utils/enums";
import { IPatient } from "patients-types";
import { useTranslation } from "react-i18next";

const PatientStatus = ({ patient }: { patient: IPatient }) => {
  const { t } = useTranslation();
  if (!patient) return null;

  const isActive = patient.patientStatus === PatientStatusEnum.ACTIVE ? true : false;
  const tooltipTitle = t("patient.status.about", {
    status: isActive ? t("patient.status.active") : t("patient.status.inactive"),
  });

  return (
    <Tooltip title={tooltipTitle}>
      <span>
        <SeverityPill color={isActive ? "success" : "error"}>{isActive ? t("patient.status.active") : t("patient.status.inactive")}</SeverityPill>
      </span>
    </Tooltip>
  );
};
export default PatientStatus;
