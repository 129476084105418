import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IGetExpensesResponse, IExpense, IAddExpenseBody, ICalculateExpense } from "expenses.types";

enum TagTypes {
  EXPENSES = "EXPENSES",
}

export const expensesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "expensesApi",
  tagTypes: [TagTypes.EXPENSES],
  endpoints: (build) => ({
    getExpenses: build.query<IGetExpensesResponse, { month: number }>({
      query: (queryParams) => ({
        method: "GET",
        url: "expenses",
        params: queryParams,
      }),
      providesTags: [TagTypes.EXPENSES],
    }),
    addExpense: build.mutation<IExpense, Partial<IAddExpenseBody>>({
      query: (expense) => ({
        method: "POST",
        url: "expenses",
        data: expense,
      }),
      invalidatesTags: [TagTypes.EXPENSES],
    }),
    deleteExpense: build.mutation<void, { expenseId: string }>({
      query: ({ expenseId }) => ({
        method: "DELETE",
        url: `expenses/${expenseId}`,
      }),
      invalidatesTags: [TagTypes.EXPENSES],
    }),
    calculateExpenses: build.query<ICalculateExpense[], { month: number }>({
      query: (queryParams) => ({
        method: "GET",
        url: `expenses/calculate`,
        params: queryParams,
      }),
      providesTags: [TagTypes.EXPENSES],
    }),
    updateExpense: build.mutation<IExpense, { expenseId: string; data: Partial<IAddExpenseBody> }>({
      query: ({ expenseId, data }) => ({
        method: "PATCH",
        url: `expenses/${expenseId}`,
        data,
      }),
      invalidatesTags: [TagTypes.EXPENSES],
    }),
  }),
});

export const { useGetExpensesQuery, useAddExpenseMutation, useDeleteExpenseMutation, useCalculateExpensesQuery, useUpdateExpenseMutation } =
  expensesApi;

export const expensesApiReducer = {
  [expensesApi.reducerPath]: expensesApi.reducer,
};
