import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import ActionMenu from "@components/ActionMenu/ActionMenu";
import PageTitle from "@components/PageTitle/PageTitle";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import ChangeInvoiceStatus from "@components/modals/Invoices/ChangeInvoiceStatus";
import { ClinicContext, IClinicContext } from "@context/ClinicProvider";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import { Box, Button, Container, Divider, Grid, ListItemIcon, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import InvoicePreview from "@pages/CreateInvoice/fragments/InvoicePreview";
import useSendInvoice from "@pages/CreateInvoice/hooks/useSendInvoice";
import { useDeleteInvoiceMutation, useGetInvoiceByIdQuery, useToggleInvoiceStatusMutation } from "@store/services/invoiceService";
import { AppRoutes, InvoiceStatus } from "@utils/enums";
import { generatePDF } from "@utils/invoiceUtils";
import { formatDate } from "@utils/timeUtils";
import { getColorForStatus, getInvoiceStatusText } from "@utils/utils";
import { IInvoice } from "invoice-types";
import jsPDF from "jspdf";
import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const PreviewInvoice: React.FC = ({ snackbarShowMessage }: SnackbarProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();

  const { currentClinic } = useContext(ClinicContext) as IClinicContext;

  const printRef = useRef<HTMLDivElement>(null);
  const { invoiceId } = useParams();
  const userRole = useContext(RoleContext);

  const [openMenu, setOpenMenu] = useState(false);

  const [uploadInvoice] = useSendInvoice();
  const [isChangeInvoiceStatusModalOpen, toggleChangeInvoiceStatusModal] = useModal();
  const [isDeleteInvoiceModalOpen, toggleDeleteInvoiceModal] = useModal();

  const [changeInvoiceStatus, { isLoading: isChangeInvoiceStatusLoading }] = useToggleInvoiceStatusMutation();
  const [deleteInvoice, { isLoading: isDeleteInvoiceLoading }] = useDeleteInvoiceMutation();

  const { invoice } = useGetInvoiceByIdQuery(
    {
      invoiceId: invoiceId,
    },
    {
      selectFromResult: ({ data }) => ({
        invoice: data || ({} as IInvoice),
      }),
      skip: !invoiceId,
    }
  );

  const { patientId, periodStartDate, periodEndDate, invoiceNumber, grossValue, status, _id, patient } = invoice || {};

  const start = periodStartDate ? new Date(periodStartDate).toISOString() : null;
  const from = periodEndDate ? new Date(periodEndDate).toISOString() : null;

  const handlePrint = useCallback(async () => {
    if (printRef.current) {
      const savePDFWithDelay = (pdf: jsPDF) => {
        setTimeout(() => {
          pdf.save(`${patient?.firstname}${patient?.lastname}`);
        }, 500);
      };

      generatePDF({
        elementRef: printRef,
        onComplete: savePDFWithDelay,
      });
    }
  }, [printRef, patient, invoiceNumber]);

  const handleSendInvoice = useCallback(() => {
    if (invoice) {
      const patientName = `${invoice.acquirerInvoiceData.firstname} ${invoice.acquirerInvoiceData.lastname}`;

      const savePDFWithDelay = (pdf: jsPDF) => {
        setTimeout(async () => {
          const fileName = `${invoiceNumber}-${patientName}.pdf`;
          const pdfBlob = pdf.output("blob");

          try {
            snackbarShowMessage(t("invoice.snackbar_message.send_invoice.pending"), "info");

            await uploadInvoice(
              pdfBlob,
              fileName,
              invoice.acquirerInvoiceData.email,
              invoiceNumber,
              parseFloat(grossValue.toFixed(2)).toString(),
              formatDate(new Date(invoice.publishDate), "dd.MM.yyyy"),
              formatDate(new Date(invoice.paymentDate), "dd.MM.yyyy"),
              patientId
            );
            snackbarShowMessage(t("invoice.snackbar_message.send_invoice.success"), "success");
          } catch (error) {
            snackbarShowMessage(t("invoice.snackbar_message.send_invoice.error"), "error");
          }
        }, 500);
      };

      generatePDF({
        elementRef: printRef,
        onComplete: savePDFWithDelay,
      });

      changeInvoiceStatus({ invoiceId: _id, status: InvoiceStatus.SENT });
    }
  }, [printRef, invoice, invoiceNumber, useSendInvoice, changeInvoiceStatus, grossValue, invoice.publishDate, invoice.paymentDate]);

  const handleChangeInvoiceStatus = useCallback(
    (status: InvoiceStatus) => {
      if (!_id) return null;

      changeInvoiceStatus({ invoiceId: _id, status })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice.snackbar_message.status.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice.snackbar_message.status.error"), "error");
        })
        .finally(() => {
          toggleChangeInvoiceStatusModal();
          setOpenMenu(false);
        });
    },
    [changeInvoiceStatus, _id, snackbarShowMessage]
  );

  const handleDeleteInvoice = useCallback(() => {
    if (!_id) return null;

    deleteInvoice({ invoiceId: _id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("invoice.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("invoice.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        navigate(-1);
        toggleDeleteInvoiceModal();
      });
  }, [deleteInvoice, snackbarShowMessage, _id]);

  const hasPatientInvoiceData = useMemo(() => {
    const invoiceData = invoice.acquirerInvoiceData;
    return !!(invoiceData?.firstname && invoiceData?.lastname);
  }, [invoice]);

  if (!invoice) return null;

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_INVOICE)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("invoice.preview.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={invoiceNumber}
          bottomContent={
            <Box>
              <SeverityPill color={getColorForStatus(status)}>{getInvoiceStatusText(status)}</SeverityPill>
            </Box>
          }
          rightContent={
            <Stack direction="row" spacing={2}>
              <Button color="secondary" variant="outlined" onClick={() => navigate(-1)} startIcon={<KeyboardBackspaceRoundedIcon />}>
                {t("go_back")}
              </Button>
              <ActionMenu
                openMenu={openMenu}
                menuItems={
                  <>
                    <MenuItem onClick={toggleChangeInvoiceStatusModal}>
                      <ListItemIcon>
                        <QuizRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.menu.change_status")}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handlePrint}>
                      <ListItemIcon>
                        <LocalPrintshopRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.menu.download")}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleSendInvoice}>
                      <ListItemIcon>
                        <HistoryEduRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.menu.send")}
                      </Typography>
                    </MenuItem>
                    {invoice.status === InvoiceStatus.DRAFT && (
                      <>
                        <Divider />
                        <MenuItem onClick={toggleDeleteInvoiceModal}>
                          <ListItemIcon>
                            <PostAddRoundedIcon fontSize="small" color="warning" />
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap sx={{ color: theme.palette.warning.main }}>
                            {t("invoice.menu.delete")}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </>
                }
                buttonLabel={t("invoice.menu.actions")}
                setOpenMenu={setOpenMenu}
              />
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoicePreview
              publishDate={new Date(invoice.publishDate)}
              paymentDate={new Date(invoice.paymentDate)}
              printRef={printRef}
              invoiceNumber={invoiceNumber}
              dateRange={{ start: new Date(start), end: new Date(from) }}
              totalAmount={grossValue}
              currentClinic={currentClinic}
              hasPatientInvoiceData={hasPatientInvoiceData}
              selectedInvoiceData={invoice.sellerInvoiceData}
              selectedAcquirerData={invoice.acquirerInvoiceData}
              readyInvoiceData={invoice.invoiceData}
            />
          </Grid>
        </Grid>
      </Container>
      <ChangeInvoiceStatus
        open={isChangeInvoiceStatusModalOpen}
        selectedInvoice={invoice}
        onSubmit={handleChangeInvoiceStatus}
        onClose={toggleChangeInvoiceStatusModal}
        loading={isChangeInvoiceStatusLoading}
      />
      <ConfirmationModal
        open={isDeleteInvoiceModalOpen}
        title={t("invoice.delete_modal.title")}
        content={t("invoice.delete_modal.content_text")}
        onClose={toggleDeleteInvoiceModal}
        onConfirm={handleDeleteInvoice}
        loading={isDeleteInvoiceLoading}
      />
    </>
  );
};

export default withSnackbar(PreviewInvoice);
