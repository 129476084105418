import { SnackbarProps } from "@hoc/AlertPopover";
import { Card, CardContent, CardHeader, Divider, TextField, IconButton, InputAdornment, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo, useCallback } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import WavingHandRoundedIcon from "@mui/icons-material/WavingHandRounded";
import { useTranslation } from "react-i18next";
import { ClinicContext } from "@context/ClinicProvider";
import { useSendCalendarIntegrationRequestMutation } from "@store/services/reservationsService";
import { LoadingButton } from "@mui/lab";

interface IReservationLinkSectionProps extends SnackbarProps {}

const ReservationLinkSection: React.FC<IReservationLinkSectionProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const { currentClinic } = useContext(ClinicContext);
  const clinicId = currentClinic._id;

  const [sendCalendarIntegrationRequest, { isLoading }] = useSendCalendarIntegrationRequestMutation();

  const link = useMemo(() => `${window.location.origin}/online-reservation/${clinicId}`, [clinicId]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(link).then(
      () => {
        snackbarShowMessage(t("reservations.calendar_reservations_link.copy.success"), "info");
      },
      () => {
        snackbarShowMessage(t("reservations.calendar_reservations_link.copy.error"), "error");
      }
    );
  }, [link, snackbarShowMessage, t]);

  const handleVisitLink = useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  const handleIntegrationRequest = useCallback(() => {
    sendCalendarIntegrationRequest({})
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("reservations.calendar_reservations_link.integrationRequest.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("reservations.calendar_reservations_link.integrationRequest.error"), "error");
      });
  }, [sendCalendarIntegrationRequest, clinicId, snackbarShowMessage, t]);

  return (
    <Stack direction="column" spacing={3}>
      <Card>
        <CardHeader title={t("reservations.calendar_reservations_link.title")} subheader={t("reservations.calendar_reservations_link.subheader")} />
        <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
        <CardContent>
          <Stack direction="row" spacing={3} alignItems="center">
            <TextField
              size="small"
              label={t("reservations.calendar_reservations_link.linkLabel")}
              value={link}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyLink}>
                      <ContentCopyRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <Button color="secondary" onClick={handleVisitLink} startIcon={<OpenInNewRoundedIcon />} sx={{ width: 200 }}>
              {t("reservations.calendar_reservations_link.visitLink")}
            </Button>
          </Stack>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t("reservations.calendar_reservations_link.visitLinkDescription")}
          </Typography>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title={t("reservations.calendar_reservations_link.integrationRequest.title")}
          subheader={t("reservations.calendar_reservations_link.integrationRequest.subheader")}
        />
        <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
        <CardContent>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleIntegrationRequest}
            startIcon={<WavingHandRoundedIcon />}
            loading={isLoading}
          >
            {t("reservations.calendar_reservations_link.integrationRequest.buttonLabel")}
          </LoadingButton>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ReservationLinkSection;
