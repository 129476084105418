import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IDiagnosis } from "patients-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";

enum TagTypes {
  DIAGNOSIS = "DIAGNOSIS",
}

export const diagnosisApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "diagnosisApi",
  tagTypes: [TagTypes.DIAGNOSIS],
  endpoints: (build) => ({
    getDiagnosesByPatient: build.query<IDiagnosis[], string>({
      query: (id) => ({ method: "GET", url: `patients/${id}/diagnoses` }),
      providesTags: (result, error, id) => [{ type: TagTypes.DIAGNOSIS, id }],
    }),
    addPatientDiagnosis: build.mutation<IDiagnosis, { patientId: string; diagnosis: Partial<IDiagnosis> }>({
      query: ({ patientId, diagnosis }) => ({
        method: "POST",
        url: `patients/${patientId}/diagnosis`,
        data: diagnosis,
      }),
      invalidatesTags: (result, error, { patientId }) => [{ type: TagTypes.DIAGNOSIS, id: patientId }],
    }),
    editDiagnosis: build.mutation<IDiagnosis, { patientId: string; diagnosisId: string; data: Partial<IDiagnosis> }>({
      query: ({ patientId, diagnosisId, data }) => ({
        method: "PATCH",
        url: `patients/${patientId}/diagnosis/${diagnosisId}`,
        data,
      }),
      invalidatesTags: [TagTypes.DIAGNOSIS],
    }),
    deleteDiagnosis: build.mutation<void, { patientId: string; diagnosisId: string }>({
      query: ({ patientId, diagnosisId }) => ({
        method: "DELETE",
        url: `patients/${patientId}/diagnosis/${diagnosisId}`,
      }),
      invalidatesTags: [TagTypes.DIAGNOSIS],
    }),
    deleteDiagnosisFile: build.mutation<void, { patientId: string; diagnosisId: string; fileId: string }>({
      query: ({ patientId, diagnosisId, fileId }) => ({
        method: "DELETE",
        url: `patients/${patientId}/diagnosis/${diagnosisId}/file/${fileId}`,
      }),
      invalidatesTags: (result, error, { patientId }) => [{ type: TagTypes.DIAGNOSIS, id: patientId }],
    }),
  }),
});

export const {
  useGetDiagnosesByPatientQuery,
  useAddPatientDiagnosisMutation,
  useEditDiagnosisMutation,
  useDeleteDiagnosisMutation,
  useDeleteDiagnosisFileMutation,
} = diagnosisApi;

export const diagnosisApiReducer = {
  [diagnosisApi.reducerPath]: diagnosisApi.reducer,
};
