import { alpha, Box, Button, Link, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { palette } from "@theme/palette";
import { formatDate } from "@utils/timeUtils";
import { addDays, addMinutes, getDay, isAfter, isBefore, isEqual, startOfDay } from "date-fns";
import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { IAvailabilityDay, IHourSetForOnlineReservation } from "reservation-types";
import { IReservationSettings } from "reservationSettings-types";

interface IAvailabilitySectionProps {
  selectedDate: Date | null;
  selectedEmployee: string | null;
  reservationSettings: IReservationSettings;
  userAvailabilty?: IAvailabilityDay[];
  availableHoursForOnlineReservation?: IHourSetForOnlineReservation[];
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setSelectedHourForOnlineReservation?: React.Dispatch<React.SetStateAction<IHourSetForOnlineReservation | null>>;
}

const AvailabilitySection: React.FC<IAvailabilitySectionProps> = ({
  selectedDate,
  selectedEmployee,
  reservationSettings,
  userAvailabilty = [],
  availableHoursForOnlineReservation,
  setSelectedDate,
  setSelectedHourForOnlineReservation,
}) => {
  if (!reservationSettings) return null;

  const { t } = useTranslation();

  const minimumLeadTime = reservationSettings.minimumBookingLeadTime || 0;
  const maximumLeadTime = reservationSettings.maximumBookingLeadTime || 30;

  const [minDate, maxDate] = useMemo(() => {
    const min = startOfDay(addMinutes(new Date(), minimumLeadTime));

    const max = startOfDay(addDays(new Date(), maximumLeadTime));
    max.setFullYear(2050);

    return [min, max];
  }, [minimumLeadTime, maximumLeadTime]);

  const availableDays = useMemo(() => userAvailabilty.filter((day) => day.isAvailable).map((day) => day.day), [userAvailabilty]);

  const renderDayComponent = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...otherProps } = props;

    const isInRange = (isAfter(day, minDate) && isBefore(day, maxDate)) || isEqual(day, minDate) || isEqual(day, maxDate);
    const isAvailable = availableDays.includes(getDay(day) - 1);

    return (
      <PickersDay
        {...otherProps}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        disabled={!isInRange || !isAvailable}
        sx={{
          ...props.sx,
          ...(isInRange &&
            isAvailable && {
              fontWeight: 700,
              color: palette.primary.main,
              backgroundColor: alpha(palette.primary.light, 0.5),
              "&:hover": {
                backgroundColor: alpha(palette.primary.light, 0.7),
              },
            }),
          ...(!isInRange && {
            color: alpha(palette.grey[500], 0.5),
          }),
        }}
      />
    );
  };

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      sx={{
        p: 1,
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      spacing={5}
    >
      <Stack flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between">
        <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
          <Box display="flex" flexDirection="column">
            <Box flex={1} sx={{ mr: isSmallScreen ? 0 : 3 }}>
              <Typography variant="h6" fontWeight="bold">
                {t("availability_section.select_appointment_date")}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {t("availability_section.select_appointment_date_helper")}
              </Typography>

              <DateCalendar
                disablePast
                views={["day", "month", "year"]}
                disabled={!selectedEmployee}
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
                minDate={minDate}
                maxDate={maxDate}
                sx={{
                  width: isSmallScreen ? "unset" : "334px",
                  m: isSmallScreen ? "auto" : 0,
                  borderRadius: 2,
                  backgroundColor: alpha(palette.grey[100], 0.5),
                }}
                slots={{
                  day: renderDayComponent,
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&:hover": {
                        bgcolor: alpha(palette.primary.lighter, 0.5),
                      },
                      "&.Mui-selected": {
                        bgcolor: palette.primary.main,
                        "&:hover": {
                          bgcolor: palette.primary.darker,
                        },
                        "&:focus": {
                          bgcolor: palette.primary.darker,
                          border: "none",
                        },
                      },
                      "&.MuiPickersDay-today": {
                        fontWeight: 700,
                        "&:hover": {
                          bgcolor: alpha(palette.primary.lighter, 0.5),
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </motion.div>

        {selectedDate && availableHoursForOnlineReservation && (
          <motion.div
            initial={{ opacity: 0, x: isSmallScreen ? 0 : 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            style={{ flex: 1 }}
          >
            <Box display="flex" flexDirection="column" sx={{ ml: isSmallScreen ? 0 : 3, mt: isSmallScreen ? 2 : 0 }}>
              <Typography variant="h6" fontWeight="bold">
                {t("availability_section.available_hours")}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {t("availability_section.select_hour_for_date", { date: selectedDate.toLocaleDateString() })}
              </Typography>

              <Box
                sx={{
                  maxHeight: 300,
                  overflowY: "auto",
                  pr: 1,
                }}
              >
                <Stack spacing={1}>
                  {availableHoursForOnlineReservation?.map((time, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={() => setSelectedHourForOnlineReservation({ start: time.start, end: time.end })}
                    >
                      {formatDate(new Date(time.start), "HH:mm")} - {formatDate(new Date(time.end), "HH:mm")}
                    </Button>
                  ))}
                </Stack>
              </Box>
            </Box>
          </motion.div>
        )}
      </Stack>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        style={{ alignSelf: "center" }}
      >
        <Stack sx={{ mt: 2 }} alignItems="center">
          <Link to="https://www.logopedaplus.com/" component={RouterLink} sx={{ display: "contents" }}>
            <img src="/assets/images/logo/primary.png" alt="Logo" style={{ width: 100 }} />
          </Link>
          <Typography variant="caption" color="text.secondary" align="center">
            © {t("availability_section.all_rights_reserved")}
          </Typography>
        </Stack>
      </motion.div>
    </Stack>
  );
};

export default AvailabilitySection;
