import { SnackbarProps } from "@hoc/AlertPopover";
import useResponsive from "@hooks/useResponsive";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useGetAllNotificationConfigQuery, useUpdateNotificationConfigMutation } from "@store/services/clinicNotificationsService";
import { UISwitch } from "@theme/styled-components/UISwitch";
import { ClinicNotificationConfig, NotificationMethod } from "@utils/enums";
import { IClinicNotificationConfig } from "clinicNotifications-types";
import { parseISO } from "date-fns";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

const shouldRenderComponent = (configKey: string, allowedConfigKeys: ClinicNotificationConfig[]) => {
  return allowedConfigKeys.includes(configKey as ClinicNotificationConfig);
};

const CustomToggleButton = styled(ToggleButton)(() => ({
  "&.Mui-disabled": {
    pointerEvents: "auto",
  },
}));

export interface IClinicNotificationConfigResponse {
  [key: string]: IClinicNotificationConfig;
}

const ClinicNotifications: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const isDesktop = useResponsive("up", "md");

  const [formatsState, setFormatsState] = useState<Record<string, NotificationMethod[]>>({});
  const [useUpdateClinicNotificationConfig] = useUpdateNotificationConfigMutation();

  const { clinicNotificationConfig } = useGetAllNotificationConfigQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        clinicNotificationConfig: data || ({} as IClinicNotificationConfigResponse),
      }),
    }
  );

  const handleEditNotificationConfig = useCallback(
    (data: Partial<IClinicNotificationConfig>, configKey: ClinicNotificationConfig) => {
      useUpdateClinicNotificationConfig({ notificationType: configKey, data })
        .unwrap()
        .then(() => snackbarShowMessage(t("clinic_notifications.snackbar_message.edit.success"), "success"))
        .catch(() => snackbarShowMessage(t("clinic_notifications.snackbar_message.edit.error"), "error"));
    },
    [useUpdateClinicNotificationConfig, snackbarShowMessage, t]
  );

  const handleFormatChange = (event: React.MouseEvent<HTMLElement>, newFormats: NotificationMethod[], configKey: ClinicNotificationConfig) => {
    setFormatsState((prev) => ({
      ...prev,
      [configKey]: newFormats,
    }));
    handleEditNotificationConfig({ notificationMethod: newFormats }, configKey);
  };

  useEffect(() => {
    if (clinicNotificationConfig) {
      const initialFormatsState: Record<string, NotificationMethod[]> = {};
      Object.keys(clinicNotificationConfig).forEach((configKey) => {
        initialFormatsState[configKey] = clinicNotificationConfig[configKey].notificationMethod || [];
      });
      setFormatsState(initialFormatsState);
    }
  }, [clinicNotificationConfig]);

  if (!clinicNotificationConfig || Object.keys(clinicNotificationConfig).length === 0) {
    return <Typography>{t("clinic_notifications.no_data")}</Typography>;
  }

  return (
    <>
      <Alert severity="info" sx={{ mb: 2 }}>
        <AlertTitle>{t("clinic_notifications.alert.title")}</AlertTitle>
        {t("clinic_notifications.alert.content")}
      </Alert>
      {Object.keys(clinicNotificationConfig).map((configKey) => {
        const config = clinicNotificationConfig[configKey];

        return (
          <Card sx={{ mb: 3 }} key={config._id}>
            <CardHeader
              title={t(`clinic_notifications.${configKey}.title`)}
              subheader={t(`clinic_notifications.${configKey}.subtitle`)}
              action={
                isDesktop && (
                  <FormControlLabel
                    control={
                      <UISwitch
                        checked={config.enabled}
                        onClick={() => handleEditNotificationConfig({ enabled: !config.enabled }, configKey as ClinicNotificationConfig)}
                      />
                    }
                    label={
                      <Typography variant="subtitle1">
                        {config.enabled
                          ? t("clinic_notifications.notification_switch.disable")
                          : t("clinic_notifications.notification_switch.enable")}
                      </Typography>
                    }
                  />
                )
              }
            />
            <Box sx={{ px: 3, pt: 2 }}>
              {!isDesktop && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={config.enabled}
                      onClick={() => handleEditNotificationConfig({ enabled: !config.enabled }, configKey as ClinicNotificationConfig)}
                    />
                  }
                  label={
                    <Typography variant="subtitle1">
                      {config.enabled ? t("clinic_notifications.notification_switch.disable") : t("clinic_notifications.notification_switch.enable")}
                    </Typography>
                  }
                />
              )}
            </Box>
            {!config.enabled && <Box sx={{ mb: 3 }} />}
            <Collapse in={config.enabled}>
              <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
              <CardContent>
                <Stack spacing={4}>
                  <Box>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {t("clinic_notifications.notification_method.title")}
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={formatsState[configKey] || []}
                      onChange={(event, newFormats) => handleFormatChange(event, newFormats, configKey as ClinicNotificationConfig)}
                      fullWidth
                    >
                      <ToggleButton value={NotificationMethod.EMAIL}>{t("clinic_notifications.notification_method.methods.email")}</ToggleButton>
                      <Tooltip title={t("clinic_notifications.disabled_push_tooltip")}>
                        <CustomToggleButton value={NotificationMethod.PUSH} disabled>
                          {t("clinic_notifications.notification_method.methods.push")}
                        </CustomToggleButton>
                      </Tooltip>
                      <Tooltip title={t("clinic_notifications.disabled_sms_tooltip")}>
                        <CustomToggleButton value={NotificationMethod.SMS} disabled>
                          {t("clinic_notifications.notification_method.methods.sms")}
                        </CustomToggleButton>
                      </Tooltip>
                    </ToggleButtonGroup>
                  </Box>
                  {shouldRenderComponent(configKey, [
                    ClinicNotificationConfig.UPCOMING_APPOINTMENT,
                    ClinicNotificationConfig.HOMEWORK_REMINDER,
                    ClinicNotificationConfig.ADDED_HOMEWORK,
                  ]) && (
                    <Box>
                      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {t("clinic_notifications.notification_message.title")}
                      </Typography>
                      <TextField
                        defaultValue={config.message}
                        minRows={5}
                        fullWidth
                        multiline
                        placeholder={t("clinic_notifications.notification_message.placeholder")}
                        onBlur={(e) => handleEditNotificationConfig({ message: e.target.value }, configKey as ClinicNotificationConfig)}
                      />
                      {shouldRenderComponent(configKey, [ClinicNotificationConfig.UPCOMING_APPOINTMENT]) && (
                        <>
                          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }} id="notification-parameters">
                            {t("clinic_notifications.notification_message.variables.info")}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {t("clinic_notifications.notification_message.variables.meeting_name")} -{" "}
                            {t("clinic_notifications.notification_message.variables.meeting_name_desc")}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {t("clinic_notifications.notification_message.variables.meeting_date")} -{" "}
                            {t("clinic_notifications.notification_message.variables.meeting_date_desc")}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {t("clinic_notifications.notification_message.variables.recipient_name")} -{" "}
                            {t("clinic_notifications.notification_message.variables.recipient_name_desc")}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                  {shouldRenderComponent(configKey, [ClinicNotificationConfig.UPCOMING_APPOINTMENT]) && (
                    <Box sx={{ width: { sm: "100%", md: "40%" } }}>
                      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {t("clinic_notifications.notification_sendBeforeHours.title")}
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={config.sendBeforeHours}
                          onChange={(e) =>
                            handleEditNotificationConfig({ sendBeforeHours: e.target.value as any }, configKey as ClinicNotificationConfig)
                          }
                        >
                          <MenuItem value={24}>{t("clinic_notifications.notification_sendBeforeHours.select.24")}</MenuItem>
                          <MenuItem value={48}>{t("clinic_notifications.notification_sendBeforeHours.select.48")}</MenuItem>
                          <MenuItem value={72}>{t("clinic_notifications.notification_sendBeforeHours.select.72")}</MenuItem>
                        </Select>
                        <FormHelperText>{t("clinic_notifications.notification_sendBeforeHours.subtitle")}</FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                  {shouldRenderComponent(configKey, [ClinicNotificationConfig.HOMEWORK_REMINDER]) && (
                    <Box sx={{ width: { sm: "100%", md: "40%" } }}>
                      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {t("clinic_notifications.notification_sendAtHour.title")}
                      </Typography>
                      <TimePicker
                        value={parseISO(config.sendAtHour)}
                        onChange={(newValue) =>
                          handleEditNotificationConfig({ sendAtHour: newValue?.toISOString() }, configKey as ClinicNotificationConfig)
                        }
                        ampm={false}
                        views={["hours"]}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: t("clinic_notifications.notification_sendAtHour.subtitle"),
                          },
                        }}
                      />
                    </Box>
                  )}
                </Stack>
              </CardContent>
            </Collapse>
          </Card>
        );
      })}
    </>
  );
};

export default ClinicNotifications;
