import { CheckCircleRounded, CancelRounded } from "@mui/icons-material";
import useResponsive from "@hooks/useResponsive";
import { Divider, List, ListItemButton, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { palette } from "@theme/palette";
import { IAppointmentType } from "appointmentTypes.types";
import React from "react";
import { useTranslation } from "react-i18next";

interface IAppointmentsTypesListProps {
  appointmentTypes: IAppointmentType[];
  selectedAppointmentType: IAppointmentType;
  handleSelectAppointmentType: (appointmentType: IAppointmentType) => void;
}

const AppointmentsTypesList: React.FC<IAppointmentsTypesListProps> = ({ appointmentTypes, selectedAppointmentType, handleSelectAppointmentType }) => {
  const { t } = useTranslation();
  const isDesktop = useResponsive("up", "sm");

  return (
    <Paper sx={{ p: 2, height: isDesktop ? 600 : 250 }}>
      <Typography variant="subtitle1">{t("reservations.appointmentType.list.title")}</Typography>
      <Typography variant="body2" color="text.secondary">
        {t("reservations.appointmentType.list.subheader")}
      </Typography>
      <List sx={{ overflowY: "auto", height: isDesktop ? 450 : 160 }}>
        {appointmentTypes.length !== 0 ? (
          appointmentTypes.map((appointmentType, index) => (
            <React.Fragment key={appointmentType._id}>
              <ListItemButton
                alignItems="flex-start"
                onClick={() => handleSelectAppointmentType(appointmentType)}
                selected={appointmentType._id === selectedAppointmentType?._id}
                sx={{
                  borderRadius: 1,
                  "&.Mui-selected": {
                    bgcolor: palette.grey[100],
                    "&:hover": {
                      bgcolor: palette.grey[200],
                    },
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center">
                      {appointmentType.isReservationEnabled ? (
                        <CheckCircleRounded color="success" sx={{ mr: 1 }} />
                      ) : (
                        <CancelRounded color="disabled" sx={{ mr: 1 }} />
                      )}
                      <Typography variant="subtitle2" noWrap>
                        {`${appointmentType.name}`}
                      </Typography>
                    </Stack>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        maxWidth: 300,
                        fontSize: 12,
                      }}
                    >
                      {appointmentType.description}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Divider variant="inset" component="li" />
              {index < appointmentTypes.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary" align="center">
            {t("reservations.appointmentType.list.noData")}
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export default AppointmentsTypesList;
