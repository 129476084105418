import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { InvoiceStatus } from "@utils/enums";
import { IInvoice } from "invoice-types";
import { useTranslation } from "react-i18next";

interface IInvoiceMenuProps {
  invoice: IInvoice;
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  toggleChangeInvoiceStatusModal: () => void;
  toggleDeleteInvoiceModal: () => void;
}

const InvoiceMenu: React.FC<IInvoiceMenuProps> = ({
  invoice,
  anchorElementMenu,
  handleCloseMenu,
  toggleChangeInvoiceStatusModal,
  toggleDeleteInvoiceModal,
}) => {
  if (!invoice) return null;
  const { t } = useTranslation();
  const theme = useTheme();

  const canDeleteInvoice = invoice.status === InvoiceStatus.DRAFT;

  return (
    <Menu anchorEl={anchorElementMenu} open={Boolean(anchorElementMenu)} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 100 }}>
      <MenuItem onClick={toggleChangeInvoiceStatusModal}>
        <ListItemIcon>
          <QuizRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("invoice.menu.change_status")}
        </Typography>
      </MenuItem>

      {canDeleteInvoice && (
        <>
          <Divider />
          <MenuItem onClick={toggleDeleteInvoiceModal}>
            <ListItemIcon>
              <DeleteRoundedIcon fontSize="small" color="warning" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap sx={{ color: theme.palette.warning.main }}>
              {t("invoice.menu.delete_invoice")}
            </Typography>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default InvoiceMenu;
