import { Card, CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IReport } from "reports-types";
import { getAppointmentStatusText } from "@utils/utils";

interface IAppointmentTypeIncomePieChartProps {
  reports: IReport;
}

const AppointmentTypeIncomePieChart: React.FC<IAppointmentTypeIncomePieChartProps> = ({ reports }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!reports.overallPresencePercentage) return null;

  const series = reports.overallPresencePercentage.map((item) => item.count);
  const labels = reports.overallPresencePercentage.map((item) => t(getAppointmentStatusText(item.status)));

  const options: ApexCharts.ApexOptions = {
    labels,
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        const count = series[seriesIndex];
        const statusText = w.config.labels[seriesIndex];
        return `
          <div style="padding: 0.75rem; color: ${theme.palette.common.white}; background: ${theme.palette.grey[800]}; border-radius: 0.5rem;">
            <strong>${statusText}</strong>: ${count}
          </div>
        `;
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.primary.light,
      // @ts-expect-error
      theme.palette.primary.lighter,
    ],
    legend: {
      fontSize: "14px",
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      position: "bottom",
      offsetY: 0,
    },
  };

  return (
    <Card>
      <CardHeader title={t("reports.appointmentsType.pieChart.title")} subheader={t("reports.appointmentsType.pieChart.subtitle")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent sx={{ mt: 2, p: 0 }}>
        <ReactApexChart options={options} series={series} type="pie" />
      </CardContent>
    </Card>
  );
};

export default AppointmentTypeIncomePieChart;
