import ReportsFilter from "@components/ReportsFilter/ReportsFilter";
import { SnackbarProps } from "@hoc/AlertPopover";
import { Grid } from "@mui/material";
import { AppointmentStatus } from "@utils/enums";
import { IPatientAppointmentsByStatus, IPatientIncomeDetails } from "reports-types";
import PatientAppointmentTable from "./fragments/PatientAppointmentTable";
import PresenceChart from "./fragments/PresenceChart";

interface IPatientReportsSummaryProps extends SnackbarProps {
  selectedDate: Date;
  selectedStatuses: AppointmentStatus[];
  presenceReports: IPatientAppointmentsByStatus;
  appointmentsReports: IPatientIncomeDetails;
  handleMonthChange: (newDate: Date | null) => void;
  setSelectedStatuses: (statuses: AppointmentStatus[]) => void;
}

const PatientReportsSummary: React.FC<IPatientReportsSummaryProps> = ({
  selectedDate,
  selectedStatuses,
  presenceReports,
  appointmentsReports,
  handleMonthChange,
  setSelectedStatuses,
  snackbarShowMessage,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReportsFilter
          selectedDate={selectedDate}
          selectedStatuses={selectedStatuses}
          handleMonthChange={handleMonthChange}
          setSelectedStatuses={setSelectedStatuses}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <PatientAppointmentTable data={appointmentsReports.patientAppointments} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PresenceChart data={presenceReports.patientAppointmentsByStatus} />
      </Grid>
    </Grid>
  );
};

export default PatientReportsSummary;
