import * as Yup from "yup";

const MAX_COST = 10000;
const MAX_DURATION = 480;

const pricingSchema = Yup.object().shape({
  duration: Yup.number()
    .nullable()
    .required("appointment_types.validation.duration_required")
    .min(0, "appointment_types.validation.duration_positive")
    .max(MAX_DURATION, `appointment_types.validation.duration_max: ${MAX_DURATION}`)
    .integer("appointment_types.validation.duration_integer"),
  cost: Yup.number()
    .nullable()
    .required("appointment_types.validation.cost_required")
    .min(0, "appointment_types.validation.cost_positive")
    .max(MAX_COST, `appointment_types.validation.cost_max: ${MAX_COST}`),
});

export const addEditAppointmentTypeValidationSchema = Yup.object().shape({
  name: Yup.string().required("appointment_types.validation.name_required"),
  pricing: Yup.array().of(pricingSchema).min(1, "appointment_types.validation.pricing_at_least_one"),
});

export const addEditAppointmentTypeDescriptionValidationSchema = Yup.object().shape({
  name: Yup.string().required("appointment_types.validation.name_required"),
  sescription: Yup.string().optional(),
});
