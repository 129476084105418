import RBAC from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions, RBACRoles } from "@auth/rbac-rules";
import ActionMenu from "@components/ActionMenu/ActionMenu";
import ClientAssignmentStatus from "@components/ClientAssignmentStatus/ClientAssignmentStatus";
import PageTitle from "@components/PageTitle/PageTitle";
import PatientStatus from "@components/PatientStatus/PatientStatus";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import AssignPatientToClientModal from "@components/modals/Patients/AssignPatientToClientModal";
import DeletePatientModal from "@components/modals/Patients/DeletePatientModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import useResponsive from "@hooks/useResponsive";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { Button, Container, Divider, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";
import {
  useAssignEmployeeToPatientMutation,
  useChangePatientStatusMutation,
  useDeletePatientMutation,
  useGenerateTokenForPatientMutation,
  useGetPatientByIdQuery,
} from "@store/services/patientsService";
import { AppRoutes, PatientStatus as PatientStatusEnum } from "@utils/enums";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import PatientDetailsContent from "./fragments/PatientDetailsContent";
import AssignEmployeeToPatientModal from "./modals/AssignEmployeeToPatientModal";

function PatientDetails({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);

  const navigate = useNavigate();
  const { patientId } = useParams();
  const isDesktop = useResponsive("up", "sm");
  const [openPatientMenu, setOpenPatientMenu] = useState(false);

  const [isAssignEmployeeToPatientModalOpen, toggleAssignEmployeeToPatientModal] = useModal();
  const [isDeletePatientModalOpen, toggleDeletePatientModal] = useModal();
  const [isAssignPatientToClientModalOpen, toggleAssignPatientToClientModal] = useModal();
  const [isChangePatientStatusModalOpen, toggleChangePatientStatusModal] = useModal();

  const [assignEmployeeToPatient, { isLoading: isAssignEmployeeLoading }] = useAssignEmployeeToPatientMutation();
  const [deletePatient, { isLoading: isDeletePatientLoading }] = useDeletePatientMutation();
  const [generateTokenForPatient, { isLoading: isGenerateTokenForPatientLoading }] = useGenerateTokenForPatientMutation();
  const [changePatientStatus, { isLoading: isChangePatientStatusLoading }] = useChangePatientStatusMutation();

  const { employees } = useGetAllEmployeesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      employees: data || [],
    }),
    skip: userRole === RBACRoles.EMPLOYEE,
  });
  const { data: patient } = useGetPatientByIdQuery(patientId);

  const isActive = useMemo(() => patient?.patientStatus === PatientStatusEnum.ACTIVE, [patient]);

  const changeStatusTitle = useMemo(() => {
    return isActive ? t("patient.status.confirm_change_to_inactive") : t("patient.status.confirm_change_to_active");
  }, [isActive, patient, t]);

  const changeStatusContent = useMemo(() => {
    return isActive ? t("patient.status.confirm_deactivation") : t("patient.status.confirm_activation");
  }, [isActive, patient, t]);

  const availableEmployees = useMemo(() => {
    if (!patient || !employees) return [];

    return employees.filter((employee) => !patient.assignedEmployees.some((assigned) => assigned._id === employee._id));
  }, [patient, employees]);

  const handleAssignEmployeeToPatient = useCallback(
    ({ employeeBodyId }: { employeeBodyId: string }) => {
      assignEmployeeToPatient({ patientId: patientId, employeeBodyId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.assign_employee_to_patient.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.assign_employee_to_patient.error"), "error");
        })
        .finally(() => {
          toggleAssignEmployeeToPatientModal();
        });
    },
    [snackbarShowMessage]
  );

  const handleDeletePatient = useCallback(async () => {
    if (!patientId) return;

    try {
      deletePatient({ patientId });
    } finally {
      toggleDeletePatientModal();
      navigate(AppRoutes.Patients);
    }
  }, [deletePatient, toggleDeletePatientModal, patientId]);

  const handleGenerateTokenForPatient = useCallback(
    ({ email }: { email: string }) => {
      generateTokenForPatient({ patientId, email })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.generate_token.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.generate_token.error"), "error");
        })
        .finally(() => {
          toggleAssignPatientToClientModal();
        });
    },
    [generateTokenForPatient, snackbarShowMessage, toggleAssignPatientToClientModal]
  );

  const handleChangePatientStatus = useCallback(() => {
    if (!patient) return;

    const newStatus = isActive ? PatientStatusEnum.INACTIVE : PatientStatusEnum.ACTIVE;

    changePatientStatus({ patientId, status: newStatus })
      .unwrap()
      .then(() => {
        snackbarShowMessage(
          t(newStatus === PatientStatusEnum.ACTIVE ? "patient.snackbar_message.patient_activated" : "patient.snackbar_message.patient_deactivated"),
          "success"
        );
      })
      .catch(() => {
        snackbarShowMessage(t("patient.snackbar_message.toggle_status_error"), "error");
      })
      .finally(() => {
        toggleChangePatientStatusModal();
      });
  }, [patient, isActive, changePatientStatus, snackbarShowMessage, toggleChangePatientStatusModal]);

  if (!patient || !employees) return null;

  return (
    <>
      <Helmet>
        <title>{t("patient.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          gap={5}
          title={`${patient.firstname} ${patient.lastname}`}
          rightContent={
            <Stack direction={"row"} spacing={2}>
              {isDesktop && (
                <Button color="secondary" variant="outlined" component={Link} to={AppRoutes.Patients} startIcon={<KeyboardBackspaceRoundedIcon />}>
                  {t("go_back")}
                </Button>
              )}

              <ActionMenu
                openMenu={openPatientMenu}
                menuItems={
                  <>
                    {isActive && (
                      <React.Fragment>
                        <RBAC
                          action={RBACActions.MAIN_MENU_INVOICE}
                          yes={() => (
                            <MenuItem onClick={() => navigate(AppRoutes.CreateInvoice.replace(":patientId", patient._id))}>
                              <ListItemIcon>
                                <LocalPrintshopRoundedIcon fontSize="small" />
                              </ListItemIcon>
                              <Typography variant="inherit" noWrap>
                                {t("patient_details.invoice")}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                        {!patient.clientId && (
                          <MenuItem onClick={toggleAssignPatientToClientModal}>
                            <ListItemIcon>
                              <AssignmentIndRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                              {t("patient_details.button.assign_to_client")}
                            </Typography>
                          </MenuItem>
                        )}
                        <RBAC
                          action={RBACActions.ACTION_ASSIGN_PATIENT_TO_EMPLOYEE}
                          yes={() => (
                            <MenuItem onClick={toggleAssignEmployeeToPatientModal}>
                              <ListItemIcon>
                                <BadgeRoundedIcon fontSize="small" />
                              </ListItemIcon>
                              <Typography variant="inherit" noWrap>
                                {t("patient_details.button.assign_to_employee")}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                      </React.Fragment>
                    )}
                    <Divider />
                    <RBAC
                      action={RBACActions.ACTION_CHANGE_PATIENT_STATUS}
                      yes={() => (
                        <MenuItem onClick={toggleChangePatientStatusModal}>
                          <ListItemIcon>
                            <ManageAccountsRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            {t("patient_details.button.change_status")}
                          </Typography>
                        </MenuItem>
                      )}
                    />
                    <RBAC
                      action={RBACActions.ACTION_DELETE_PATIENT}
                      yes={() => (
                        <MenuItem onClick={toggleDeletePatientModal}>
                          <ListItemIcon>
                            <DeleteRoundedIcon fontSize="small" color="error" />
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap color="error">
                            {t("patient_details.button.delete")}
                          </Typography>
                        </MenuItem>
                      )}
                    />
                  </>
                }
                buttonLabel={t("patient_details.button.menu")}
                setOpenMenu={setOpenPatientMenu}
              />
            </Stack>
          }
          bottomContent={
            <Stack direction={"row"} spacing={1}>
              <PatientStatus patient={patient} />
              <ClientAssignmentStatus patient={patient} />
            </Stack>
          }
        />

        <PatientDetailsContent patient={patient} patientId={patientId} snackbarShowMessage={snackbarShowMessage} isActive={isActive} />
      </Container>
      <AssignEmployeeToPatientModal
        employees={availableEmployees}
        open={isAssignEmployeeToPatientModalOpen}
        onClose={toggleAssignEmployeeToPatientModal}
        onSubmit={handleAssignEmployeeToPatient}
        loading={isAssignEmployeeLoading}
      />
      <DeletePatientModal
        open={isDeletePatientModalOpen}
        onClose={toggleDeletePatientModal}
        onSubmit={handleDeletePatient}
        patientLastName={patient && patient.lastname}
        loading={isDeletePatientLoading}
      />
      <AssignPatientToClientModal
        open={isAssignPatientToClientModalOpen}
        onClose={toggleAssignPatientToClientModal}
        onSubmit={handleGenerateTokenForPatient}
        loading={isGenerateTokenForPatientLoading}
      />
      <ConfirmationModal
        open={isChangePatientStatusModalOpen}
        title={changeStatusTitle}
        content={changeStatusContent}
        loading={isChangePatientStatusLoading}
        onClose={toggleChangePatientStatusModal}
        onConfirm={handleChangePatientStatus}
      />
    </>
  );
}

export default withSnackbar(PatientDetails);
