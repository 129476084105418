import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import { Avatar, Box, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointmentTypeOnlineReservation } from "appointmentTypes.types";
import { motion } from "framer-motion";
import { IFile } from "patients-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { IHourSetForOnlineReservation } from "reservation-types";

interface IConfirmationSectionProps {
  selectedEmployee: string;
  clinicInfo: { name: string; files: IFile[] } | undefined;
  selectedAppointment: IAppointmentTypeOnlineReservation;
  selectedHourForOnlineReservation: IHourSetForOnlineReservation;
}
const ConfirmationSection: React.FC<IConfirmationSectionProps> = ({
  selectedEmployee,
  clinicInfo,
  selectedAppointment,
  selectedHourForOnlineReservation,
}) => {
  if (!selectedAppointment || !clinicInfo || !selectedHourForOnlineReservation) return null;
  const { t } = useTranslation();

  const chosenEmployee = useMemo(() => {
    return selectedAppointment.assignedEmployees.find((employee) => employee._id === selectedEmployee);
  }, [selectedAppointment, selectedEmployee]);

  return (
    <Box height="100%" display="flex" flexDirection="column" sx={{ p: 1 }}>
      <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {t("confirmation_section.confirm_reservation")}
          </Typography>
        </Stack>
      </motion.div>

      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mb: 3 }}
        component={motion.div}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Avatar src={clinicInfo?.files?.[0]?.url} sx={{ width: 20, height: 20 }} />
        <Typography variant="body2" color="text.secondary">
          {clinicInfo?.name}
        </Typography>
      </Stack>

      <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
        {selectedAppointment.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {selectedAppointment.description}
      </Typography>

      <Box sx={{ border: "1px solid", borderColor: "grey.300", borderRadius: 2, p: 0, px: 2, py: 0.5, mt: 2 }}>
        <List>
          <ListItem disableGutters sx={{ py: 0 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <AccessTimeRoundedIcon color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight="600">
                  {t("confirmation_section.duration")}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {formatDuration(selectedAppointment.pricing.duration)}
                </Typography>
              }
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <LocalAtmRoundedIcon color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight="600">
                  {t("confirmation_section.cost")}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {formatCost(selectedAppointment.pricing.cost)}
                </Typography>
              }
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <BadgeRoundedIcon color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight="600">
                  {t("confirmation_section.specialist")}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {`${chosenEmployee.firstname} ${chosenEmployee.lastname}`}
                </Typography>
              }
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <CalendarMonthRoundedIcon color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight="600">
                  {t("confirmation_section.reservation_date")}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {`${formatDate(new Date(selectedHourForOnlineReservation.start), "HH:mm")} - ${formatDate(
                    new Date(selectedHourForOnlineReservation.end),
                    "HH:mm"
                  )}, ${formatDate(new Date(selectedHourForOnlineReservation.start), "EEEE, dd MMMM yyyy")}`}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        style={{ alignSelf: "center" }}
      >
        <Stack sx={{ mt: 8 }} alignItems="center">
          <Link to="https://www.logopedaplus.com/" component={RouterLink} sx={{ display: "contents" }}>
            <img src="/assets/images/logo/primary.png" alt="Logo" style={{ width: 100 }} />
          </Link>
          <Typography variant="caption" color="text.secondary" align="center">
            © {t("confirmation_section.all_rights_reserved")}
          </Typography>
        </Stack>
      </motion.div>
    </Box>
  );
};

export default ConfirmationSection;
