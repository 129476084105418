import { TimelineItem } from "@components/TimelineItem/TimelineItem";
import { Avatar, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import { IClinic } from "clinics-types";
import { IPatient } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledEmployeeBox } from "./PatientDataWidget";
import { stringAvatar } from "@utils/utils";

interface IClinicDataWidget {
  patient: IPatient;
  currentClinic: IClinic;
}
const ClinicDataWidget: React.FC<IClinicDataWidget> = ({ currentClinic, patient }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("patient_details.clinic_data.title")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent>
        <Stack direction="column" spacing={2}>
          <TimelineItem color="success" title={currentClinic.name} subtitle={t("patient_details.clinic_data.subtitle")} />
          <TimelineItem
            color="secondary"
            title={currentClinic.contactNumber ?? t("patient_details.clinic_data.phone_number_helper_text")}
            subtitle={t("patient_details.clinic_data.phone_number")}
          />
          <TimelineItem
            color="error"
            title={currentClinic.contactEmail ?? t("patient_details.clinic_data.email")}
            subtitle={t("patient_details.clinic_data.email_helper_text")}
          />
        </Stack>
        {!!patient.assignedEmployees.length && (
          <>
            <Divider sx={{ borderStyle: "dashed", my: 2 }} />
            <Typography variant="subtitle1" gutterBottom>
              {t("patient_details.data.assigned_employee")}
            </Typography>
            <Stack gap={1}>
              {patient.assignedEmployees.map((employee) => (
                <StyledEmployeeBox sx={{ p: 2 }} key={employee._id}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack gap={2} direction="row" alignItems="center">
                      <Avatar src={employee?.avatar} {...stringAvatar(`${employee.firstname} ${employee.lastname}`)} />
                      <Stack>
                        <Typography variant="subtitle2" sx={{ color: "text.primary", mb: -0.3 }} noWrap>
                          {`${employee.firstname} ${employee.lastname}`}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                          {employee?.email}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                          {employee?.phoneNumber}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </StyledEmployeeBox>
              ))}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ClinicDataWidget;
