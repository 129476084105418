import { Box, Typography, styled } from "@mui/material";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { FieldProps } from "formik";
import React, { useMemo, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImagePreview);

interface IDropZoneFieldProps extends Partial<FieldProps> {
  helperText: string;
  error: boolean;
  label?: string;
}
const StyledFilePond = styled(FilePond)(() => ({
  marginBottom: "0 !important",
}));

const DropZoneField: React.FC<IDropZoneFieldProps> = ({ field, form, label, helperText, error }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File[]>([]);

  const labelIdle = useMemo(() => {
    const defaultLabelIdleStart = t("drop_zone_field");
    return `${label || defaultLabelIdleStart} <span class="filepond--label-action">${t("browse")}</span>`;
  }, [label]);

  return (
    <Box sx={{ minWidth: { xs: 250, sm: 550 } }}>
      <StyledFilePond
        labelIdle={labelIdle}
        files={file}
        onupdatefiles={(fileItems) => {
          const newFiles = fileItems.map((fileItem) => fileItem.file);
          setFile(newFiles as File[]);
          form.setFieldValue(field.name, newFiles);
        }}
        allowMultiple={true}
        maxFiles={5}
        name={field.name}
      />
      {helperText && (
        <Typography color={error ? "error" : "text.secondary"} variant="caption" sx={{ ml: 2 }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default DropZoneField;
