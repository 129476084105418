import { RBACRoles } from "@auth/rbac-rules";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import ToggleReservationStatusModal from "@components/modals/Reservations/ToggleReservationStatusModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import { Paper, SelectChangeEvent } from "@mui/material";
import { useGetAllAppointmentsQuery, useGetReservationStatsQuery, useToggleReservationStatusMutation } from "@store/services/appointmentsService";
import { useGetAppointmentTypesForOnlineReservationQuery } from "@store/services/appointmentTypesService";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";
import { ReservationStatus } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { IAppointment } from "appointment.types";
import { endOfMonth, startOfMonth } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IReservationStats } from "reservation-types";
import ReservationFilterPanel from "./fragments/ReservationFilterPanel";
import ReservationsTable from "./fragments/ReservationsTable";
import ReservationStatusFilter from "./fragments/ReservationStatusFilter";

export type ReservationFilter = {
  assignedEmployees: string;
  start: string;
  from: string;
  status: string;
  typeId: string;
};

interface IReservationCalendarProps extends SnackbarProps {}

const ReservationCalendarSection: React.FC<IReservationCalendarProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();

  const userRole = useContext(RoleContext);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [isReservationtStatusModalOpen, toggleReservationStatusModalOpen] = useModal();

  const handleCloseToggleReservationStatusModal = () => handleCloseModal([setSelectedAppointment], toggleReservationStatusModalOpen);
  const [toggleReservationStatus, { isLoading: isToggleReservationStatusLoading }] = useToggleReservationStatusMutation();

  const [selectedAppointment, setSelectedAppointment] = useState<Partial<IAppointment> | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const reservationStatusFilter = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return [ReservationStatus.PENDING, ReservationStatus.ACCEPTED, ReservationStatus.REJECTED];
      case 1:
        return [ReservationStatus.ACCEPTED];
      case 2:
        return [ReservationStatus.REJECTED];
      case 3:
        return [ReservationStatus.PENDING];
      default:
        return [];
    }
  }, [selectedTab]);

  const [filters, setFilters] = useState<ReservationFilter>({
    assignedEmployees: null,
    start: startOfMonth(new Date()).toISOString(),
    from: endOfMonth(new Date()).toISOString(),
    status: null,
    typeId: null,
  });

  const { appointmentTypes } = useGetAppointmentTypesForOnlineReservationQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
    }
  );

  const { appointments } = useGetAllAppointmentsQuery(
    {
      reservationStatus: reservationStatusFilter,
      start: filters.start,
      from: filters.from,
      assignedEmployees: selectedEmployees,
      typeId: selectedTypes,
    },
    {
      selectFromResult: ({ data }) => ({
        appointments: data || [],
      }),
    }
  );

  const { employees } = useGetAllEmployeesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
      skip: userRole === RBACRoles.EMPLOYEE,
    }
  );

  const { reservationStats } = useGetReservationStatsQuery(
    {
      startDate: filters.start,
      endDate: filters.from,
    },
    {
      selectFromResult: ({ data }) => ({
        reservationStats: data || [],
      }),
    }
  );

  const filteredEmployees = useMemo(() => {
    if (selectedTypes.length === 0) return employees;

    const employeesSet = selectedTypes.reduce((set, typeId) => {
      const type = appointmentTypes.find((t) => t._id === typeId);
      if (type) {
        type.assignedEmployees.forEach((emp) => set.add(emp._id));
      }
      return set;
    }, new Set());

    return employees.filter((emp) => employeesSet.has(emp._id));
  }, [selectedTypes, employees, appointmentTypes]);

  const filteredTypes = useMemo(() => {
    if (selectedEmployees.length === 0) return appointmentTypes;

    const appointmentTypesSet = selectedEmployees.reduce((set, empId) => {
      appointmentTypes.forEach((type) => {
        if (type.assignedEmployees.some((emp) => emp._id === empId)) {
          set.add(type._id);
        }
      });
      return set;
    }, new Set());

    return appointmentTypes.filter((type) => appointmentTypesSet.has(type._id));
  }, [selectedEmployees, appointmentTypes]);

  const handleEmployeeChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedEmployees(event.target.value as string[]);
    },
    [setSelectedEmployees]
  );

  const handleTypeChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedTypes(event.target.value as string[]);
    },
    [setSelectedTypes]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOpenToggleReservationStatusModal = useCallback(
    (reservationData: Partial<IAppointment>) => {
      setSelectedAppointment(reservationData);
      toggleReservationStatusModalOpen();
    },
    [setSelectedAppointment, toggleReservationStatusModalOpen]
  );

  const handleToggleReservationStatus = useCallback(
    (reservationStatus: ReservationStatus) => {
      if (!selectedAppointment) return;
      toggleReservationStatus({ appointmentId: selectedAppointment._id, data: { reservationStatus } })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("reservations.settings.snackbar_message.toggle_status.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("reservations.settings.snackbar_message.toggle_status.error"), "error");
        })
        .finally(() => {
          handleCloseToggleReservationStatusModal();
        });
    },
    [toggleReservationStatus, snackbarShowMessage, selectedAppointment, handleCloseToggleReservationStatusModal, t]
  );

  return (
    <>
      <Paper>
        <ReservationStatusFilter
          selectedTab={selectedTab}
          reservationStats={reservationStats as IReservationStats[]}
          handleTabChange={handleTabChange}
        />
        <ReservationFilterPanel
          employees={filteredEmployees}
          selectedEmployees={selectedEmployees}
          handleEmployeeChange={handleEmployeeChange}
          filters={filters}
          setFilters={setFilters}
          appointmentTypes={filteredTypes}
          selectedTypes={selectedTypes}
          handleTypeChange={handleTypeChange}
        />
        <ReservationsTable
          handleOpenToggleReservationStatusModal={handleOpenToggleReservationStatusModal}
          appointments={appointments}
          appointmentTypes={appointmentTypes}
        />
      </Paper>
      <ToggleReservationStatusModal
        selectedReservation={selectedAppointment}
        open={isReservationtStatusModalOpen}
        onClose={handleCloseToggleReservationStatusModal}
        onSubmit={handleToggleReservationStatus}
        loading={isToggleReservationStatusLoading}
      />
    </>
  );
};

export default withSnackbar(ReservationCalendarSection);
