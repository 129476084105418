import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IUserDetails } from "user-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";

enum TagTypes {
  USER_DETAILS = "USER_DETAILS",
  EMPLOYEES = "EMPLOYEES",
  USER_NOTE = "USER_NOTE",
}

export const usersApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: apiUrl,
    isTokenRequired: true,
  }),
  reducerPath: "usersApi",
  tagTypes: [TagTypes.USER_DETAILS, TagTypes.EMPLOYEES, TagTypes.USER_NOTE],
  endpoints: (build) => ({
    getUserDetails: build.query<IUserDetails, unknown>({
      query: () => ({
        method: "GET",
        url: `users/me/`,
      }),
      providesTags: [TagTypes.USER_DETAILS],
    }),
    editUserDetails: build.mutation<IUserDetails, { clinicId: string; userId: string; data: Partial<IUserDetails> }>({
      query: ({ userId, clinicId, data }) => ({
        method: "PATCH",
        url: `users/${clinicId}/${userId}`,
        data,
      }),
      invalidatesTags: [TagTypes.USER_DETAILS, TagTypes.EMPLOYEES],
    }),
    assignPatientToClient: build.mutation<void, { data: { accessPatientToken: string } }>({
      query: ({ data }) => ({
        method: "POST",
        url: `clinics/:clinicId/assign-patient`,
        data,
      }),
    }),
    getUserNote: build.query<{ note: string }, { userId: string }>({
      query: ({ userId }) => ({
        method: "GET",
        url: `users/${userId}/note/`,
      }),
      providesTags: [TagTypes.USER_NOTE],
    }),
    updateUserNote: build.mutation<void, { userId: string; content: string }>({
      query: ({ userId, content }) => ({
        method: "PUT",
        url: `users/${userId}/note/`,
        data: { content },
      }),
      invalidatesTags: [TagTypes.USER_NOTE],
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useEditUserDetailsMutation,
  useAssignPatientToClientMutation,
  useGetUserNoteQuery,
  useUpdateUserNoteMutation,
} = usersApi;

export const usersApiReducer = {
  [usersApi.reducerPath]: usersApi.reducer,
};
