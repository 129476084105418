import { RBACRoles } from "@auth/rbac-rules";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Form } from "@theme/styled-components/StyledForm";
import { formatDate } from "@utils/timeUtils";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointment } from "appointment.types";
import { IAppointmentType } from "appointmentTypes.types";
import { parseISO } from "date-fns";
import { IEmployee } from "employees-types";
import { Field, FieldProps, Formik } from "formik";
import { IPatient } from "patients-types";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { addEditAppointmentValidationSchema } from "src/validations/appointmentValidation";
import CustomDialog from "../CustomDialog";

interface IEditAppointmentModalProps {
  open: boolean;
  appointmentTypes: IAppointmentType[];
  patients: IPatient[];
  employees: IEmployee[];
  initialValues: Partial<IAppointment>;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IAppointment>) => void;
}

const EditAppointmentModal: React.FC<IEditAppointmentModalProps> = ({
  open,
  patients,
  employees,
  initialValues,
  loading,
  appointmentTypes,
  onClose,
  onSubmit,
}) => {
  if (!initialValues || !appointmentTypes || !patients) return null;
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const { startDate } = initialValues;

  const dialogTitle = useMemo(
    () =>
      `${t("appointments.edit_modal.title")} ${
        startDate
          ? `${t("appointments.edit_modal.appointment_on", {
              date: formatDate(parseISO(startDate), "EEEE dd.MM.yyyy"),
            })}`
          : ""
      }`,
    [startDate, t]
  );

  const patientsData = useMemo(
    () =>
      patients.map((patient) => ({
        label: `${patient?.firstname} ${patient?.lastname}`,
        additionalLabel: patient.assignedEmployees,
        id: patient?._id,
      })),
    [patients]
  );

  const employeesData = useMemo(
    () =>
      employees.map((employee) => ({
        id: employee._id,
        firstname: employee.firstname,
        lastname: employee.lastname,
        email: employee.email,
      })),
    [employees]
  );

  const formValues = useMemo(() => {
    const type = appointmentTypes.find((type) => type._id === initialValues.typeId);
    const pricingOption = type?.pricing.find((p) => p._id === initialValues.pricingId);
    return {
      ...initialValues,
      typeId: initialValues.typeId || null,
      pricingId: pricingOption?._id || null,
      patientId: initialValues.patientId ? initialValues.patientId._id : null,
      assignedEmployees: initialValues.assignedEmployees?.map((employee) => employee._id) || [],
      leadEmployee: initialValues.leadEmployee?._id || "",
    };
  }, [initialValues, appointmentTypes]);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={addEditAppointmentValidationSchema}
      onSubmit={(values) => {
        if (!values.patientId) {
          const isLeadEmployee = employees.some((employee) => employee._id === values.leadEmployee && employee.accountType === RBACRoles.EMPLOYEE);

          if (isLeadEmployee) {
            values.assignedEmployees = [values.leadEmployee];
          } else if (userDetails.accountType === RBACRoles.EMPLOYEE) {
            values.assignedEmployees = [userDetails._id];
          }
        }
        // @ts-expect-error
        onSubmit(values);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => {
        return (
          <CustomDialog open={open} onClose={onClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>{t("appointments.edit_modal.content_text")}</DialogContentText>
                <Grid container sx={{ my: 1 }} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={touched.typeId && !!errors.typeId}>
                      <InputLabel>{t("appointments.add_modal.type")}</InputLabel>
                      <Select
                        name="typeId"
                        value={values.typeId}
                        onChange={(event) => {
                          const selectedTypeId = event.target.value;
                          const selectedType = appointmentTypes.find((type) => type._id === selectedTypeId);
                          setFieldValue("typeId", selectedTypeId);
                          const defaultPricing = selectedType?.pricing[0]?._id || "";
                          setFieldValue("pricingId", defaultPricing);
                        }}
                      >
                        {appointmentTypes.map((type) => (
                          <MenuItem value={type._id} key={type._id}>
                            <Stack direction="row" spacing={1}>
                              <Box>{type.name}</Box>
                              <SeverityPill color={type.isArchive ? "warning" : "success"}>
                                {type.isArchive ? t("appointments.add_modal.is_archived") : t("appointments.add_modal.is_not_archived")}
                              </SeverityPill>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{touched.typeId && errors.typeId ? t(errors.typeId.toString()) : ""}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {values.typeId && (
                    <Grid item xs={12}>
                      <FormControl fullWidth error={touched.pricingId && !!errors.pricingId}>
                        <InputLabel>{t("appointments.add_modal.pricing")}</InputLabel>
                        <Select
                          name="pricingId"
                          value={values.pricingId}
                          onChange={(event) => setFieldValue("pricingId", event.target.value)}
                          displayEmpty
                        >
                          {appointmentTypes
                            .find((type) => type._id === values.typeId)
                            ?.pricing.map((price) => (
                              <MenuItem key={price._id} value={price._id}>
                                <Stack direction="row" spacing={1}>
                                  <Typography variant="subtitle2">{formatDuration(price.duration)}</Typography>
                                  <SeverityPill color="info">{formatCost(price.cost)}</SeverityPill>
                                </Stack>
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{touched.pricingId && errors.pricingId ? t(errors.pricingId.toString()) : ""}</FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field name="description">
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          rows={2}
                          fullWidth
                          multiline
                          label={t("appointments.edit_modal.description")}
                          helperText={
                            touched.description && errors.description
                              ? t(errors.description.toString())
                              : t("appointments.edit_modal.description_helper_text")
                          }
                          error={touched.description && !!errors.description}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="startDate">
                      {({ field }: FieldProps) => (
                        <DateTimePicker
                          {...field}
                          label={t("appointments.edit_modal.start_date")}
                          ampm={false}
                          minTime={new Date(0, 0, 0, 6, 0)}
                          maxTime={new Date(0, 0, 0, 23, 59)}
                          value={new Date(values.startDate)}
                          onChange={(value) => setFieldValue("startDate", value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              helperText:
                                touched.startDate && errors.startDate
                                  ? t(errors.startDate.toString())
                                  : t("appointments.edit_modal.start_date_helper_text"),
                              error: touched.startDate && !!errors.startDate,
                            },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="endDate">
                      {({ field }: FieldProps) => (
                        <DateTimePicker
                          {...field}
                          label={t("appointments.edit_modal.end_date")}
                          ampm={false}
                          minTime={new Date(0, 0, 0, 6, 0)}
                          maxTime={new Date(0, 0, 0, 23, 59)}
                          value={new Date(values.endDate)}
                          onChange={(value) => setFieldValue("endDate", value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              helperText:
                                touched.endDate && errors.endDate ? t(errors.endDate.toString()) : t("appointments.edit_modal.end_date_helper_text"),
                              error: touched.endDate && !!errors.endDate,
                            },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={touched.patientId && !!errors.patientId}>
                      <InputLabel>{t("roles.patient")}</InputLabel>
                      <Select
                        name="patientId"
                        value={values.patientId}
                        onChange={(event) => {
                          const selectedPatientId = event.target.value;
                          setFieldValue("patientId", selectedPatientId);
                          const selectedPatient = patients.find((patient) => patient._id === selectedPatientId);
                          const assignedEmployeeIds = selectedPatient?.assignedEmployees?.map((employee) => employee._id) || [];
                          setFieldValue("assignedEmployees", assignedEmployeeIds);
                        }}
                      >
                        <MenuItem value={null}>
                          <Typography color="text.secondary">{t("appointments.add_modal.no_patient_selected")}</Typography>
                        </MenuItem>
                        {patientsData.map((option) => (
                          <MenuItem value={option.id} key={option.id}>
                            <ListItem sx={{ p: 0 }}>
                              <ListItemText
                                primary={<Typography variant="subtitle1">{option.label}</Typography>}
                                secondary={
                                  option.additionalLabel.length
                                    ? `${t("appointments.add_modal.therapists")} ${option.additionalLabel
                                        .map((employee) => `${employee.firstname} ${employee.lastname}`)
                                        .join(", ")}`
                                    : ""
                                }
                              />
                            </ListItem>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.patientId && errors.patientId
                          ? t(errors.patientId.toString())
                          : t("appointments.edit_modal.choose_patient_helper_text")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {userDetails.accountType !== RBACRoles.EMPLOYEE && (
                    <Grid item xs={12}>
                      <Field name="leadEmployee">
                        {({ form }: FieldProps) => {
                          let assignedEmployeesData = values.patientId
                            ? values.assignedEmployees.map((employeeId: string) => {
                                const employee = patients
                                  .flatMap((patient) => patient.assignedEmployees)
                                  .find((employee) => employee._id === employeeId);
                                return {
                                  id: employee?._id,
                                  firstname: employee?.firstname,
                                  lastname: employee?.lastname,
                                  email: employee?.email,
                                };
                              })
                            : employeesData;

                          if (userDetails.accountType === RBACRoles.OWNER) {
                            const isUserAlreadyInList = assignedEmployeesData.some((employee) => employee?.id === userDetails._id);
                            if (!isUserAlreadyInList) {
                              assignedEmployeesData = [
                                ...assignedEmployeesData,
                                {
                                  id: userDetails._id,
                                  firstname: userDetails.firstname,
                                  lastname: userDetails.lastname,
                                  email: userDetails.email,
                                  // @ts-expect-error: no isCurrentUser type in IEmployee
                                  isCurrentUser: true,
                                },
                              ];
                            }
                          }

                          return (
                            <FormControl fullWidth error={touched.leadEmployee && !!errors.leadEmployee}>
                              <InputLabel>{t("appointments.edit_modal.choose_lead_employee")}</InputLabel>
                              <Select
                                name="leadEmployee"
                                value={values.leadEmployee}
                                onChange={(event) => {
                                  const selectedLeadEmployeeId = event.target.value;
                                  form.setFieldValue("leadEmployee", selectedLeadEmployeeId);
                                }}
                              >
                                <MenuItem value="">
                                  <Typography color="text.secondary">{t("appointments.edit_modal.no_lead_employee_selected")}</Typography>
                                </MenuItem>
                                {assignedEmployeesData.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <UsernameAvatar
                                      avatar
                                      firstname={option.firstname}
                                      lastname={option.lastname}
                                      email={option.email}
                                      additionalInfo={
                                        // @ts-expect-error: no isCurrentUser type in IEmployee
                                        option.isCurrentUser && <SeverityPill color="primary">{t("reservations.scheduler.my_account")}</SeverityPill>
                                      }
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {touched.leadEmployee && errors.leadEmployee
                                  ? t(errors.leadEmployee.toString())
                                  : t("appointments.edit_modal.choose_lead_employee_helper_text")}
                              </FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined" color="secondary">
                  {t("cancel")}
                </Button>
                <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                  {t("submit")}
                </LoadingButton>
              </DialogActions>
            </Form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
};

export default EditAppointmentModal;
