import CustomDialog from "@components/modals/CustomDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface IDeleteClinicModalProps {
  open: boolean;
  loading: boolean;
  clinicName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteClinicModal: React.FC<IDeleteClinicModalProps> = ({ open, loading, clinicName, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    clinicName: "",
  };

  const validationSchema = Yup.object({
    clinicName: Yup.string()
      .required(t("my_clinic.delete_modal.validation.name_required"))
      .oneOf([clinicName], t("my_clinic.delete_modal.validation.name_must_match")),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, values }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("my_clinic.delete_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("my_clinic.delete_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="clinicName">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("my_clinic.delete_modal.clinic_name")}
                        helperText={
                          touched.clinicName && errors.clinicName
                            ? t(errors.clinicName.toString())
                            : t("my_clinic.delete_modal.clinic_name_helper_text")
                        }
                        error={touched.clinicName && !!errors.clinicName}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" disabled={values.clinicName !== clinicName} color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default DeleteClinicModal;
