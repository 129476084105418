import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { getColorForReservationStatus, getReservationStatusText } from "@utils/utils";
import { IAppointment } from "appointment.types";
import { IAppointmentType } from "appointmentTypes.types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IReservationsTableProps {
  appointments: IAppointment[];
  appointmentTypes: IAppointmentType[];
  handleOpenToggleReservationStatusModal: (reservation: IAppointment) => void;
}

const ReservationsTable: React.FC<IReservationsTableProps> = ({ appointments, appointmentTypes, handleOpenToggleReservationStatusModal }) => {
  const { t } = useTranslation();

  const getAppointmentTypeName = useMemo(() => {
    return (typeId: string) => {
      return appointmentTypes.find((type) => type._id === typeId)?.name || "";
    };
  }, [appointmentTypes, t]);

  const parseDescription = (description: string) => {
    const nameMatch = description.match(/Imię i nazwisko:\s*([^\n]*?)\s*Telefon:/);
    const phoneMatch = description.match(/Telefon:\s*([^\n]*?)\s*Email:/);
    const emailMatch = description.match(/Email:\s*(.+)/);

    const name = nameMatch && nameMatch[1].trim();
    const phone = phoneMatch && phoneMatch[1].trim();
    const email = emailMatch && emailMatch[1].trim();

    return { name, phone, email };
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{t("reservations.calendar.table.description")}</TableCell>
            <TableCell>{t("reservations.calendar.table.date")}</TableCell>
            <TableCell>{t("reservations.calendar.table.type")}</TableCell>
            <TableCell>{t("reservations.calendar.table.specialist")}</TableCell>
            <TableCell>{t("reservations.calendar.table.status")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments.length > 0 ? (
            appointments.map((appointment, index) => {
              const { name, phone, email } = parseDescription(appointment.description);

              return (
                <TableRow key={appointment._id}>
                  <TableCell>
                    <Typography variant="caption" color="text.secondary">
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "300px" }}>
                    <Typography variant="subtitle2">
                      {t("reservations.calendar.table.name")}: {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("reservations.calendar.table.phoneNumber")}: {phone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("reservations.calendar.table.email")}: {email}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Stack>
                      <Typography variant="subtitle2">{formatDate(new Date(appointment.startDate), "dd MMM yyyy")}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formatDate(new Date(appointment.startDate), "HH:mm")}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">{getAppointmentTypeName(appointment.typeId)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {appointment.assignedEmployees.map((emp) => (
                      <Stack key={emp._id}>
                        <Typography variant="subtitle2">{`${emp.firstname} ${emp.lastname}`}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {emp.email}
                        </Typography>
                      </Stack>
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    <SeverityPill color={getColorForReservationStatus(appointment.reservationStatus)}>
                      {getReservationStatusText(appointment.reservationStatus)}
                    </SeverityPill>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => handleOpenToggleReservationStatusModal(appointment)}>
                      <CoPresentRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="body2" color="text.secondary">
                  {t("reservations.calendar.table.no_reservations")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReservationsTable;
