import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldProps, Form, Formik } from "formik";
import { IHomework } from "patients-types";
import React from "react";
import { addEditHomeworkValidationSchema } from "src/validations/homeworkValidation";
import CustomDialog from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

interface IEditPatientHomeworkModalProps {
  open: boolean;
  initialValues: Partial<IHomework>;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IHomework>) => void;
}

const EditPatientHomework: React.FC<IEditPatientHomeworkModalProps> = ({ open, initialValues, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  if (!initialValues) return null;

  return (
    <Formik initialValues={initialValues} validationSchema={addEditHomeworkValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
      {({ errors, touched, setFieldValue, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("homework.edit_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("homework.edit_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="title">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("homework.edit_modal.homework_title")}
                        helperText={touched.title && errors.title ? t(errors.title.toString()) : t("homework.edit_modal.homework_title_helper_text")}
                        error={touched.title && !!errors.title}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="description">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={4}
                        fullWidth
                        multiline
                        label={t("homework.edit_modal.description")}
                        helperText={
                          touched.description && errors.description
                            ? t(errors.description.toString())
                            : t("homework.edit_modal.description_helper_text")
                        }
                        error={touched.description && !!errors.description}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="dueDate">
                    {({ field }: FieldProps) => (
                      <DatePicker
                        label={t("homework.edit_modal.due_date")}
                        value={new Date(field.value) || null}
                        onChange={(value) => {
                          setFieldValue("dueDate", value);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: touched.dueDate && errors.dueDate ? t(errors.dueDate) : t("homework.edit_modal.due_date_helper_text"),
                            error: touched.dueDate && !!errors.dueDate,
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default EditPatientHomework;
