import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IAvailabilityDay, IAvailabilityRequest } from "reservation-types";

export enum TagTypes {
  RESERVATIONS = "RESERVATIONS",
}

export const reservationApi = createApi({
  reducerPath: "reservationApi",
  baseQuery: axiosBaseQuery({
    baseUrl: apiUrl,
    isTokenRequired: true,
    includeClinicId: true,
  }),
  tagTypes: [TagTypes.RESERVATIONS],
  endpoints: (builder) => ({
    sendCalendarIntegrationRequest: builder.mutation<void, unknown>({
      query: () => ({
        url: `calendar-integration`,
        method: "POST",
      }),
      invalidatesTags: [TagTypes.RESERVATIONS],
    }),

    getAvailability: builder.query<IAvailabilityDay[], { userId: string }>({
      query: ({ userId }) => ({
        url: `user/${userId}/availability`,
        method: "GET",
      }),
      providesTags: [TagTypes.RESERVATIONS],
    }),

    createAvailability: builder.mutation<void, { userId: string; data: IAvailabilityRequest }>({
      query: ({ userId, data }) => ({
        url: `user/${userId}/availability`,
        method: "POST",
        data,
      }),
      invalidatesTags: [TagTypes.RESERVATIONS],
    }),

    updateAvailability: builder.mutation<void, { userId: string; data: IAvailabilityRequest }>({
      query: ({ userId, data }) => ({
        url: `user/${userId}/availability`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [TagTypes.RESERVATIONS],
    }),

    deleteAvailability: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `user/${userId}/availability`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypes.RESERVATIONS],
    }),
  }),
});

export const {
  useSendCalendarIntegrationRequestMutation,
  useGetAvailabilityQuery,
  useCreateAvailabilityMutation,
  useUpdateAvailabilityMutation,
  useDeleteAvailabilityMutation,
} = reservationApi;

export const reservationApiReducer = {
  [reservationApi.reducerPath]: reservationApi.reducer,
};
