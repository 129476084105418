import ReportsFilter from "@components/ReportsFilter/ReportsFilter";
import { SnackbarProps } from "@hoc/AlertPopover";
import { Grid, Skeleton } from "@mui/material";
import { AppointmentStatus } from "@utils/enums";
import { AppointmentReportsData, PatientAppointment } from "reports-types";
import AppointmentTable from "@components/PatientReportsSummary/fragments/AppointmentTable";
import PresenceChart from "@components/PatientReportsSummary/fragments/PresenceChart";

interface IEmployeeReportsSummaryProps extends SnackbarProps {
  isLoading?: boolean;
  selectedDate: Date;
  selectedStatuses: AppointmentStatus[];
  presenceReports: AppointmentReportsData[];
  appointmentsReports: PatientAppointment[];
  handleMonthChange: (newDate: Date | null) => void;
  setSelectedStatuses: (statuses: AppointmentStatus[]) => void;
}

const EmployeeReportsSummary: React.FC<IEmployeeReportsSummaryProps> = ({
  isLoading = false,
  selectedDate,
  selectedStatuses,
  presenceReports,
  appointmentsReports,
  handleMonthChange,
  setSelectedStatuses,
  snackbarShowMessage,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReportsFilter
          selectedDate={selectedDate}
          selectedStatuses={selectedStatuses}
          handleMonthChange={handleMonthChange}
          setSelectedStatuses={setSelectedStatuses}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        {isLoading ? <Skeleton variant="rounded" width="100%" height={300} /> : <AppointmentTable data={appointmentsReports} />}
      </Grid>
      <Grid item xs={12} sm={4}>
        {isLoading ? <Skeleton variant="rounded" width="100%" height={300} /> : <PresenceChart data={presenceReports} />}
      </Grid>
    </Grid>
  );
};

export default EmployeeReportsSummary;
