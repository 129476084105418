import { ReactNode } from "react";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ClinicProvider } from "src/context/ClinicProvider";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const AuthenticatedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);

  return usersTokens ? (
    <ClinicProvider>
      {children}
      <Analytics />
      <SpeedInsights />
    </ClinicProvider>
  ) : (
    <Navigate to={AppRoutes.Login} />
  );
};
export default AuthenticatedRoute;
