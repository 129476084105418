import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Button, Container, Typography } from "@mui/material";
import { useGetEmployeeAppointmentsReportsQuery, useGetEmployeePresenceReportsQuery } from "@store/services/reportsService";
import { AppRoutes, AppointmentStatus } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { getMonth, getYear } from "date-fns";
import React, { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import EmployeeReportsSummary from "./fragments/EmployeeReportsSummary";
import { IEmployeeAppointmentReports } from "reports-types";

interface IEmployeeReportsProps extends SnackbarProps {}

const EmployeeReports: React.FC<IEmployeeReportsProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const { employeeId } = useParams<{ employeeId: string }>();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)) {
    navigate(AppRoutes.Dashboard);
    return null;
  }

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);

  const { presenceReports, isFetching: isFetchingPresence } = useGetEmployeePresenceReportsQuery(
    {
      leadEmployeeId: employeeId,
      month: getMonth(selectedDate),
      year: getYear(selectedDate),
      appointmentStatus: selectedStatuses,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        presenceReports: data || [],
        isFetching,
      }),
    }
  );

  const { appointmentsReports, isFetching: isFetchingAppointments } = useGetEmployeeAppointmentsReportsQuery(
    {
      leadEmployeeId: employeeId,
      month: getMonth(selectedDate),
      year: getYear(selectedDate),
      appointmentStatus: selectedStatuses,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        appointmentsReports: data || ({} as IEmployeeAppointmentReports),
        isFetching,
      }),
    }
  );

  const isLoading = isFetchingPresence || isFetchingAppointments;

  const handleMonthChange = useCallback((newDate: Date | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  }, []);

  if (!isLoading && (!presenceReports || !appointmentsReports || !appointmentsReports.employeeIncome)) return null;

  return (
    <>
      <Helmet>
        <title>{t("reports.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={`${appointmentsReports.employeeIncome?.firstname || ""} ${appointmentsReports.employeeIncome?.lastname || ""}`}
          gap={3}
          isLoading={isLoading}
          rightContent={
            <Button color="secondary" variant="outlined" onClick={() => navigate(-1)} startIcon={<KeyboardBackspaceRoundedIcon />}>
              {t("go_back")}
            </Button>
          }
          bottomContent={
            <>
              <Typography variant="h6">
                {t("reports.total_income")}: {appointmentsReports.employeeIncome ? formatCost(appointmentsReports.employeeIncome.totalIncome) : ""}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {`${t("reports.period")} ${formatDate(selectedDate, "dd.MM.yyyy")} - ${formatDate(selectedDate, "dd.MM.yyyy")}`}
              </Typography>
            </>
          }
        />
        <EmployeeReportsSummary
          isLoading={isLoading}
          selectedDate={selectedDate}
          selectedStatuses={selectedStatuses}
          presenceReports={presenceReports}
          appointmentsReports={appointmentsReports.patientAppointments || []}
          setSelectedStatuses={setSelectedStatuses}
          handleMonthChange={handleMonthChange}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Container>
    </>
  );
};

export default withSnackbar(EmployeeReports);
