import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { IAppointment } from "appointment.types";
import { parseISO } from "date-fns";
import React, { useMemo, useState } from "react";
import CustomDialog from "../CustomDialog";
import { useTranslation } from "react-i18next";

interface IDeleteAppointmentModalProps {
  open: boolean;
  selectedAppointment?: IAppointment;
  loading: boolean;
  onClose: () => void;
  onSubmit: (deleteSeries: boolean) => void;
}

const DeleteAppointmentModal: React.FC<IDeleteAppointmentModalProps> = ({ selectedAppointment, open, loading, onClose, onSubmit }) => {
  if (!selectedAppointment) return null;
  const { t } = useTranslation();
  const [deleteSeries, setDeleteSeries] = useState(false);

  const dialogTitle = useMemo(
    () => t("appointments.delete_modal.confirmation", { date: formatDate(parseISO(selectedAppointment.startDate), "EEEE dd.MM.yyyy") }),
    [selectedAppointment]
  );

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("appointments.delete_modal.content_text")}</DialogContentText>
        <Box sx={{ my: 2 }}>
          {selectedAppointment.seriesId && (
            <ToggleButtonGroup
              fullWidth
              color="primary"
              value={deleteSeries.toString()}
              exclusive
              onChange={(e, newValue) => setDeleteSeries(newValue === "true")}
            >
              <ToggleButton value="false">{t("appointments.delete_modal.only_this")}</ToggleButton>
              <ToggleButton value="true">{t("appointments.delete_modal.all_in_series")}</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={() => onSubmit(deleteSeries)} variant="contained" color="secondary" autoFocus>
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default DeleteAppointmentModal;
