import { Card, CardContent, Typography, useTheme } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React from "react";

interface StatsCardProps {
  icon: React.ReactElement;
  value: number | string;
  label: string;
  color: "primary" | "secondary" | "error" | "info" | "warning" | "success";
  onClick?: () => void;
}

const StatsCard: React.FC<StatsCardProps> = ({ icon, value, label, color, onClick }) => {
  const theme = useTheme();

  const StyledCard = styled(Card)<{ clickable: boolean }>(({ theme, clickable }) => ({
    borderRadius: 16,
    minWidth: 150,
    backgroundColor: alpha(theme.palette[color].main, 0.12),
    cursor: clickable ? "pointer" : "default",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: clickable ? "scale(1.05)" : "none",
    },
  }));

  const StyledCardContent = styled(CardContent)({
    textAlign: "center",
    "&:last-child": {
      paddingBottom: 32,
    },
  });

  const IconContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  });

  return (
    <StyledCard onClick={onClick} clickable={!!onClick}>
      <StyledCardContent>
        <IconContainer sx={{ color: theme.palette[color].main }}>{icon}</IconContainer>
        <Typography variant="h4" sx={{ color: theme.palette[color].main, marginBottom: -0.5 }}>
          {value}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: theme.palette[color].main }} noWrap>
          {label}
        </Typography>
      </StyledCardContent>
    </StyledCard>
  );
};

export default StatsCard;
