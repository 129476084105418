import { styled, Theme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

const CustomCard = styled(Box)(({ theme, width }: { theme: Theme; width: string }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F2F5FE",
  borderRadius: "1rem",
  padding: "2rem",
  width: width || "90%",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const TextSection = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
  },
}));

const Illustration = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "100%",
  height: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "40%",
    maxHeight: "12.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

interface IInfoCardProps {
  title: string;
  secondTitle: string;
  additionalText: string;
  additionalText2?: string;
  imageUrl: string;
  buttonText?: string;
  width?: string;
  onButtonClick?: () => void;
}

const InfoCard: React.FC<IInfoCardProps> = ({ title, secondTitle, buttonText, onButtonClick, imageUrl, additionalText, additionalText2, width }) => {
  return (
    <CustomCard width={width}>
      <TextSection>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
          {secondTitle}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {additionalText}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {additionalText2}
        </Typography>
        {buttonText && (
          <Button variant="contained" onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </TextSection>
      <Illustration src={imageUrl} alt="Celebration illustration" />
    </CustomCard>
  );
};

export default InfoCard;
