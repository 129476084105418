import { apiUrl } from "@utils/urlsUtils";
import axios from "axios";
import { useCallback, useState } from "react";

type UseUploadFileReturnType = [(files: File[]) => Promise<any>, { isLoading: boolean }];

const useUploadFile = (providedUrl: string): UseUploadFileReturnType => {
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = useCallback(
    async (files: File[]) => {
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      const token = JSON.parse(localStorage.getItem("token-data") || "{}").accessToken;
      const clinicId = localStorage.getItem("clinicId");
      const url = `${apiUrl}clinics/${clinicId}/${providedUrl}`;

      const apiKey = import.meta.env.MODE !== "development" ? import.meta.env.VITE_API_KEY : import.meta.env.VITE_API_KEY_DEV;

      try {
        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": apiKey,
          },
        });
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    [providedUrl]
  );

  return [uploadFile, { isLoading }];
};

export default useUploadFile;
