import React, { useState, useCallback, useMemo } from "react";
import { Grid, Skeleton } from "@mui/material";
import { getMonth, getYear } from "date-fns";
import { withSnackbar, SnackbarProps } from "@hoc/AlertPopover";
import { useGetReportsQuery } from "@store/services/reportsService";
import { useCalculateExpensesQuery } from "@store/services/expensesService";
import { AppointmentStatus } from "@utils/enums";
import { IReport } from "reports-types";
import ReportsFilter from "@components/ReportsFilter/ReportsFilter";
import AppointmentTypeIncomeBarChart from "./fragments/AppointmentTypeIncomeBarChart";
import AppointmentTypeIncomePieChart from "./fragments/AppointmentTypeIncomePieChart";
import ReportsStats from "./fragments/ReportsStats";

const GeneralCharts: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);

  const handleMonthChange = useCallback((newDate: Date | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  }, []);

  const {
    data: reportsData,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useGetReportsQuery({
    month: getMonth(selectedDate),
    year: getYear(selectedDate),
    appointmentStatus: selectedStatuses,
  });
  const reports = reportsData || ({} as IReport);

  const {
    data: expensesData,
    isLoading: isLoadingExpenses,
    isFetching: isFetchingExpenses,
  } = useCalculateExpensesQuery({ month: getMonth(selectedDate), year: getYear(selectedDate) });
  const expensesStats = expensesData || [];

  const totalExpense = useMemo(() => expensesStats.find((item) => item.type === "total"), [expensesStats]);

  const isLoading = isLoadingReports || isLoadingExpenses || isFetchingReports || isFetchingExpenses;

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" width="100%" height={100} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rounded" width="100%" height={50} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Skeleton variant="rounded" width="100%" height={400} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rounded" width="100%" height={400} />
        </Grid>
      </Grid>
    );
  }

  if (!reports || !expensesStats) return null;

  return (
    <Grid container spacing={3}>
      <ReportsStats totalIncome={reports.totalIncome} totalExpenseValue={totalExpense?.value} />
      <Grid item xs={12}>
        <ReportsFilter
          fields={{ date: true, status: true }}
          selectedDate={selectedDate}
          handleMonthChange={handleMonthChange}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <AppointmentTypeIncomeBarChart reports={reports} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AppointmentTypeIncomePieChart reports={reports} />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(GeneralCharts);
