import { RBACRoles } from "@auth/rbac-rules";
import Iconify from "@components/Iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ILogin } from "auth-types";
import { FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ISignUpForm {
  formik: FormikProps<ILogin & { confirmPassword: string }>;
  isLoading: boolean;
}

const SignUpForm: React.FC<ISignUpForm> = ({ formik, isLoading }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(RBACRoles.CLIENT);

  const handleRoleChange = (event: React.MouseEvent<HTMLElement>, newRole: RBACRoles) => {
    if (newRole === RBACRoles.OWNER) {
      window.location.href = "https://www.logopedaplus.com/rejestracja";
    } else {
      setSelectedRole(newRole);
    }
  };

  const isSubmitDisabled =
    isLoading || !formik.isValid || !formik.values.email || !formik.values.password || formik.values.password !== formik.values.confirmPassword;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <ToggleButtonGroup color="primary" exclusive fullWidth value={selectedRole} onChange={handleRoleChange}>
            <ToggleButton value={RBACRoles.CLIENT}>{t("signup.form.client_role_label")}</ToggleButton>
            <ToggleButton value={RBACRoles.OWNER}>{t("signup.form.owner_role_label")}</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            id="email"
            name="email"
            label={t("signup.form.email_label")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? t(formik.errors.email) : ""}
          />
          <TextField
            id="password"
            name="password"
            label={t("signup.form.password_label")}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password ? t(formik.errors.password) : t("signup.form.password_helper_text")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label={t("signup.form.confirm_password_label")}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword ? t(formik.errors.confirmPassword) : t("signup.form.confirm_password_helper_text")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton
          loading={isLoading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 1 }}
          color="secondary"
          disabled={isSubmitDisabled}
        >
          {t("signup.form.register_button")}
        </LoadingButton>

        <Typography variant="caption" color="text.secondary">
          {t("signup.clauze.read")}{" "}
          <a
            href="https://www.logopedaplus.com/assets/docs/regulamin.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "black",
              textDecoration: "underline",
            }}
          >
            {t("signup.clauze.terms")}
          </a>{" "}
          {t("signup.clauze.and")}{" "}
          <a
            href="https://www.logopedaplus.com/assets/docs/polityka_prywatnosci.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "black",
              textDecoration: "underline",
            }}
          >
            {t("signup.clauze.privacy_policy")}.
          </a>
        </Typography>
      </form>
    </>
  );
};

export default SignUpForm;
