import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useAddInvoiceDataMutation,
  useDeleteInvoiceDataMutation,
  useEditInvoiceDataMutation,
  useGetAllInvoiceDataQuery,
} from "@store/services/InvoiceDataService";
import { formatBankAccountNumber } from "@utils/typographyUtils";
import { IInvoiceData } from "invoiceData-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AddInvoiceDataModal from "../modals/InvoiceData/AddInvoiceDataModal";
import EditInvoiceDataModal from "../modals/InvoiceData/EditInvoiceDataModal";

const InvoiceDataSection: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<Partial<IInvoiceData> | null>(null);

  const [isAddInvoiceDataModalOpen, toggleAddInvoiceDataModal] = useModal();
  const [isEditInvoiceDataModalOpen, toggleEditInvoiceDataModal] = useModal();
  const [isDeleteInvoiceDataModalOpen, toggleDeleteInvoiceDeleteModal] = useModal();

  const { data: invoiceData } = useGetAllInvoiceDataQuery({ invoiceDataId: "" });
  const [addInvoiceData, { isLoading: isAddInvoiceDataLoading }] = useAddInvoiceDataMutation();
  const [editInvoiceData, { isLoading: isEditInvoiceDataLoading }] = useEditInvoiceDataMutation();
  const [deleteInvoiceData, { isLoading: isDeleteInvoiceDataLoading }] = useDeleteInvoiceDataMutation();

  const handleAddInvoiceData = useCallback(
    (invoiceData: Partial<IInvoiceData>) => {
      addInvoiceData(invoiceData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.add.error"), "error");
        })
        .finally(() => {
          toggleAddInvoiceDataModal();
        });
    },
    [addInvoiceData, snackbarShowMessage, toggleAddInvoiceDataModal]
  );

  const handleEditInvoiceData = useCallback(
    (invoiceData: Partial<IInvoiceData>) => {
      if (selectedInvoiceId) {
        editInvoiceData({ invoiceDataId: selectedInvoiceId, updatedInvoiceData: invoiceData })
          .unwrap()
          .then(() => {
            snackbarShowMessage(t("invoice_data.snackbar_messages.edit.success"), "success");
          })
          .catch(() => {
            snackbarShowMessage(t("invoice_data.snackbar_messages.edit.error"), "error");
          })
          .finally(() => {
            toggleEditInvoiceDataModal();
          });
      }
    },
    [selectedInvoiceId, editInvoiceData, snackbarShowMessage, toggleEditInvoiceDataModal]
  );

  const handleDeleteInvoiceData = useCallback(() => {
    if (selectedInvoiceId) {
      deleteInvoiceData({ invoiceDataId: selectedInvoiceId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.delete.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.delete.error"), "error");
        })
        .finally(() => {
          toggleDeleteInvoiceDeleteModal();
        });
    }
  }, [selectedInvoiceId, deleteInvoiceData, toggleDeleteInvoiceDeleteModal, snackbarShowMessage, t]);

  const handleOpenEditModal = useCallback(
    (invoice: IInvoiceData) => {
      setSelectedInvoiceId(invoice._id);
      setSelectedInvoiceData(invoice);
      toggleEditInvoiceDataModal();
    },
    [toggleEditInvoiceDataModal]
  );

  const handleOpenDeleteModal = useCallback(
    (invoiceId: string) => {
      setSelectedInvoiceId(invoiceId);
      toggleDeleteInvoiceDeleteModal();
    },
    [toggleDeleteInvoiceDeleteModal]
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={t("invoice_data.section.title")}
          subheader={t("invoice_data.section.subheader")}
          action={
            <RBAC
              action={RBACActions.ACTION_ADD_INVOICE_DATA}
              yes={() => (
                <IconWithTooltip
                  label={t("invoice_data.section.button.add")}
                  icon={<AddRoundedIcon />}
                  onClick={toggleAddInvoiceDataModal}
                  id="invoice-data-add-button"
                />
              )}
            />
          }
        />
        <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
        {invoiceData && invoiceData.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2" sx={{ ml: 2 }}>
                      {t("invoice_data.table.bank_name")}
                    </Typography>
                  </TableCell>
                  <TableCell>{t("invoice_data.table.address")}</TableCell>
                  <TableCell>{t("invoice_data.table.nip")}</TableCell>
                  <TableCell>{t("invoice_data.table.regon")}</TableCell>
                  <TableCell>{t("invoice_data.table.account_number")}</TableCell>
                  <TableCell>{t("invoice_data.table.payment_day")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.map((invoice) => (
                  <TableRow key={invoice._id}>
                    <TableCell>
                      <Typography variant="subtitle2" sx={{ ml: 2 }}>
                        {invoice.bankName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {invoice.address}, {invoice.city}, {invoice.postalCode}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="text.secondary">
                        {invoice.NIP}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="text.secondary">
                        {invoice.REGON}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="text.secondary">
                        {formatBankAccountNumber(invoice.bankAccountNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="text.secondary">
                        {invoice.paymentDayOfMonth} {t("invoice_data.section.dayOfMonth")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row">
                        <RBAC
                          action={RBACActions.ACTION_EDIT_INVOICE_DATA}
                          yes={() => (
                            <IconButton onClick={() => handleOpenEditModal(invoice)} aria-label="edit">
                              <EditRoundedIcon />
                            </IconButton>
                          )}
                        />
                        <RBAC
                          action={RBACActions.ACTION_DELETE_INVOICE_DATA}
                          yes={() => (
                            <IconButton onClick={() => handleOpenDeleteModal(invoice._id)} aria-label="delete" color="error">
                              <DeleteRoundedIcon />
                            </IconButton>
                          )}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="text.secondary" align="center" sx={{ my: 3 }}>
            {t("invoice_data.section.no_data")}
          </Typography>
        )}
      </Card>
      <AddInvoiceDataModal
        open={isAddInvoiceDataModalOpen}
        onClose={toggleAddInvoiceDataModal}
        onSubmit={handleAddInvoiceData}
        loading={isAddInvoiceDataLoading}
      />
      <EditInvoiceDataModal
        open={isEditInvoiceDataModalOpen}
        onClose={toggleEditInvoiceDataModal}
        onSubmit={handleEditInvoiceData}
        loading={isEditInvoiceDataLoading}
        initialValues={selectedInvoiceData || {}}
      />
      <ConfirmationModal
        open={isDeleteInvoiceDataModalOpen}
        onClose={toggleDeleteInvoiceDeleteModal}
        onConfirm={handleDeleteInvoiceData}
        title={t("invoice_data.delete_modal.title")}
        content={t("invoice_data.delete_modal.content_text")}
        loading={isDeleteInvoiceDataLoading}
      />
    </>
  );
};

export default InvoiceDataSection;
