import { ClinicContext } from "@context/ClinicProvider";
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { logOut } from "@store/features/authSlice";
import { removeClinicId } from "@store/features/clinicSlice";
import { resetApiState } from "@store/utils/resetApiState";
import { palette } from "@theme/palette";
import { AppRoutes } from "@utils/enums";
import { formatRoleString } from "@utils/typographyUtils";
import { SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IUserDetails } from "user-types";

interface IAccountPopover {
  userDetails: IUserDetails;
}

const AccountPopover: React.FC<IAccountPopover> = ({ userDetails }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const { currentClinic } = useContext(ClinicContext);

  const handleOpen = (event: SyntheticEvent) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = async () => {
    await dispatch(logOut());
    await resetApiState(dispatch);
    await navigate(AppRoutes.Login);
    await dispatch(removeClinicId());
  };

  const MENU_OPTIONS = [
    {
      label: t("menu.dashboard"),
      path: AppRoutes.Dashboard,
    },
    {
      label: t("menu.my_clinic"),
      path: AppRoutes.MyClinic,
    },
    {
      label: t("menu.settings"),
      path: AppRoutes.Settings,
    },
  ];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={currentClinic?.files[0]?.url} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${(userDetails?.firstname && `${userDetails.firstname} ${userDetails.lastname}`) || userDetails?.email}`}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {t(formatRoleString(userDetails?.accountType))}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => navigate(option.path)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleLogout}
          sx={{
            m: 1,
            backgroundColor: alpha(palette.error.light, 0.2),
            color: palette.error.dark,
            "&:hover": {
              backgroundColor: alpha(palette.error.light, 0.45),
              color: palette.error.darker,
              transition: "background-color 0.3s",
            },
          }}
        >
          {t("logout")}
        </MenuItem>
      </Popover>
    </>
  );
};
export default AccountPopover;
