import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import { Avatar, Box, Paper, Stack, styled, Typography, useTheme } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { IExpense, IGetExpensesResponse } from "expenses.types";
import React from "react";
import { useTranslation } from "react-i18next";

interface ExpenseListProps {
  expenses: IGetExpensesResponse;
  handleDeleteModalOpen: (expense: IExpense) => void;
  handleOpenEditModal: (expense: IExpense) => void;
}

interface ExpenseBoxProps {
  index: number;
  length: number;
}

const StyledExpenseBox = styled(Paper)<ExpenseBoxProps>`
  padding: 16px;
  border-top-left-radius: ${({ index }) => (index === 0 ? 16 : 0)}px;
  border-top-right-radius: ${({ index }) => (index === 0 ? 16 : 0)}px;
  border-bottom-left-radius: ${({ index, length }) => (index === length - 1 ? 16 : 0)}px;
  border-bottom-right-radius: ${({ index, length }) => (index === length - 1 ? 16 : 0)}px;
  overflow-x: auto; /* Ensure horizontal scrolling if content is too wide */
`;

const ExpenseList: React.FC<ExpenseListProps> = ({ expenses, handleDeleteModalOpen, handleOpenEditModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderExpense = (expense: IExpense, index: number, length: number) => (
    <StyledExpenseBox key={expense._id} index={index} length={length}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ minWidth: "600px", flexWrap: "nowrap" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ minWidth: "300px" }}>
            <Avatar sx={{ bgcolor: expense.isOneTimePayment ? theme.palette.warning.main : theme.palette.success.main }}>
              <SavingsRoundedIcon />
            </Avatar>
            <Box sx={{ maxWidth: "200px" }}>
              <Typography variant="subtitle2" noWrap sx={{ textTransform: "capitalize" }}>
                {expense.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap sx={{ textTransform: "capitalize" }}>
                {expense.description}
              </Typography>
            </Box>
          </Stack>
          <Typography variant="subtitle2" noWrap sx={{ width: "100px" }}>
            {formatCost(expense.value)}
          </Typography>
          <Box sx={{ minWidth: "150px" }}>
            <Typography variant="subtitle2" noWrap>
              {t("expenses.created_at")}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {formatDate(new Date(expense.createdAt), "d MMMM yyyy")}
            </Typography>
          </Box>
          <Box sx={{ minWidth: "150px" }}>
            <Typography variant="subtitle2" noWrap>
              {t("expenses.end_date")}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {expense.isOneTimePayment ? t("expenses.no_end_date") : formatDate(new Date(expense.endDate), "d MMMM yyyy")}
            </Typography>
          </Box>
          <Box sx={{ minWidth: "150px" }}>
            <SeverityPill color={expense.isOneTimePayment ? "warning" : "success"}>
              {expense.isOneTimePayment ? t("expenses.one_time_expense") : t("expenses.fixed_expense")}
            </SeverityPill>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ flexShrink: 0 }}>
          <IconWithTooltip label={t("expenses.edit.button")} icon={<EditRoundedIcon />} onClick={() => handleOpenEditModal(expense)} />
          <IconWithTooltip
            label={t("expenses.delete.button")}
            icon={<DeleteRoundedIcon />}
            onClick={() => handleDeleteModalOpen(expense)}
            color="error"
          />
        </Stack>
      </Stack>
    </StyledExpenseBox>
  );

  return (
    <Box sx={{ overflowY: "auto", maxHeight: "80vh" }}>
      {expenses.fixed.length === 0 && expenses.oneTimeCost.length === 0 ? (
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {t("expenses.no_expenses")}
        </Typography>
      ) : (
        <>
          {!!expenses.fixed.length && (
            <>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                {`${t("expenses.fixed")} (${expenses.fixed.length})`}
              </Typography>
              {expenses.fixed.map((expense, index) => renderExpense(expense, index, expenses.fixed.length))}
            </>
          )}
          {!!expenses.oneTimeCost.length && (
            <>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ mt: 10 }}>
                {`${t("expenses.one_time_cost")} (${expenses.oneTimeCost.length})`}
              </Typography>
              {expenses.oneTimeCost.map((expense, index) => renderExpense(expense, index, expenses.oneTimeCost.length))}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ExpenseList;
