import { alpha, Stack, Typography } from "@mui/material";
import { palette } from "@theme/palette";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

type TimelineItemProps = {
  color: "primary" | "success" | "secondary" | "info" | "error";
  title: string | number;
  subtitle: string;
  lastItem?: boolean;
  id?: string;
};

export const TimelineItem: React.FC<TimelineItemProps> = ({ color, title, subtitle, id }) => {
  const colorMap = {
    primary: alpha(palette.primary.main, 0.5),
    success: alpha(palette.success.main, 0.5),
    secondary: alpha(palette.secondary.main, 0.5),
    info: alpha(palette.info.main, 0.5),
    error: alpha(palette.error.main, 0.5),
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" id={id}>
      <FiberManualRecordIcon sx={{ color: colorMap[color] }} />
      <Stack>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Stack>
    </Stack>
  );
};
