import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import SortOrderButton from "@components/SortOrderButton/SortOrderButton";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { palette } from "@theme/palette";
import { AppointmentStatus, SortBy, SortOrder } from "@utils/enums";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const StyledMenuItem = styled(MenuItem)(() => ({
  "&.Mui-selected": {
    backgroundColor: `${palette.grey[200]} !important`,
  },
}));

interface IReportsFilterProps extends SnackbarProps {
  selectedDate: Date;
  selectedStatuses: AppointmentStatus[];
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  setSelectedStatuses: (statuses: AppointmentStatus[]) => void;
  setSortBy?: (sortBy: SortBy) => void;
  setSortOrder?: (sortOrder: SortOrder) => void;
  handleMonthChange: (newDate: Date | null) => void;
}

const ReportsFilter: React.FC<IReportsFilterProps> = ({
  selectedDate,
  selectedStatuses,
  sortBy,
  sortOrder,
  setSelectedStatuses,
  setSortBy,
  setSortOrder,
  snackbarShowMessage,
  handleMonthChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangeStatus = useCallback(
    (event: SelectChangeEvent<AppointmentStatus[]>) => {
      const value = event.target.value;
      if (!Array.isArray(value)) return;
      if (value.length === 0) {
        snackbarShowMessage(t("reports.status.alert"), "info");
        return;
      }
      setSelectedStatuses(value as AppointmentStatus[]);
    },
    [setSelectedStatuses, snackbarShowMessage, t]
  );

  const handleSortByChange = useCallback(
    (event: SelectChangeEvent<SortBy>) => {
      setSortBy(event.target.value as SortBy);
    },
    [setSortBy]
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <DatePicker
              views={["month", "year"]}
              label={t("expenses.month_picker")}
              value={selectedDate}
              onChange={handleMonthChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: { backgroundColor: theme.palette.background.paper },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <InputLabel>{t("reports.status")}</InputLabel>
              <Select
                multiple
                value={selectedStatuses}
                onChange={handleChangeStatus}
                input={<OutlinedInput label={t("reports.status")} />}
                renderValue={(selected) => (
                  <Stack direction="row" spacing={1}>
                    {(selected as AppointmentStatus[]).map((value) => (
                      <SeverityPill key={value} color={getAppointmentStatusColor(value)}>
                        {t(getAppointmentStatusText(value))}
                      </SeverityPill>
                    ))}
                  </Stack>
                )}
              >
                {Object.values(AppointmentStatus).map((status) => (
                  <StyledMenuItem key={status} value={status}>
                    <SeverityPill key={status} color={getAppointmentStatusColor(status)}>
                      {t(getAppointmentStatusText(status))}
                    </SeverityPill>
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {sortBy && sortOrder && (
            <>
              <Grid item xs={12} sm={4}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControl fullWidth>
                    <InputLabel>{t("reports.sortBy.title")}</InputLabel>
                    <Select value={sortBy} onChange={handleSortByChange}>
                      <MenuItem value={SortBy.FIRST_NAME}>{t("reports.sortBy.by_firstname")}</MenuItem>
                      <MenuItem value={SortBy.LAST_NAME}>{t("reports.sortBy.by_lastname")}</MenuItem>
                      <MenuItem value={SortBy.TOTAL_INCOME}>{t("reports.sortBy.by_income")}</MenuItem>
                    </Select>
                  </FormControl>
                  <SortOrderButton
                    sortOrder={sortOrder}
                    onToggleSortOrder={() => setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)}
                  />
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withSnackbar(ReportsFilter);
