import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IPatient, IPatientInvoiceData } from "patients-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { PatientStatus, SortBy, SortOrder } from "@utils/enums";

enum TagTypes {
  PATIENTS = "PATIENTS",
}

export const patientsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "patientsApi",
  tagTypes: [TagTypes.PATIENTS],
  endpoints: (build) => ({
    getAllPatients: build.query<
      IPatient[],
      {
        assignedEmployees?: string[];
        patientStatus?: PatientStatus;
        search?: string;
        sortBy?: SortBy;
        sortOrder?: SortOrder;
      }
    >({
      query: (queryParams) => ({
        method: "GET",
        url: "patients",
        params: queryParams,
      }),
      providesTags: [TagTypes.PATIENTS],
    }),
    getPatientById: build.query<IPatient, string>({
      query: (patientId) => ({ method: "GET", url: `patients/${patientId}/` }),
      providesTags: (result, error, id) => [{ type: TagTypes.PATIENTS, id }],
    }),
    addPatient: build.mutation<IPatient, Partial<IPatient>>({
      query: (patient) => ({
        method: "POST",
        url: "patients",
        data: patient,
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    editPatient: build.mutation<IPatient, { patientId: string; data: Partial<IPatient> }>({
      query: ({ patientId, data }) => ({
        method: "PATCH",
        url: `patients/${patientId}`,
        data,
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    deletePatient: build.mutation<void, { patientId: string }>({
      query: ({ patientId }) => ({
        method: "DELETE",
        url: `patients/${patientId}`,
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    generateTokenForPatient: build.mutation<{ token: string }, { patientId: string; email: string }>({
      query: ({ patientId, email }) => ({
        method: "POST",
        url: `patients/${patientId}/generate-token`,
        data: { email },
      }),
    }),
    assignEmployeeToPatient: build.mutation<void, { patientId: string; employeeBodyId: string }>({
      query: ({ patientId, employeeBodyId }) => ({
        method: "POST",
        url: `patients/${patientId}`,
        data: { employeeBodyId },
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    unassignEmployeeFromPatient: build.mutation<void, { patientId: string; employeeId: string }>({
      query: ({ patientId, employeeId }) => ({
        method: "DELETE",
        url: `patients/${patientId}/${employeeId}`,
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    changePatientStatus: build.mutation<IPatient, { patientId: string; status: string }>({
      query: ({ patientId, status }) => ({
        method: "PATCH",
        url: `patients/${patientId}/change-status`,
        data: { patientStatus: status },
      }),
      invalidatesTags: [TagTypes.PATIENTS],
    }),
    getPatientInvoiceData: build.query<IPatientInvoiceData, string>({
      query: (patientId) => ({
        method: "GET",
        url: `patients/${patientId}/invoice-data`,
      }),
      providesTags: (result, error, patientId) => [{ type: TagTypes.PATIENTS, id: patientId }],
    }),
    addPatientInvoiceData: build.mutation<IPatientInvoiceData, { patientId: string; data: Partial<IPatientInvoiceData> }>({
      query: ({ patientId, data }) => ({
        method: "POST",
        url: `cpatients/${patientId}/invoice-data`,
        data,
      }),
      invalidatesTags: (result, error, { patientId }) => [{ type: TagTypes.PATIENTS, id: patientId }],
    }),
    updatePatientInvoiceData: build.mutation<IPatientInvoiceData, { patientId: string; data: Partial<IPatientInvoiceData> }>({
      query: ({ patientId, data }) => ({
        method: "PATCH",
        url: `patients/${patientId}/invoice-data`,
        data,
      }),
      invalidatesTags: (result, error, { patientId }) => [{ type: TagTypes.PATIENTS, id: patientId }],
    }),
  }),
});

export const {
  useGetAllPatientsQuery,
  useGetPatientByIdQuery,
  useAddPatientMutation,
  useEditPatientMutation,
  useDeletePatientMutation,
  useGenerateTokenForPatientMutation,
  useAssignEmployeeToPatientMutation,
  useUnassignEmployeeFromPatientMutation,
  useChangePatientStatusMutation,
  useGetPatientInvoiceDataQuery,
  useAddPatientInvoiceDataMutation,
  useUpdatePatientInvoiceDataMutation,
} = patientsApi;

export const patientsApiReducer = {
  [patientsApi.reducerPath]: patientsApi.reducer,
};
