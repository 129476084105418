import EventRepeatRoundedIcon from "@mui/icons-material/EventRepeatRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import { Box, Stack, Typography } from "@mui/material";
import { getFormattedTime } from "@utils/timeUtils";
import { getEventBackgroundColor, getEventFontColor } from "@utils/utils";
import { IAppointment } from "appointment.types";
import { parseISO } from "date-fns";

const EventComponent: React.FC<{ event: IAppointment }> = ({ event }) => {
  const startTime = getFormattedTime(parseISO(event.startDate));
  const endTime = getFormattedTime(parseISO(event.endDate));

  return (
    <Box
      sx={{
        pl: 0.5,
        height: "100%",
        border: "0.5px solid #fff",
        borderRadius: 1,
        color: getEventFontColor(event),
        backgroundColor: getEventBackgroundColor(event),
      }}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {event.seriesId ? <EventRepeatRoundedIcon /> : <EventRoundedIcon />}
        <Box>
          {event.patientId ? (
            <>
              <Typography variant="subtitle2" noWrap sx={{ mb: -0.75 }}>
                {`${event.patientId.firstname} ${event.patientId.lastname}`}
              </Typography>
              <Typography variant="subtitle2" noWrap sx={{ fontSize: "0.575rem" }}>
                {startTime} - {endTime}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle2" noWrap sx={{ mb: -0.75 }}>
                {startTime} - {endTime}
              </Typography>
              <Typography variant="subtitle2" noWrap sx={{ fontSize: "0.575rem" }}>
                {event.title}
              </Typography>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default EventComponent;
