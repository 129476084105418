import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { LoadingButton } from "@mui/lab";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { useAssignPatientToClientMutation } from "@store/services/usersService";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function VerifyAssignment() {
  const { t } = useTranslation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);

  const [assignPatientToClient, { isLoading: isMutationLoading }] = useAssignPatientToClientMutation();
  const [isLoading, setIsLoading] = useState(false);

  const getQueryParam = (param: string): string | null => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const token = getQueryParam("token");

  const handleAssignPatientToClient = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      assignPatientToClient({ data: { accessPatientToken: token } })
        .unwrap()
        .then(() => {
          navigate(AppRoutes.Dashboard);
        })
        .catch(() => {
          navigate(AppRoutes.VerifyAssignmentFailed);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000);
  }, [token, navigate, assignPatientToClient]);

  if (!usersTokens) return <Navigate to={AppRoutes.Login} />;
  if (!rbacCheck(userRole, RBACActions.ACTION_VERIFY_ASSIGNMENT)) navigate(AppRoutes.Dashboard);

  return (
    <NotificationWithAction
      helmetTitle={t("verify_assignment.helmet")}
      title={t("verify_assignment.title")}
      message={t("verify_assignment.message")}
      buttonComponent={
        <LoadingButton
          onClick={handleAssignPatientToClient}
          loading={isLoading || isMutationLoading}
          variant="contained"
          size="large"
          sx={{ mt: 3, width: "50%" }}
        >
          {t("verify_assignment.verify")}
        </LoadingButton>
      }
    />
  );
}

export default VerifyAssignment;
