import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { Button } from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ChangePasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <NotificationWithAction
      helmetTitle={t("change_password.success.helmet")}
      title={t("change_password.success.title")}
      message={t("change_password.success.message")}
      buttonComponent={
        <Button component={Link} color="secondary" to={AppRoutes.Login} variant="contained" size="large" sx={{ mt: 3, width: "50%" }}>
          {t("change_password.success.go_to_login_button")}
        </Button>
      }
    />
  );
};

export default ChangePasswordSuccess;
