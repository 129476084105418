import ClientAssignmentStatus from "@components/ClientAssignmentStatus/ClientAssignmentStatus";
import PageTitle from "@components/PageTitle/PageTitle";
import PatientAutocomplete from "@components/PatientAutocomplete/PatientAutocomplete";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { Container } from "@mui/material";
import PatientDetailsContent from "@pages/PatientDetails/fragments/PatientDetailsContent";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { IPatient } from "patients-types";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function ClientDashboard({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();

  const { patients } = useGetAllPatientsQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  const [filterPatient, setFilterPatient] = useState<IPatient | null>(patients[0] || null);

  const hasMorePatientsAssigned = useMemo(() => patients.length > 1, [patients]);

  useEffect(() => {
    if (patients.length > 0) setFilterPatient(patients[0]);
  }, [patients]);

  if (!filterPatient) return null;

  return (
    <>
      <Helmet>
        <title>{t("dashboard.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          gap={6}
          title={`${filterPatient.firstname} ${filterPatient.lastname}`}
          rightContent={
            hasMorePatientsAssigned && (
              <PatientAutocomplete
                disableClearable
                patients={patients}
                value={filterPatient}
                onPatientChange={setFilterPatient}
                width={400}
                placeholder={t("dashboard.client.patient_autocomplete_placeholder")}
                helperText={t("dashboard.client.patient_autocomplete_helper_text")}
              />
            )
          }
          bottomContent={<ClientAssignmentStatus patient={filterPatient} />}
        />

        <PatientDetailsContent patient={filterPatient} patientId={filterPatient._id} snackbarShowMessage={snackbarShowMessage} />
      </Container>
    </>
  );
}

export default withSnackbar(ClientDashboard);
