import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import PatientStatus from "@components/PatientStatus/PatientStatus";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import AssignPatientToClientModal from "@components/modals/Patients/AssignPatientToClientModal";
import DeletePatientModal from "@components/modals/Patients/DeletePatientModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Box, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import {
  useChangePatientStatusMutation,
  useDeletePatientMutation,
  useEditPatientMutation,
  useGenerateTokenForPatientMutation,
} from "@store/services/patientsService";
import { AppRoutes, PatientStatus as PatientStatusEnum } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { formatDate } from "@utils/timeUtils";
import { capitalizeWord, formatPhoneNumber, formatSexString } from "@utils/typographyUtils";
import { IPatient } from "patients-types";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SeverityPill } from "../../../components/SeverityPill/SeverityPill";
import EditPatientModal from "../../../components/modals/Patients/EditPatientModal";
import PatientCardMenu from "../fragments/PatientMenu";

export interface PatientsTableProps extends SnackbarProps {
  patients: IPatient[];
  isFetching: boolean;
  setInitialCountsSet: (value: boolean) => void;
}

function PatientsTable({ snackbarShowMessage, patients, setInitialCountsSet, isFetching }: PatientsTableProps) {
  const { t } = useTranslation();

  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);

  const [isEditPatientModalOpen, toggleEditPatientModal] = useModal();
  const [isDeletePatientModalOpen, toggleDeletePatientModal] = useModal();
  const [isAssignPatientToClientModalOpen, toggleAssignPatientToClientModal] = useModal();
  const [isChangePatientStatusModalOpen, toggleChangePatientStatusModal] = useModal();

  const [editPatient, { isLoading: isEditPatientLoading }] = useEditPatientMutation();
  const [deletePatient, { isLoading: isDeletePatientLoading }] = useDeletePatientMutation();
  const [generateTokenForPatient, { isLoading: isGenerateTokenForPatientLoading }] = useGenerateTokenForPatientMutation();
  const [changePatientStatus, { isLoading: isChangePatientStatusLoading }] = useChangePatientStatusMutation();

  const handleCloseEditModal = () => handleCloseModal([setSelectedPatient], toggleEditPatientModal);
  const handleCloseDeleteModal = () => handleCloseModal([setSelectedPatient], toggleDeletePatientModal);
  const handleCloseChangePatientStatusModal = () => handleCloseModal([setSelectedPatient], toggleChangePatientStatusModal);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, patient: IPatient) => {
      setAnchorElementMenu(event.currentTarget);
      setSelectedPatient(patient);
    },
    [setAnchorElementMenu]
  );

  const changeStatusTitle = useMemo(() => {
    return selectedPatient?.patientStatus === PatientStatusEnum.ACTIVE
      ? t("patient.status.confirm_change_to_inactive")
      : t("patient.status.confirm_change_to_active");
  }, [selectedPatient, t]);

  const changeStatusContent = useMemo(() => {
    return selectedPatient?.patientStatus === PatientStatusEnum.ACTIVE
      ? t("patient.status.confirm_deactivation")
      : t("patient.status.confirm_activation");
  }, [selectedPatient, t]);

  const handleRowClick = useCallback((patientId: string) => {
    const shouldNavigateToPatients = location.pathname.includes("/employees/");
    const navigationPath = shouldNavigateToPatients ? `${AppRoutes.Patients}/${patientId}` : patientId;
    navigate(navigationPath);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleOpenEditModal = useCallback(
    (patient: IPatient) => {
      setSelectedPatient(patient);
      toggleEditPatientModal();
    },
    [setSelectedPatient, toggleEditPatientModal]
  );

  const handleOpenDeleteModal = useCallback(() => {
    toggleDeletePatientModal();
    handleCloseMenu();
  }, [toggleDeletePatientModal]);

  const handleEditPatient = useCallback(
    (patientData: Partial<IPatient>) => {
      if (!selectedPatient) return;

      editPatient({ patientId: selectedPatient._id, data: patientData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          handleCloseEditModal();
        });
    },
    [editPatient, snackbarShowMessage, handleCloseEditModal, selectedPatient]
  );

  const handleDeletePatient = useCallback(() => {
    if (!selectedPatient) return;

    deletePatient({ patientId: selectedPatient._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("patient.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("patient.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeletePatientModal();
        handleCloseMenu();
        setInitialCountsSet(false);
      });
  }, [deletePatient, snackbarShowMessage, toggleDeletePatientModal, selectedPatient]);

  const handleGenerateTokenForPatient = useCallback(
    ({ email }: { email: string }) => {
      if (!selectedPatient) return;

      generateTokenForPatient({ patientId: selectedPatient._id, email })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient.snackbar_message.delete.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient.snackbar_message.delete.error"), "error");
        })
        .finally(() => {
          toggleAssignPatientToClientModal();
        });
    },
    [generateTokenForPatient, selectedPatient, snackbarShowMessage, toggleAssignPatientToClientModal]
  );

  const handleChangePatientStatus = useCallback(() => {
    if (!selectedPatient) return;

    const newStatus = selectedPatient.patientStatus === PatientStatusEnum.ACTIVE ? PatientStatusEnum.INACTIVE : PatientStatusEnum.ACTIVE;
    changePatientStatus({ patientId: selectedPatient._id, status: newStatus })
      .unwrap()
      .then(() => {
        snackbarShowMessage(
          t(newStatus === PatientStatusEnum.ACTIVE ? "patient.snackbar_message.patient_activated" : "patient.snackbar_message.patient_deactivated"),
          "success"
        );
      })
      .catch(() => {
        snackbarShowMessage(t("patient.snackbar_message.toggle_status_error"), "error");
      })
      .finally(() => {
        handleCloseChangePatientStatusModal();
      });
  }, [changePatientStatus, snackbarShowMessage, handleCloseChangePatientStatusModal, selectedPatient]);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_PATIENTS)) navigate(AppRoutes.Dashboard);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t("roles.patient")}</TableCell>
                  <TableCell>{t("personal_data.phone_number")}</TableCell>
                  <TableCell>{t("personal_data.gender")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{t("personal_data.date_of_birth")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{t("patient.status.info")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="patients-table">
                {patients.length ? (
                  patients.map((patient, index) => (
                    <TableRow key={patient._id} hover sx={{ cursor: "pointer" }} onClick={() => handleRowClick(patient._id)}>
                      <TableCell>
                        <Typography variant="caption" color="text.secondary">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <UsernameAvatar
                          avatar
                          firstname={patient.firstname}
                          lastname={patient.lastname}
                          email={patient.email}
                          isFetching={isFetching}
                        />
                      </TableCell>
                      <TableCell>
                        {patient.phoneNumber ? (
                          <SeverityPill>{`+48 ${formatPhoneNumber(patient.phoneNumber)}`}</SeverityPill>
                        ) : (
                          <Tooltip title={t("patient.no_phone_number.title")}>
                            <Box>
                              <SeverityPill>{t("patient.no_phone_number.info")}</SeverityPill>
                            </Box>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" color="text.secondary">
                          {capitalizeWord(t(formatSexString(patient.sex)))}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" color="text.secondary">
                          {formatDate(new Date(patient.dateOfBirth), "dd.MM.yyyy")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PatientStatus patient={patient} />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" justifyContent="end">
                          <IconWithTooltip
                            label={t("patient.button.edit")}
                            icon={<EditRoundedIcon />}
                            locked={{ isLocked: patient.patientStatus === PatientStatusEnum.INACTIVE }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenEditModal(patient);
                            }}
                          />
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenMenu(e, patient);
                            }}
                          >
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="body2" color="text.secondary">
                        {t("patient.no_patients")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <PatientCardMenu
        {...{ anchorElementMenu }}
        {...{ handleOpenMenu }}
        {...{ handleCloseMenu }}
        {...{ handleOpenDeleteModal }}
        {...{ toggleChangePatientStatusModal }}
        {...{ toggleAssignPatientToClientModal }}
        isActive={selectedPatient && selectedPatient.patientStatus === PatientStatusEnum.ACTIVE}
        isAssignedToClient={selectedPatient && selectedPatient.clientId}
        patientId={selectedPatient && selectedPatient._id}
      />
      <EditPatientModal
        open={isEditPatientModalOpen}
        onClose={handleCloseEditModal}
        onSubmit={handleEditPatient}
        initialValues={selectedPatient}
        loading={isEditPatientLoading}
      />
      <DeletePatientModal
        open={isDeletePatientModalOpen}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDeletePatient}
        patientLastName={selectedPatient && selectedPatient.lastname}
        loading={isDeletePatientLoading}
      />
      <AssignPatientToClientModal
        open={isAssignPatientToClientModalOpen}
        onClose={toggleAssignPatientToClientModal}
        onSubmit={handleGenerateTokenForPatient}
        loading={isGenerateTokenForPatientLoading}
      />
      <ConfirmationModal
        open={isChangePatientStatusModalOpen}
        title={changeStatusTitle}
        content={changeStatusContent}
        loading={isChangePatientStatusLoading}
        onClose={handleCloseChangePatientStatusModal}
        onConfirm={handleChangePatientStatus}
      />
    </>
  );
}
export default withSnackbar(PatientsTable);
