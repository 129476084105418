import { ColorType, SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { InvoiceStatus } from "@utils/enums";
import { getColorForStatus, getInvoiceStatusText } from "@utils/utils";
import { IInvoiceStats } from "invoice-types";
import React from "react";

interface IInvoiceStatusFilterProps {
  selectedTab: number;
  invoiceStats: IInvoiceStats[];
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface ITabData {
  label: string;
  severityCount: number;
  color?: ColorType;
  value: number;
}

const InvoiceStatusFilter: React.FC<IInvoiceStatusFilterProps> = ({ selectedTab, invoiceStats, handleTabChange }) => {
  const tabData: ITabData[] = invoiceStats.map((stat, index) => ({
    label: getInvoiceStatusText(stat.status),
    severityCount: stat.invoiceCount,
    color: getColorForStatus(stat.status as InvoiceStatus),
    value: index + 1,
  }));

  const renderTab = (tab: ITabData) => (
    <Tab
      key={tab.value}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{tab.label.split(" - ")[0]}</Typography>
          <SeverityPill color={tab.color}>{tab.severityCount}</SeverityPill>
        </Stack>
      }
      value={tab.value}
      title={tab.label}
    />
  );

  return (
    <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable">
      {tabData.map(renderTab)}
    </Tabs>
  );
};

export default InvoiceStatusFilter;
