import CustomDialog from "@components/modals/CustomDialog";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { formatDate } from "@utils/timeUtils";
import { IActivityLog } from "activityLog.types";
import { Field, FieldProps, Formik } from "formik";
import { IPatient } from "patients-types";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { addEditActivityLogValidationSchema } from "src/validations/activityLogValidation";

interface IAddLogModalProps {
  open: boolean;
  loading: boolean;
  patientId: string;
  appointments: { label: string; date: Date; time: string; id: string }[];
  date: Date;
  onClose: () => void;
  onSubmit: (values: Partial<IActivityLog>) => void;
  handlePrevMonthClick: () => void;
  handleNextMonthClick: () => void;
}

const AddLogModal: React.FC<IAddLogModalProps> = ({
  open,
  loading,
  patientId,
  appointments,
  date,
  onClose,
  onSubmit,
  handlePrevMonthClick,
  handleNextMonthClick,
}) => {
  if (!patientId) return null;
  const { t } = useTranslation();

  const initialValues = {
    patientId: patientId,
    appointmentId: null,
    description: "",
  } as Partial<IPatient> as any;

  const formikRef = useRef<any>(null);

  const currentAppointment = useMemo(() => {
    return (value: string | null) => appointments.find((appointment) => appointment.id === value) || null;
  }, [appointments]);

  useEffect(() => {
    if (!open) formikRef.current?.resetForm();
  }, [open]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addEditActivityLogValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog
          open={open}
          onClose={() => {
            onClose();
            formikRef.current?.resetForm();
          }}
        >
          <DialogTitle>{t("activity_log.add.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("activity_log.add.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    <IconButton onClick={handlePrevMonthClick}>
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography variant="subtitle2" color="text.secondary">
                      {formatDate(date, "MMM yyyy")}
                    </Typography>
                    <IconButton onClick={handleNextMonthClick}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Field name="appointmentId">
                    {({ field, form }: FieldProps) => (
                      <Autocomplete
                        noOptionsText={t("no_options")}
                        key={field.value}
                        options={appointments}
                        getOptionLabel={(option) => `${option.label} (${formatDate(option.date, "dd MMM yyyy")})`}
                        onChange={(event, value) => {
                          form.setFieldValue("appointmentId", value ? value.id : null);
                        }}
                        defaultValue={currentAppointment(field.value)}
                        renderOption={(props, option) => (
                          <ListItem {...props}>
                            <ListItemText primary={`${option.label} (${formatDate(option.date, "dd MMM yyyy")})`} secondary={option.time} />
                          </ListItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label={t("appointments.label")}
                            helperText={
                              touched.appointmentId && errors.appointmentId
                                ? t(errors.appointmentId.toString())
                                : t("activity_log.choose_appointment_helper_text")
                            }
                            error={touched.appointmentId && !!errors.appointmentId}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="description">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={3}
                        fullWidth
                        multiline
                        label={t("activity_log.subject_of_appointment")}
                        helperText={
                          touched.description && errors.description
                            ? t(errors.description.toString())
                            : t("activity_log.subject_of_appointment_helper_text")
                        }
                        error={touched.description && !!errors.description}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  formikRef.current?.resetForm();
                }}
                variant="outlined"
                color="secondary"
              >
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddLogModal;
