import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { assignPatientToClientValidationSchema } from "src/validations/patientValidation";
import { IUserDetails } from "user-types";

interface IAssignPatientToClientModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: ({ email }: { email: string }) => void;
}

const AssignPatientToClientModal: React.FC<IAssignPatientToClientModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    email: null,
  } as Partial<IUserDetails>;

  return (
    <Formik initialValues={initialValues} validationSchema={assignPatientToClientValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("patient.assign_patient_to_client_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("patient.assign_patient_to_client_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.email")}
                        helperText={
                          touched.email && errors.email ? t(errors.email.toString()) : t("patient.assign_patient_to_client_modal.helper_text")
                        }
                        error={touched.email && !!errors.email}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AssignPatientToClientModal;
