import styled from "@emotion/styled";

interface BackgroundVideoProps {
  src: string;
  type?: string;
  className?: string;
  opacity?: number;
}

interface VideoContainerProps {
  opacity: number;
}

const VideoContainer = styled("video")<VideoContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: ${({ opacity }) => opacity};
  pointer-events: none;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
`;

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ src, type = "video/mp4", className, opacity = 0.5 }) => {
  return (
    <VideoContainer autoPlay loop muted playsInline disablePictureInPicture src={src} className={className} opacity={opacity} preload="auto">
      <source src={src} type={type} />
    </VideoContainer>
  );
};

export default BackgroundVideo;
