import { RBACRoles } from "@auth/rbac-rules";
import BackgroundVideo from "@components/BackgroundVideo/BackgroundVideo";
import Logo from "@components/Logo/Logo";
import { AlertColor, Link, Typography } from "@mui/material";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { useRegisterMutation } from "@store/services/authService";
import { StyledCard } from "@theme/styled-components/AuthCard";
import { API_MESSAGE_TYPE, AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { withSnackbar } from "src/hoc/AlertPopover";
import { registerValidationSchema } from "src/validations/authValidation";
import SignUpForm from "./fragments/SignUpForm";

const SignUp: React.FC = ({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) => {
  const { t } = useTranslation();
  const [register, { isLoading }] = useRegisterMutation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const accountTypeFromUrl = searchParams.get("accountType");

  const validAccountTypes = Object.values(RBACRoles);
  const initalAccountType = validAccountTypes.includes(accountTypeFromUrl as RBACRoles) ? accountTypeFromUrl : RBACRoles.CLIENT;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstname: "",
      lastname: "",
      phoneNumber: "",
      accountType: initalAccountType as RBACRoles,
    },
    validationSchema: registerValidationSchema,
    onSubmit: async (values) => {
      register(values)
        .unwrap()
        .then(() => {
          navigate(AppRoutes.SignUpSuccess, { replace: true });
        })
        .catch((error) => {
          const apiErrorMsg = error?.data[0].msg;
          const message =
            apiErrorMsg === API_MESSAGE_TYPE.EMAIL_IN_USE ? t("employee.snackbar_message.add.email_exists") : t("signup.snackbar_message.error");
          snackbarShowMessage(message, "error");
        });
    },
  });

  if (usersTokens) return <Navigate replace to={AppRoutes.Dashboard} />;

  return (
    <>
      <Helmet>
        <title>{t("signup.helmet")}</title>
      </Helmet>
      <BackgroundVideo src={"/assets/images/backgrounds/background_hero.mp4"} />
      <Logo />
      <StyledCard>
        <Typography variant="h6" sx={{ mb: -0.5 }}>
          {t("signup.title")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }} color="text.secondary">
          {t("signup.already_have_account")}
          {` `}
          <Link
            href={AppRoutes.Login}
            variant="subtitle2"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("signup.login")}
          </Link>
        </Typography>
        <SignUpForm {...{ formik }} {...{ isLoading }} />
      </StyledCard>
    </>
  );
};

export default withSnackbar(SignUp);
