import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AppRoutes } from "@utils/enums";
import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";

let isRefreshing = false;

const apiKey = import.meta.env.MODE !== "development" ? import.meta.env.VITE_API_KEY : import.meta.env.VITE_API_KEY_DEV;

const axiosBaseQuery =
  (
    { baseUrl, isTokenRequired, includeClinicId = false }: { baseUrl: string; isTokenRequired?: boolean; includeClinicId?: boolean } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    const clinicId = includeClinicId ? localStorage.getItem("clinicId") : null;

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    } as any;

    if (isTokenRequired) {
      headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("token-data") || "{}").accessToken}`;
    }

    if (clinicId && includeClinicId) {
      url = `clinics/${clinicId}/${url}`;
    }

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const status = err.response?.status;

      if (isTokenRequired && status === 401 && !isRefreshing) {
        isRefreshing = true;

        try {
          const refreshResult = await axios({
            url: baseUrl + "auth/refresh-token",
            method: "POST",
            data: {
              refreshToken: JSON.parse(localStorage.getItem("token-data") || "{}").refreshToken,
            },
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiKey,
            },
          });

          const newAccessToken = refreshResult?.data?.accessToken;
          if (newAccessToken) {
            const currentTokenData = JSON.parse(localStorage.getItem("token-data") || "{}");
            currentTokenData.accessToken = newAccessToken;

            localStorage.setItem("token-data", JSON.stringify(currentTokenData));

            headers.Authorization = `Bearer ${newAccessToken}`;

            const result = await axios({
              url: baseUrl + url,
              method,
              data,
              params,
              headers,
            });

            isRefreshing = false;
            return { data: result.data };
          } else {
            localStorage.removeItem("token-data");
            localStorage.removeItem("clinicId");
          }
        } catch (refreshError) {
          localStorage.removeItem("token-data");
          localStorage.removeItem("clinicId");
          window.location.href = AppRoutes.Login;
        } finally {
          isRefreshing = false;
        }
      }

      return {
        error: {
          status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
