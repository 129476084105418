import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AppointmentStatus } from "@utils/enums";
import { IAppointment } from "appointment.types";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";
type StatusToTranslationKeyMap = {
  [key in AppointmentStatus]?: string;
};

interface ToggleAppointmentStatusModalProps {
  open: boolean;
  loading?: boolean;
  selectedAppointment: IAppointment;
  onSubmit: (status: AppointmentStatus) => void;
  onClose: () => void;
}

const ToggleAppointmentStatusModal: React.FC<ToggleAppointmentStatusModalProps> = ({ open, loading, selectedAppointment, onSubmit, onClose }) => {
  if (!selectedAppointment) return null;
  const { t } = useTranslation();

  const [appointmentStatus, setAppointmentStatus] = useState<AppointmentStatus>(selectedAppointment.appointmentStatus);

  const dialogContentText = useMemo(() => {
    const statusToTranslationKey: StatusToTranslationKeyMap = {
      [AppointmentStatus.PRESENT]: "appointments.status_modal.present_content",
      [AppointmentStatus.PENDING]: "appointments.status_modal.pending_content",
      [AppointmentStatus.EXCUSED]: "appointments.status_modal.excused_content",
      [AppointmentStatus.NOT_EXCUSED]: "appointments.status_modal.not_excused_content",
    };

    return t(statusToTranslationKey[appointmentStatus]);
  }, [appointmentStatus, t]);

  useEffect(() => {
    setAppointmentStatus(selectedAppointment.appointmentStatus);
  }, [selectedAppointment]);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("appointments.status_modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          exclusive
          value={appointmentStatus}
          onChange={(event: React.MouseEvent<HTMLElement>, newStatus: AppointmentStatus | null) => {
            if (newStatus !== null) setAppointmentStatus(newStatus);
          }}
          sx={{ mt: 3 }}
        >
          <ToggleButton value={AppointmentStatus.PRESENT} color="success">
            {t("appointments.status.present")}
          </ToggleButton>
          <ToggleButton value={AppointmentStatus.PENDING} color="info">
            {t("appointments.status.pending")}
          </ToggleButton>
          <ToggleButton value={AppointmentStatus.EXCUSED} color="warning">
            {t("appointments.status.excused")}
          </ToggleButton>
          <ToggleButton value={AppointmentStatus.NOT_EXCUSED} color="error">
            {t("appointments.status.not_excused")}
          </ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={() => onSubmit(appointmentStatus)} variant="contained" autoFocus color="secondary">
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default ToggleAppointmentStatusModal;
