import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Box, Button, Divider, Popover, Stack, Typography } from "@mui/material";
import { formatDate, getFormattedTime } from "@utils/timeUtils";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { IAppointment } from "appointment.types";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IEventPopoverProps {
  selectedAppointment: IAppointment;
  anchorEventPopover: HTMLButtonElement;
  handleCloseEventPopover: () => void;
  toggleEditAppointmentModal?: () => void;
  toggleDeleteAppointmentModal?: () => void;
  toggleAppointmentStatusModal?: () => void;
}

const EventPopover: React.FC<IEventPopoverProps> = ({
  selectedAppointment,
  anchorEventPopover,
  handleCloseEventPopover,
  toggleEditAppointmentModal,
  toggleDeleteAppointmentModal,
  toggleAppointmentStatusModal,
}) => {
  if (!selectedAppointment) return null;
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Popover
      open={Boolean(anchorEventPopover)}
      anchorEl={anchorEventPopover}
      onClose={handleCloseEventPopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ zIndex: "1200" }}
    >
      <Box sx={{ width: 400, p: 2 }}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              {selectedAppointment.patientId ? (
                <Typography
                  variant="h6"
                  onClick={() => navigate(`/patients/${selectedAppointment.patientId._id}`)}
                  sx={{ cursor: "pointer" }}
                >{`${selectedAppointment.patientId.firstname} ${selectedAppointment.patientId.lastname}`}</Typography>
              ) : (
                <Typography variant="h6">{selectedAppointment.title}</Typography>
              )}
              <Typography variant="body2">{formatDate(parseISO(selectedAppointment.startDate), "PPPP")}</Typography>
              <Typography variant="subtitle2">
                {getFormattedTime(parseISO(selectedAppointment.startDate))} - {getFormattedTime(parseISO(selectedAppointment.endDate))}
              </Typography>
            </Box>
          </Stack>
          <Divider sx={{ my: 1, borderStyle: "dashed" }} />
          <Stack direction="column">
            <Typography variant="subtitle2" color="text.secondary">
              {t("appointments.event_popover.title")}:
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {selectedAppointment.title}
            </Typography>
            {selectedAppointment.description && (
              <>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("appointments.event_popover.description")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {selectedAppointment.description}
                </Typography>
              </>
            )}
            {!!selectedAppointment.assignedEmployees.length && (
              <>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("appointments.event_popover.assigned_employee")}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  {selectedAppointment.assignedEmployees.map((employee) => `${employee.firstname} ${employee.lastname}`).join(", ")}
                </Typography>
              </>
            )}
            {selectedAppointment.appointmentStatus && (
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("appointments.event_popover.status")}:
                </Typography>

                <SeverityPill color={getAppointmentStatusColor(selectedAppointment.appointmentStatus)}>
                  {t(getAppointmentStatusText(selectedAppointment.appointmentStatus))}
                </SeverityPill>
              </Box>
            )}
          </Stack>
          {(toggleEditAppointmentModal || toggleDeleteAppointmentModal) && (
            <RBAC
              action={RBACActions.ACTION_EDIT_APPOINTMENT || RBACActions.ACTION_DELETE_APPOINTMENT}
              yes={() => (
                <>
                  <Divider sx={{ my: 2, borderStyle: "dashed" }} />
                  <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={toggleDeleteAppointmentModal} fullWidth color="error" variant="outlined">
                      {t("delete")}
                    </Button>
                    <Button onClick={toggleEditAppointmentModal} fullWidth color="secondary" variant="contained">
                      {t("edit")}
                    </Button>
                    <RBAC
                      action={RBACActions.ACTION_TOGGLE_APPOINTMENT_STATUS}
                      yes={() => (
                        <Button onClick={toggleAppointmentStatusModal} fullWidth color="secondary" variant={"outlined"} sx={{ whiteSpace: "nowrap" }}>
                          {t("appointments.event_popover.change_status_button")}
                        </Button>
                      )}
                    />
                  </Stack>
                </>
              )}
            />
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default EventPopover;
