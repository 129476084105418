import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldProps, Formik } from "formik";
import { IDiagnosis } from "patients-types";
import React from "react";
import { addEditDiagnosisValidationSchema } from "src/validations/diagnosisValidation";
import CustomDialog from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Form } from "@theme/styled-components/StyledForm";

interface IAddPatientDiagnosisModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IDiagnosis>) => void;
}

const AddPatientDiagnosis: React.FC<IAddPatientDiagnosisModalProps> = ({ open, onClose, loading, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    result: "",
    interview: "",
    therapyPlan: "",
    medicalRecommendations: "",
    diagnosisDate: null,
  } as Partial<IDiagnosis>;

  return (
    <Formik initialValues={initialValues} validationSchema={addEditDiagnosisValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, setFieldValue, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <DialogTitle>{t("diagnosis.add_modal.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("diagnosis.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="result">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={2}
                        multiline
                        fullWidth
                        label={t("diagnosis.add_modal.result")}
                        helperText={touched.result && errors.result ? t(errors.result.toString()) : t("diagnosis.add_modal.result_helper_text")}
                        error={touched.result && !!errors.result}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="interview">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={3}
                        fullWidth
                        multiline
                        label={t("diagnosis.add_modal.interview")}
                        helperText={
                          touched.interview && errors.interview ? t(errors.interview.toString()) : t("diagnosis.add_modal.interview_helper_text")
                        }
                        error={touched.interview && !!errors.interview}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="therapyPlan">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        minRows={2}
                        fullWidth
                        multiline
                        label={t("diagnosis.add_modal.therapy_plan")}
                        helperText={
                          touched.therapyPlan && errors.therapyPlan
                            ? t(errors.therapyPlan.toString())
                            : t("diagnosis.add_modal.therapy_plan_helper_text")
                        }
                        error={touched.therapyPlan && !!errors.therapyPlan}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="medicalRecommendations">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        label={t("diagnosis.add_modal.recommendations")}
                        helperText={
                          touched.medicalRecommendations && errors.medicalRecommendations
                            ? t(errors.medicalRecommendations.toString())
                            : t("diagnosis.add_modal.recommendations_helper_text")
                        }
                        error={touched.medicalRecommendations && !!errors.medicalRecommendations}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="diagnosisDate">
                    {({ field }: FieldProps) => (
                      <DatePicker
                        label={t("diagnosis.add_modal.date")}
                        value={field.value || null}
                        onChange={(value) => {
                          setFieldValue("diagnosisDate", value);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText:
                              touched.diagnosisDate && errors.diagnosisDate ? t(errors.diagnosisDate) : t("diagnosis.add_modal.date_helper_text"),
                            error: touched.diagnosisDate && !!errors.diagnosisDate,
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddPatientDiagnosis;
