import { Card, CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import { formatCost } from "@utils/typographyUtils";
import React, { useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IEmployeesReports } from "reports-types";
import { calculateDynamicChartHeight, getCommonOptions } from "../../../utils/chartUtils";

interface IEmployeeIncomeChartProps {
  reports: IEmployeesReports;
}

const EmployeeIncomeChart: React.FC<IEmployeeIncomeChartProps> = ({ reports }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!reports || reports.length === 0) return null;

  const chartHeight = calculateDynamicChartHeight({ itemCount: reports.length });

  const handleDataPointSelection = useCallback(
    (event: any, chartContext: any, config: any) => {
      const employeeIndex = config.dataPointIndex;
      const selectedEmployee = reports[employeeIndex];
      if (selectedEmployee) {
        navigate(`/reports/employee/${selectedEmployee._id}`);
      }
    },
    [reports, navigate]
  );

  const series = [
    {
      name: t("reports.total_income"),
      data: reports.map((employee) => employee.totalIncome),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    ...getCommonOptions(theme),
    chart: {
      ...getCommonOptions(theme).chart,
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    xaxis: {
      categories: reports.map((employee) => `${employee.firstname} ${employee.lastname}`),
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: theme.typography.body2.fontSize as string,
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightRegular as string,
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => formatCost(val),
      },
    },
  };

  return (
    <Card>
      <CardHeader title={t("reports.employeeIncome.title")} subheader={t("reports.employeeIncome.subtitle")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent>
        <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
      </CardContent>
    </Card>
  );
};

export default EmployeeIncomeChart;
