import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import FileListItem from "@components/FileListItem/FileListItem";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import FileUploadModal from "@components/modals/FileUploadModal";
import EditPatientHomework from "@components/modals/Homeworks/EditPatientHomework";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Box, Checkbox, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import useUploadFile from "@hooks/useUploadFile";
import {
  useDeleteHomeworkFileMutation,
  useDeleteHomeworkMutation,
  useEditHomeworkMutation,
  useTogglePatientHomeworkStatusMutation,
} from "@store/services/homeworksService";
import { formatDate } from "@utils/timeUtils";
import { isPast, isToday, parseISO, startOfDay } from "date-fns";
import { IHomework } from "patients-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PatientHomeworkMenu from "./PatientHomeworkMenu";

interface IPatientHomeworkWidgetItemProps extends SnackbarProps {
  patientId: string;
  homework: IHomework;
  isActive: boolean;
  refetch: () => void;
}

const PatientHomeworkWidgetItem: React.FC<IPatientHomeworkWidgetItemProps> = ({ patientId, homework, isActive, refetch, snackbarShowMessage }) => {
  const { t } = useTranslation();

  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);
  const [isEditPatientHomeworkModalOpen, toggleEditPatientHomeworkModal] = useModal();
  const [isDeletePatientHomeworkModalOpen, toggleDeletePatientHomeworkModal] = useModal();
  const [isUploadHomeworkFileModalOpen, toggleUploadHomeworkFileModal] = useModal();

  const [editHomework, { isLoading: isEditHomeworkLoading }] = useEditHomeworkMutation();
  const [deleteHomework, { isLoading: isDeleteHomeworkLoading }] = useDeleteHomeworkMutation();
  const [toggleHomeworkStatus] = useTogglePatientHomeworkStatusMutation();
  const [uploadPatientFiles, { isLoading }] = useUploadFile(`patients/${patientId}/homeworks/${homework?._id}`);
  const [deleteHomeworkFile] = useDeleteHomeworkFileMutation();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementMenu(event.currentTarget);
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleEditPatientHomework = useCallback(
    (homeworkData: Partial<IHomework>) => {
      if (!homeworkData) return;

      editHomework({ patientId, homeworkId: homeworkData._id, data: homeworkData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("homework.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("homework.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditPatientHomeworkModal();
        });
    },
    [editHomework, snackbarShowMessage, toggleEditPatientHomeworkModal, patientId]
  );

  const handleDeletePatientHomework = useCallback(() => {
    if (!patientId) return;

    deleteHomework({ patientId, homeworkId: homework._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("homework.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("homework.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeletePatientHomeworkModal();
      });
  }, [deleteHomework, snackbarShowMessage, toggleDeletePatientHomeworkModal, patientId, homework?._id]);

  const handleToggleHomeworkStatus = useCallback(() => {
    if (!patientId) return;

    toggleHomeworkStatus({ patientId, homeworkId: homework._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("homework.snackbar_message.edit.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("homework.snackbar_message.edit.error"), "error");
      });
  }, [toggleHomeworkStatus, snackbarShowMessage, patientId, homework?._id]);

  const handleUploadDiagnosisFiles = useCallback(
    (values: { files: File[] }) => {
      if (!Array.isArray(values.files) || values.files.length === 0) return;

      uploadPatientFiles(values.files)
        .then(() => {
          snackbarShowMessage(t("file.upload.success_message"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("file.upload.error_message"), "error");
        })
        .finally(() => {
          refetch();
          toggleUploadHomeworkFileModal();
        });
    },
    [snackbarShowMessage, toggleUploadHomeworkFileModal]
  );

  const handleDeleteHomeworkFile = useCallback(
    (fileId: string) => {
      if (!fileId) return;

      deleteHomeworkFile({ patientId, homeworkId: homework._id, fileId: fileId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("file.delete.success_message"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("file.delete.error_message"), "error");
        });
    },
    [snackbarShowMessage, patientId, homework?._id]
  );

  const homeworkStatus = useMemo(() => {
    const dueDate = parseISO(homework.dueDate);
    const startOfToday = startOfDay(new Date());

    if (homework.isDone) {
      return <SeverityPill color="success">{t("homework.widget.item.status.done")}</SeverityPill>;
    } else if (isToday(dueDate) || (isPast(startOfToday) && !isPast(dueDate))) {
      return <SeverityPill color="warning">{t("homework.widget.item.status.to_do")}</SeverityPill>;
    } else {
      return <SeverityPill color="error">{t("homework.widget.item.status.outdated")}</SeverityPill>;
    }
  }, [homework.isDone, homework.dueDate]);

  return (
    <>
      <ListItem key={homework._id}>
        <RBAC
          action={RBACActions.ACTION_TOGGLE_HOMEWORK_STATUS}
          yes={() => (
            <Tooltip title={t("homework.button.change_status")}>
              <ListItemIcon>
                <Checkbox checked={homework.isDone} color="success" onChange={handleToggleHomeworkStatus} />
              </ListItemIcon>
            </Tooltip>
          )}
        />
        <ListItemText
          primary={
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: 1 }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    textDecoration: homework.isDone ? "line-through" : "none",
                    color: homework.isDone ? "grey" : "inherit",
                  }}
                >
                  {homework.title}
                </Typography>

                {homeworkStatus}
              </Stack>
              <RBAC
                action={RBACActions.ACTION_EDIT_HOMEWORK}
                yes={() => (
                  <IconWithTooltip
                    label={t("homework.menu.open")}
                    icon={<MoreVertRoundedIcon />}
                    onClick={handleOpenMenu}
                    locked={{ isLocked: !isActive }}
                  />
                )}
              />
            </Stack>
          }
          secondary={
            <>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {t("homework.widget.item.due_date", { date: formatDate(parseISO(homework.dueDate), "MMMM d, yyyy") })}
              </Typography>
              <Typography variant="body2" color="text.primary" gutterBottom style={{ whiteSpace: "pre-line" }}>
                {homework.description}
              </Typography>
              {!!homework.files.length && (
                <Box>
                  <Typography variant="subtitle2" color={"text.secondary"}>
                    {t("file.upload.files")}
                  </Typography>
                  <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                    {homework.files.map((file) => (
                      <FileListItem
                        completeFile={file}
                        key={file._id}
                        fileId={file._id}
                        filename={file.filename}
                        size={file.size}
                        createdAt={file.createdAt}
                        url={file.url}
                        onDelete={handleDeleteHomeworkFile}
                        formatDate={formatDate}
                      />
                    ))}
                  </Stack>
                </Box>
              )}
            </>
          }
        />
      </ListItem>
      <PatientHomeworkMenu
        {...{ anchorElementMenu }}
        {...{ handleCloseMenu }}
        {...{ toggleUploadHomeworkFileModal, toggleEditPatientHomeworkModal, toggleDeletePatientHomeworkModal }}
      />
      <EditPatientHomework
        open={isEditPatientHomeworkModalOpen}
        onClose={toggleEditPatientHomeworkModal}
        onSubmit={handleEditPatientHomework}
        initialValues={homework}
        loading={isEditHomeworkLoading}
      />
      <ConfirmationModal
        open={isDeletePatientHomeworkModalOpen}
        onClose={toggleDeletePatientHomeworkModal}
        onConfirm={handleDeletePatientHomework}
        title={t("homework.delete_modal.title")}
        content={t("homework.delete_modal.content_text")}
        loading={isDeleteHomeworkLoading}
      />
      <FileUploadModal
        open={isUploadHomeworkFileModalOpen}
        loading={isLoading}
        onClose={toggleUploadHomeworkFileModal}
        onSubmit={handleUploadDiagnosisFiles}
      />
    </>
  );
};

export default PatientHomeworkWidgetItem;
