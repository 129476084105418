import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { withSnackbar } from "@hoc/AlertPopover";
import { AppRoutes } from "@utils/enums";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function VerifyAssignmentFailed() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);

  if (!rbacCheck(userRole, RBACActions.ACTION_VERIFY_ASSIGNMENT)) navigate(AppRoutes.Dashboard);

  return (
    <NotificationWithAction
      helmetTitle={t("authentication.helmet")}
      title={t("authentication.title")}
      message={t("authentication.invalidLinkMessage")}
    />
  );
}

export default withSnackbar(VerifyAssignmentFailed);
