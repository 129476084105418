import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { palette } from "@theme/palette";
import typography from "@theme/typography";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { useTranslation } from "react-i18next";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { AppointmentReportsData } from "reports-types";

interface IPresenceChartProps {
  data: AppointmentReportsData[];
}

const PresenceChart: React.FC<IPresenceChartProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return (
      <Card>
        <CardHeader title={t("reports.presenceChart.title")} subheader={t("reports.presenceChart.subtitle")} />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("reports.no_data")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const series = data.map((item) => item.count);

  const options: ApexCharts.ApexOptions = {
    labels: data.map((item) => t(getAppointmentStatusText(item.appointmentStatus))),
    chart: {
      type: "donut",
      width: "150%",
    },
    dataLabels: {
      formatter: function (val, opts) {
        return data[opts.seriesIndex].count.toString();
      },
      dropShadow: {
        enabled: true,
        color: palette.grey[500],
      },
      style: {
        ...typography.body2,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          labels: {
            name: {
              offsetY: -1,
            },
            show: true,
            value: {
              show: true,
              ...typography.h6,
              offsetY: 2,
            },
            total: {
              show: true,
              showAlways: true,
              label: t("reports.presenceChart.total"),
              ...typography.h5,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0).toString();
              },
            },
          },
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        const value = series[seriesIndex];
        const statusName = w.config.labels[seriesIndex];
        return `
          <div style="padding: 0.75rem; color: ${palette.common.white}; background: ${palette.grey[800]}; border-radius: 0.5rem;">
            <strong>${statusName}</strong>: ${value}
          </div>
        `;
      },
    },
    colors: data.map((item) => alpha(palette[getAppointmentStatusColor(item.appointmentStatus)].main, 0.6)),
    legend: {
      fontSize: "14px",
      fontFamily: typography.fontFamily,
      fontWeight: typography.fontWeightMedium,
      position: "bottom",
      offsetY: 0,
    },
  };

  return (
    <Card>
      <CardHeader title={t("reports.presenceChart.title")} subheader={t("reports.presenceChart.subtitle")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent sx={{ mt: 2, p: 0 }}>
        <ReactApexChart options={options} series={series} type="donut" />
      </CardContent>
    </Card>
  );
};

export default PresenceChart;
