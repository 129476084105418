import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { Alert, alpha, Box, Container, FormControlLabel, Grid, Switch, Tab, Tabs, Typography } from "@mui/material";
import { CustomTabPanel } from "@pages/Settings/Settings";
import { useGetReservationSettingsQuery, useUpdateReservationSettingsMutation } from "@store/services/reservationSettingsService";
import { palette } from "@theme/palette";
import { AppRoutes } from "@utils/enums";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppointmentsTypesConfigSection from "./fragments/AppointmentsTypesConfigSection/AppointmentsTypesConfigSection";
import AvaliabilityHoursConfig from "./fragments/AvailabilityHoursConfigSection/AvailabilityHoursConfig";
import ReservationCalendarSection from "./fragments/ReservationCalendarSection/ReservationCalendarSection";
import ReservationLinkSection from "./fragments/ReservationLinkSection";
import SettingsSection from "./fragments/SettingsSection";

interface IReservationsProps extends SnackbarProps {}
const Reservations: React.FC<IReservationsProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const { data: reservationSettings } = useGetReservationSettingsQuery();
  const isReservationEnabled = reservationSettings?.isReservationEnabled;

  const [updateReservationSettings, { isLoading: isUpdating }] = useUpdateReservationSettingsMutation();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;

    updateReservationSettings({ isReservationEnabled: newValue })
      .unwrap()
      .then(() => {
        setValue(0);
        snackbarShowMessage(t("reservations.settings.snackbar_message.edit.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("reservations.settings.snackbar_message.edit.error"), "error");
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!reservationSettings) return null;

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_RESERVATIONS)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("reservations.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          gap={5}
          title={t("reservations.title")}
          rightContent={
            <FormControlLabel
              control={<Switch checked={isReservationEnabled} onChange={handleSwitchChange} disabled={isUpdating} />}
              label={
                <Typography variant="subtitle1">
                  {t(!isReservationEnabled ? "reservations.settings.reservation_enable" : "reservations.settings.reservation_disable")}
                </Typography>
              }
            />
          }
        />
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tabs value={value} onChange={handleChange} variant="scrollable">
                {rbacCheck(userRole, RBACActions.ACTION_GET_RESERVATIONS) && (
                  <Tab label={t("reservations.tabs.calendar_reservations")} value={0} disabled={!isReservationEnabled} />
                )}
                {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                  <Tab label={t("reservations.tabs.working_hours")} value={1} disabled={!isReservationEnabled} />
                )}
                {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                  <Tab label={t("reservations.tabs.appointments_types_config")} value={2} disabled={!isReservationEnabled} />
                )}
                {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                  <Tab label={t("reservations.tabs.reservation_link")} value={3} disabled={!isReservationEnabled} />
                )}
                {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                  <Tab label={t("reservations.tabs.settings")} value={4} disabled={!isReservationEnabled} />
                )}
              </Tabs>
              <Box position="relative">
                <CustomTabPanel value={value} index={0}>
                  <Alert severity="warning" sx={{ my: 2 }}>
                    {t("reservations.settings.alert_message")}
                  </Alert>
                  <ReservationCalendarSection snackbarShowMessage={snackbarShowMessage} />
                </CustomTabPanel>
                {!isReservationEnabled && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={alpha(palette.grey[100], 0.8)}
                    zIndex={1}
                  >
                    <Box textAlign="center" sx={{ mx: 2 }}>
                      <Typography variant="h6" mt={2} color="text.disabled">
                        {t("reservations.settings.enable_reservations_message")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                <CustomTabPanel value={value} index={1}>
                  <AvaliabilityHoursConfig snackbarShowMessage={snackbarShowMessage} />
                </CustomTabPanel>
              )}
              {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                <CustomTabPanel value={value} index={2}>
                  <AppointmentsTypesConfigSection snackbarShowMessage={snackbarShowMessage} />
                </CustomTabPanel>
              )}
              {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                <CustomTabPanel value={value} index={3}>
                  <ReservationLinkSection {...{ snackbarShowMessage }} />
                </CustomTabPanel>
              )}
              {rbacCheck(userRole, RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS) && (
                <CustomTabPanel value={value} index={4}>
                  <SettingsSection {...{ reservationSettings, snackbarShowMessage }} />
                </CustomTabPanel>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default withSnackbar(Reservations);
