import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { SnackbarProps } from "@hoc/AlertPopover";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardHeader,
  DialogActions,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAppointmentStatusQuery, useUpdateAppointmentStatusShareMutation } from "@store/services/appointmentStatusService";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { IAppointmentStatus } from "appointment.types";
import { Field, Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAppointmentsStatusSectionProps extends SnackbarProps {}

interface FormValues extends Partial<IAppointmentStatus> {
  [key: string]: any;
}

const AppointmentsStatusSection: React.FC<IAppointmentsStatusSectionProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);

  const [editAppointmentStatus, { isLoading: isEditAppointmentStatus }] = useUpdateAppointmentStatusShareMutation();

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const { appointmentStatus } = useGetAppointmentStatusQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentStatus: data || [],
      }),
    }
  );

  const handleFormSubmit = useCallback(
    (values: FormValues, actions: any, initialValues: FormValues) => {
      const changedValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {} as FormValues);

      const promises = Object.keys(changedValues).map((statusId) =>
        editAppointmentStatus({ statusId, updatedValue: changedValues[statusId] })
          .unwrap()
          .then(() => {
            snackbarShowMessage(t("appointments.snackbar_message.toggle_status.success"), "success");
          })
          .catch(() => {
            snackbarShowMessage(t("appointments.snackbar_message.toggle_status.error"), "error");
          })
      );

      Promise.all(promises).finally(() => {
        actions.setSubmitting(false);
        if (isEditMode) toggleEditMode();
      });
    },
    [editAppointmentStatus, isEditMode, snackbarShowMessage]
  );

  if (!appointmentStatus) return null;

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title={t("appointments.status_section.title")} subheader={t("appointments.status_section.subtitle")} />
      <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
      <Formik
        initialValues={appointmentStatus.reduce((acc, status) => {
          acc[status._id] = status.value;
          return acc;
        }, {} as FormValues)}
        onSubmit={(values, actions) =>
          handleFormSubmit(
            values,
            actions,
            appointmentStatus.reduce((acc, status) => {
              acc[status._id] = status.value;
              return acc;
            }, {} as FormValues)
          )
        }
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TableContainer sx={{ mb: 2 }} id="appointment-status-edit">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" sx={{ ml: 2 }}>
                        {t("appointments.status_section.name")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2" sx={{ mr: 2 }}>
                        {t("appointments.status_section.price_in_percentage")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointmentStatus.map((status) => (
                    <TableRow key={status._id}>
                      <TableCell>
                        <Box sx={{ ml: 2 }}>
                          <SeverityPill color={getAppointmentStatusColor(status.status)}>{t(getAppointmentStatusText(status.status))}</SeverityPill>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Field
                          sx={{ mr: 2 }}
                          as={TextField}
                          size="small"
                          type="number"
                          name={String(status._id)}
                          disabled={!isEditMode}
                          label={t("appointments.status_section.price_in_percentage_label")}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogActions>
              <Button onClick={toggleEditMode} variant="outlined" color="secondary" id="appointment-status-edit-button">
                {isEditMode ? t("cancel") : t("edit")}
              </Button>
              {isEditMode && (
                <LoadingButton loading={isEditAppointmentStatus} type="submit" variant="contained" color="secondary">
                  {t("submit")}
                </LoadingButton>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default AppointmentsStatusSection;
