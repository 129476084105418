import { useContext } from "react";
import { RoleContext } from "./RBACProvider";
import rules, { RBACActions, RBACRoles } from "../rbac-rules";

interface IRBACProps {
  action: RBACActions;
  data?: any;
  yes(): React.ReactElement;
  no?(): React.ReactElement;
  context?: RBACRoles;
}
export const rbacCheck = (role: RBACRoles, action: RBACActions) => {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }
  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }
  return false;
};

const RBAC: React.FC<IRBACProps> = (props) => {
  const userRole = useContext(RoleContext);

  const role = props.context || userRole;
  return rbacCheck(role, props.action) ? props.yes() : props?.no?.() || null;
};

export default RBAC;
