import useResponsive from "@hooks/useResponsive";
import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

interface IPageTitleProps {
  titleId?: string;
  gap?: number;
  title: string;
  avatarSrc?: string;
  bottomContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  isLoading?: boolean;
}

const PageTitle: React.FC<IPageTitleProps> = (props) => {
  const { title, bottomContent, rightContent, gap = 8, isLoading = false, avatarSrc, titleId } = props;
  const isDesktop = useResponsive("up", "sm");

  return (
    <Stack spacing={3} sx={{ mb: isDesktop ? gap : 4 }}>
      <Stack direction={isDesktop ? "row" : "column"} justifyContent="space-between" spacing={isDesktop ? 4 : 2}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {avatarSrc &&
              (isLoading ? (
                <Skeleton variant="rounded" width={56} height={56} />
              ) : (
                <Avatar
                  src={avatarSrc}
                  sx={{
                    width: 56,
                    height: 56,
                  }}
                />
              ))}
            {isLoading ? (
              <Skeleton variant="rounded" width={200} height={32} />
            ) : (
              <Typography variant="h4" id={titleId}>
                {title}
              </Typography>
            )}
          </Stack>
          {bottomContent && (isLoading ? <Skeleton variant="rounded" width="100%" height={20} /> : bottomContent)}
        </Stack>
        {rightContent && <Box>{isLoading ? <Skeleton variant="rounded" width={150} height={30} /> : rightContent}</Box>}
      </Stack>
    </Stack>
  );
};

export default PageTitle;
