import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Button, Stack, Typography } from "@mui/material";
import { NavigateAction } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { useTranslation } from "react-i18next";

interface ICalendarToolbarProps {
  startWeek: Date;
  endWeek: Date;
  handleNavigate: (type: NavigateAction) => void;
}

const CalendarToolbar: React.FC<ICalendarToolbarProps> = ({ startWeek, endWeek, handleNavigate }) => {
  const { t } = useTranslation();
  const formatWeekRange = formatDate(startWeek, "dd MMM") + " - " + formatDate(endWeek, "dd MMM yyyy");

  return (
    <Stack sx={{ mb: 2 }} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">{formatWeekRange}</Typography>
      <Stack spacing={3} direction="row">
        <IconWithTooltip
          label={t("appointments.calendar.previous_button")}
          icon={<ArrowBackIosNewRoundedIcon />}
          onClick={() => handleNavigate(NavigateAction.PREV)}
        />
        <IconWithTooltip
          label={t("appointments.calendar.next_button")}
          icon={<ArrowForwardIosRoundedIcon />}
          onClick={() => handleNavigate(NavigateAction.NEXT)}
        />
        <Button startIcon={<HomeRoundedIcon />} color="secondary" variant="contained" onClick={() => handleNavigate(NavigateAction.TODAY)}>
          {t("appointments.calendar.today_button")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CalendarToolbar;
