import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import { useModal } from "@hooks/useModal";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { Avatar, Box, IconButton, Stack, Tooltip, Typography, styled } from "@mui/material";
import { parseToUTF8 } from "@utils/typographyUtils";
import { getColorByFileExtension } from "@utils/utils";
import { parseISO } from "date-fns";
import { IFile } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";
import FilePreviewModal from "./modals/FilePreviewModal";

export const CustomStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius * 2,
  cursor: "pointer",
  transition: theme.transitions.create(["box-shadow", "transform"], {
    duration: theme.transitions.duration.standard,
  }),
  "&:hover": {
    boxShadow: theme.shadows[3],
  },
}));

interface FileListItemProps {
  fileId: string;
  filename: string;
  size: number;
  createdAt: string;
  url: string;
  completeFile: IFile;
  onDelete: (fileId: string) => void;
  formatDate: (date: Date, format: string) => string;
}

const FileListItem: React.FC<FileListItemProps> = ({ fileId, filename, size, createdAt, completeFile, onDelete, formatDate, url }) => {
  const { t } = useTranslation();
  const [filePreviewModalOpen, toggleFilePreviewModal] = useModal();

  const openFile = (url: string) => {
    window.open(url, "_blank");
  };

  const handleClick = () => {
    if (filename.endsWith(".docx")) {
      openFile(url);
    } else {
      toggleFilePreviewModal();
    }
  };

  return (
    <>
      <CustomStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        key={fileId}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        <Stack alignItems="center" direction="row">
          <Avatar sx={{ bgcolor: getColorByFileExtension(parseToUTF8(filename)) }}>
            <DescriptionRoundedIcon />
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" color="text.primary" noWrap>
              {parseToUTF8(filename)}
            </Typography>
            <Typography variant="caption" color="text.primary" noWrap>
              {`${size} MB • ${formatDate(parseISO(createdAt), "MMMM d, yyyy HH:mm")}`}
            </Typography>
          </Box>
        </Stack>
        <RBAC
          action={RBACActions.ACTION_DELETE_PATIENT}
          yes={() => (
            <Tooltip title={t("file.delete_file")} enterDelay={300}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(fileId);
                }}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </CustomStack>
      <FilePreviewModal file={completeFile} open={filePreviewModalOpen} onClose={toggleFilePreviewModal} />
    </>
  );
};

export default FileListItem;
