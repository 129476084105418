import Iconify from "@components/Iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, Link, Stack, TextField } from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { ILogin } from "auth-types";
import { FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ILoginForm {
  formik: FormikProps<ILogin>;
  isLoading: boolean;
}

const LoginForm: React.FC<ILoginForm> = ({ formik, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="email"
            name="email"
            label={t("sign_in.email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && t(formik.errors.email)}
          />
          <TextField
            id="password"
            name="password"
            label={t("sign_in.password")}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && t(formik.errors.password)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} color="secondary">
          {t("sign_in.title")}
        </LoadingButton>
        <Link
          onClick={() => navigate(AppRoutes.PasswordReset)}
          variant="subtitle2"
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {t("sign_in.forgot_password")}
        </Link>
      </form>
    </>
  );
};
export default LoginForm;
