import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import DeleteAppointmentModal from "@components/modals/Appointments/DeleteAppointmentModal";
import EditAppointmentModal from "@components/modals/Appointments/EditAppointmentModal";
import ToggleAppointmentStatusModal from "@components/modals/Appointments/ToggleAppointmentStatusModal";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Box, IconButton, Stack, Typography, alpha, styled } from "@mui/material";
import EventPopover from "@pages/Appointments/fragments/EventPopover";
import { useDeleteAppointmentMutation, useEditAppointmentMutation, useToggleAppointmentStatusMutation } from "@store/services/appointmentsService";
import { AppointmentStatus } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { formatDate, getFormattedTime } from "@utils/timeUtils";
import { IAppointment } from "appointment.types";
import { IAppointmentType } from "appointmentTypes.types";
import { isSameDay, parseISO } from "date-fns";
import { IPatient } from "patients-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import UpcomingAppointmentsMenu from "./UpcomingAppointmentsMenu";

const StyledDateBadge = styled(Stack)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  borderRadius: `0.75rem`,
  minWidth: `2.75rem`,
  height: `4rem`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

interface IUpcomingAppointmentsWidgetItemProps extends SnackbarProps {
  appointment: IAppointment;
  appointmentTypes: IAppointmentType[];
  patients: IPatient[];
}

const UpcomingAppointmentsWidgetItem: React.FC<IUpcomingAppointmentsWidgetItemProps> = ({
  appointment,
  patients,
  appointmentTypes,
  snackbarShowMessage,
}) => {
  const { t } = useTranslation();

  const { title, startDate, endDate, seriesId } = appointment;

  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);
  const [anchorEventPopover, setAnchorEventPopover] = useState<HTMLButtonElement | null>(null);

  const [isEditAppointmentModalOpen, toggleEditAppointmentModal] = useModal();
  const [isDeleteAppointmentModalOpen, toggleDeleteAppointmentModal] = useModal();
  const [isAppointmentStatusModalOpen, toggleAppointmentStatusModal] = useModal();

  const handleCloseEditModal = () => handleCloseModal([], toggleEditAppointmentModal);
  const handleCloseDeleteModal = () => handleCloseModal([], toggleDeleteAppointmentModal);

  const [editAppointment, { isLoading: isEditAppointmentLoading }] = useEditAppointmentMutation();
  const [deleteAppointment, { isLoading: isDeleteAppointmentLoading }] = useDeleteAppointmentMutation();
  const [toggleAppointmentStatus, { isLoading: isToggleAppointmentStatusLoading }] = useToggleAppointmentStatusMutation();

  const handleEditAppointment = useCallback(
    (appointmentData: Partial<IAppointment>) => {
      if (!appointment) return;
      if (!isSameDay(new Date(appointmentData.startDate), new Date(appointmentData.endDate))) {
        snackbarShowMessage(t("appointments.snackbar_message.same_day_error"), "error");
        return;
      }

      editAppointment({ appointmentId: appointment._id, data: appointmentData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments_updaappointments.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          handleCloseEditModal();
        });
    },
    [editAppointment, snackbarShowMessage]
  );

  const handleDeleteAppointment = useCallback(
    (deleteSeries: boolean) => {
      if (!appointment) return;

      deleteAppointment({ appointmentId: appointment._id, deleteSeries })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete.error"), "error");
        })
        .finally(() => {
          handleCloseDeleteModal();
        });
    },
    [deleteAppointment, snackbarShowMessage]
  );

  const handleToggleAppointmentStatus = useCallback(
    (status: AppointmentStatus) => {
      toggleAppointmentStatus({ appointmentId: appointment?._id, data: { appointmentStatus: status } })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.toggle_status.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.toggle_status.error"), "error");
        })
        .finally(() => {
          toggleAppointmentStatusModal();
          handleCloseEventPopover();
        });
    },
    [toggleAppointmentStatus, snackbarShowMessage, appointment]
  );

  const handleCloseEventPopover = useCallback(() => {
    setAnchorEventPopover(null);
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementMenu(event.currentTarget);
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2, justifyContent: "space-between" }}>
        <StyledDateBadge>
          <Typography color="text.secondary" variant="caption">
            {formatDate(parseISO(startDate), "MMM").toUpperCase()}
          </Typography>
          <Typography variant="h6">{formatDate(parseISO(startDate), "d")}</Typography>
        </StyledDateBadge>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" noWrap>
              {title}
            </Typography>
            {seriesId ? (
              <SeverityPill color="warning">{t("appointments.frequency.repeated_weekly")}</SeverityPill>
            ) : (
              <SeverityPill color="primary">{t("appointments.frequency.one_time")}</SeverityPill>
            )}
          </Stack>
          <Typography color="text.secondary">
            {t("appointments.frequency.time_range", {
              start: getFormattedTime(parseISO(startDate)),
              end: getFormattedTime(parseISO(endDate)),
            })}
          </Typography>
        </Box>
        <RBAC
          action={RBACActions.ACTION_EDIT_APPOINTMENT || RBACActions.ACTION_DELETE_APPOINTMENT}
          yes={() => (
            <IconButton onClick={handleOpenMenu}>
              <MoreVertRoundedIcon />
            </IconButton>
          )}
          no={() => (
            <IconButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEventPopover(e.currentTarget);
              }}
            >
              <CalendarTodayRoundedIcon fontSize="small" />
            </IconButton>
          )}
        />
      </Box>
      <UpcomingAppointmentsMenu
        {...{ anchorElementMenu }}
        {...{ handleCloseMenu }}
        {...{ toggleEditAppointmentModal, toggleAppointmentStatusModal, toggleDeleteAppointmentModal }}
      />
      <EventPopover selectedAppointment={appointment} anchorEventPopover={anchorEventPopover} handleCloseEventPopover={handleCloseEventPopover} />
      <ToggleAppointmentStatusModal
        selectedAppointment={appointment}
        open={isAppointmentStatusModalOpen}
        onClose={toggleAppointmentStatusModal}
        onSubmit={handleToggleAppointmentStatus}
        loading={isToggleAppointmentStatusLoading}
      />
      <EditAppointmentModal
        employees={[]}
        patients={patients}
        appointmentTypes={appointmentTypes}
        initialValues={appointment}
        open={isEditAppointmentModalOpen}
        onClose={toggleEditAppointmentModal}
        onSubmit={handleEditAppointment}
        loading={isEditAppointmentLoading}
      />
      <DeleteAppointmentModal
        selectedAppointment={appointment}
        open={isDeleteAppointmentModalOpen}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDeleteAppointment}
        loading={isDeleteAppointmentLoading}
      />
    </>
  );
};
export default UpcomingAppointmentsWidgetItem;
