import ClinicSelection from "@pages/ClinicSelection/ClinicSelection";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { selectCurrentClinicId } from "@store/features/clinicSlice";
import { useGetAllClinicsQuery } from "@store/services/clinicsService";
import { IClinic } from "clinics-types";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IUserContextType, UserContext } from "./UserProvider";

export interface IClinicContext {
  currentClinic: IClinic | null;
  setCurrentClinic: React.Dispatch<React.SetStateAction<IClinic | null>>;
}

export const ClinicContext = createContext<IClinicContext | null>(null);

export const ClinicProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentClinic, setCurrentClinic] = useState<IClinic | null>(null);

  const usersTokens = useSelector(selectCurrentUserTokens);
  const selectedClinic = useSelector(selectCurrentClinicId);
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const { clinics, isLoading, isSuccess, isFetching } = useGetAllClinicsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess, isFetching }) => ({
      clinics: data || [],
      isLoading,
      isSuccess,
      isFetching,
    }),
  });

  useEffect(() => {
    if (!isLoading && !isFetching && isSuccess) {
      const hasClinics = clinics.length > 0;
      window.StonlyWidget("identify", userDetails._id, {
        has_clinics: hasClinics,
      });
    }
  }, [isSuccess, clinics, userDetails, isFetching]);

  useEffect(() => {
    if (isSuccess && clinics && selectedClinic) {
      const clinicDetails = clinics.find((clinic) => clinic._id === selectedClinic);
      if (clinicDetails) {
        setCurrentClinic(clinicDetails);
      }
    }
  }, [isSuccess, clinics, selectedClinic]);

  if (!selectedClinic && !!usersTokens) {
    return <ClinicSelection clinics={clinics} isClinicsLoading={isLoading} isClinicsInitialized={isSuccess} />;
  }

  return <ClinicContext.Provider value={{ currentClinic, setCurrentClinic }}>{children}</ClinicContext.Provider>;
};
