import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import { Skeleton, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointmentTypeOnlineReservation } from "appointmentTypes.types";
import { IEmployee } from "employees-types";
import { motion } from "framer-motion";
import { IFile } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface SelectionSectionProps {
  appointmentsTypes: IAppointmentTypeOnlineReservation[];
  selectedAppointmentType: string | null;
  assignedEmployees: IEmployee[];
  selectedEmployee: string | null;
  clinicInfo: { name: string; files: IFile[] } | undefined;
  isClinicInfoLoading: boolean;
  isAppointmentsTypesLoading: boolean;
  handleAppointmentTypeSelect: (appointmentId: string) => void;
  setSelectedEmployee: React.Dispatch<React.SetStateAction<string | null>>;
}

const SelectionSection: React.FC<SelectionSectionProps> = ({
  appointmentsTypes,
  selectedAppointmentType,
  assignedEmployees,
  selectedEmployee,
  clinicInfo,
  isClinicInfoLoading,
  isAppointmentsTypesLoading,
  handleAppointmentTypeSelect,
  setSelectedEmployee,
}) => {
  const { t } = useTranslation();

  const handleAppointmentChange = (event: SelectChangeEvent) => {
    handleAppointmentTypeSelect(event.target.value);
  };

  const handleEmployeeChange = (event: SelectChangeEvent) => {
    setSelectedEmployee(event.target.value);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" sx={{ p: 1 }}>
      <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        <Typography variant="h6" fontWeight="bold">
          {t("selection_section.book_online_appointment")}
        </Typography>
      </motion.div>

      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mb: 4 }}
        component={motion.div}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {isClinicInfoLoading ? (
          <>
            <Skeleton variant="circular" width={45} height={45} />
            <Skeleton variant="text" width={200} height={30} />
          </>
        ) : (
          <>
            <Avatar src={clinicInfo?.files?.[0]?.url} sx={{ width: 20, height: 20 }} variant="rounded" />
            <Typography variant="body2" color="text.secondary">
              {clinicInfo?.name}
            </Typography>
          </>
        )}
      </Stack>

      {isAppointmentsTypesLoading ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 3 }} />
          <Skeleton variant="rectangular" width="100%" height={60} />
        </motion.div>
      ) : (
        <>
          <FormControl
            fullWidth
            sx={{ mb: 3 }}
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <InputLabel id="appointment-type-select-label">{t("selection_section.select_appointment_type")}</InputLabel>
            <Select value={selectedAppointmentType || ""} onChange={handleAppointmentChange} label={t("selection_section.select_appointment_type")}>
              {appointmentsTypes.map((appointment) => (
                <MenuItem key={appointment._id} value={appointment._id}>
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="subtitle2" fontWeight="600" noWrap>
                      {appointment.name}
                    </Typography>
                    {appointment?.pricing && (
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        {formatCost(appointment.pricing.cost)} - {formatDuration(appointment.pricing.duration)}
                      </Typography>
                    )}
                    {appointment.description && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          maxWidth: 300,
                          fontSize: 12,
                        }}
                      >
                        {appointment.description}
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t("selection_section.select_appointment_type_helper")}</FormHelperText>
          </FormControl>

          <Typography
            variant="subtitle2"
            fontWeight="600"
            gutterBottom
            component={motion.div}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {t("selection_section.specialist")}
          </Typography>

          <FormControl
            fullWidth
            disabled={!selectedAppointmentType}
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <InputLabel id="specialist-select-label">{t("selection_section.select_specialist")}</InputLabel>
            <Select value={selectedEmployee || ""} onChange={handleEmployeeChange} label={t("selection_section.select_specialist")}>
              {assignedEmployees.map((employee) => (
                <MenuItem key={employee._id} value={employee._id}>
                  <UsernameAvatar avatar firstname={employee.firstname} lastname={employee.lastname} email={employee.email} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {selectedAppointmentType ? t("selection_section.select_specialist_helper") : t("selection_section.select_specialist_disabled_helper")}
            </FormHelperText>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default SelectionSection;
