import { Card, CardHeader, Divider } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface RichTextEditorProps {
  header: string;
  subheader: string;
  value: string;
  onChange: (value: string) => void;
  toolbarOptions?: Array<any>;
  height?: string | number;
}

export interface RichTextEditorHandle {
  getContent: () => string;
}

const RichTextEditor = forwardRef<RichTextEditorHandle, RichTextEditorProps>(
  ({ header, subheader, value, onChange, toolbarOptions, height }, ref) => {
    const quillRef = useRef<ReactQuill | null>(null);

    useImperativeHandle(ref, () => ({
      getContent: () => {
        if (quillRef.current) {
          return quillRef.current.getEditor().root.innerHTML;
        }
        return "";
      },
    }));

    const modules = {
      toolbar: toolbarOptions || [
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
      ],
    };

    const editorStyle = {
      minHeight: height || "300px",
    };

    return (
      <Card>
        <CardHeader title={header} subheader={subheader} />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <ReactQuill ref={quillRef} value={value} onChange={onChange} modules={modules} theme="snow" style={editorStyle} className="custom-quill" />
      </Card>
    );
  }
);

export default RichTextEditor;
