import { Autocomplete, TextField, Typography } from "@mui/material";
import { IPatient } from "patients-types";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface PatientAutocompleteProps {
  patients: IPatient[];
  disableClearable?: boolean;
  value?: IPatient | null;
  size?: "small" | "medium";
  width?: number | string;
  placeholder?: string;
  helperText?: string;
  onPatientChange: (data: any) => void;
}

const PatientAutocomplete: FC<PatientAutocompleteProps> = ({
  value,
  patients,
  placeholder,
  helperText,
  size,
  width,
  disableClearable = false,
  onPatientChange,
}) => {
  const { t } = useTranslation();
  const handleFilterChange = useCallback(
    (event: React.SyntheticEvent, newValue: IPatient | null) => {
      onPatientChange(newValue);
    },
    [onPatientChange]
  );

  return (
    <>
      <Autocomplete
        noOptionsText={t("no_options")}
        disableClearable={disableClearable}
        value={value}
        options={patients}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        onChange={handleFilterChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            sx={{
              width: width,
            }}
            placeholder={placeholder}
          />
        )}
      />
      {helperText && (
        <Typography variant="body2" color="text.secondary" sx={{ mx: 2, mt: 0.5 }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default PatientAutocomplete;
