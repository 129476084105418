import { messaging } from "@config/firebaseConfig";
import { useUpdateNotificationTokenMutation } from "@store/services/notificationsService";
import { getToken } from "firebase/messaging";
import { useEffect } from "react";
import { IUserDetails } from "user-types";

const useNotificationToken = (user: IUserDetails) => {
  const [updateNotificationToken] = useUpdateNotificationTokenMutation();

  useEffect(() => {
    const requestAndUpdateToken = async () => {
      if (typeof window === "undefined" || typeof Notification === "undefined") return;

      if (Notification.permission !== "granted") return;

      const currentToken = await getToken(messaging, { vapidKey: import.meta.env.VITE_VAPID_KEY as string });

      if (currentToken && user) {
        const storedToken = localStorage.getItem("fcm-token");

        if (currentToken !== storedToken) {
          localStorage.setItem("fcm-token", currentToken);
        }

        if (user.notificationToken !== storedToken) {
          await updateNotificationToken({ userId: user._id, notificationToken: currentToken });
        }
      }
    };

    requestAndUpdateToken();
  }, [user, updateNotificationToken]);
};

export default useNotificationToken;
