import { Divider, Grid, Paper } from "@mui/material";
import {
  useCreateOnlineReservationMutation,
  useGetAppointmentsTypesForOnlineReservationQuery,
  useGetClinicBasicInfoQuery,
  useGetHoursForOnlineReservationQuery,
  useGetReservationSettingsQuery,
  useGetUserAvailabilityQuery,
} from "@store/services/miscService";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AvailabilitySection from "./fragments/AvailabilitySection";
import SelectionSection from "./fragments/SelectionSection";
import { IAvailabilityDay, IHourSetForOnlineReservation } from "reservation-types";
import { format } from "date-fns";
import ConfirmationSection from "./fragments/ConfirmationSection";
import ConfirmationFormSection from "./fragments/ConfirmationFormSection";
import { motion } from "framer-motion";
import FinalConfirmation from "./fragments/FinalConfirmation";

const OnlineReservation: React.FC = () => {
  const { clinicId } = useParams<{ clinicId: string }>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
  const [userAvailability, setUserAvailability] = useState<IAvailabilityDay[] | undefined>(undefined);
  const [availableHoursForOnlineReservation, setAvailableHoursForOnlineReservation] = useState<IHourSetForOnlineReservation[] | undefined>(undefined);
  const [selectedHourForOnlineReservation, setSelectedHourForOnlineReservation] = useState<IHourSetForOnlineReservation | null>(null);
  const [isReservationConfirmed, setIsReservationConfirmed] = useState<boolean>(false);
  const [reservationDetails, setReservationDetails] = useState<{
    firstName: string;
    lastName: string;
    phoneNumber: number;
    email: string;
  } | null>(null);

  const [createOnlineReservation, { isSuccess: isReservationCreated }] = useCreateOnlineReservationMutation();

  const { data: clinicInfo, isLoading: isClinicInfoLoading } = useGetClinicBasicInfoQuery({ clinicId }, { skip: !clinicId });

  const { data: appointmentsTypes, isLoading: isAppointmentsTypesLoading } = useGetAppointmentsTypesForOnlineReservationQuery(
    { clinicId },
    { skip: !clinicId }
  );

  const { data: reservationSettings } = useGetReservationSettingsQuery(
    { clinicId },
    {
      skip: !clinicId,
    }
  );

  const { data: fetchedUserAvailability } = useGetUserAvailabilityQuery(
    { clinicId, userId: selectedEmployee },
    { skip: !clinicId || !selectedEmployee }
  );

  const selectedAppointment = useMemo(() => {
    return appointmentsTypes?.find((item) => item._id === selectedAppointmentType);
  }, [appointmentsTypes, selectedAppointmentType]);

  const { data: fetchedAvailableHours } = useGetHoursForOnlineReservationQuery(
    {
      clinicId,
      appointmentTypeId: selectedAppointmentType,
      employeeId: selectedEmployee,
      pricingId: selectedAppointment?.pricing._id,
      currentDate: format(new Date(selectedDate), "yyyy-MM-dd"),
    },
    { skip: !clinicId || !selectedAppointmentType || !selectedEmployee || !selectedDate }
  );

  useEffect(() => {
    if (fetchedUserAvailability) {
      setUserAvailability(fetchedUserAvailability);
    }
  }, [fetchedUserAvailability]);

  useEffect(() => {
    if (selectedAppointmentType !== null) {
      setUserAvailability(undefined);
      setSelectedEmployee(null);
    }
  }, [selectedAppointmentType]);

  useEffect(() => {
    if (fetchedAvailableHours) {
      setAvailableHoursForOnlineReservation(fetchedAvailableHours);
    }
  }, [fetchedAvailableHours]);

  useEffect(() => {
    setAvailableHoursForOnlineReservation(undefined);
  }, [selectedAppointmentType, selectedEmployee]);

  useEffect(() => {
    if (selectedHourForOnlineReservation) {
      setIsReservationConfirmed(true);
    }
  }, [selectedHourForOnlineReservation]);

  useEffect(() => {
    if (reservationDetails && isReservationConfirmed && selectedHourForOnlineReservation && selectedAppointment) {
      createOnlineReservation({
        clinicId: clinicId,
        data: {
          firstname: reservationDetails.firstName,
          lastname: reservationDetails.lastName,
          userId: [selectedEmployee!],
          phoneNumber: reservationDetails.phoneNumber,
          email: reservationDetails.email,
          pricingId: selectedAppointment.pricing._id,
          typeId: selectedAppointmentType,
          startDate: selectedHourForOnlineReservation.start,
          endDate: selectedHourForOnlineReservation.end,
        },
      });
    }
  }, [reservationDetails, isReservationConfirmed, selectedHourForOnlineReservation, selectedAppointment, clinicId, selectedEmployee]);

  useEffect(() => {
    const chatWidget = document.getElementById("chat-widget-container");
    if (chatWidget) chatWidget.style.display = "none";

    return () => {
      if (chatWidget) chatWidget.style.display = "";
    };
  }, []);

  useEffect(() => {
    if (selectedEmployee !== null) {
      setSelectedDate(null);
    }
  }, [selectedEmployee]);

  const handleAppointmentTypeSelect = useCallback((appointmentId: string) => {
    setSelectedAppointmentType(appointmentId);
    setSelectedEmployee(null);
  }, []);

  const assignedEmployees = useMemo(() => {
    return selectedAppointment?.assignedEmployees || [];
  }, [selectedAppointment]);

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
      <Grid item xs={12} md={12} lg={9}>
        <Paper elevation={3} sx={{ padding: 2, margin: 4 }}>
          {reservationDetails && isReservationConfirmed && isReservationCreated ? (
            <motion.div initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
              <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="center">
                <Grid item xs={12}>
                  <FinalConfirmation
                    clinicInfo={clinicInfo}
                    selectedEmployee={selectedEmployee}
                    reservationDetails={reservationDetails}
                    selectedAppointment={selectedAppointment}
                    selectedHourForOnlineReservation={selectedHourForOnlineReservation}
                  />
                </Grid>
              </Grid>
            </motion.div>
          ) : isReservationConfirmed ? (
            <motion.div initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
              <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <ConfirmationSection
                    selectedEmployee={selectedEmployee}
                    clinicInfo={clinicInfo}
                    selectedAppointment={selectedAppointment}
                    selectedHourForOnlineReservation={selectedHourForOnlineReservation}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginX: 2, display: "block" }} />
                <Grid item xs={12} sm={12} md={7}>
                  <ConfirmationFormSection setIsReservationConfirmed={setIsReservationConfirmed} setReservationDetails={setReservationDetails} />
                </Grid>
              </Grid>
            </motion.div>
          ) : (
            <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="space-around">
              <Grid item xs={12} sm={12} md={4}>
                <SelectionSection
                  isClinicInfoLoading={isClinicInfoLoading}
                  clinicInfo={clinicInfo}
                  isAppointmentsTypesLoading={isAppointmentsTypesLoading}
                  appointmentsTypes={appointmentsTypes || []}
                  selectedAppointmentType={selectedAppointmentType}
                  assignedEmployees={assignedEmployees}
                  selectedEmployee={selectedEmployee}
                  handleAppointmentTypeSelect={handleAppointmentTypeSelect}
                  setSelectedEmployee={setSelectedEmployee}
                />
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginX: 2, display: "block" }} />
              <Grid item xs={12} sm={12} md={7}>
                <AvailabilitySection
                  availableHoursForOnlineReservation={availableHoursForOnlineReservation}
                  selectedDate={selectedDate}
                  selectedEmployee={selectedEmployee}
                  reservationSettings={reservationSettings}
                  userAvailabilty={userAvailability}
                  setSelectedDate={setSelectedDate}
                  setSelectedHourForOnlineReservation={setSelectedHourForOnlineReservation}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OnlineReservation;
