import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { formatBankAccountNumber } from "@utils/typographyUtils";

interface SellerInfoProps {
  title: string;
  name: string;
  email?: string;
  bankName?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  NIP?: string;
  REGON?: string;
  bankAccountNumber?: string;
  onClick: () => void;
}

const SellerInfo: React.FC<SellerInfoProps> = ({
  title,
  name,
  email,
  bankName,
  address,
  city,
  postalCode,
  NIP,
  REGON,
  bankAccountNumber,
  onClick,
}) => {
  return (
    <Box sx={{ p: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Typography variant="h6" color="text.secondary">
          {title}:
        </Typography>
        <IconWithTooltip label={"Wybierz inne dane sprzedawcy"} icon={<EditRoundedIcon />} onClick={onClick} />
      </Stack>
      <Typography variant="subtitle2">{name}</Typography>

      {email && (
        <Typography variant="body2" color="textSecondary">
          Adres e-mail: {email}
        </Typography>
      )}

      {bankName && (
        <Typography variant="body2" color="textSecondary">
          Bank: {bankName}
        </Typography>
      )}

      {address && (
        <Typography variant="body2" color="textSecondary">
          Adres: {address}, {city}, {postalCode}
        </Typography>
      )}

      {NIP && (
        <Typography variant="body2" color="textSecondary">
          NIP: {NIP}
        </Typography>
      )}

      {REGON && (
        <Typography variant="body2" color="textSecondary">
          REGON: {REGON}
        </Typography>
      )}

      {bankAccountNumber && (
        <Typography variant="body2" color="textSecondary">
          Numer konta: {formatBankAccountNumber(bankAccountNumber)}
        </Typography>
      )}
    </Box>
  );
};

export default SellerInfo;
