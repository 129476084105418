import { RBACActions } from "@auth/rbac-rules";
import RBAC, { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import PageTitle from "@components/PageTitle/PageTitle";
import RichTextEditor from "@components/RichTextEditor/RichTextEditor";
import StatsCard from "@components/StatsCard/StatsCard";
import UpcomingAppointmentsWidget from "@components/UpcomingAppointmentsWidget/UpcomingAppointmentsWidget";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import { Container, Grid } from "@mui/material";
import { useGetAllAppointmentsQuery } from "@store/services/appointmentsService";
import { useGetAppointmentTypesQuery } from "@store/services/appointmentTypesService";
import { useGetInvoiceStatsQuery } from "@store/services/invoiceService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { useGetUserNoteQuery, useUpdateUserNoteMutation } from "@store/services/usersService";
import { AppointmentStatus, AppRoutes, InvoiceStatus, PatientStatus } from "@utils/enums";
import { adjustAppointment, formatDate } from "@utils/timeUtils";
import { addHours, endOfMonth, format, startOfMonth } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDebounce from "@hooks/useDebounce";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";

function ClinicDashboard({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updateUserNote] = useUpdateUserNoteMutation();

  const userRole = useContext(RoleContext);
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const [noteContent, setNoteContent] = useState<string>("");
  const [originalNoteContent, setOriginalNoteContent] = useState<string>("");

  const debouncedNoteContent = useDebounce(noteContent, 1000);

  const { noteData } = useGetUserNoteQuery(
    {
      userId: userDetails?._id,
    },
    {
      selectFromResult: ({ data }) => ({
        noteData: data || { note: "" },
      }),
      skip: !rbacCheck(userRole, RBACActions.ACTION_GET_USER_NOTE),
    }
  );

  const { patients } = useGetAllPatientsQuery(
    {
      patientStatus: PatientStatus.ACTIVE,
    },
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  const { appointments } = useGetAllAppointmentsQuery(
    {
      start: format(new Date(), "yyyy-MM-dd"),
      from: format(addHours(new Date(), 24), "yyyy-MM-dd"),
      appointmentStatus: [AppointmentStatus.PRESENT, AppointmentStatus.NOT_EXCUSED, AppointmentStatus.PENDING],
    },
    {
      selectFromResult: ({ data }) => ({
        appointments: data || [],
      }),
    }
  );

  const adjustedAppointments = useMemo(() => appointments.map(adjustAppointment), [appointments]);

  const { appointmentTypes } = useGetAppointmentTypesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
      skip: !rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENT_TYPES),
    }
  );

  const { invoiceStats } = useGetInvoiceStatsQuery(
    {
      startDate: startOfMonth(new Date()).toISOString(),
      endDate: endOfMonth(new Date()).toISOString(),
    },
    {
      selectFromResult: ({ data }) => ({
        invoiceStats: data || [],
      }),
      skip: !rbacCheck(userRole, RBACActions.ACTION_GET_INVOICE_DATA),
    }
  );

  const { employees } = useGetAllEmployeesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      employees: data || [],
    }),
    skip: !rbacCheck(userRole, RBACActions.ACTION_GET_EMPLOYESS),
  });

  const handleNoteChange = (value: string) => {
    setNoteContent(value);
  };

  const handleNoteSave = async (content: string) => {
    try {
      await updateUserNote({ userId: userDetails._id, content }).unwrap();
      setOriginalNoteContent(content);
      snackbarShowMessage && snackbarShowMessage(t("dashboard.snackbar_message.update_note.success"), "success");
    } catch (error) {
      snackbarShowMessage && snackbarShowMessage(t("dashboard.snackbar_message.update_note.error"), "error");
    }
  };

  const overdueInvoiceCount = useMemo(() => {
    if (Array.isArray(invoiceStats)) {
      const overdueStat = invoiceStats.find((stat) => stat.status === InvoiceStatus.OVERDUE);
      return overdueStat ? overdueStat.invoiceCount : 0;
    }
    return 0;
  }, [invoiceStats]);

  useEffect(() => {
    if (noteData && noteData.note !== undefined) {
      setNoteContent(noteData.note);
      setOriginalNoteContent(noteData.note);
    }
  }, [noteData]);

  useEffect(() => {
    if (debouncedNoteContent !== originalNoteContent) {
      handleNoteSave(debouncedNoteContent);
    }
  }, [debouncedNoteContent]);

  return (
    <>
      <Helmet>
        <title>{t("dashboard.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle gap={5} title={t("dashboard.page_title")} titleId={"dashboard-page-title"} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={!rbacCheck(userRole, RBACActions.MAIN_MENU_EMPLOYEES) ? 6 : 3}>
            <StatsCard
              icon={<PeopleAltRoundedIcon sx={{ fontSize: 40 }} />}
              value={`${patients.length}/∞`}
              label={t("dashboard.patients")}
              color="success"
              onClick={() => navigate(AppRoutes.Patients)}
            />
          </Grid>
          <RBAC
            action={RBACActions.MAIN_MENU_EMPLOYEES}
            yes={() => (
              <Grid item xs={12} sm={3}>
                <StatsCard
                  icon={<WatchLaterRoundedIcon sx={{ fontSize: 40 }} />}
                  value={formatDate(new Date("2024-12-31"), "dd.MM.yyyy")}
                  label={t("dashboard.date")}
                  color="info"
                  onClick={() => window.open("https://logopedaplus.com/#cennik", "_blank")}
                />
              </Grid>
            )}
          />
          <Grid item xs={12} sm={!rbacCheck(userRole, RBACActions.MAIN_MENU_EMPLOYEES) ? 6 : 3}>
            <StatsCard
              icon={<CalendarMonthRoundedIcon sx={{ fontSize: 40 }} />}
              value={appointments.length}
              label={t("dashboard.appointments")}
              color="primary"
              onClick={() => navigate(AppRoutes.Appointments)}
            />
          </Grid>
          <RBAC
            action={RBACActions.MAIN_MENU_INVOICE}
            yes={() => (
              <Grid item xs={12} sm={3}>
                <StatsCard
                  icon={<ErrorOutlineRoundedIcon sx={{ fontSize: 40 }} />}
                  value={overdueInvoiceCount}
                  label={t("dashboard.overdue_invoices")}
                  onClick={() => navigate(AppRoutes.Invoices)}
                  color="error"
                />
              </Grid>
            )}
          />
          <Grid item xs={12} sm={5}>
            <UpcomingAppointmentsWidget
              appointments={adjustedAppointments}
              appointmentTypes={appointmentTypes}
              employees={employees}
              patients={patients}
              snackbarShowMessage={snackbarShowMessage}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <RichTextEditor
              header={t("dashboard.rich_text_editor_header")}
              subheader={t("dashboard.rich_text_editor_subheader")}
              value={noteContent}
              onChange={handleNoteChange}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withSnackbar(ClinicDashboard);
