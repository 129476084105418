import { createContext, useState } from "react";
import { IUserDetails } from "user-types";

export interface IUserContextType {
  userDetails: IUserDetails;
  setUserDetails: React.Dispatch<React.SetStateAction<IUserDetails>>;
}

export const UserContext = createContext<IUserContextType | null>(null);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<IUserDetails>();

  return <UserContext.Provider value={{ userDetails, setUserDetails }}>{children}</UserContext.Provider>;
};
