import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { INotification } from "notification.types";
import { fToNow } from "../../../../../utils/timeUtils";
import Iconify from "../../../../Iconify/Iconify";
interface NotificationItem {
  notification: INotification;
}

const NotificationItem: React.FC<NotificationItem> = ({ notification }) => {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }} src={notification.image} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="body2">{notification.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {notification.message}
            </Typography>
          </>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.dateSent)}
          </Typography>
        }
      />
    </ListItemButton>
  );
};
export default NotificationItem;
