import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACRoles } from "@auth/rbac-rules";
import ClientDashboard from "@pages/ClientDashboard/ClientDashboard";
import ClinicDashboard from "@pages/ClinicDashboard/ClinicDashboard";
import React, { useContext, useMemo } from "react";

const Main: React.FC = () => {
  const userRole = useContext(RoleContext);

  const roleToComponentMap = useMemo(
    () => ({
      [RBACRoles.CLIENT]: <ClientDashboard />,
      [RBACRoles.OWNER]: <ClinicDashboard />,
      [RBACRoles.EMPLOYEE]: <ClinicDashboard />,
      [RBACRoles.ADMIN]: <ClinicDashboard />,
    }),
    []
  );
  return roleToComponentMap[userRole] || null;
};

export default Main;
