import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import ReportsFilter from "@components/ReportsFilter/ReportsFilter";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { Container, Grid, Typography } from "@mui/material";
import { useCalculateExpensesQuery } from "@store/services/expensesService";
import { useGetReportsQuery } from "@store/services/reportsService";
import { AppointmentStatus, AppRoutes, SortBy, SortOrder } from "@utils/enums";
import { endOfMonth, getMonth, startOfMonth } from "date-fns";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IReport } from "reports-types";
import AppointmentTypeIncomeBarChart from "./fragments/AppointmentTypeIncomeBarChart";
import AppointmentTypeIncomePieChart from "./fragments/AppointmentTypeIncomePieChart";
import PatientIncomeChart from "./fragments/PatientIncomeChart";
import ReportsStats from "./fragments/ReportsStats";
import { formatDate } from "@utils/timeUtils";

interface IReportsProps extends SnackbarProps {}

const Reports: React.FC<IReportsProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.TOTAL_INCOME);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);

  const { reports } = useGetReportsQuery(
    {
      month: getMonth(selectedDate),
      appointmentStatus: selectedStatuses,
      sortBy,
      sortOrder,
    },
    {
      selectFromResult: ({ data }) => ({
        reports: data || ({ incomeByPatient: [] } as IReport),
      }),
    }
  );
  const { expensesStats } = useCalculateExpensesQuery(
    { month: getMonth(selectedDate) },
    {
      selectFromResult: ({ data }) => ({
        expensesStats: data || [],
      }),
    }
  );

  const handleMonthChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        setSelectedDate(newDate);
      }
    },
    [setSelectedDate]
  );

  const totalExpense = useMemo(() => expensesStats.find((item) => item.type === "total"), [expensesStats]);

  if (!reports || !expensesStats) return null;
  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("reports.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={t("reports.page_title")}
          gap={3}
          bottomContent={
            <Typography variant="subtitle2" color="text.secondary">
              {`${t("reports.period")} ${formatDate(startOfMonth(selectedDate), "dd.MM.yyyy")} - ${formatDate(
                endOfMonth(selectedDate),
                "dd.MM.yyyy"
              )}`}
            </Typography>
          }
        />
        <Grid container spacing={3}>
          <ReportsStats totalIncome={reports?.totalIncome} totalExpenseValue={totalExpense?.value} />
          <Grid item xs={12}>
            <ReportsFilter
              selectedDate={selectedDate}
              handleMonthChange={handleMonthChange}
              selectedStatuses={selectedStatuses}
              sortBy={sortBy}
              sortOrder={sortOrder}
              setSelectedStatuses={setSelectedStatuses}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              snackbarShowMessage={snackbarShowMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientIncomeChart reports={reports} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <AppointmentTypeIncomeBarChart reports={reports} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AppointmentTypeIncomePieChart reports={reports} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withSnackbar(Reports);
