import BackgroundVideo from "@components/BackgroundVideo/BackgroundVideo";
import Logo from "@components/Logo/Logo";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { AlertColor, Typography } from "@mui/material";
import { logOut } from "@store/features/authSlice";
import { useSendPasswordResetEmailMutation } from "@store/services/authService";
import { resetApiState } from "@store/utils/resetApiState";
import { StyledCard } from "@theme/styled-components/AuthCard";
import { AppRoutes } from "@utils/enums";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withSnackbar } from "src/hoc/AlertPopover";
import { sendResetPasswordEmailValidationSchema } from "src/validations/authValidation";
import PasswordResetForm from "./fragments/PasswordResetForm";

const PasswordReset: React.FC = ({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const [sendResetMail, { isLoading }] = useSendPasswordResetEmailMutation();

  const formik = useFormik({
    initialValues: {
      email: userDetails?.email || "",
    },
    validationSchema: sendResetPasswordEmailValidationSchema,
    onSubmit: (values) => {
      sendResetMail({
        ...values,
      })
        .unwrap()
        .then(() => {
          dispatch(logOut());
          resetApiState(dispatch);
          navigate(AppRoutes.PasswordResetSuccess, { replace: true });
        })
        .catch((error) => snackbarShowMessage(error.data.message, "error"));
    },
  });

  return (
    <>
      <Helmet>
        <title>{t("password_reset.helmet")}</title>
      </Helmet>
      <BackgroundVideo src={"/assets/images/backgrounds/background_hero.mp4"} />
      <Logo />
      <StyledCard>
        <Typography variant="h6" sx={{ mb: -0.5 }}>
          {t("password_reset.title")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }} color="text.secondary">
          {t("password_reset.instructions")}
        </Typography>
        <PasswordResetForm {...{ formik }} {...{ isLoading }} />
      </StyledCard>
    </>
  );
};

export default withSnackbar(PasswordReset);
