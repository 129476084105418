import { forwardRef } from "react";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

interface IIconify {
  sx?: object;
  width?: number;
  height?: number;
  color?: string;
  icon: string;
}

const Iconify: React.FC<IIconify> = forwardRef(
  ({ icon, width = 20, sx, ...other }, ref) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
);

export default Iconify;
