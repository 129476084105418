import { addHours, differenceInMonths, differenceInYears, format, formatDistanceToNow, subHours } from "date-fns";
import { enUS, Locale, pl } from "date-fns/locale";
import i18n from "i18next";
import { capitalizeWord } from "./typographyUtils";
import { t } from "i18next";
import { formatInTimeZone } from "date-fns-tz";
import { TimeZone } from "./enums";

const localeMap: Record<string, Locale> = {
  en: enUS,
  pl: pl,
};

export const locale = localeMap[i18n.language] || pl;

export function fToNow(date: Date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: locale,
      })
    : "";
}

export function formatTime(timeString: string) {
  const [hours, minutes] = timeString.split(":").slice(0, 2);
  return `${hours}:${minutes}`;
}

export const getFormattedTime = (date: Date): string => {
  return format(date, "HH:mm");
};

export function formatDate(date: Date, formatStr: string): string {
  if (!(date instanceof Date && !isNaN(date.getTime()))) return "";

  return capitalizeWord(formatInTimeZone(date, TimeZone.EuropeWarsaw, formatStr, { locale }));
}

export function formatRelativeTime(date: Date): string {
  return capitalizeWord(formatDistanceToNow(date, { addSuffix: true, locale }));
}

const getMonthDeclension = (months: number): string => {
  if (months === 1) {
    return t("month");
  } else if (months >= 2 && months <= 4) {
    return t("months_1");
  } else {
    return t("months_2");
  }
};

export const calculateAge = (dateOfBirth: Date): string => {
  const today = new Date();
  const ageInYears = differenceInYears(today, dateOfBirth);

  if (ageInYears < 1) {
    const ageInMonths = differenceInMonths(today, dateOfBirth);
    const monthDeclension = getMonthDeclension(ageInMonths);
    return `${ageInMonths} ${monthDeclension}`;
  }

  return `${ageInYears}`;
};

export function getTimezoneOffsetInHours(date: Date): number {
  return -date.getTimezoneOffset() / 60;
}

export function adjustAppointment<T extends { startDate: string; endDate: string; createdAt?: string | null }>(appointment: T): T {
  const { startDate, endDate, createdAt } = appointment;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const startDateOffset = getTimezoneOffsetInHours(startDateObj);
  const createdAtOffset = createdAt ? getTimezoneOffsetInHours(new Date(createdAt)) : null;

  let adjustedStartDate = startDateObj;
  let adjustedEndDate = endDateObj;

  if (!createdAt) {
    if (startDateOffset === 1) {
      adjustedStartDate = addHours(startDateObj, 1);
      adjustedEndDate = addHours(endDateObj, 1);
    }
  } else if (createdAtOffset !== null) {
    if (createdAtOffset === 2 && startDateOffset === 1) {
      adjustedStartDate = addHours(startDateObj, 1);
      adjustedEndDate = addHours(endDateObj, 1);
    } else if (createdAtOffset === 1 && startDateOffset === 2) {
      adjustedStartDate = subHours(startDateObj, 1);
      adjustedEndDate = subHours(endDateObj, 1);
    }
  }

  return {
    ...appointment,
    startDate: adjustedStartDate.toISOString(),
    endDate: adjustedEndDate.toISOString(),
  };
}
