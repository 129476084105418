import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IActivityLog } from "activityLog.types";

enum TagTypes {
  ACTIVITY_LOG = "ACTIVITY_LOG",
}

export const activityLogApi = createApi({
  reducerPath: "activityLogApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [TagTypes.ACTIVITY_LOG],
  endpoints: (build) => ({
    getAllActivityLogs: build.query<IActivityLog[], { patientId?: string; month?: string }>({
      query: (queryParams) => ({
        method: "GET",
        url: `activityLogs`,
        params: queryParams,
      }),
      providesTags: [TagTypes.ACTIVITY_LOG],
    }),
    addActivityLog: build.mutation<IActivityLog, Partial<IActivityLog>>({
      query: (newLog) => ({
        method: "POST",
        url: `activityLogs`,
        data: newLog,
      }),
      invalidatesTags: [TagTypes.ACTIVITY_LOG],
    }),
    editActivityLog: build.mutation<IActivityLog, { activityLogId: string; data: Partial<IActivityLog> }>({
      query: ({ activityLogId, data }) => ({
        method: "PATCH",
        url: `activityLogs/${activityLogId}`,
        data: data,
      }),
      invalidatesTags: [TagTypes.ACTIVITY_LOG],
    }),
    deleteActivityLog: build.mutation<void, { activityLogId: string }>({
      query: ({ activityLogId }) => ({
        method: "DELETE",
        url: `activityLogs/${activityLogId}`,
      }),
      invalidatesTags: [TagTypes.ACTIVITY_LOG],
    }),
  }),
});

export const { useGetAllActivityLogsQuery, useAddActivityLogMutation, useEditActivityLogMutation, useDeleteActivityLogMutation } = activityLogApi;

export const activityLogApiReducer = {
  [activityLogApi.reducerPath]: activityLogApi.reducer,
};
