import PatientReportsSummary from "@components/PatientReportsSummary/PatientReportsSummary";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { AppointmentStatus } from "@utils/enums";
import React from "react";
import { AppointmentReportsData, PatientAppointment } from "reports-types";

interface IPatientSummaryProps extends SnackbarProps {
  selectedDate: Date;
  selectedStatuses: AppointmentStatus[];
  presenceReports: AppointmentReportsData[];
  appointmentsReports: PatientAppointment[];
  handleMonthChange: (newDate: Date | null) => void;
  setSelectedStatuses: (statuses: AppointmentStatus[]) => void;
}

const PatientSummary: React.FC<IPatientSummaryProps> = ({
  selectedDate,
  selectedStatuses,
  presenceReports,
  appointmentsReports,
  handleMonthChange,
  setSelectedStatuses,
  snackbarShowMessage,
}) => {
  return (
    <PatientReportsSummary
      selectedDate={selectedDate}
      handleMonthChange={handleMonthChange}
      selectedStatuses={selectedStatuses}
      setSelectedStatuses={setSelectedStatuses}
      presenceReports={presenceReports}
      appointmentsReports={appointmentsReports}
      snackbarShowMessage={snackbarShowMessage}
    />
  );
};

export default withSnackbar(PatientSummary);
