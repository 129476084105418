import CustomDialog from "@components/modals/CustomDialog";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { formatBankAccountNumber } from "@utils/typographyUtils";
import { IInvoiceData } from "invoiceData-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface IChangeInvoiceDataModalProps {
  open: boolean;
  invoiceData: IInvoiceData[];
  onClose: () => void;
  handleInvoiceDataSelect: (invoiceData: IInvoiceData) => void;
}

const ChangeInvoiceDataModal: React.FC<IChangeInvoiceDataModalProps> = ({ open, invoiceData, onClose, handleInvoiceDataSelect }) => {
  if (!invoiceData) return null;
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("invoice_data.change_data_modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("invoice_data.change_data_modal.content_text")}</DialogContentText>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          {invoiceData.map((invoice) => (
            <Grid item xs={12} key={invoice._id}>
              <Card onClick={() => handleInvoiceDataSelect(invoice)}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="subtitle1">{invoice.bankName}</Typography>
                    <Typography variant="body2">
                      <Box component="span" sx={{ fontWeight: "600" }}>
                        {t("invoice_data.section.address")}
                      </Box>{" "}
                      {invoice.address}, {invoice.city}, {invoice.postalCode}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" sx={{ fontWeight: "600" }}>
                        {t("invoice_data.section.nip")}
                      </Box>{" "}
                      {invoice.NIP}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" sx={{ fontWeight: "600" }}>
                        {t("invoice_data.section.regon")}
                      </Box>{" "}
                      {invoice.REGON}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" sx={{ fontWeight: "600" }}>
                        {t("invoice_data.section.account_number")}
                      </Box>{" "}
                      {formatBankAccountNumber(invoice.bankAccountNumber)}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" sx={{ fontWeight: "600" }}>
                        {t("invoice_data.section.paymentDayOfMonth")}
                      </Box>
                      {invoice.paymentDayOfMonth} {t("invoice_data.section.dayOfMonth")}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ChangeInvoiceDataModal;
