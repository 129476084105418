import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import PatientHomeworkWidgetItem from "@components/PatientHomeworkWidget/fragments/PatientHomeworkWidgetItem";
import AddPatientHomework from "@components/modals/Homeworks/AddPatientHomework";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Button, Card, Container, Grid, Stack } from "@mui/material";
import { useAddPatientHomeworkMutation, useGetHomeworksByPatientQuery } from "@store/services/homeworksService";
import { useGetPatientByIdQuery } from "@store/services/patientsService";
import { PatientStatus } from "@utils/enums";
import { compareDesc, parseISO } from "date-fns";
import { IHomework } from "patients-types";
import { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

function PatientHomeworks({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();

  const { patientId } = useParams();
  const navigate = useNavigate();

  const [isAddPatientHomeworkModalOpen, toggleAddPatientHomeworkModal] = useModal();

  const { data: patient } = useGetPatientByIdQuery(patientId);
  const { data: homeworks, refetch } = useGetHomeworksByPatientQuery(patientId);

  const isActive = useMemo(() => patient?.patientStatus === PatientStatus.ACTIVE, [patient]);

  const sortedHomeworks = useMemo(() => {
    return homeworks?.slice().sort((a, b) => compareDesc(parseISO(a.dueDate), parseISO(b.dueDate))) || [];
  }, [homeworks]);

  const [addHomework, { isLoading: isAddHomeworkLoading }] = useAddPatientHomeworkMutation();

  const handleAddPatientHomework = useCallback(
    (homeworkData: Partial<IHomework>) => {
      addHomework({ patientId, homework: homeworkData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("homework.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("homework.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddPatientHomeworkModal();
        });
    },
    [addHomework, snackbarShowMessage, toggleAddPatientHomeworkModal, patientId]
  );

  if (!patient) return null;

  return (
    <>
      <Helmet>
        <title>{t("homework.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={t("homework.title", { firstname: patient.firstname, lastname: patient.lastname })}
          rightContent={
            <Stack direction="row" spacing={2}>
              <Button color="secondary" variant="outlined" onClick={() => navigate(-1)} startIcon={<KeyboardBackspaceRoundedIcon />}>
                {t("go_back")}
              </Button>
              <RBAC
                action={RBACActions.ACTION_ADD_HOMEWORK}
                yes={() => (
                  <Button color="secondary" variant="contained" onClick={toggleAddPatientHomeworkModal} startIcon={<AddRoundedIcon />}>
                    {t("homework.button.add_new")}
                  </Button>
                )}
              />
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {sortedHomeworks?.map((homework) => (
              <Card key={homework._id} sx={{ mb: 3 }}>
                <PatientHomeworkWidgetItem
                  {...{ patientId }}
                  {...{ homework }}
                  snackbarShowMessage={snackbarShowMessage}
                  refetch={refetch}
                  isActive={isActive}
                />
              </Card>
            ))}
          </Grid>
        </Grid>
      </Container>
      <AddPatientHomework
        open={isAddPatientHomeworkModalOpen}
        onClose={toggleAddPatientHomeworkModal}
        onSubmit={handleAddPatientHomework}
        loading={isAddHomeworkLoading}
      />
    </>
  );
}

export default withSnackbar(PatientHomeworks);
