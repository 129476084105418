import { ExpensesTypeWidget } from "@components/ExpensesTypeWIdget/ExpensesTypeWidget";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import { Grid, Paper, alpha, useTheme } from "@mui/material";
import { ICalculateExpense } from "expenses.types";
import React from "react";
import { useTranslation } from "react-i18next";

export enum ExpensesType {
  FIXED = "fixed",
  ONE_TIME_COST = "oneTimeCost",
}

const translationMap: Record<string, string> = {
  total: "expenses.stats.total",
  [ExpensesType.FIXED]: "expenses.stats.fixed",
  [ExpensesType.ONE_TIME_COST]: "expenses.stats.one_time_cost",
};

export interface ExpensesStatsProps {
  data: ICalculateExpense[];
}

const iconMap: Record<string, React.ReactNode> = {
  total: <RequestQuoteRoundedIcon color="primary" />,
  [ExpensesType.FIXED]: <CurrencyExchangeRoundedIcon color="info" />,
  [ExpensesType.ONE_TIME_COST]: <AttachMoneyRoundedIcon color="warning" />,
};

export const ExpensesStats: React.FC<ExpensesStatsProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colorMap: Record<string, string> = {
    total: alpha(theme.palette.primary.main, 0.12),
    [ExpensesType.FIXED]: alpha(theme.palette.info.main, 0.12),
    [ExpensesType.ONE_TIME_COST]: alpha(theme.palette.warning.main, 0.12),
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {data.map((type, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Paper sx={{ p: 2 }}>
            <ExpensesTypeWidget type={t(translationMap[type.type])} value={type.value} icon={iconMap[type.type]} color={colorMap[type.type]} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ExpensesStats;
