import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import { Alert, AlertTitle, Button, Container, Grid, Paper, Stack } from "@mui/material";
import {
  useDeleteInvoiceMutation,
  useGetInvoiceStatsQuery,
  useGetInvoicesQuery,
  useToggleInvoiceStatusMutation,
} from "@store/services/invoiceService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { AppRoutes, InvoiceStatus, PatientStatus } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { endOfMonth, isAfter, startOfDay, startOfMonth } from "date-fns";
import { IInvoice, IInvoiceStats } from "invoice-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChangeInvoiceStatus from "../../components/modals/Invoices/ChangeInvoiceStatus";
import InvoiceFilterPanel from "./fragments/InvoiceFilterPanel";
import InvoiceMenu from "./fragments/InvoiceMenu";
import { InvoiceStats } from "./fragments/InvoiceStats";
import InvoiceStatusFilter from "./fragments/InvoiceStatusFilter";
import InvoiceTable from "./fragments/InvoiceTable";

export type InvoiceFilter = {
  patientId: string;
  startDate: string;
  endDate: string;
  invoiceNumber: string;
};

const Invoices: React.FC = ({ snackbarShowMessage }: SnackbarProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [filters, setFilters] = useState<InvoiceFilter>({
    patientId: null,
    startDate: startOfMonth(new Date()).toISOString(),
    endDate: endOfMonth(new Date()).toISOString(),
    invoiceNumber: null,
  });
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);

  const [isChangeInvoiceStatusModalOpen, toggleChangeInvoiceStatusModal] = useModal();
  const [isDeleteInvoiceModalOpen, toggleDeleteInvoiceModal] = useModal();

  const debouncedInvoiceNumber = useDebounce(filters.invoiceNumber, 500);

  const status = selectedTab === 1 ? Object.values(InvoiceStatus) : [Object.values(InvoiceStatus)[selectedTab - 2]];

  const [changeInvoiceStatus, { isLoading: isChangeInvoiceStatusLoading }] = useToggleInvoiceStatusMutation();
  const [deleteInvoice, { isLoading: isDeleteInvoiceLoading }] = useDeleteInvoiceMutation();

  const handleCloseChangeInvoiceStatusModal = () => handleCloseModal([setSelectedInvoice], toggleChangeInvoiceStatusModal);
  const handleCloseDeleteModal = () => handleCloseModal([setSelectedInvoice], toggleDeleteInvoiceModal);

  const { invoices } = useGetInvoicesQuery(
    {
      status,
      patientId: filters.patientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      invoiceNumber: debouncedInvoiceNumber,
    },
    {
      selectFromResult: ({ data }) => ({
        invoices: data || [],
      }),
    }
  );

  const { invoiceStats } = useGetInvoiceStatsQuery(
    {
      startDate: filters.startDate,
      endDate: filters.endDate,
    },
    {
      selectFromResult: ({ data }) => ({
        invoiceStats: data || [],
      }),
    }
  );

  const { patients } = useGetAllPatientsQuery(
    {
      patientStatus: PatientStatus.ACTIVE,
    },
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_INVOICE)) navigate(AppRoutes.Dashboard);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, invoice: IInvoice) => {
      setAnchorElementMenu(event.currentTarget);
      setSelectedInvoice(invoice);
    },
    [setAnchorElementMenu]
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleChangeInvoiceStatus = useCallback(
    (status: InvoiceStatus): void => {
      if (!selectedInvoice) return null;

      changeInvoiceStatus({ invoiceId: selectedInvoice._id, status })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice.snackbar_message.status.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice.snackbar_message.status.error"), "error");
        })
        .finally(() => {
          handleCloseChangeInvoiceStatusModal();
          handleCloseMenu();
        });
    },
    [changeInvoiceStatus, selectedInvoice, snackbarShowMessage]
  );

  const handleDeleteInvoice = useCallback((): void => {
    if (!selectedInvoice) return null;

    deleteInvoice({ invoiceId: selectedInvoice._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("invoice.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("invoice.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        handleCloseDeleteModal();
        handleCloseMenu();
      });
  }, [deleteInvoice, selectedInvoice, snackbarShowMessage]);

  useEffect(() => {
    const today = startOfDay(new Date());

    const updateInvoicesStatus = async () => {
      for (const invoice of invoices) {
        const paymentDate = startOfDay(new Date(invoice.paymentDate));
        if ((invoice.status === InvoiceStatus.SENT || invoice.status === InvoiceStatus.DRAFT) && isAfter(today, paymentDate)) {
          await changeInvoiceStatus({ invoiceId: invoice._id, status: InvoiceStatus.OVERDUE });
        }
      }
    };

    updateInvoicesStatus();
  }, [invoices, changeInvoiceStatus]);

  return (
    <>
      <Helmet>
        <title>{t("invoice.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={t("invoice.title")}
          rightContent={
            <Stack direction="row" spacing={2}>
              <Button color="secondary" variant="contained" startIcon={<NoteAddRoundedIcon />} onClick={() => navigate(AppRoutes.CreateInvoice)}>
                {t("invoice.button.add")}
              </Button>
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle>{t("invoice.alert_info.title")}</AlertTitle>
              {t("invoice.alert_info.content")}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <InvoiceStats data={invoiceStats as IInvoiceStats[]} />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <InvoiceStatusFilter selectedTab={selectedTab} invoiceStats={invoiceStats as IInvoiceStats[]} handleTabChange={handleTabChange} />
              <InvoiceFilterPanel filters={filters} setFilters={setFilters} patients={patients} />
              <InvoiceTable invoices={invoices} handleOpenMenu={handleOpenMenu} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ChangeInvoiceStatus
        open={isChangeInvoiceStatusModalOpen}
        selectedInvoice={selectedInvoice}
        onSubmit={handleChangeInvoiceStatus}
        onClose={handleCloseChangeInvoiceStatusModal}
        loading={isChangeInvoiceStatusLoading}
      />
      <ConfirmationModal
        open={isDeleteInvoiceModalOpen}
        title={t("invoice.delete_modal.title")}
        content={t("invoice.delete_modal.content_text")}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteInvoice}
        loading={isDeleteInvoiceLoading}
      />
      <InvoiceMenu
        invoice={selectedInvoice}
        anchorElementMenu={anchorElementMenu}
        handleCloseMenu={handleCloseMenu}
        {...{
          toggleChangeInvoiceStatusModal,
          toggleDeleteInvoiceModal,
        }}
      />
    </>
  );
};

export default withSnackbar(Invoices);
