import { LoadingButton } from "@mui/lab";
import { Link, Stack, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

interface IPasswordResetForm {
  formik: FormikProps<{ email: string }>;
  isLoading: boolean;
}

const PasswordResetForm: React.FC<IPasswordResetForm> = ({ formik, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="email"
            name="email"
            label={t("password_reset.form.email_label")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && t(formik.errors.email)}
          />
        </Stack>
        <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} color="secondary">
          {t("password_reset.form.reset_password_button")}
        </LoadingButton>
        <Typography variant="body2" color="text.secondary">
          {t("password_reset.form.cant_remember_email")}
          <Link
            href="https://www.logopedaplus.com/contact"
            variant="subtitle2"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("password_reset.form.contact_us")}
          </Link>
        </Typography>
      </form>
    </>
  );
};

export default PasswordResetForm;
