import { useThemeSettings } from "@context/ThemeSettingsProvider";
import { Box, Link, Stack } from "@mui/material";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

const Logo: React.FC<{ sx?: object }> = forwardRef(({ sx, ...other }, ref) => {
  const { settings } = useThemeSettings();

  const logoSrcMap = {
    PRIMARY: "/assets/images/logo/primary.png",
    PRIMARY_GREEN: "/assets/images/logo/primary_green.png",
    PRIMARY_BLUE: "/assets/images/logo/primary_blue.png",
    PRIMARY_PURPLE: "/assets/images/logo/primary_purple.png",
    PRIMARY_DARK_BLUE: "/assets/images/logo/primary_dark_blue.png",
    PRIMARY_RED: "/assets/images/logo/primary_red.png",
  };

  const logoSrc = logoSrcMap[settings.primaryColor];

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        display: "inline-flex",
        position: "fixed",
        top: { xs: 24, sm: 40 },
        left: { xs: 24, sm: 40 },
        ...sx,
      }}
      {...other}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <img src={logoSrc} alt="Logo" style={{ width: 140 }} />
      </Stack>
    </Box>
  );

  return (
    <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

export default Logo;
