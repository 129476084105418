import { Box, List, ListItemButton, ListItemIcon, ListItemText, Stack, alpha, Typography, Collapse, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface IMenuItems {
  data: IMenuSection[];
}

interface IMenuItem {
  id: string;
  title: string;
  path: string;
  icon?: React.ReactNode;
  info?: React.ReactNode;
}

interface IMenuSection {
  id: string;
  title?: string;
  items: IMenuItem[];
}

const StyledMenuItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  "&.active": {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    fontWeight: 650,
  },
}));

const StyledMenuItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 50,
});

const MenuItem: React.FC<IMenuItem> = ({ id, title, path, icon, info }) => {
  return (
    // @ts-expect-error
    <StyledMenuItem component={RouterLink} to={path} id={id}>
      <StyledMenuItemIcon>{icon && icon}</StyledMenuItemIcon>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </Stack>
    </StyledMenuItem>
  );
};

const MenuItems: React.FC<IMenuItems> = ({ data = [], ...other }) => {
  const theme = useTheme();

  const [openSections, setOpenSections] = useState<string[]>(data.map((section) => section.id));

  const handleToggleSection = (id: string) => {
    setOpenSections((prevOpenSections) =>
      prevOpenSections.includes(id) ? prevOpenSections.filter((sectionId) => sectionId !== id) : [...prevOpenSections, id]
    );
  };

  const isSectionOpen = (id: string) => openSections.includes(id);

  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{
          p: 1,
          mx: 1,
          pr: 2,
        }}
      >
        {data.map((section: IMenuSection) => (
          <React.Fragment key={section.id}>
            {section.title && (
              <Box
                onClick={() => handleToggleSection(section.id)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  px: 1,
                  pb: 0.5,
                  pt: 1.5,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.grey[500],
                    textTransform: "uppercase",
                    fontSize: "0.6875rem",
                    fontWeight: "700",
                  }}
                >
                  {section.title}
                </Typography>
                {isSectionOpen(section.id) ? (
                  <ExpandLess
                    sx={{
                      color: theme.palette.grey[500],
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      color: theme.palette.grey[500],
                    }}
                  />
                )}
              </Box>
            )}
            <Collapse in={isSectionOpen(section.id)} timeout="auto" unmountOnExit>
              {section.items.map((item: IMenuItem) => (
                <MenuItem key={item.id} title={item.title} path={item.path} icon={item.icon} id={item.id} />
              ))}
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default MenuItems;
