import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SignUpSuccess = () => {
  const { t } = useTranslation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);

  if (usersTokens) return <Navigate to={AppRoutes.Dashboard} />;
  return <NotificationWithAction helmetTitle={t("signup.success.helmet")} title={t("signup.success.title")} message={t("signup.success.message")} />;
};

export default SignUpSuccess;
