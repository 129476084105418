import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IClinic } from "clinics-types";
import { IUserDetails } from "user-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";

enum TagTypes {
  CLINICS = "CLINICS",
  CLINIC_CLIENTS = "CLINIC_CLIENTS",
}

export const clinicsApi = createApi({
  reducerPath: "clinicsApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true }),
  tagTypes: [TagTypes.CLINICS, TagTypes.CLINIC_CLIENTS],
  endpoints: (builder) => ({
    getAllClinics: builder.query<IClinic[], unknown>({
      query: () => ({ url: "clinics", method: "GET" }),
      providesTags: (result) =>
        result
          ? [...result.map(({ _id }) => ({ type: TagTypes.CLINICS, _id } as const)), { type: TagTypes.CLINICS, id: "LIST" }]
          : [{ type: TagTypes.CLINICS, id: "LIST" }],
    }),
    getClinicClients: builder.query<IUserDetails[], { clinicId: string }>({
      query: ({ clinicId }) => ({ url: `clinics/${clinicId}/clients`, method: "GET" }),
      providesTags: (result, error, { clinicId }) =>
        result
          ? [...result.map(({ _id }) => ({ type: TagTypes.CLINIC_CLIENTS, _id } as const)), { type: TagTypes.CLINIC_CLIENTS, id: clinicId }]
          : [{ type: TagTypes.CLINIC_CLIENTS, id: clinicId }],
    }),
    addClinic: builder.mutation<IClinic, Partial<IClinic>>({
      query: (newClinic) => ({
        url: "clinics",
        method: "POST",
        data: newClinic,
      }),
      invalidatesTags: [{ type: TagTypes.CLINICS, id: "LIST" }],
    }),
    editClinic: builder.mutation<IClinic, { clinicId: string; data: Partial<IClinic> }>({
      query: ({ clinicId, data }) => ({
        url: `clinics/${clinicId}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [TagTypes.CLINICS],
    }),
    deleteClinic: builder.mutation<void, { clinicId: string }>({
      query: ({ clinicId }) => ({
        url: `clinics/${clinicId}`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypes.CLINICS],
    }),
    deleteClinicLogo: builder.mutation<void, { clinicId: string; fileId: string }>({
      query: ({ clinicId, fileId }) => ({
        method: "DELETE",
        url: `clinics/${clinicId}/file/${fileId} `,
      }),
      invalidatesTags: [TagTypes.CLINICS],
    }),
  }),
});

export const {
  useGetAllClinicsQuery,
  useGetClinicClientsQuery,
  useAddClinicMutation,
  useEditClinicMutation,
  useDeleteClinicMutation,
  useDeleteClinicLogoMutation,
} = clinicsApi;

export const clinicsApiReducer = {
  [clinicsApi.reducerPath]: clinicsApi.reducer,
};
