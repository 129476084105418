import ActionMenu from "@components/ActionMenu/ActionMenu";
import FilterControls from "@components/FilterControls/FilterControls";
import PageTitle from "@components/PageTitle/PageTitle";
import PatientFilterTabs, { PatientStatusFilter, PatientStatusFilterAll } from "@components/PatientFilterTabs/PatientFilterTabs";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import useResponsive from "@hooks/useResponsive";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Button, Container, Divider, ListItemIcon, MenuItem, Paper, Stack, Typography } from "@mui/material";
import AssignPatientToEmployeeModal from "@pages/Employees/modals/AssignPatientToEmployeeModal";
import EditEmployeeModal from "@pages/Employees/modals/EditEmployeeModal";
import PatientsTable from "@pages/Patients/fragments/PatientsTable";
import { selectCurrentClinicId } from "@store/features/clinicSlice";
import { useAssignPatientToEmployeeMutation, useDeleteEmployeeMutation, useGetAllEmployeesQuery } from "@store/services/employeesService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { useEditUserDetailsMutation } from "@store/services/usersService";
import { AppRoutes, PatientStatus, SortBy, SortOrder } from "@utils/enums";
import { IEmployee } from "employees-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

function EmployeeDetails({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "sm");

  const selectedClinic = useSelector(selectCurrentClinicId);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState<PatientStatusFilter>(PatientStatusFilterAll.ALL);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.LAST_NAME);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
  const [searchText, setSearchText] = useState<string>("");
  const [initialCountsSet, setInitialCountsSet] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 300);

  const patientStatusFilter = selectedTab === PatientStatusFilterAll.ALL ? undefined : selectedTab;

  const [isEditEmployeeModalOpen, toggleEditEmployeeModal] = useModal();
  const [isDeleteEmployeeModalOpen, toggleDeleteEmployeeModal] = useModal();
  const [isAssignPatientToEmployeeModalOpen, toggleAssignPatientToEmployeeModal] = useModal();

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: PatientStatusFilter) => {
    setSelectedTab(newValue);
  }, []);

  const { employees, refetch: refetchEmployees } = useGetAllEmployeesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      employees: data || [],
    }),
  });

  const employee = employees.find((employee) => employee._id === employeeId);

  const {
    patients,
    refetch: refetchPatients,
    isFetching,
  } = useGetAllPatientsQuery(
    {
      sortBy,
      sortOrder,
      patientStatus: patientStatusFilter,
      search: debouncedSearchText,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        patients: data || [],
        isFetching,
      }),
    }
  );

  const [deleteEmployee, { isLoading: isDeleteEmployeeLoading }] = useDeleteEmployeeMutation();
  const [editEmployee, { isLoading: isEditEmployeeLoading }] = useEditUserDetailsMutation();
  const [assignPatientToEmployee, { isLoading: isAssignPatientToEmployeeLoading }] = useAssignPatientToEmployeeMutation();

  const handleEditEmployee = useCallback(
    (employeeData: Partial<IEmployee>) => {
      if (!employee) return;

      editEmployee({ clinicId: selectedClinic, userId: employee._id, data: employeeData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("employee.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("employee.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditEmployeeModal();
          refetchEmployees();
        });
    },
    [editEmployee, snackbarShowMessage, toggleEditEmployeeModal, refetchEmployees, employee]
  );

  const handleDeleteEmployee = useCallback(() => {
    if (!employee) return;

    deleteEmployee({ employeeId })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("employee.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("employee.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        navigate(-1);
        toggleDeleteEmployeeModal();
      });
  }, [deleteEmployee, snackbarShowMessage, toggleDeleteEmployeeModal, employee]);

  const handleAssignPatientToEmployee = useCallback(
    ({ patientBodyId }: { patientBodyId: string }) => {
      if (!employee) return;

      assignPatientToEmployee({ employeeId: employee._id, patientBodyId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("employee.snackbar_message.assign_patient_to_employee.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("employee.snackbar_message.assign_patient_to_employee.error"), "error");
        })
        .finally(() => {
          toggleAssignPatientToEmployeeModal();
          refetchPatients();
        });
    },
    [snackbarShowMessage, refetchPatients, employee]
  );

  const myPatients = useMemo(
    () => patients.filter((patient) => patient.assignedEmployees.some((emp) => emp._id === employeeId)) || [],
    [patients, employeeId]
  );
  const [patientCounts, setPatientCounts] = useState({ all: 0, active: 0, inactive: 0 });

  useEffect(() => {
    if (myPatients.length > 0 && !initialCountsSet) {
      const allCount = myPatients.length;
      const activeCount = myPatients.filter((patient) => patient.patientStatus === PatientStatus.ACTIVE).length;
      const inactiveCount = myPatients.filter((patient) => patient.patientStatus === PatientStatus.INACTIVE).length;
      setPatientCounts({ all: allCount, active: activeCount, inactive: inactiveCount });
      setInitialCountsSet(true);
    }
  }, [myPatients, initialCountsSet]);

  const availablePatientsToAssign = useMemo(() => {
    if (!employee) return [];

    return patients.filter(
      (patient) =>
        patient.patientStatus === PatientStatus.ACTIVE && !patient.assignedEmployees.some((assignedEmployee) => assignedEmployee._id === employee._id)
    );
  }, [patients, employee]);

  if (!patients || !employee) return null;

  return (
    <>
      <Helmet>
        <title>{t("employee.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={`${employee.firstname} ${employee.lastname}`}
          bottomContent={employee.email}
          rightContent={
            <Stack direction={"row"} spacing={2}>
              {isDesktop && (
                <Button color="secondary" variant="outlined" component={Link} to={AppRoutes.Employees} startIcon={<KeyboardBackspaceRoundedIcon />}>
                  {t("go_back")}
                </Button>
              )}

              <ActionMenu
                openMenu={openMenu}
                menuItems={
                  <>
                    <MenuItem onClick={toggleEditEmployeeModal}>
                      <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("employee.button.edit")}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={toggleAssignPatientToEmployeeModal}>
                      <ListItemIcon>
                        <AssignmentIndRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("employee.button.assign_to_patient_modal")}
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={toggleDeleteEmployeeModal}>
                      <ListItemIcon>
                        <DeleteRoundedIcon fontSize="small" color="error" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap color="error">
                        {t("employee.button.delete")}
                      </Typography>
                    </MenuItem>
                  </>
                }
                buttonLabel={t("employee.details.open_menu")}
                setOpenMenu={setOpenMenu}
              />
            </Stack>
          }
        />
        {!isDesktop && (
          <Typography variant="subtitle1" sx={{ m: 1 }}>
            {t("employee.details.patients.title")}
          </Typography>
        )}
        <Paper>
          <PatientFilterTabs selectedTab={selectedTab} onTabChange={handleTabChange} counts={patientCounts} />
          <FilterControls {...{ searchText, setSearchText, sortBy, setSortBy, sortOrder, setSortOrder }} />
          <PatientsTable patients={myPatients} {...{ setInitialCountsSet, isFetching }} />
        </Paper>
      </Container>
      <EditEmployeeModal
        open={isEditEmployeeModalOpen}
        onClose={toggleEditEmployeeModal}
        onSubmit={handleEditEmployee}
        initialValues={employee}
        loading={isEditEmployeeLoading}
      />
      <ConfirmationModal
        open={isDeleteEmployeeModalOpen}
        onClose={toggleDeleteEmployeeModal}
        onConfirm={handleDeleteEmployee}
        title={t("employee.button.delete")}
        content={t("employee.delete_modal.content_text")}
        loading={isDeleteEmployeeLoading}
      />
      <AssignPatientToEmployeeModal
        open={isAssignPatientToEmployeeModalOpen}
        onClose={toggleAssignPatientToEmployeeModal}
        onSubmit={handleAssignPatientToEmployee}
        patients={availablePatientsToAssign}
        loading={isAssignPatientToEmployeeLoading}
      />
    </>
  );
}

export default withSnackbar(EmployeeDetails);
