import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IReservationSettings } from "reservationSettings-types";

export enum TagTypes {
  RESERVATION_SETTINGS = "RESERVATION_SETTINGS",
}

export const reservationSettingsApi = createApi({
  reducerPath: "reservationSettingsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: apiUrl,
    isTokenRequired: true,
    includeClinicId: true,
  }),
  tagTypes: [TagTypes.RESERVATION_SETTINGS],
  endpoints: (builder) => ({
    getReservationSettings: builder.query<IReservationSettings, void>({
      query: () => ({
        url: `settings/reservation-calendar`,
        method: "GET",
      }),
      providesTags: [TagTypes.RESERVATION_SETTINGS],
    }),

    updateReservationSettings: builder.mutation<IReservationSettings, Partial<IReservationSettings>>({
      query: (settings) => ({
        url: `settings/reservation-calendar`,
        method: "PATCH",
        data: settings,
      }),
      invalidatesTags: [TagTypes.RESERVATION_SETTINGS],
    }),

    toggleReservationStatus: builder.mutation<IReservationSettings, void>({
      query: () => ({
        url: `settings/reservation-calendar/toggle`,
        method: "PATCH",
      }),
      invalidatesTags: [TagTypes.RESERVATION_SETTINGS],
    }),
  }),
});

export const { useGetReservationSettingsQuery, useUpdateReservationSettingsMutation, useToggleReservationStatusMutation } = reservationSettingsApi;

export const reservationSettingsApiReducer = {
  [reservationSettingsApi.reducerPath]: reservationSettingsApi.reducer,
};
