import SplashScreen from "@components/SplashScreen/SplashScreen";
import { IUserContextType, UserContext } from "@context/UserProvider";
import useNotificationToken from "@hooks/useNotificationToken";
import { selectCurrentUserTokens, setUserInfo } from "@store/features/authSlice";
import { useLazyGetUserDetailsQuery } from "@store/services/usersService";
import { ILoginResponse } from "auth-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RBACRoles } from "../rbac-rules";
import { motion, AnimatePresence } from "framer-motion";

export const RoleContext = createContext<RBACRoles | undefined>(undefined);

export const mapAccountTypeToRole = (accountType: string | undefined): RBACRoles | undefined => {
  const mapping: { [key: string]: RBACRoles } = {
    OWNER: RBACRoles.OWNER,
    EMPLOYEE: RBACRoles.EMPLOYEE,
    CLIENT: RBACRoles.CLIENT,
    ADMIN: RBACRoles.ADMIN,
  };

  return accountType ? mapping[accountType] : undefined;
};

const RBACProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);

  const { userDetails, setUserDetails } = useContext(UserContext) as IUserContextType;

  const [roleDetermined, setRoleDetermined] = useState(false);
  const [isDelayOver, setIsDelayOver] = useState(false);

  const [fetchUserDetails, { isLoading }] = useLazyGetUserDetailsQuery({
    selectFromResult: ({ data, isLoading }) => ({
      details: data || {},
      isLoading,
    }),
  });

  useNotificationToken(userDetails);

  useEffect(() => {
    const delay = 500;

    if (usersTokens !== null) {
      fetchUserDetails({})
        .unwrap()
        .then((payload) => {
          setUserDetails(payload);
          dispatch(
            setUserInfo({
              firstname: payload.firstname,
              userId: payload._id,
              role: payload.accountType,
            })
          );

          setTimeout(() => {
            setRoleDetermined(true);
            setIsDelayOver(true);
          }, delay);
        });
    } else {
      setTimeout(() => {
        setRoleDetermined(true);
        setIsDelayOver(true);
      }, delay);
    }
  }, [usersTokens, fetchUserDetails, setUserDetails]);

  const role = useMemo(() => {
    return userDetails?.accountType ? mapAccountTypeToRole(userDetails.accountType) : undefined;
  }, [userDetails]);

  if (isLoading || !roleDetermined || !isDelayOver) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
          key="splash-screen"
        >
          <SplashScreen title={t("splash_screen.title")} subtitle={t("splash_screen.subtitle")} />
        </motion.div>
      </AnimatePresence>
    );
  }

  return <RoleContext.Provider value={role}>{props.children}</RoleContext.Provider>;
};

export default RBACProvider;
