import useResponsive from "@hooks/useResponsive";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { forwardRef } from "react";

const Transition = forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICustomDialogProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const CustomDialog: React.FC<ICustomDialogProps> = ({ children, open, onClose }) => {
  const isDesktop = useResponsive("up", "sm");

  return (
    <Dialog fullScreen={!isDesktop} TransitionComponent={Transition} open={open} onClose={onClose} keepMounted>
      {children}
    </Dialog>
  );
};

export default CustomDialog;
