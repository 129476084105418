import { Box, Card, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { getAppointmentStatusText } from "@utils/utils";
import { IClinic } from "clinics-types";
import { IInvoiceItem, IInvoiceResults, IInvoiceServiceData } from "invoice-types";
import { IInvoiceData } from "invoiceData-types";
import { IPatientInvoiceData } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface IInvoicePreviewProps {
  totalAmount: number;
  invoiceNumber: string;
  currentClinic: IClinic;
  paymentDate: Date | null;
  selectedAcquirerData: IPatientInvoiceData;
  hasPatientInvoiceData: boolean;
  selectedInvoiceData: IInvoiceData;
  dateRange: { start: Date; end: Date };
  invoiceDraftData?: IInvoiceResults;
  readyInvoiceData?: IInvoiceServiceData[];
  printRef: React.MutableRefObject<HTMLDivElement>;
}

const InvoicePreview: React.FC<IInvoicePreviewProps> = ({
  printRef,
  dateRange,
  totalAmount,
  paymentDate,
  invoiceNumber,
  currentClinic,
  readyInvoiceData,
  invoiceDraftData,
  selectedInvoiceData,
  selectedAcquirerData,
  hasPatientInvoiceData,
}) => {
  const { t } = useTranslation();

  const invoiceData = readyInvoiceData || invoiceDraftData;

  if (!selectedInvoiceData || !invoiceData || !currentClinic) return null;

  return (
    <Card
      ref={printRef}
      className="printable"
      sx={{
        width: "210mm",
        minHeight: "297mm",
        padding: 5,
        margin: "auto",
        boxShadow: 3,
        backgroundColor: "#fff",
      }}
    >
      <Box mt={4} mb={10}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <img src={`${currentClinic?.files[0]?.url}?x-request=html`} style={{ width: 100 }} crossOrigin="anonymous"></img>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary">
              {t("invoice.preview.invoice_number")}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {invoiceNumber}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("invoice.preview.date")}
            </Typography>
            <Typography variant="subtitle2">{formatDate(new Date(), "dd MMMM yyyy")}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} mb={6}>
        <Grid item xs={8}>
          <Typography variant="body1">
            <strong>{t("invoice.preview.seller")}</strong>
          </Typography>
          <Typography variant="body2">{currentClinic.name}</Typography>
          <Typography variant="body2">{`${selectedInvoiceData.address} ${selectedInvoiceData.city} ${selectedInvoiceData.postalCode}`}</Typography>
          <Typography variant="body2">{`NIP: ${selectedInvoiceData.NIP} REGON: ${selectedInvoiceData.REGON}`}</Typography>
          <Typography variant="body2">
            {t("invoice.preview.bank")} {selectedInvoiceData.bankName}
          </Typography>
          <Typography variant="body2">
            {t("invoice.preview.bank_account_number")} {selectedInvoiceData.bankAccountNumber}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            <strong>{t("invoice.preview.buyer")}</strong>
          </Typography>
          {hasPatientInvoiceData ? (
            <>
              <Typography variant="body2">{`${selectedAcquirerData.firstname} ${selectedAcquirerData.lastname}`}</Typography>
              <Typography variant="body2">{`${selectedAcquirerData.email}`}</Typography>
            </>
          ) : (
            <Typography variant="body2">{t("invoice.preview.no_data")}</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" mb={4}>
        <Grid item>
          <Typography variant="subtitle1">{t("invoice.preview.period_from")}</Typography>
          <Typography variant="body2">{formatDate(dateRange.start, "dd MMM yyyy")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{t("invoice.preview.period_to")}</Typography>
          <Typography variant="body2">{formatDate(dateRange.end, "dd MMM yyyy")}</Typography>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("invoice.preview.service_name")}</TableCell>
              <TableCell align="right">{t("invoice.preview.quantity")}</TableCell>
              <TableCell align="right">{t("invoice.preview.net_price")}</TableCell>
              <TableCell align="right">{t("invoice.preview.vat")}</TableCell>
              <TableCell align="right">{t("invoice.preview.vat_amount")}</TableCell>
              <TableCell align="right">{t("invoice.preview.net_worth")}</TableCell>
              <TableCell align="right">{t("invoice.preview.gross_value")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceDraftData
              ? Object.entries(invoiceDraftData).map(([status, services], index) =>
                  services.map((service: IInvoiceItem, serviceIndex: number) => (
                    <TableRow key={serviceIndex}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{` ${service.name} (${t(getAppointmentStatusText(status as any))})`}</Typography>
                      </TableCell>
                      <TableCell align="right">{service.count}</TableCell>
                      <TableCell align="right">{formatCost(parseFloat(service.costPerCount.toFixed(2)))}</TableCell>
                      <TableCell align="right">{"zw"}</TableCell>
                      <TableCell align="right">{0}</TableCell>
                      <TableCell align="right">{formatCost(parseFloat(service.totalAmount.toFixed(2)))}</TableCell>
                      <TableCell align="right">{formatCost(parseFloat(service.totalAmount.toFixed(2)))}</TableCell>
                    </TableRow>
                  ))
                )
              : readyInvoiceData.map((service, serviceIndex: number) => (
                  <TableRow key={serviceIndex}>
                    <TableCell>{serviceIndex + 1}</TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{service.serviceName}</Typography>
                    </TableCell>
                    <TableCell align="right">{service.amount}</TableCell>
                    <TableCell align="right">{formatCost(parseFloat(service.netPrice.toFixed(2)))}</TableCell>
                    <TableCell align="right">{service.VAT}</TableCell>
                    <TableCell align="right">{service.vatAmount}</TableCell>
                    <TableCell align="right">{formatCost(parseFloat(service.totalNetValue.toFixed(2)))}</TableCell>
                    <TableCell align="right">{formatCost(parseFloat(service.totalGrossValue.toFixed(2)))}</TableCell>
                  </TableRow>
                ))}
            <TableRow>
              <TableCell rowSpan={4} colSpan={3}>
                <Typography variant="body2" color="textSecondary">
                  {t("invoice.preview.date_of_payment")}
                </Typography>
                <Typography variant="subtitle1">{formatDate(paymentDate, "dd MMM yyyy")}</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                <strong>{t("invoice.preview.total_payment")}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{formatCost(parseFloat(totalAmount.toFixed(2)))}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{formatCost(parseFloat(totalAmount.toFixed(2)))}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>{t("invoice.preview.including_vat")}</TableCell>
              <TableCell align="right">zw</TableCell>
              <TableCell align="right">{formatCost(0)}</TableCell>
              <TableCell align="right">{formatCost(parseFloat(totalAmount.toFixed(2)))}</TableCell>
              <TableCell align="right">{formatCost(parseFloat(totalAmount.toFixed(2)))}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ mb: 3, borderStyle: "dashed", position: "absolute", bottom: 50, left: 40, right: 40 }} />
      <Typography variant="body2" color="textSecondary" sx={{ position: "absolute", bottom: 30, left: 40, right: 40 }}>
        {t("invoice.preview.information_clause")}
      </Typography>
    </Card>
  );
};

export default InvoicePreview;
