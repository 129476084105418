import React from "react";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";

interface NoDataCardProps {
  title: string;
  description: string;
  buttonText: string;
  rbacAction?: RBACActions;
  disableButton?: boolean;
  onButtonClick: () => void;
}

const NoDataCard: React.FC<NoDataCardProps> = ({ title, description, buttonText, rbacAction, disableButton, onButtonClick }) => {
  return (
    <Card sx={{ p: 5 }}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Stack spacing={2}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
            <RBAC
              action={rbacAction}
              yes={() => (
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button color="secondary" variant="contained" onClick={onButtonClick} disabled={disableButton}>
                    {buttonText}
                  </Button>
                </Box>
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NoDataCard;
