import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IAppointmentTypeOnlineReservation } from "appointmentTypes.types";
import { IAvailabilityDay, IHourSetForOnlineReservation, IReservationData } from "reservation-types";
import { IReservationSettings } from "reservationSettings-types";

export const miscApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl }),
  reducerPath: "miscApi",
  endpoints: (build) => ({
    getReservationSettings: build.query<IReservationSettings, { clinicId: string }>({
      query: ({ clinicId }) => ({
        url: `clinics/${clinicId}/settings/reservation-calendar`,
        method: "GET",
      }),
    }),
    getClinicBasicInfo: build.query<any, { clinicId: string }>({
      query: ({ clinicId }) => ({
        method: "GET",
        url: `clinics/${clinicId}/basic-info`,
      }),
    }),
    getAppointmentsTypesForOnlineReservation: build.query<IAppointmentTypeOnlineReservation[], { clinicId: string }>({
      query: ({ clinicId }) => ({
        method: "GET",
        url: `clinics/${clinicId}/settings/appointment-types/online-reservation`,
      }),
    }),
    getUserAvailability: build.query<IAvailabilityDay[], { clinicId: string; userId: string }>({
      query: ({ clinicId, userId }) => ({
        url: `clinics/${clinicId}/user/${userId}/availability`,
        method: "GET",
      }),
    }),
    getHoursForOnlineReservation: build.query<
      IHourSetForOnlineReservation[],
      { clinicId: string; appointmentTypeId: string; employeeId: string; pricingId: string; currentDate: string }
    >({
      query: ({ clinicId, appointmentTypeId, employeeId, pricingId, currentDate }) => ({
        url: `clinics/${clinicId}/available-hours/${appointmentTypeId}/${employeeId}/${pricingId}/${currentDate}`,
        method: "GET",
      }),
    }),
    createOnlineReservation: build.mutation<any, { clinicId: string; data: IReservationData }>({
      query: ({ clinicId, data }) => ({
        url: `clinics/${clinicId}/add-reservation`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useGetReservationSettingsQuery,
  useGetUserAvailabilityQuery,
  useGetClinicBasicInfoQuery,
  useGetAppointmentsTypesForOnlineReservationQuery,
  useGetHoursForOnlineReservationQuery,
  useCreateOnlineReservationMutation,
} = miscApi;

export const miscApiReducer = {
  [miscApi.reducerPath]: miscApi.reducer,
};
