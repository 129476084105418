import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import InvoiceDataBox from "@components/InvoiceDataBox/InvoiceDataBox";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import {
  useAddInvoiceDataMutation,
  useDeleteInvoiceDataMutation,
  useEditInvoiceDataMutation,
  useGetAllInvoiceDataQuery,
} from "@store/services/InvoiceDataService";
import { IInvoiceData } from "invoiceData-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AddInvoiceDataModal from "../modals/InvoiceData/AddInvoiceDataModal";
import EditInvoiceDataModal from "../modals/InvoiceData/EditInvoiceDataModal";

const InvoiceDataSection: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<Partial<IInvoiceData> | null>(null);

  const [isAddInvoiceDataModalOpen, toggleAddInvoiceDataModal] = useModal();
  const [isEditInvoiceDataModalOpen, toggleEditInvoiceDataModal] = useModal();
  const [isDeleteInvoiceDataModalOpen, toggleDeleteInvoiceDeleteModal] = useModal();

  const { data: invoiceData } = useGetAllInvoiceDataQuery({ invoiceDataId: "" });
  const [addInvoiceData, { isLoading: isAddInvoiceDataLoading }] = useAddInvoiceDataMutation();
  const [editInvoiceData, { isLoading: isEditInvoiceDataLoading }] = useEditInvoiceDataMutation();
  const [deleteInvoiceData, { isLoading: isDeleteInvoiceDataLoading }] = useDeleteInvoiceDataMutation();

  const handleAddInvoiceData = useCallback(
    (invoiceData: Partial<IInvoiceData>) => {
      addInvoiceData(invoiceData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.add.error"), "error");
        })
        .finally(() => {
          toggleAddInvoiceDataModal();
        });
    },
    [addInvoiceData, snackbarShowMessage, toggleAddInvoiceDataModal]
  );

  const handleEditInvoiceData = useCallback(
    (invoiceData: Partial<IInvoiceData>) => {
      if (selectedInvoiceId) {
        editInvoiceData({ invoiceDataId: selectedInvoiceId, updatedInvoiceData: invoiceData })
          .unwrap()
          .then(() => {
            snackbarShowMessage(t("invoice_data.snackbar_messages.edit.success"), "success");
          })
          .catch(() => {
            snackbarShowMessage(t("invoice_data.snackbar_messages.edit.error"), "error");
          })
          .finally(() => {
            toggleEditInvoiceDataModal();
          });
      }
    },
    [selectedInvoiceId, editInvoiceData, snackbarShowMessage, toggleEditInvoiceDataModal]
  );

  const handleDeleteInvoiceData = useCallback(() => {
    if (selectedInvoiceId) {
      deleteInvoiceData({ invoiceDataId: selectedInvoiceId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.delete.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("invoice_data.snackbar_messages.delete.error"), "error");
        })
        .finally(() => {
          toggleDeleteInvoiceDeleteModal();
        });
    }
  }, [selectedInvoiceId, deleteInvoiceData, toggleDeleteInvoiceDeleteModal, snackbarShowMessage, t]);

  const handleOpenEditModal = useCallback(
    (invoice: IInvoiceData) => {
      setSelectedInvoiceId(invoice._id);
      setSelectedInvoiceData(invoice);
      toggleEditInvoiceDataModal();
    },
    [toggleEditInvoiceDataModal]
  );

  const handleOpenDeleteModal = useCallback(
    (invoiceId: string) => {
      setSelectedInvoiceId(invoiceId);
      toggleDeleteInvoiceDeleteModal();
    },
    [toggleDeleteInvoiceDeleteModal]
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={t("invoice_data.section.title")}
          subheader={t("invoice_data.section.subheader")}
          action={
            <RBAC
              action={RBACActions.ACTION_ADD_INVOICE_DATA}
              yes={() => (
                <IconWithTooltip
                  label={t("invoice_data.section.button.add")}
                  icon={<AddRoundedIcon />}
                  onClick={toggleAddInvoiceDataModal}
                  id="invoice-data-add-button"
                />
              )}
            />
          }
        />
        <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
        <CardContent>
          {invoiceData && invoiceData.length > 0 ? (
            invoiceData.map((invoice, index) => (
              <InvoiceDataBox
                key={invoice._id}
                invoice={invoice}
                index={index}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                actions
              />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              {t("invoice_data.section.no_data")}
            </Typography>
          )}
        </CardContent>
      </Card>
      <AddInvoiceDataModal
        open={isAddInvoiceDataModalOpen}
        onClose={toggleAddInvoiceDataModal}
        onSubmit={handleAddInvoiceData}
        loading={isAddInvoiceDataLoading}
      />
      <EditInvoiceDataModal
        open={isEditInvoiceDataModalOpen}
        onClose={toggleEditInvoiceDataModal}
        onSubmit={handleEditInvoiceData}
        loading={isEditInvoiceDataLoading}
        initialValues={selectedInvoiceData || {}}
      />
      <ConfirmationModal
        open={isDeleteInvoiceDataModalOpen}
        onClose={toggleDeleteInvoiceDeleteModal}
        onConfirm={handleDeleteInvoiceData}
        title={t("invoice_data.delete_modal.title")}
        content={t("invoice_data.delete_modal.content_text")}
        loading={isDeleteInvoiceDataLoading}
      />
    </>
  );
};

export default InvoiceDataSection;
