import useResponsive from "@hooks/useResponsive";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Divider, List, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { palette } from "@theme/palette";
import { IPatient } from "patients-types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface PatientsListProps {
  searchTerm: string;
  filteredPatients: IPatient[];
  selectedPatient: string;
  handleSearchChange: (value: string) => void;
  setSelectedPatient: (patientId: string) => void;
}

const PatientsList: FC<PatientsListProps> = ({ searchTerm, filteredPatients, selectedPatient, setSelectedPatient, handleSearchChange }) => {
  const { t } = useTranslation();

  const isDesktop = useResponsive("up", "sm");

  return (
    <Paper sx={{ p: 2, height: isDesktop ? 600 : 250 }}>
      <TextField
        fullWidth
        label={t("activity_log.filter_by_patient")}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        InputProps={{
          endAdornment: <SearchRoundedIcon color="action" />,
        }}
      />
      <List sx={{ overflowY: "auto", height: isDesktop ? 450 : 160 }}>
        {filteredPatients.length !== 0 ? (
          filteredPatients.map((patient, index) => (
            <React.Fragment key={patient._id}>
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setSelectedPatient(patient._id)}
                selected={patient._id === selectedPatient}
                sx={{
                  borderRadius: 1,
                  "&.Mui-selected": {
                    bgcolor: palette.grey[100],
                    "&:hover": {
                      bgcolor: palette.grey[200],
                    },
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" noWrap>
                      {`${patient.firstname} ${patient.lastname}`}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" noWrap color="text.secondary">
                      {patient.email || t("personal_data.no_email")}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Divider variant="inset" component="li" />
              {index < filteredPatients.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary" align="center">
            {t("activity_log.no_patient")}
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export default PatientsList;
