import { RBACActions } from "@auth/rbac-rules";
import RBAC from "@auth/RBAC/RBAC";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  SelectChangeEvent,
  Paper,
  FormHelperText,
  Typography,
} from "@mui/material";
import { IEmployee } from "employees-types";
import { IPatient } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICalendarFilterProps {
  showExcused: boolean;
  employees: IEmployee[];
  patients: IPatient[];
  selectedEmployees: string[];
  selectedPatients: string[];
  handlePatientChange: (event: SelectChangeEvent<string[]>) => void;
  handleEmployeeChange: (event: SelectChangeEvent<string[]>) => void;
  handleSwitchChange: (event: React.MouseEvent<HTMLElement>, value: boolean) => void;
}

const CalendarFilter: React.FC<ICalendarFilterProps> = ({
  employees,
  patients,
  showExcused,
  selectedPatients,
  selectedEmployees,
  handlePatientChange,
  handleEmployeeChange,
  handleSwitchChange,
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2, mb: 5 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t("filter.title")}
      </Typography>
      <Grid container spacing={3}>
        <RBAC
          action={RBACActions.ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE}
          yes={() => (
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel>{t("filter.by_employee")}</InputLabel>
                <Select
                  multiple
                  value={selectedEmployees}
                  onChange={handleEmployeeChange}
                  renderValue={(selected) => {
                    const selectedEmployeesNames = selected.map((employeeId) => {
                      const employee = employees.find((e) => e._id === employeeId);
                      return employee ? `${employee.firstname} ${employee.lastname}` : "";
                    });
                    return selectedEmployeesNames.join(", ");
                  }}
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee._id} value={employee._id}>
                      <Checkbox checked={selectedEmployees.indexOf(employee._id) > -1} />
                      <ListItemText primary={`${employee.firstname} ${employee.lastname}`} secondary={employee.email} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t("filter.select_employees_helper")}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        />
        <RBAC
          action={RBACActions.ACTION_FILTER_APPOINTMENTS_BY_PATIENT}
          yes={() => (
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>{t("filter.by_patient")}</InputLabel>
                <Select
                  multiple
                  value={selectedPatients}
                  onChange={handlePatientChange}
                  renderValue={(selected) => {
                    const selectedPatientsNames = selected.map((patientId) => {
                      const patient = patients.find((e) => e._id === patientId);
                      return patient ? `${patient.firstname} ${patient.lastname}` : "";
                    });
                    return selectedPatientsNames.join(", ");
                  }}
                >
                  {patients.map((patient) => (
                    <MenuItem key={patient._id} value={patient._id}>
                      <Checkbox checked={selectedPatients.indexOf(patient._id) > -1} />
                      <ListItemText primary={`${patient.firstname} ${patient.lastname}`} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t("filter.select_patients_helper")}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        />
        <Grid item xs={12} sm={3}>
          <ToggleButtonGroup size="large" color="warning" value={showExcused} exclusive onChange={handleSwitchChange} fullWidth>
            <ToggleButton value={true}>{t("appointments.show_excused")}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CalendarFilter;
