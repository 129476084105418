import { SnackbarProps } from "@hoc/AlertPopover";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, IconButton, Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useCreateAvailabilityMutation, useUpdateAvailabilityMutation } from "@store/services/reservationsService";
import { format, parse, isBefore, isAfter } from "date-fns";
import { IEmployee } from "employees-types";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IAvailabilityDay, IAvailabilityRequest, ITimeslot } from "reservation-types";

interface IEmployeeAvailabilitySchedulerProps extends SnackbarProps {
  availabilityData: IAvailabilityDay[];
  selectedEmployee: IEmployee;
}

const daysOfWeek = [
  { label: "weekdays.monday", index: 0 },
  { label: "weekdays.tuesday", index: 1 },
  { label: "weekdays.wednesday", index: 2 },
  { label: "weekdays.thursday", index: 3 },
  { label: "weekdays.friday", index: 4 },
  { label: "weekdays.saturday", index: 5 },
  { label: "weekdays.sunday", index: 6 },
];

const EmployeeAvailabilityScheduler: React.FC<IEmployeeAvailabilitySchedulerProps> = ({
  availabilityData,
  selectedEmployee,
  snackbarShowMessage,
}) => {
  if (!selectedEmployee || !availabilityData) return null;
  const { t } = useTranslation();

  const [availability, setAvailability] = useState<IAvailabilityDay[]>(availabilityData);
  const [isModified, setIsModified] = useState(false);
  const [hasTimeConflict, setHasTimeConflict] = useState(false);

  const [createAvailability] = useCreateAvailabilityMutation();
  const [updateAvailability] = useUpdateAvailabilityMutation();

  useEffect(() => {
    setAvailability(availabilityData);
    setIsModified(false);
  }, [selectedEmployee, availabilityData]);

  const getDayAvailability = useCallback(
    (dayIndex: number) => {
      return availability?.find((day) => day.day === dayIndex);
    },
    [availability]
  );

  const parseTime = useCallback((time: string) => parse(time, "HH:mm", new Date()), []);
  const formatTime = useCallback((date: Date) => format(date, "HH:mm"), []);

  const checkForConflicts = useCallback(
    (timeslots: ITimeslot[]): boolean => {
      return timeslots.some((slotA, indexA) =>
        timeslots.some((slotB, indexB) => {
          if (indexA === indexB) return false;
          const startA = parseTime(slotA.start);
          const endA = parseTime(slotA.end);
          const startB = parseTime(slotB.start);
          const endB = parseTime(slotB.end);

          return (
            (isBefore(startB, endA) && isAfter(startB, startA)) ||
            (isBefore(startA, endB) && isAfter(startA, startB)) ||
            format(startA, "HH:mm") === format(startB, "HH:mm")
          );
        })
      );
    },
    [parseTime]
  );

  const handleAvailabilityChange = useCallback((dayIndex: number) => {
    setIsModified(true);
    setAvailability((prev) => {
      const day = prev.find((d) => d.day === dayIndex);
      if (day) {
        return prev.map((d) =>
          d.day === dayIndex
            ? {
                ...d,
                isAvailable: !d.isAvailable,
                timeslots: !d.isAvailable ? [{ start: "08:00", end: "15:00" }] : [],
              }
            : d
        );
      } else {
        return [...prev, { day: dayIndex, isAvailable: true, timeslots: [{ start: "08:00", end: "15:00" }] }];
      }
    });
  }, []);

  const handleAddTimeslot = useCallback(
    (dayIndex: number) => {
      setIsModified(true);
      setAvailability((prev) => {
        const newAvailability = prev.map((d) =>
          d.day === dayIndex
            ? {
                ...d,
                timeslots: [...d.timeslots, { start: "08:00", end: "15:00" }],
              }
            : d
        );
        const day = newAvailability.find((d) => d.day === dayIndex);
        if (day && checkForConflicts(day.timeslots)) {
          setHasTimeConflict(true);
          snackbarShowMessage(t("reservations.scheduler.time_conflict_error"), "error");
        } else {
          setHasTimeConflict(false);
        }
        return newAvailability;
      });
    },
    [checkForConflicts, snackbarShowMessage, t]
  );

  const handleRemoveTimeslot = useCallback(
    (dayIndex: number, slotIndex: number) => {
      setIsModified(true);
      setAvailability((prev) => {
        const newAvailability = prev.map((d) =>
          d.day === dayIndex
            ? {
                ...d,
                timeslots: d.timeslots.filter((_, idx) => idx !== slotIndex),
              }
            : d
        );
        const day = newAvailability.find((d) => d.day === dayIndex);
        if (day && checkForConflicts(day.timeslots)) {
          setHasTimeConflict(true);
          snackbarShowMessage(t("reservations.scheduler.time_conflict_error"), "error");
        } else {
          setHasTimeConflict(false);
        }
        return newAvailability;
      });
    },
    [checkForConflicts, snackbarShowMessage, t]
  );

  const handleTimeslotChange = useCallback(
    (dayIndex: number, slotIndex: number, field: "start" | "end", newValue: Date | null) => {
      if (!newValue) return;
      setIsModified(true);
      setAvailability((prev) => {
        const newAvailability = prev.map((d) =>
          d.day === dayIndex
            ? {
                ...d,
                timeslots: d.timeslots.map((slot, idx) => (idx === slotIndex ? { ...slot, [field]: formatTime(newValue) } : slot)),
              }
            : d
        );
        const day = newAvailability.find((d) => d.day === dayIndex);
        if (day && checkForConflicts(day.timeslots)) {
          setHasTimeConflict(true);
          snackbarShowMessage(t("reservations.scheduler.time_conflict_error"), "error");
        } else {
          setHasTimeConflict(false);
        }
        return newAvailability;
      });
    },
    [formatTime, checkForConflicts, snackbarShowMessage, t]
  );

  const handleSave = useCallback(async () => {
    const data: IAvailabilityRequest = {
      availability,
    };

    try {
      if (availabilityData.length === 0) {
        await createAvailability({ userId: selectedEmployee._id, data }).unwrap();
        snackbarShowMessage(t("reservations.scheduler.snackbar_messages.success"), "success");
      } else {
        await updateAvailability({ userId: selectedEmployee._id, data }).unwrap();
        snackbarShowMessage(t("reservations.scheduler.snackbar_messages.success"), "success");
      }
      setIsModified(false);
    } catch (error) {
      snackbarShowMessage?.("reservations.scheduler.snackbar_messages.error", "error");
    }
  }, [availability, availabilityData, createAvailability, selectedEmployee._id, snackbarShowMessage, t, updateAvailability]);

  return (
    <Card>
      <CardHeader
        title={`${t("reservations.scheduler.title")}: ${selectedEmployee.firstname} ${selectedEmployee.lastname}`}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleSave}
            disabled={!isModified || hasTimeConflict}
            startIcon={<SaveRoundedIcon />}
          >
            {t("reservations.scheduler.save")}
          </Button>
        }
      />
      <Divider sx={{ borderStyle: "dashed", mt: 3, mb: 1 }} />
      <CardContent>
        {daysOfWeek.map((day, index) => {
          const dayAvailability = getDayAvailability(day.index);
          const isAvailable = dayAvailability?.isAvailable || false;
          const timeslots = dayAvailability?.timeslots || [];

          return (
            <Box key={day.index} sx={{ mt: index > 0 && 3, border: "1px solid", borderColor: "grey.300", borderRadius: 2, p: 2 }}>
              <Stack alignItems="baseline">
                <Stack direction="row" alignItems="center">
                  <Checkbox color="primary" checked={isAvailable} onChange={() => handleAvailabilityChange(day.index)} />
                  <Typography variant="subtitle1" sx={{ width: 100 }}>
                    {t(day.label)}
                  </Typography>
                  {!isAvailable && (
                    <Typography variant="body2" color="text.secondary">
                      {t("reservations.scheduler.not_available")}
                    </Typography>
                  )}
                </Stack>
                {isAvailable && timeslots.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    {timeslots.map((slot, idx) => {
                      const startTime = parseTime(slot.start);
                      const endTime = parseTime(slot.end);

                      return (
                        <Stack direction="row" alignItems="center" key={idx} sx={{ mb: 2 }}>
                          <TimePicker
                            label={t("reservations.scheduler.start_time")}
                            value={startTime}
                            onChange={(newValue) => handleTimeslotChange(day.index, idx, "start", newValue)}
                            sx={{ marginRight: 1 }}
                            maxTime={endTime}
                          />
                          <Typography variant="body1" sx={{ marginRight: 1 }}>
                            -
                          </Typography>
                          <TimePicker
                            label={t("reservations.scheduler.end_time")}
                            value={endTime}
                            onChange={(newValue) => handleTimeslotChange(day.index, idx, "end", newValue)}
                            sx={{ marginRight: 1 }}
                            minTime={startTime}
                          />
                          <IconButton onClick={() => handleRemoveTimeslot(day.index, idx)} disabled={timeslots.length === 1}>
                            <RemoveRoundedIcon />
                          </IconButton>
                          <IconButton onClick={() => handleAddTimeslot(day.index)}>
                            <AddRoundedIcon />
                          </IconButton>
                        </Stack>
                      );
                    })}
                  </Box>
                )}
              </Stack>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default EmployeeAvailabilityScheduler;
