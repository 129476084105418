import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { IAppointment, IAppointmentFilter } from "appointment.types";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { ReservationStatus } from "@utils/enums";
import { IReservationStats } from "reservation-types";

enum TagTypes {
  APPOINTMENTS = "APPOINTMENTS",
}

export const appointmentsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "appointmentsApi",
  tagTypes: [TagTypes.APPOINTMENTS],
  endpoints: (build) => ({
    getAllAppointments: build.query<IAppointment[], IAppointmentFilter>({
      query: (queryParams) => ({
        method: "GET",
        url: `appointments`,
        params: queryParams,
      }),
      providesTags: [TagTypes.APPOINTMENTS],
    }),
    addAppointment: build.mutation<IAppointment, Partial<IAppointment>>({
      query: (appointment) => ({
        method: "POST",
        url: "appointments",
        data: appointment,
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),
    editAppointment: build.mutation<IAppointment, { appointmentId: string; data: Partial<IAppointment> }>({
      query: ({ appointmentId, data }) => ({
        method: "PATCH",
        url: `appointments/${appointmentId}`,
        data,
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),
    deleteAppointment: build.mutation<void, { appointmentId: string; deleteSeries: boolean }>({
      query: ({ appointmentId, deleteSeries }) => ({
        method: "DELETE",
        url: `appointments/${appointmentId}`,
        params: { deleteSeries },
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),
    toggleAppointmentStatus: build.mutation<IAppointment, { appointmentId: string; data: Partial<IAppointment> }>({
      query: ({ appointmentId, data }) => ({
        method: "PATCH",
        url: `appointments/${appointmentId}/toggle-status`,
        data,
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),
    toggleReservationStatus: build.mutation<IAppointment, { appointmentId: string; data: { reservationStatus: ReservationStatus } }>({
      query: ({ appointmentId, data }) => ({
        url: `appointments/${appointmentId}/toggle-reservation-status`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),

    getReservationStats: build.query<IReservationStats, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        url: `reservation-stats`,
        method: "GET",
        params: { startDate, endDate },
      }),
      providesTags: [TagTypes.APPOINTMENTS],
    }),

    deleteManyAppointments: build.mutation<void, { patientId: string; startDate: string; endDate: string }>({
      query: ({ patientId, startDate, endDate }) => ({
        method: "DELETE",
        url: `appointments/patients/${patientId}`,
        params: { startDate, endDate },
      }),
      invalidatesTags: [TagTypes.APPOINTMENTS],
    }),
  }),
});

export const {
  useGetAllAppointmentsQuery,
  useAddAppointmentMutation,
  useEditAppointmentMutation,
  useDeleteAppointmentMutation,
  useToggleAppointmentStatusMutation,
  useToggleReservationStatusMutation,
  useGetReservationStatsQuery,
  useDeleteManyAppointmentsMutation,
} = appointmentsApi;

export const appointmentsApiReducer = {
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
};
