import { Checkbox, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { IAppointmentType } from "appointmentTypes.types";
import { endOfMonth, startOfMonth } from "date-fns";
import { IEmployee } from "employees-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReservationFilter } from "../ReservationCalendarSection";

interface IReservationFilterPanelProps {
  employees: IEmployee[];
  selectedEmployees: string[];
  appointmentTypes: IAppointmentType[];
  selectedTypes: string[];
  filters: ReservationFilter;
  handleEmployeeChange: (event: SelectChangeEvent<string[]>) => void;
  handleTypeChange: (event: SelectChangeEvent<string[]>) => void;
  setFilters: React.Dispatch<React.SetStateAction<ReservationFilter>>;
}

const ReservationFilterPanel: React.FC<IReservationFilterPanelProps> = ({
  filters,
  appointmentTypes,
  selectedTypes,
  employees,
  selectedEmployees,
  setFilters,
  handleEmployeeChange,
  handleTypeChange,
}) => {
  const { t } = useTranslation();

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);
      setFilters({
        ...filters,
        start: startDate.toISOString(),
        from: endDate.toISOString(),
      });
    }
  };

  return (
    <Grid container sx={{ p: 3 }} spacing={3}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel>{t("filter.by_employee")}</InputLabel>
          <Select
            multiple
            value={selectedEmployees}
            onChange={handleEmployeeChange}
            renderValue={(selected) => {
              const selectedEmployeesNames = selected.map((employeeId) => {
                const employee = employees.find((e) => e._id === employeeId);
                return employee ? `${employee.firstname} ${employee.lastname}` : "";
              });
              return selectedEmployeesNames.join(", ");
            }}
          >
            {employees.map((employee) => (
              <MenuItem key={employee._id} value={employee._id}>
                <Checkbox checked={selectedEmployees.indexOf(employee._id) > -1} />
                <ListItemText primary={`${employee.firstname} ${employee.lastname}`} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t("filter.select_employees_helper")}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          label={t("date.label")}
          views={["year", "month"]}
          value={filters.start ? new Date(filters.from) : new Date()}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel>{t("filter.by_type")}</InputLabel>
          <Select
            multiple
            value={selectedTypes}
            onChange={handleTypeChange}
            renderValue={(selected) => {
              const selectedTypesNames = selected.map((typeId) => {
                const type = appointmentTypes.find((e) => e._id === typeId);
                return type ? `${type.name}` : "";
              });
              return selectedTypesNames.join(", ");
            }}
          >
            {appointmentTypes.map((type) => (
              <MenuItem key={type._id} value={type._id}>
                <Checkbox checked={selectedTypes.indexOf(type._id) > -1} />
                <ListItemText primary={type.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t("filter.select_types_helper")}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ReservationFilterPanel;
