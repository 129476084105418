import { ExpensesTypeWidget } from "@components/ExpensesTypeWIdget/ExpensesTypeWidget";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded";
import { Grid, Paper, alpha, useTheme } from "@mui/material";
import { t } from "i18next";
import React from "react";

interface IReportsStatsProps {
  totalIncome: number;
  totalExpenseValue: number;
}

const ReportsStats: React.FC<IReportsStatsProps> = ({ totalIncome, totalExpenseValue }) => {
  const theme = useTheme();

  const incomeColor = totalIncome - totalExpenseValue >= 0 ? alpha(theme.palette.success.main, 0.12) : alpha(theme.palette.error.main, 0.12);
  const incomeIconColor = totalIncome - totalExpenseValue >= 0 ? "success" : "error";

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ p: 2 }}>
          <ExpensesTypeWidget
            type={t("reports.total_income")}
            value={totalIncome}
            icon={<PointOfSaleRoundedIcon color="primary" />}
            color={alpha(theme.palette.primary.main, 0.12)}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ p: 2 }}>
          <ExpensesTypeWidget
            type={t("reports.total_revenue")}
            value={totalIncome - totalExpenseValue}
            icon={<PriceCheckRoundedIcon color={incomeIconColor} />}
            color={incomeColor}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default ReportsStats;
