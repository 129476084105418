import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Sex } from "@utils/enums";
import { getEnumValues } from "@utils/utils";
import { Field, FieldProps, Formik } from "formik";
import { IPatient } from "patients-types";
import React from "react";
import { addEditPatientValidationSchema } from "src/validations/patientValidation";
import CustomDialog from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { formatSexString } from "@utils/typographyUtils";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { Form } from "@theme/styled-components/StyledForm";

interface IEditPatientModalProps {
  open: boolean;
  initialValues: Partial<IPatient>;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IPatient>) => void;
}

const EditPatientModal: React.FC<IEditPatientModalProps> = ({ open, loading, initialValues, onSubmit, onClose }) => {
  const { t } = useTranslation();

  if (!initialValues) return null;

  return (
    <Formik initialValues={initialValues} validationSchema={addEditPatientValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, setFieldValue }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("patient.edit_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("patient.edit_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="firstname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.firstname")}
                        helperText={touched.firstname && errors.firstname ? t(errors.firstname.toString()) : t("personal_data.firstname_helper")}
                        error={touched.firstname && !!errors.firstname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.lastname")}
                        helperText={touched.lastname && errors.lastname ? t(errors.lastname.toString()) : t("personal_data.lastname_helper")}
                        error={touched.lastname && !!errors.lastname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="sex">
                    {({ field }: FieldProps) => (
                      <FormControl fullWidth error={touched.sex && !!errors.sex}>
                        <InputLabel>{t("personal_data.gender")}</InputLabel>
                        <Select {...field}>
                          {getEnumValues(Sex).map((value) => (
                            <MenuItem value={value} key={value}>
                              {t(formatSexString(value))}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.sex && errors.sex ? t(errors.sex.toString()) : t("personal_data.gender_helper")}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="dateOfBirth">
                    {() => (
                      <Field name="dateOfBirth">
                        {({ field }: FieldProps) => (
                          <DatePicker
                            label={t("personal_data.date_of_birth")}
                            value={parseISO(field.value || null)}
                            onChange={(value) => setFieldValue(field.name, value)}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                helperText:
                                  touched.dateOfBirth && errors.dateOfBirth ? t(errors.dateOfBirth) : t("personal_data.date_of_birth_helper"),
                                error: touched.dateOfBirth && !!errors.dateOfBirth,
                              },
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="phoneNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label={t("personal_data.phone_number")}
                        helperText={
                          touched.phoneNumber && errors.phoneNumber ? t(errors.phoneNumber.toString()) : t("personal_data.phone_number_helper")
                        }
                        error={touched.phoneNumber && !!errors.phoneNumber}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.email")}
                        helperText={touched.email && errors.email ? t(errors.email.toString()) : t("personal_data.email_helper")}
                        error={touched.email && !!errors.email}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default EditPatientModal;
