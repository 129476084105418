import * as Yup from "yup";

export const authValidationSchema = Yup.object({
  email: Yup.string().email("validation.valid_email").required("validation.email_required"),
  password: Yup.string().min(8, "validation.password_min_length").required("validation.password_required"),
});

export const sendResetPasswordEmailValidationSchema = Yup.object({
  email: Yup.string().email("validation.valid_email").required("validation.email_required"),
});

export const changePasswordValidationSchema = Yup.object({
  password: Yup.string().min(8, "change_password.validation.password_min_length").required("change_password.validation.password_required"),
  newPassword: Yup.string().min(8, "change_password.validation.password_min_length").required("change_password.validation.password_required"),
  token: Yup.string().required("change_password.validation.token_required"),
});
