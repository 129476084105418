import React, { createContext, useContext, useState, useEffect } from "react";
import { hexToRgb } from "@theme/utils/stylesUtils";
import { PrimaryPaletteKey, primaryPalettes } from "@theme/palette";

interface ThemeSettings {
  primaryColor: PrimaryPaletteKey;
  primaryFont: string;
  [key: string]: any;
}

const defaultSettings: ThemeSettings = {
  primaryColor: "PRIMARY",
  primaryFont: "Nunito Sans, sans-serif",
};

const ThemeSettingsContext = createContext<{
  settings: ThemeSettings;
  updateSettings: (updates: Partial<ThemeSettings>) => void;
}>({
  settings: defaultSettings,
  updateSettings: () => null,
});

const updateCSSVariables = (primaryColor: string) => {
  document.documentElement.style.setProperty("--time-selection-bg-color", `rgba(${hexToRgb(primaryColor)}, 0.12)`);
  document.documentElement.style.setProperty("--time-selection-color", `${primaryColor}`);
  document.documentElement.style.setProperty("--current-time-color", `${primaryColor}`);
};

export const useThemeSettings = () => useContext(ThemeSettingsContext);

export const ThemeSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<ThemeSettings>(defaultSettings);

  useEffect(() => {
    const savedSettings = localStorage.getItem("themeSettings");
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  const updateSettings = (updates: Partial<ThemeSettings>) => {
    const newSettings = { ...settings, ...updates };
    setSettings(newSettings);
    localStorage.setItem("themeSettings", JSON.stringify(newSettings));
    const primaryColor = primaryPalettes[newSettings.primaryColor].main;
    updateCSSVariables(primaryColor);
  };

  useEffect(() => {
    const primaryColor = primaryPalettes[settings.primaryColor].main;
    updateCSSVariables(primaryColor);
  }, [settings]);

  return <ThemeSettingsContext.Provider value={{ settings, updateSettings }}>{children}</ThemeSettingsContext.Provider>;
};
