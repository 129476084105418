import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import PatientReportsSummary from "@components/PatientReportsSummary/PatientReportsSummary";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Button, Container, Typography } from "@mui/material";
import { useGetPatientAppointmentsReportsQuery, useGetPatientPresenceReportsQuery } from "@store/services/reportsService";
import { AppRoutes, AppointmentStatus } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { getMonth } from "date-fns";
import React, { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IPatientAppointmentsByStatus, IPatientIncomeDetails } from "reports-types";

interface IPatientReportsProps extends SnackbarProps {}

const PatientReports: React.FC<IPatientReportsProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);

  const { presenceReports } = useGetPatientPresenceReportsQuery(
    {
      patientId: patientId,
      month: getMonth(selectedDate),
    },
    {
      selectFromResult: ({ data }) => ({
        presenceReports: data || ({} as IPatientAppointmentsByStatus),
      }),
    }
  );
  const { appointmentsReports } = useGetPatientAppointmentsReportsQuery(
    {
      patientId: patientId,
      month: getMonth(selectedDate),
      appointmentStatus: selectedStatuses,
    },
    {
      selectFromResult: ({ data }) => ({
        appointmentsReports: data || ({} as IPatientIncomeDetails),
      }),
    }
  );

  const handleMonthChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        setSelectedDate(newDate);
      }
    },
    [setSelectedDate]
  );

  if (!presenceReports || !appointmentsReports || !appointmentsReports.patientIncome) return null;
  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("reports.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={`${appointmentsReports.patientIncome.firstname} ${appointmentsReports.patientIncome.lastname}`}
          gap={3}
          rightContent={
            <Button color="secondary" variant="outlined" onClick={() => navigate(-1)} startIcon={<KeyboardBackspaceRoundedIcon />}>
              {t("go_back")}
            </Button>
          }
          bottomContent={
            <>
              <Typography variant="h6">
                {t("reports.total_income")}: {formatCost(appointmentsReports.patientIncome.totalIncome)}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {`${t("reports.period")} ${formatDate(selectedDate, "dd.MM.yyyy")} - ${formatDate(selectedDate, "dd.MM.yyyy")}`}
              </Typography>
            </>
          }
        />
        <PatientReportsSummary
          selectedDate={selectedDate}
          selectedStatuses={selectedStatuses}
          presenceReports={presenceReports}
          appointmentsReports={appointmentsReports}
          setSelectedStatuses={setSelectedStatuses}
          handleMonthChange={handleMonthChange}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Container>
    </>
  );
};

export default withSnackbar(PatientReports);
