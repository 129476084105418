import AccountPopover from "@components/Header/fragments/AccountPopover";
import LanguagePopover from "@components/Header/fragments/LanguagePopover";
import Iconify from "@components/Iconify/Iconify";
import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import { bgBlur } from "src/theme/utils/stylesUtils";
import { IUserDetails } from "user-types";
import PresetDrawer from "./fragments/ThemeSettingsDrawer";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const NAV_WIDTH = 300;

export const HEADER_MOBILE = 64;

export const HEADER_DESKTOP = 92;

//@ts-expect-error
const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface IHeader {
  userDetails: IUserDetails;
  onOpenNav: () => void;
}

const Header: React.FC<IHeader> = ({ onOpenNav, userDetails }) => {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
          }}
        >
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <PresetDrawer
            isHeader
            icon={
              <Iconify
                icon="eva:settings-2-fill"
                sx={{
                  animation: `${spin} 10s linear infinite`,
                }}
              />
            }
          />
          <AccountPopover {...{ userDetails }} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};
export default Header;
