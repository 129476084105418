import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import ActionMenu from "@components/ActionMenu/ActionMenu";
import PageTitle from "@components/PageTitle/PageTitle";
import { ClinicContext, IClinicContext } from "@context/ClinicProvider";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import { Button, CircularProgress, Container, Divider, Grid, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { useGetAllInvoiceDataQuery } from "@store/services/InvoiceDataService";
import { useAddInvoiceMutation, useGenerateInvoiceDataQuery, useLazySuggestInvoiceNumberQuery } from "@store/services/invoiceService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { AppRoutes, InvoiceStatus } from "@utils/enums";
import { generatePDF } from "@utils/invoiceUtils";
import { formatDate } from "@utils/timeUtils";
import { getAppointmentStatusText } from "@utils/utils";
import { addMonths, endOfMonth, isBefore, setDate, startOfMonth } from "date-fns";
import { IInvoiceData } from "invoiceData-types";
import jsPDF from "jspdf";
import { IPatient } from "patients-types";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InvoicePreview from "./fragments/InvoicePreview";
import InvoiceSettings from "./fragments/InvoiceSettings";
import useSendInvoice from "./hooks/useSendInvoice";
import ChangeInvoiceDataModal from "./modals/ChangeInvoiceDataModal";
import ChooseAcquirerDataModal from "./modals/ChooseAcquirerDataModal";
import CreateInvoiceSteps from "./fragments/CreateInvoiceSteps";

const calculatePaymentDate = (paymentDayOfMonth: number): Date => {
  const today = new Date();
  const currentMonth = setDate(today, paymentDayOfMonth);
  const nextMonth = setDate(addMonths(today, 1), paymentDayOfMonth);
  return isBefore(today, currentMonth) ? currentMonth : nextMonth;
};

const CreateInvoice: React.FC = ({ snackbarShowMessage }: SnackbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const printRef = useRef<HTMLDivElement>(null);
  const userRole = useContext(RoleContext);

  const [openMenu, setOpenMenu] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const [uploadInvoice] = useSendInvoice();

  const [isChangeInvoiceDataModalOpen, toggleChangeInvoiceDataModal] = useModal();
  const [isChooseAcquirerDataModalOpen, toggleChooseAcquirerDataModal] = useModal();
  const { currentClinic } = useContext(ClinicContext) as IClinicContext;

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 300);

  const [addInvoice] = useAddInvoiceMutation();

  const [suggestInvoiceNumber] = useLazySuggestInvoiceNumberQuery();

  const [invoiceNumber, setInvoiceNumber] = useState<string>("");

  const { invoiceData: invoiceData, isLoading: isLoadingInvoiceData } = useGetAllInvoiceDataQuery(
    {},
    {
      selectFromResult: ({ data, isLoading }) => ({
        invoiceData: data || [],
        isLoading,
      }),
    }
  );

  const { patients, isLoading: isLoadingPatients } = useGetAllPatientsQuery(
    {
      search: debouncedSearchText,
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        patients: data || [],
        isLoading,
      }),
    }
  );

  const [selectedAcquirerData, setSelectedAcquirerData] = useState<IPatient | undefined>(undefined);
  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const { invoiceDraftData, isLoading: isLoadingInvoiceDraftData } = useGenerateInvoiceDataQuery(
    { patientId: selectedAcquirerData?._id, start: dateRange.start.toISOString(), from: dateRange.end.toISOString() },
    {
      selectFromResult: ({ data, isLoading }) => ({
        invoiceDraftData: data?.results || {},
        isLoading,
      }),
      skip: !selectedAcquirerData,
    }
  );

  const [selectedInvoiceData, setSelectedInvoiceData] = useState<IInvoiceData | undefined>(undefined);

  useEffect(() => {
    if (selectedInvoiceData) {
      setPaymentDate(calculatePaymentDate(selectedInvoiceData.paymentDayOfMonth));
    }
  }, [selectedInvoiceData]);

  const invoiceDataArray = useMemo(() => {
    return Object.entries(invoiceDraftData).flatMap(([status, services]) =>
      services.map((service: any) => ({
        serviceName: `${service.name} (${t(getAppointmentStatusText(status as any))})`,
        amount: service.count,
        netPrice: service.costPerCount.toFixed(2),
        VAT: "zw",
        vatAmount: 0,
        netValue: service.totalAmount.toFixed(2),
        grossValue: service.totalAmount.toFixed(2),
        totalNetValue: service.totalAmount.toFixed(2),
        totalGrossValue: service.totalAmount.toFixed(2),
      }))
    );
  }, [invoiceDraftData, t]);

  const totalAmount = useMemo(() => {
    return Object.values(invoiceDraftData).reduce((total, services) => {
      return total + services.reduce((serviceTotal: number, service: { totalAmount: number }) => serviceTotal + service.totalAmount, 0);
    }, 0);
  }, [invoiceDraftData]);

  const handleSuggestInvoiceNumber = useCallback(async () => {
    try {
      const data = await suggestInvoiceNumber().unwrap();
      setInvoiceNumber(data?.suggestion);
    } catch (error) {
      console.error("Nie udało się pobrać ostatniego numeru faktury:", error);
    }
  }, [suggestInvoiceNumber]);

  const handleDateChange = useCallback((field: string, value: Date) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const handlePaymentDateChange = useCallback((date: Date | null) => {
    setPaymentDate(date);
  }, []);

  const handlePrint = useCallback(() => {
    if (selectedAcquirerData) {
      const patientName = `${selectedAcquirerData.firstname}${selectedAcquirerData.lastname}`;

      const savePDFWithDelay = (pdf: jsPDF) => {
        setTimeout(() => {
          pdf.save(`${invoiceNumber}-${patientName}.pdf`);
        }, 500);
      };

      generatePDF({
        elementRef: printRef,
        onComplete: savePDFWithDelay,
      });
    }
  }, [printRef, selectedAcquirerData, invoiceNumber]);

  const handleInvoiceDataSelect = useCallback(
    (invoiceData: IInvoiceData) => {
      setSelectedInvoiceData(invoiceData);
      toggleChangeInvoiceDataModal();
    },
    [toggleChangeInvoiceDataModal]
  );

  const handleAcquirerDataSelect = useCallback(
    (patient: IPatient) => {
      setSelectedAcquirerData(patient);
      toggleChooseAcquirerDataModal();
    },
    [toggleChooseAcquirerDataModal]
  );

  const handleAddInvoice = useCallback(
    (invoiceStatus?: InvoiceStatus, shouldNavigate = true) => {
      if (!selectedAcquirerData || !selectedInvoiceData) return Promise.resolve();

      return addInvoice({
        grossValue: parseFloat(totalAmount.toFixed(2)),
        invoiceNumber: invoiceNumber,
        patientId: selectedAcquirerData?._id,
        publishDate: new Date(),
        paymentDate: paymentDate?.toISOString(),
        periodStartDate: dateRange.start,
        periodEndDate: dateRange.end,
        status: invoiceStatus || InvoiceStatus.DRAFT,
        invoiceData: invoiceDataArray,
        patient: {
          firstname: selectedAcquirerData.firstname,
          lastname: selectedAcquirerData.lastname,
        },
        acquirerInvoiceData: {
          firstname: selectedAcquirerData.patientInvoiceData.firstname,
          lastname: selectedAcquirerData.patientInvoiceData.lastname,
          email: selectedAcquirerData.patientInvoiceData.email,
        },
        sellerInvoiceData: selectedInvoiceData,
      })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("invoice.snackbar_message.add.success"), "success");
          if (shouldNavigate) {
            navigate(AppRoutes.Invoices);
          }
        })
        .catch(() => {
          snackbarShowMessage(t("invoice.snackbar_message.add.error"), "error");
        });
    },
    [
      addInvoice,
      snackbarShowMessage,
      selectedAcquirerData,
      selectedInvoiceData,
      dateRange.start,
      dateRange.end,
      invoiceNumber,
      totalAmount,
      paymentDate,
      invoiceDataArray,
      navigate,
      t,
    ]
  );

  const handlePrintAndSave = useCallback(async () => {
    if (selectedAcquirerData) {
      const patientName = `${selectedAcquirerData.firstname}${selectedAcquirerData.lastname}`;

      await handleAddInvoice(undefined, false);

      const savePDFWithDelay = (pdf: jsPDF) => {
        setTimeout(() => {
          pdf.save(`${invoiceNumber}-${patientName}.pdf`);
          navigate(AppRoutes.Invoices);
        }, 500);
      };

      generatePDF({
        elementRef: printRef,
        onComplete: savePDFWithDelay,
      });
    }
  }, [printRef, selectedAcquirerData, invoiceNumber, handleAddInvoice, navigate]);

  const handleSendAndSave = useCallback(() => {
    if (selectedAcquirerData) {
      const patientName = `${selectedAcquirerData.firstname}${selectedAcquirerData.lastname}`;

      const savePDFWithDelay = (pdf: jsPDF) => {
        setTimeout(async () => {
          const fileName = `${invoiceNumber}-${patientName}.pdf`;
          const pdfBlob = pdf.output("blob");

          try {
            snackbarShowMessage(t("invoice.snackbar_message.send_invoice.pending"), "info");

            await uploadInvoice(
              pdfBlob,
              fileName,
              selectedAcquirerData.patientInvoiceData.email,
              invoiceNumber,
              parseFloat(totalAmount.toFixed(2)).toString(),
              formatDate(new Date(), "dd.MM.yyyy"),
              formatDate(new Date(paymentDate!), "dd.MM.yyyy"),
              selectedAcquirerData._id
            );
          } catch (error) {
            console.error("Error in handleSendAndSave:", error);
          }
        }, 500);
      };

      generatePDF({
        elementRef: printRef,
        onComplete: savePDFWithDelay,
      });

      handleAddInvoice(InvoiceStatus.SENT);
    }
  }, [printRef, selectedAcquirerData, invoiceNumber, totalAmount, paymentDate, uploadInvoice, handleAddInvoice, snackbarShowMessage, t]);

  useEffect(() => {
    if (invoiceData && invoiceData.length > 0 && !selectedInvoiceData) {
      setSelectedInvoiceData(invoiceData[0]);
    }
  }, [invoiceData, selectedInvoiceData]);

  useEffect(() => {
    if (patients && patients.length > 0 && !selectedAcquirerData) {
      const selectedPatient = patientId ? patients.find((p) => p._id === patientId) : patients[0];
      setSelectedAcquirerData(selectedPatient || patients[0]);
    }
  }, [patients, selectedAcquirerData, patientId]);

  const hasPatientInvoiceData = useMemo(() => {
    const invoiceData = selectedAcquirerData?.patientInvoiceData;
    return !!(invoiceData?.firstname && invoiceData?.lastname);
  }, [selectedAcquirerData]);

  const isLoading = isLoadingInvoiceData || isLoadingPatients || isLoadingInvoiceDraftData;

  if (isLoading) {
    return (
      <Container sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!invoiceDraftData || !invoiceData || !patients || !selectedAcquirerData)
    return <CreateInvoiceSteps hasPatients={patients?.length > 0} hasInvoiceData={invoiceData?.length > 0} />;

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_INVOICE)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("invoice.helmet_create")}</title>
      </Helmet>
      <Container>
        <PageTitle
          title={t("invoice.title_create")}
          bottomContent={
            selectedAcquirerData && (
              <Typography variant="subtitle1" color="text.secondary">
                {`${selectedAcquirerData?.firstname} ${selectedAcquirerData?.lastname}`}
              </Typography>
            )
          }
          rightContent={
            <Stack direction="row" spacing={2}>
              <Button color="secondary" variant="outlined" onClick={() => navigate(-1)} startIcon={<KeyboardBackspaceRoundedIcon />}>
                {t("go_back")}
              </Button>
              <ActionMenu
                openMenu={openMenu}
                menuItems={
                  <>
                    <MenuItem onClick={handlePrint}>
                      <ListItemIcon>
                        <LocalPrintshopRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.download")}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleAddInvoice()}>
                      <ListItemIcon>
                        <PostAddRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.save")}
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handlePrintAndSave}>
                      <ListItemIcon>
                        <SimCardDownloadRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.save_and_download")}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleSendAndSave}>
                      <ListItemIcon>
                        <HistoryEduRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("invoice.save_and_send")}
                      </Typography>
                    </MenuItem>
                  </>
                }
                buttonLabel={t("invoice.button.actions")}
                setOpenMenu={setOpenMenu}
              />
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoiceSettings
              {...{
                invoiceNumber,
                dateRange,
                selectedInvoiceData,
                selectedAcquirerData,
                currentClinic,
                invoiceDraftData,
                hasPatientInvoiceData,
                handleDateChange,
                setInvoiceNumber,
                toggleChangeInvoiceDataModal,
                toggleChooseAcquirerDataModal,
                paymentDate,
                handlePaymentDateChange,
                handleSuggestInvoiceNumber,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InvoicePreview
              {...{
                paymentDate,
                printRef,
                invoiceNumber,
                dateRange,
                totalAmount,
                selectedInvoiceData,
                currentClinic,
                invoiceDraftData,
                hasPatientInvoiceData,
              }}
              selectedAcquirerData={selectedAcquirerData?.patientInvoiceData}
            />
          </Grid>
        </Grid>
      </Container>
      <ChangeInvoiceDataModal
        invoiceData={invoiceData}
        open={isChangeInvoiceDataModalOpen}
        onClose={toggleChangeInvoiceDataModal}
        handleInvoiceDataSelect={handleInvoiceDataSelect}
      />
      <ChooseAcquirerDataModal
        open={isChooseAcquirerDataModalOpen}
        patients={patients}
        searchText={searchText}
        setSearchText={setSearchText}
        onClose={toggleChooseAcquirerDataModal}
        handleAcquirerDataSelect={handleAcquirerDataSelect}
      />
    </>
  );
};

export default withSnackbar(CreateInvoice);
