import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Form } from "@theme/styled-components/StyledForm";
import { IAddExpenseBody } from "expenses.types";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { addEditExpenseValidationSchema } from "src/validations/expensesValidation";

interface IAddExpenseModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IAddExpenseBody>) => void;
}

const AddExpenseModal: React.FC<IAddExpenseModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const initialValues = {
    name: "",
    description: "",
    value: null,
    isOneTimePayment: false,
    endDate: "",
  } as Partial<IAddExpenseBody>;

  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validationSchema={addEditExpenseValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, setFieldValue, values }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("expenses.add_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("expenses.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="isOneTimePayment">
                    {() => (
                      <ToggleButtonGroup
                        color="primary"
                        value={values.isOneTimePayment}
                        exclusive
                        onChange={(event, newValue) => {
                          setFieldValue("isOneTimePayment", newValue);
                          if (newValue) setFieldValue("endDate", null);
                        }}
                        fullWidth
                      >
                        <ToggleButton value={true} aria-label="one-time">
                          {t("expenses.add_modal.one_time_payment")}
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="recurring">
                          {t("expenses.add_modal.recurring_payment")}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="name">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("expenses.add_modal.name")}
                        helperText={touched.name && errors.name ? t(errors.name.toString()) : t("expenses.add_modal.name_helper_text")}
                        error={touched.name && !!errors.name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="description">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("expenses.add_modal.description")}
                        helperText={
                          touched.description && errors.description
                            ? t(errors.description.toString())
                            : t("expenses.add_modal.description_helper_text")
                        }
                        error={touched.description && !!errors.description}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="value">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label={t("expenses.add_modal.value")}
                        helperText={touched.value && errors.value ? t(errors.value.toString()) : t("expenses.add_modal.value_helper_text")}
                        error={touched.value && !!errors.value}
                      />
                    )}
                  </Field>
                </Grid>
                {!values.isOneTimePayment && (
                  <Grid item xs={12}>
                    <Field name="endDate">
                      {({ field }: FieldProps) => (
                        <DatePicker
                          label={t("expenses.add_modal.end_date")}
                          value={field.value || null}
                          onChange={(value) => {
                            setFieldValue("endDate", value);
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddExpenseModal;
