import CustomDialog from "@components/modals/CustomDialog";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { t } from "i18next";
import { IFile } from "patients-types";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import ReactPlayer from "react-player";
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

interface FilePreviewProps {
  file: IFile;
  open: boolean;
  onClose: () => void;
}

const allowedExtensions = ["jpeg", "jpg", "png", "pdf", "docx", "mp4", "mov"];

const FilePreviewModal: React.FC<FilePreviewProps> = ({ file, open, onClose }) => {
  const renderFilePreview = () => {
    if (!file) return null;
    const { url, filename } = file;
    const fileExtension = filename.split(".").pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      return <DialogContentText>{t("file.preview_modal.unsupported_file")}</DialogContentText>;
    }

    switch (fileExtension) {
      case "jpeg":
      case "jpg":
      case "png":
        return <img src={url} alt={filename} style={{ width: "100%" }} />;
      case "pdf":
        return (
          <Document file={url}>
            <Page pageNumber={1} width={500} />
          </Document>
        );
      case "mp4":
      case "mov":
        return <ReactPlayer url={url} controls width="100%" />;
      default:
        return <DialogContentText>{t("file.preview_modal.unsupported_file")}</DialogContentText>;
    }
  };

  const openFile = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("file.preview_modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{file?.filename}</DialogContentText>
        <Grid container sx={{ my: 1 }}>
          {renderFilePreview()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("file.preview_modal.close")}
        </Button>
        <Button onClick={() => openFile(file?.url)} variant="contained" color="secondary">
          {t("file.preview_modal.download")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default FilePreviewModal;
