import { RBACRoles } from "@auth/rbac-rules";
import Iconify from "@components/Iconify/Iconify";
import AnimatedWrapper from "@hoc/AnimatedWrapper";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { IRegister } from "auth-types";
import { FormikProps } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface ISignUpForm {
  formik: FormikProps<IRegister & { confirmPassword: string }>;
  isLoading: boolean;
}

const SignUpForm: React.FC<ISignUpForm> = ({ formik, isLoading }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRoleChange = (event: React.MouseEvent<HTMLElement>, newRole: RBACRoles) => {
    if (newRole !== null) {
      formik.setFieldValue("accountType", newRole);
      const newParams = new URLSearchParams(searchParams);
      newParams.set("accountType", newRole);
      setSearchParams(newParams);
    }
  };

  const handleNext = () => {
    const { firstname, lastname } = formik.values;
    if (firstname && lastname) {
      setActiveStep(1);
    } else {
      formik.setTouched({
        firstname: true,
        lastname: true,
      });
    }
  };

  const isSubmitDisabled = useMemo(() => {
    return (
      isLoading || !formik.values.email || !formik.values.password || !formik.isValid || formik.values.password !== formik.values.confirmPassword
    );
  }, [isLoading, formik.isValid, formik.values.email, formik.values.password, formik.values.confirmPassword]);

  const isNextDisabled = useMemo(() => {
    return !formik.values.firstname || !formik.values.lastname || isLoading;
  }, [isLoading, formik.values.firstname, formik.values.lastname]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <ToggleButtonGroup color="primary" exclusive fullWidth value={formik.values.accountType} onChange={handleRoleChange}>
            <ToggleButton value={RBACRoles.CLIENT}>{t("signup.form.client_role_label")}</ToggleButton>
            <ToggleButton value={RBACRoles.OWNER}>{t("signup.form.owner_role_label")}</ToggleButton>
          </ToggleButtonGroup>
          <AnimatedWrapper
            animation={{
              initial: { opacity: 0, x: 50 },
              animate: { opacity: 1, x: 0 },
              exit: { opacity: 0, x: -50 },
              transition: { duration: 0.3 },
            }}
          >
            <Stack spacing={3} sx={{ width: "100%" }}>
              {activeStep === 0 ? (
                <>
                  <TextField
                    fullWidth
                    id="firstname"
                    name="firstname"
                    label={t("signup.form.firstname_label")}
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={formik.touched.firstname && t(formik.errors.firstname)}
                  />
                  <TextField
                    fullWidth
                    id="lastname"
                    name="lastname"
                    label={t("signup.form.lastname_label")}
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={formik.touched.lastname && t(formik.errors.lastname)}
                  />
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label={t("signup.form.phone_number_label")}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.errors.phoneNumber ? t(formik.errors.phoneNumber) : t("signup.form.phone_number_helper_text")}
                  />
                </>
              ) : (
                <>
                  <TextField
                    id="email"
                    name="email"
                    label={t("signup.form.email_label")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && t(formik.errors.email)}
                  />
                  <TextField
                    id="password"
                    name="password"
                    label={t("signup.form.password_label")}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password ? t(formik.errors.password) : t("signup.form.password_helper_text")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                            <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label={t("signup.form.confirm_password_label")}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword ? t(formik.errors.confirmPassword) : t("signup.form.confirm_password_helper_text")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                            <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </Stack>
          </AnimatedWrapper>
        </Stack>
        {activeStep === 1 ? (
          <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
            <Button variant="outlined" size="large" color="secondary" onClick={() => setActiveStep(0)}>
              {t("signup.form.back_button")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
              loading={isLoading}
              disabled={isSubmitDisabled}
              sx={{ flex: 2 }}
            >
              {t("signup.form.register_button")}
            </LoadingButton>
          </Stack>
        ) : (
          <LoadingButton
            fullWidth
            size="large"
            loading={isLoading}
            disabled={isNextDisabled}
            onClick={handleNext}
            variant="contained"
            color="secondary"
            sx={{ mt: 3 }}
          >
            {t("signup.form.next_button")}
          </LoadingButton>
        )}
        <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
          {t("signup.clauze.read")}{" "}
          <a
            href="https://www.logopedaplus.com/docs/regulamin.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            {t("signup.clauze.terms")}
          </a>{" "}
          {t("signup.clauze.and")}{" "}
          <a
            href="https://www.logopedaplus.com/docs/polityka_prywatnosci.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            {t("signup.clauze.privacy_policy")}.
          </a>
        </Typography>
      </form>
    </>
  );
};

export default SignUpForm;
