import React, { useCallback, useContext, useRef, useState, useMemo } from "react";
import { Badge, Box, Divider, IconButton, List, ListSubheader, Popover, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Iconify from "../../../Iconify/Iconify";
import NotificationItem from "./fragments/NotificationItem";
import { INotification } from "notification.types";
import { useGetUserNotificationsQuery } from "@store/services/notificationsService";
import useNotificationMessage from "@hooks/useNotificationMessage";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { MessagePayload } from "firebase/messaging";

const NotificationsPopover: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const { data: historicalNotifications = [], refetch } = useGetUserNotificationsQuery(userDetails?._id);
  const [liveNotifications, setLiveNotifications] = useState<INotification[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  useNotificationMessage((payload: MessagePayload) => {
    const newNotification: INotification = {
      id: `notification_${Date.now()}`,
      title: payload.notification?.title,
      message: payload.notification?.body,
      image: payload.notification?.image,
      dateSent: new Date(),
      isRead: false,
    };
    setLiveNotifications((prev) => [newNotification, ...prev]);
  });

  const displayedNotifications = useMemo(
    () => (isOpen ? [...liveNotifications, ...historicalNotifications] : liveNotifications),
    [isOpen, liveNotifications, historicalNotifications]
  );

  const totalUnreadCount = useMemo(() => liveNotifications.filter((n) => !n.isRead).length, [liveNotifications]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      anchorRef.current = event.currentTarget as HTMLButtonElement;
      setIsOpen(true);
      refetch();
    },
    [refetch]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setLiveNotifications((prev) => prev.map((n) => ({ ...n, isRead: true })));
  }, []);

  const notificationAllowed = useMemo(() => {
    if (typeof window !== "undefined" && typeof Notification !== "undefined") {
      return Notification.permission === "granted";
    }
    return false;
  }, []);

  return (
    <>
      <Tooltip title={!notificationAllowed && t("header.notifications.allow")}>
        <span>
          <IconButton ref={anchorRef} color="default" onClick={handleOpen} sx={{ ml: 0.5, width: 40, height: 40 }} disabled={!notificationAllowed}>
            <Badge badgeContent={totalUnreadCount} color="error">
              <Iconify icon="eva:bell-fill" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{ sx: { mt: 1.5, ml: 0.75, width: 360, maxHeight: "70vh" } }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t("header.notifications.title")}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("header.notifications.overview")}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <List
          sx={{
            width: "100%",
            maxHeight: 350,
            overflowY: "auto",
          }}
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
              {t("header.notifications.latest")}
            </ListSubheader>
          }
        >
          {displayedNotifications.length ? (
            displayedNotifications.map((notification) => <NotificationItem key={notification.id} notification={notification} />)
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("header.notifications.no_notifications")}
              </Typography>
            </Box>
          )}
        </List>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
