import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import { Box, Card, CardActionArea, CardContent, CardHeader, Divider, ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { formatDate } from "@utils/timeUtils";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IPatientsMissedAppointments } from "reports-types";

interface IPatientsMissedAppointmentsCardProps {
  reports: IPatientsMissedAppointments[];
  isFetching: boolean;
  fromDate: Date | null;
  toDate: Date | null;
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
}

const PatientsMissedAppointmentsCard: React.FC<IPatientsMissedAppointmentsCardProps> = ({
  reports,
  isFetching,
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader title={t("reports.patientMissedAppointments.title")} subheader={t("reports.patientMissedAppointments.subtitle")} />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
          <DatePicker
            label={t("reports.patientMissedAppointments.from")}
            value={fromDate}
            onChange={onFromDateChange}
            maxDate={toDate || undefined}
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: t("reports.patientMissedAppointments.from_helper"),
              },
            }}
          />
          <DatePicker
            label={t("reports.patientMissedAppointments.to")}
            value={toDate}
            onChange={onToDateChange}
            minDate={fromDate || undefined}
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: t("reports.patientMissedAppointments.to_helper"),
              },
            }}
          />
        </Box>
      </Box>
      <CardContent sx={{ maxHeight: "600px", overflowY: "auto", pt: 2 }}>
        {(!reports || reports.length === 0) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "150px",
            }}
          >
            <Typography variant="subtitle1">{t("reports.patientMissedAppointments.noData")}</Typography>
          </Box>
        )}
        {reports.map((patient) => {
          const filteredAppointments = patient.missedAppointments.filter((appointment) => {
            const appointmentDate = new Date(appointment.startDate);
            if (fromDate && appointmentDate < fromDate) return false;
            if (toDate && appointmentDate > toDate) return false;
            return true;
          });
          return (
            <Card key={patient._id} sx={{ mb: 2 }}>
              <CardActionArea onClick={() => navigate(`/patients/${patient._id}?tab=6`)}>
                <CardContent>
                  <UsernameAvatar avatar firstname={patient.firstname} lastname={patient.lastname} email={patient.email} isFetching={isFetching} />
                  <Typography variant="body1" sx={{ mt: 3 }}>
                    <Typography component="span" variant="subtitle1">
                      {t("reports.patientMissedAppointments.count")}
                    </Typography>
                    : {patient.missedCount}
                  </Typography>
                  {filteredAppointments.map((appointment, index) => (
                    <ListItem
                      key={appointment._id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px dashed",
                        borderColor: theme.palette.divider,
                        borderRadius: 2,
                        mt: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography variant="body2" sx={{ mr: 2, minWidth: "24px", textAlign: "center", display: { xs: "none", sm: "block" } }}>
                        {index + 1}.
                      </Typography>
                      <ListItemText
                        primary={<Typography variant="subtitle1">{appointment.title}</Typography>}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            {formatDate(new Date(appointment.startDate), "HH:mm")} - {formatDate(new Date(appointment.endDate), "HH:mm")}{" "}
                            {formatDate(new Date(appointment.startDate), "dd MMMM yyyy")}
                          </Typography>
                        }
                      />
                      <Box
                        sx={{
                          mt: { xs: 1, sm: 0 },
                          p: { xs: 0.5, sm: 1 },
                        }}
                      >
                        <SeverityPill color={getAppointmentStatusColor(appointment.appointmentStatus)}>
                          {t(getAppointmentStatusText(appointment.appointmentStatus))}
                        </SeverityPill>
                      </Box>
                    </ListItem>
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default PatientsMissedAppointmentsCard;
