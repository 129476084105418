import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IPatientMenuProps {
  patientId: string;
  anchorElementMenu: HTMLElement;
  isAssignedToClient: boolean;
  isActive: boolean;
  handleCloseMenu: () => void;
  handleOpenDeleteModal: (patientId: string) => void;
  toggleChangePatientStatusModal: () => void;
  toggleAssignPatientToClientModal: () => void;
}

const PatientMenu: React.FC<IPatientMenuProps> = ({
  patientId,
  anchorElementMenu,
  isAssignedToClient,
  isActive,
  handleCloseMenu,
  handleOpenDeleteModal,
  toggleChangePatientStatusModal,
  toggleAssignPatientToClientModal,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Menu anchorEl={anchorElementMenu} open={Boolean(anchorElementMenu)} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 100 }}>
      <MenuItem onClick={() => navigate(`/patients/${patientId}`)}>
        <ListItemIcon>
          <OpenInNewRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("patient.menu.show_details")}
        </Typography>
      </MenuItem>
      <RBAC
        action={RBACActions.MAIN_MENU_INVOICE}
        yes={() => (
          <MenuItem onClick={() => navigate(AppRoutes.CreateInvoice.replace(":patientId", patientId))}>
            <ListItemIcon>
              <LocalPrintshopRoundedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t("patient.invoice")}
            </Typography>
          </MenuItem>
        )}
      />
      {!isAssignedToClient && isActive && (
        <MenuItem onClick={toggleAssignPatientToClientModal}>
          <ListItemIcon>
            <AssignmentIndRoundedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t("patient.menu.assign_to_client")}
          </Typography>
        </MenuItem>
      )}
      <RBAC
        action={RBACActions.ACTION_CHANGE_PATIENT_STATUS}
        yes={() => (
          <MenuItem onClick={toggleChangePatientStatusModal}>
            <ListItemIcon>
              <ManageAccountsRoundedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t("patient.menu.change_status")}
            </Typography>
          </MenuItem>
        )}
      />
      <RBAC
        action={RBACActions.ACTION_DELETE_PATIENT}
        yes={() => (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <MenuItem onClick={() => handleOpenDeleteModal(patientId)}>
              <ListItemIcon>
                <DeleteRoundedIcon fontSize="small" color="error" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap color="error">
                {t("delete")}
              </Typography>
            </MenuItem>
          </>
        )}
      />
    </Menu>
  );
};

export default PatientMenu;
