import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import { ILogin, ILoginResponse, IRegister } from "auth-types";
import axiosBaseQuery from "../utils/axiosBaseQuery";

enum TagTypes {
  AUTH = "AUTH",
}

export const authApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl }),
  reducerPath: "authApi",
  tagTypes: [TagTypes.AUTH],
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, ILogin>({
      query: (data) => ({
        method: "POST",
        url: `auth/login`,
        data,
      }),
      invalidatesTags: [TagTypes.AUTH],
    }),
    sendPasswordResetEmail: build.mutation<void, { email: string }>({
      query: (data) => ({
        method: "POST",
        url: `users/request-password-reset`,
        data,
      }),
    }),
    resetPassword: build.mutation<void, { newPassword: string; token: string }>({
      query: (data) => ({
        method: "POST",
        url: `users/change-password`,
        data,
      }),
    }),
    register: build.mutation<void, IRegister>({
      query: (data) => ({
        method: "POST",
        url: `auth/register`,
        data,
      }),
    }),
    confirmRegistration: build.mutation<void, { token: string }>({
      query: (data) => ({
        method: "POST",
        url: `auth/register/confirm-registration`,
        data,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useConfirmRegistrationMutation, useSendPasswordResetEmailMutation, useResetPasswordMutation } =
  authApi;

export const authApiReducer = {
  [authApi.reducerPath]: authApi.reducer,
};
