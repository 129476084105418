import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { IClinic } from "clinics-types";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { addEditClinicValidationSchema } from "src/validations/clinicValidation";

interface IAddClinicModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IClinic>) => void;
}

const AddClinicModal: React.FC<IAddClinicModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const initialValues = {
    name: "",
    contactNumber: null,
    contactEmail: "",
  } as Partial<IClinic>;

  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validationSchema={addEditClinicValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("clinic_selection.add_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("clinic_selection.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2} id={"add-clinic-modal"}>
                <Grid item xs={12}>
                  <Field name="name">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("clinic_selection.add_modal.clinic_name")}
                        helperText={touched.name && errors.name ? t(errors.name.toString()) : t("clinic_selection.add_modal.clinic_name_helper_text")}
                        error={touched.name && !!errors.name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="contactNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label={t("clinic_selection.add_modal.phone_number")}
                        helperText={
                          touched.contactNumber && errors.contactNumber
                            ? t(errors.contactNumber.toString())
                            : t("clinic_selection.add_modal.phone_number_helper_text")
                        }
                        error={touched.contactNumber && !!errors.contactNumber}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="contactEmail">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("clinic_selection.add_modal.email")}
                        helperText={
                          touched.contactEmail && errors.contactEmail
                            ? t(errors.contactEmail.toString())
                            : t("clinic_selection.add_modal.email_helper_text")
                        }
                        error={touched.contactEmail && !!errors.contactEmail}
                        id="add-clinic-contact-email"
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary" id="add-clinic-modal-confirm-button">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddClinicModal;
