import { RBACRoles } from "@auth/rbac-rules";
import { createSlice } from "@reduxjs/toolkit";

interface IUsersTokens {
  auth: {
    usersTokens: { refreshToken: string; accessToken: string };
    userDetails: {
      firstname: string | null;
      userId: string | null;
      role: RBACRoles | null;
    };
  };
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    usersTokens: JSON.parse(window.localStorage.getItem("token-data")),
    userDetails: JSON.parse(window.localStorage.getItem("user-details")) || {
      firstname: null,
      userId: null,
      role: null,
    },
  },
  reducers: {
    setCredentials: (state, action) => {
      state.usersTokens = action.payload;
      window.localStorage.setItem("token-data", JSON.stringify(action.payload));
    },
    logOut: (state) => {
      state.usersTokens = null;
      state.userDetails = { firstname: null, userId: null, role: null };
      window.localStorage.removeItem("token-data");
      window.localStorage.removeItem("fcm-token");
      window.localStorage.removeItem("user-details");
    },
    setUserInfo: (state, action) => {
      state.userDetails = action.payload;
      window.localStorage.setItem("user-details", JSON.stringify(action.payload));
    },
  },
});

export const { setCredentials, logOut, setUserInfo } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUserTokens = (state: IUsersTokens) => state.auth.usersTokens;
export const selectCurrentUserDetails = (state: IUsersTokens) => state.auth.userDetails;
