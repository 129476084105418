import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import FileListItem from "@components/FileListItem/FileListItem";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import NoDataCard from "@components/NoDataCard/NoDataCard";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import FileUploadModal from "@components/modals/FileUploadModal";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Box, Button, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import useUploadFile from "@hooks/useUploadFile";
import {
  useAddPatientDiagnosisMutation,
  useDeleteDiagnosisFileMutation,
  useDeleteDiagnosisMutation,
  useEditDiagnosisMutation,
} from "@store/services/diagnosisService";
import { palette } from "@theme/palette";
import { formatDate } from "@utils/timeUtils";
import { IDiagnosis } from "patients-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AddPatientDiagnosis from "../modals/Diagnosis/AddPatientDiagnosis";
import EditPatientDiagnosis from "../modals/Diagnosis/EditPatientDiagnosis";
import PatientDiagnosisMenu from "./fragments/PatientDiagnosisMenu";
import { parseISO } from "date-fns";

interface IPatientDiagnosisWidgetProps extends SnackbarProps {
  index?: number;
  patientId: string;
  diagnosis: IDiagnosis;
  displayShowAll?: boolean;
  isActive: boolean;
  refetch?: () => void;
}

const PatientDiagnosisWidget: React.FC<IPatientDiagnosisWidgetProps> = ({
  index,
  patientId,
  diagnosis,
  displayShowAll = false,
  isActive,
  refetch,
  snackbarShowMessage,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);
  const [isAddPatientDiagnosisModalOpen, toggleAddPatientDiagnosisModal] = useModal();
  const [isEditPatientDiagnosisModalOpen, toggleEditPatientDiagnosisModal] = useModal();
  const [isDeletePatientDiagnosisModalOpen, toggleDeletePatientDiagnosisModal] = useModal();
  const [isUploadDiagnosisFileModalOpen, toggleUploadDiagnosisFileModal] = useModal();

  const [addDiagnosis, { isLoading: isAddDiagnosisLoading }] = useAddPatientDiagnosisMutation();
  const [editDiagnosis, { isLoading: isEditDiagnosisLoading }] = useEditDiagnosisMutation();
  const [deleteDiagnosis, { isLoading: isDeleteDiagnosisLoading }] = useDeleteDiagnosisMutation();
  const [uploadPatientFiles, { isLoading }] = useUploadFile(`patients/${patientId}/diagnosis/${diagnosis?._id}`);
  const [deleteDiagnosisFile] = useDeleteDiagnosisFileMutation();

  const isPatientDiagnosisPage = useMemo(() => {
    return pathname.match(/\/patients\/\w+$/) && !pathname.includes("diagnosis");
  }, [pathname]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementMenu(event.currentTarget);
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleAddPatientDiagnosis = useCallback(
    (diagnosisData: Partial<IDiagnosis>) => {
      addDiagnosis({ patientId, diagnosis: diagnosisData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("diagnosis.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("diagnosis.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddPatientDiagnosisModal();
        });
    },
    [addDiagnosis, snackbarShowMessage, toggleAddPatientDiagnosisModal, patientId]
  );

  const handleEditPatientDiagnosis = useCallback(
    (diagnosisData: Partial<IDiagnosis>) => {
      if (!diagnosisData) return;

      editDiagnosis({ patientId, diagnosisId: diagnosisData._id, data: diagnosisData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("diagnosis.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("diagnosis.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditPatientDiagnosisModal();
        });
    },
    [editDiagnosis, snackbarShowMessage, toggleEditPatientDiagnosisModal, patientId]
  );

  const handleDeletePatientDiagnosis = useCallback(() => {
    if (!patientId) return;

    deleteDiagnosis({ patientId, diagnosisId: diagnosis._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("diagnosis.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("diagnosis.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeletePatientDiagnosisModal();
      });
  }, [deleteDiagnosis, snackbarShowMessage, toggleDeletePatientDiagnosisModal, patientId, diagnosis?._id]);

  const handleUploadDiagnosisFiles = useCallback(
    (values: { files: File[] }) => {
      if (!Array.isArray(values.files) || values.files.length === 0) return;

      uploadPatientFiles(values.files)
        .then(() => {
          snackbarShowMessage(t("file.upload.success_message"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("file.upload.error_message"), "error");
        })
        .finally(() => {
          refetch();
          toggleUploadDiagnosisFileModal();
        });
    },
    [snackbarShowMessage, toggleUploadDiagnosisFileModal]
  );

  const handleDeleteDiagnosisFile = useCallback(
    (fileId: string) => {
      if (!fileId) return;

      deleteDiagnosisFile({ patientId, diagnosisId: diagnosis._id, fileId: fileId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("file.delete.success_message"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("file.delete.error_message"), "error");
        });
    },
    [snackbarShowMessage, patientId, diagnosis?._id]
  );

  if (!diagnosis)
    return (
      <>
        <NoDataCard
          title={t("diagnosis.widget.title")}
          description={t("diagnosis.widget.description")}
          buttonText={t("diagnosis.menu.add")}
          rbacAction={RBACActions.ACTION_ADD_DIAGNOSIS}
          onButtonClick={toggleAddPatientDiagnosisModal}
          disableButton={!isActive}
        />
        <AddPatientDiagnosis
          open={isAddPatientDiagnosisModalOpen}
          onClose={toggleAddPatientDiagnosisModal}
          onSubmit={handleAddPatientDiagnosis}
          loading={isAddDiagnosisLoading}
        />
      </>
    );

  const dataToDisplay = [
    { label: t("diagnosis.add_modal.result"), content: diagnosis.result },
    { label: t("diagnosis.add_modal.interview"), content: diagnosis.interview },
    { label: t("diagnosis.add_modal.therapy_plan"), content: diagnosis.therapyPlan },
    { label: t("diagnosis.add_modal.recommendations"), content: diagnosis.medicalRecommendations },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={!index ? t("diagnosis.current") : t("diagnosis.name")}
          subheader={t("diagnosis.date", { date: formatDate(parseISO(diagnosis.diagnosisDate), "d MMMM yyyy") })}
          action={
            <RBAC
              action={RBACActions.ACTION_EDIT_DIAGNOSIS}
              yes={() => (
                <IconWithTooltip
                  label={t("diagnosis.menu.open")}
                  icon={<MoreVertRoundedIcon />}
                  onClick={handleOpenMenu}
                  locked={{ isLocked: !isActive }}
                />
              )}
            />
          }
        />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent sx={{ pl: 5, pr: 5 }}>
          {dataToDisplay.map(
            ({ label, content }, index) =>
              content && (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1">{label}</Typography>
                  <Typography variant="body1" gutterBottom style={{ whiteSpace: "pre-line" }}>
                    {content}
                  </Typography>
                  {index !== dataToDisplay.filter(({ content }) => content).length - 1 && <Divider sx={{ borderStyle: "dashed", mt: 3 }} />}
                </Box>
              )
          )}
          {!!diagnosis.files.length && (
            <Box>
              <Typography variant="subtitle2" color={"text.secondary"} sx={{ mb: 0.5 }}>
                {t("file.upload.files")}
              </Typography>
              <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                {diagnosis.files.map((file) => (
                  <FileListItem
                    completeFile={file}
                    key={file._id}
                    fileId={file._id}
                    filename={file.filename}
                    size={file.size}
                    createdAt={file.createdAt}
                    url={file.url}
                    onDelete={handleDeleteDiagnosisFile}
                    formatDate={formatDate}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </CardContent>
        {displayShowAll && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box sx={{ px: 2, py: 1 }}>
              <Button
                endIcon={<EastRoundedIcon />}
                onClick={() => navigate(`/patients/${patientId}/diagnosis`)}
                sx={{
                  color: "text.primary",
                  "&:hover": {
                    backgroundColor: palette.grey[200],
                    color: "text.primary",
                  },
                }}
              >
                {t("diagnosis.widget.see_all_button")}
              </Button>
            </Box>
          </>
        )}
      </Card>
      <PatientDiagnosisMenu
        {...{ isPatientDiagnosisPage }}
        {...{ anchorElementMenu }}
        {...{ handleCloseMenu }}
        {...{ toggleAddPatientDiagnosisModal, toggleEditPatientDiagnosisModal, toggleDeletePatientDiagnosisModal, toggleUploadDiagnosisFileModal }}
      />
      <AddPatientDiagnosis
        open={isAddPatientDiagnosisModalOpen}
        onClose={toggleAddPatientDiagnosisModal}
        onSubmit={handleAddPatientDiagnosis}
        loading={isAddDiagnosisLoading}
      />
      <EditPatientDiagnosis
        open={isEditPatientDiagnosisModalOpen}
        onClose={toggleEditPatientDiagnosisModal}
        onSubmit={handleEditPatientDiagnosis}
        initialValues={diagnosis}
        loading={isEditDiagnosisLoading}
      />
      <FileUploadModal
        open={isUploadDiagnosisFileModalOpen}
        loading={isLoading}
        onClose={toggleUploadDiagnosisFileModal}
        onSubmit={handleUploadDiagnosisFiles}
      />
      <ConfirmationModal
        open={isDeletePatientDiagnosisModalOpen}
        onClose={toggleDeletePatientDiagnosisModal}
        onConfirm={handleDeletePatientDiagnosis}
        title={t("diagnosis.menu.delete")}
        content={t("diagnosis.delete_modal.content_text")}
        loading={isDeleteDiagnosisLoading}
      />
    </>
  );
};

export default PatientDiagnosisWidget;
