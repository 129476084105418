import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { ColorType, SeverityPill } from "@components/SeverityPill/SeverityPill";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Divider, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { IActivityLog } from "activityLog.types";
import { parseISO } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ActivityLogTableProps {
  currentDate: Date;
  activityLogs: IActivityLog[];
  sortedActivityLogs: IActivityLog[];
  handlePreviousMonth: () => void;
  handleNextMonth: () => void;
  handleOpenToggleAppointmentStatusModal: (log: IActivityLog) => void;
  handleOpenEditModal: (log: IActivityLog) => void;
  handleOpenDeleteModal: (logId: string) => void;
}

const ActivityLogTable: FC<ActivityLogTableProps> = ({
  currentDate,
  activityLogs,
  sortedActivityLogs,
  handlePreviousMonth,
  handleNextMonth,
  handleOpenToggleAppointmentStatusModal,
  handleOpenEditModal,
  handleOpenDeleteModal,
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ height: 600, overflow: "hidden" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ p: 2 }}
      >
        <IconButton onClick={handlePreviousMonth}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography variant="subtitle2" color="text.secondary">
          {formatDate(currentDate, "MMM yyyy")}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ minWidth: "7rem" }}>
                {t("appointments.label")}
              </TableCell>
              <TableCell sx={{ minWidth: "20rem" }}>{t("activity_log.subject_of_appointment")}</TableCell>
              <TableCell align="left">{t("activity_log.presence")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityLogs.length ? (
              sortedActivityLogs.map((log, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left" component="th" scope="row">
                    {log.appointmentId ? (
                      <>
                        <Typography variant="subtitle2">{formatDate(parseISO(log.appointmentId.startDate), "d MMMM yyyy")}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {`${formatDate(parseISO(log.appointmentId.startDate), "HH:mm")} - ${formatDate(
                            parseISO(log.appointmentId.endDate),
                            "HH:mm"
                          )}`}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        {t("activity_log.no_appointment")}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                    {log.description}
                  </TableCell>
                  <TableCell align="left">
                    {log.appointmentId ? (
                      <SeverityPill color={getAppointmentStatusColor(log.appointmentId.appointmentStatus) as ColorType}>
                        {t(getAppointmentStatusText(log.appointmentId.appointmentStatus))}
                      </SeverityPill>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        {t("activity_log.no_appointment")}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent="flex-end">
                      <IconWithTooltip
                        label={t("appointments.status_modal.title")}
                        icon={<CoPresentRoundedIcon />}
                        onClick={() => handleOpenToggleAppointmentStatusModal(log)}
                      />
                      <IconWithTooltip label={t("activity_log.edit.button")} icon={<EditRoundedIcon />} onClick={() => handleOpenEditModal(log)} />
                      <IconWithTooltip
                        label={t("activity_log.delete.button")}
                        icon={<DeleteRoundedIcon />}
                        onClick={() => handleOpenDeleteModal(log._id)}
                        color="error"
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body2" color="text.secondary">
                    {t("activity_log.no_activity_logs")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ActivityLogTable;
