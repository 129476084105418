import BackgroundVideo from "@components/BackgroundVideo/BackgroundVideo";
import Logo from "@components/Logo/Logo";
import AnimatedWrapper from "@hoc/AnimatedWrapper";
import { Typography } from "@mui/material";
import { StyledBox } from "@theme/styled-components/StyledBox";
import React from "react";
import { Helmet } from "react-helmet-async";

interface NotificationWithActionProps {
  helmetTitle: string;
  title: string;
  message: string;
  buttonComponent?: React.ReactNode;
}

const NotificationWithAction: React.FC<NotificationWithActionProps> = ({ helmetTitle, title, message, buttonComponent }) => {
  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <BackgroundVideo src={"/assets/images/backgrounds/background_hero.mp4"} />
      <Logo />
      <AnimatedWrapper
        animation={{
          initial: { opacity: 0, y: 0 },
          animate: { opacity: 1, y: 0 },
          transition: { duration: 0.5 },
        }}
      >
        <StyledBox>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            {title}
          </Typography>
          <Typography color="text.secondary" sx={{ textAlign: "center", fontSize: 20 }}>
            {message}
          </Typography>
          {buttonComponent && buttonComponent}
        </StyledBox>
      </AnimatedWrapper>
    </>
  );
};

export default NotificationWithAction;
