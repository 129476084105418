import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface GeneratePDFProps {
  elementRef: React.RefObject<HTMLDivElement>;
  onComplete?: (pdf: jsPDF) => void;
}

export const generatePDF = async ({ elementRef, onComplete }: GeneratePDFProps): Promise<void> => {
  if (elementRef.current) {
    const canvas = await html2canvas(elementRef.current, { useCORS: true, allowTaint: true });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    if (onComplete) {
      onComplete(pdf);
    }
  }
};
