import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import { AppRoutes } from "@utils/enums";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface IMenuSection {
  id: string;
  title?: string;
  items: IMenuItem[];
}

interface IMenuItem {
  id: string;
  title: string;
  path: string;
  icon?: React.ReactNode;
}

const MenuItemsConfig = (): IMenuSection[] => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);

  const sections: IMenuSection[] = [
    {
      id: "main",
      items: [
        {
          id: "dashboard",
          title: t("menu.dashboard"),
          path: AppRoutes.Dashboard,
          icon: <DashboardRoundedIcon />,
        },
      ],
    },
  ];

  if (rbacCheck(userRole, RBACActions.MAIN_MENU_CLINIC_SECTION)) {
    const clinicManagementSection: IMenuSection = {
      id: "clinic-management",
      title: t("menu.clinic_management_section"),
      items: [],
    };

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_CLINICS)) {
      clinicManagementSection.items.push({
        id: "my-clinic",
        title: t("menu.my_clinic"),
        path: AppRoutes.MyClinic,
        icon: <HomeWorkRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_EMPLOYEES)) {
      clinicManagementSection.items.push({
        id: "employees",
        title: t("menu.employees"),
        path: AppRoutes.Employees,
        icon: <BadgeRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_APPOINTMENTS)) {
      clinicManagementSection.items.push({
        id: "appointments",
        title: t("menu.appointments"),
        path: AppRoutes.Appointments,
        icon: <CalendarMonthRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_RESERVATIONS)) {
      clinicManagementSection.items.push({
        id: "reservations",
        title: t("menu.reservations"),
        path: AppRoutes.Reservations,
        icon: <EventAvailableRoundedIcon />,
      });
    }

    sections.push(clinicManagementSection);
  }

  if (rbacCheck(userRole, RBACActions.MAIN_MENU_PATIENT_SECTION)) {
    const patientsSection: IMenuSection = {
      id: "patients",
      title: t("menu.patients_section"),
      items: [],
    };

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_PATIENTS)) {
      patientsSection.items.push({
        id: "patients",
        title: t("menu.patients"),
        path: AppRoutes.Patients,
        icon: <PeopleAltRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_ACTIVITY_LOG)) {
      patientsSection.items.push({
        id: "activity-log",
        title: t("menu.activity_log"),
        path: AppRoutes.ActivityLog,
        icon: <BookRoundedIcon />,
      });
    }

    sections.push(patientsSection);
  }

  if (rbacCheck(userRole, RBACActions.MAIN_MENU_FINICIAL_SECTION)) {
    const financialsSection: IMenuSection = {
      id: "financials",
      title: t("menu.financials_section"),
      items: [],
    };

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)) {
      financialsSection.items.push({
        id: "reports",
        title: t("menu.reports"),
        path: AppRoutes.Reports,
        icon: <AssessmentRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_INVOICE)) {
      financialsSection.items.push({
        id: "invoices",
        title: t("menu.invoices"),
        path: AppRoutes.Invoices,
        icon: <DescriptionRoundedIcon />,
      });
    }

    if (rbacCheck(userRole, RBACActions.MAIN_MENU_EXPENSES)) {
      financialsSection.items.push({
        id: "expenses",
        title: t("menu.expenses"),
        path: AppRoutes.Expenses,
        icon: <SavingsRoundedIcon />,
      });
    }

    sections.push(financialsSection);
  }

  return sections;
};

export default MenuItemsConfig;
