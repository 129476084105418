import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { withSnackbar } from "@hoc/AlertPopover";
import { AlertColor, LoadingButton } from "@mui/lab";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { useConfirmRegistrationClientMutation } from "@store/services/authService";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function ConfirmRegistrationPatient({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) {
  const { t } = useTranslation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);
  const location = useLocation();
  const navigate = useNavigate();

  const [confirmRegistration, { isLoading: isMutationLoading }] = useConfirmRegistrationClientMutation();
  const [isLoading, setIsLoading] = useState(false);

  const getQueryParam = (param: string): string | null => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const token = getQueryParam("token");

  const handleConfirmRegistration = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      confirmRegistration({ token })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("confirm_registration.snackbar_message.success"), "success");
          navigate(AppRoutes.ConfirmRegistrationPatientSuccess);
        })
        .catch(() => {
          snackbarShowMessage(t("confirm_registration.snackbar_message.error"), "error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000);
  }, [token, navigate, confirmRegistration, snackbarShowMessage, t]);

  if (usersTokens) return <Navigate to={AppRoutes.Dashboard} />;

  return (
    <NotificationWithAction
      helmetTitle={t("confirm_registration.helmet")}
      title={t("confirm_registration.title")}
      message={t("confirm_registration.instructions.patient")}
      buttonComponent={
        <LoadingButton
          sx={{ mt: 3, width: "50%" }}
          onClick={handleConfirmRegistration}
          loading={isLoading || isMutationLoading}
          variant="contained"
          size="large"
          disabled={isLoading || isMutationLoading}
        >
          {t("confirm_registration.form.confirm_registration_button")}
        </LoadingButton>
      }
    />
  );
}

export default withSnackbar(ConfirmRegistrationPatient);
