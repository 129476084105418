import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { Field, FieldProps, Formik } from "formik";
import React from "react";
import { addEditInvoiceDataValidationSchema } from "src/validations/invoiceDataValidation";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { IInvoiceData } from "invoiceData-types";
import CustomDialog from "@components/modals/CustomDialog";
import { Form } from "@theme/styled-components/StyledForm";

interface IAddInvoiceDataModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IInvoiceData>) => void;
}

const AddInvoiceDataModal: React.FC<IAddInvoiceDataModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    bankName: "",
    bankAccountNumber: "",
    NIP: "",
    REGON: "",
    address: "",
    postalCode: "",
    city: "",
    paymentDayOfMonth: null,
  } as Partial<IInvoiceData>;

  return (
    <Formik initialValues={initialValues} validationSchema={addEditInvoiceDataValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("invoice_data.add_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit} id="add-invoice-data-modal">
            <DialogContent>
              <DialogContentText>{t("invoice_data.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="bankName">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.bank_name")}
                        helperText={touched.bankName && errors.bankName ? t(errors.bankName.toString()) : ""}
                        error={touched.bankName && !!errors.bankName}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="bankAccountNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.bank_account_number")}
                        helperText={touched.bankAccountNumber && errors.bankAccountNumber ? t(errors.bankAccountNumber.toString()) : ""}
                        error={touched.bankAccountNumber && !!errors.bankAccountNumber}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="NIP">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.NIP")}
                        helperText={touched.NIP && errors.NIP ? t(errors.NIP.toString()) : ""}
                        error={touched.NIP && !!errors.NIP}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="REGON">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.REGON")}
                        helperText={touched.REGON && errors.REGON ? t(errors.REGON.toString()) : ""}
                        error={touched.REGON && !!errors.REGON}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="address">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.address")}
                        helperText={touched.address && errors.address ? t(errors.address.toString()) : ""}
                        error={touched.address && !!errors.address}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5}>
                  <Field name="postalCode">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.postal_code")}
                        helperText={touched.postalCode && errors.postalCode ? t(errors.postalCode.toString()) : ""}
                        error={touched.postalCode && !!errors.postalCode}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={7}>
                  <Field name="city">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("invoice_data.add_modal.city")}
                        helperText={touched.city && errors.city ? t(errors.city.toString()) : ""}
                        error={touched.city && !!errors.city}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="paymentDayOfMonth">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 31 } }}
                        fullWidth
                        label={t("invoice_data.add_modal.paymentDayOfMonth")}
                        helperText={touched.paymentDayOfMonth && errors.paymentDayOfMonth ? t(errors.paymentDayOfMonth.toString()) : ""}
                        error={touched.paymentDayOfMonth && !!errors.paymentDayOfMonth}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddInvoiceDataModal;
