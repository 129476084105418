import * as Yup from "yup";

export const addEmployeeValidationSchema = Yup.object({
  email: Yup.string().email("employee.validation.valid_email").required("employee.validation.email_required"),
  password: Yup.string().min(8, "employee.validation.password_min_length").required("employee.validation.password_required"),
  firstname: Yup.string().required("employee.validation.firstname_required"),
  lastname: Yup.string().required("employee.validation.lastname_required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, "employee.validation.phone_number_format")
    .required("employee.validation.phone_required"),
});

export const editEmployeeValidationSchema = Yup.object({
  firstname: Yup.string().required("employee.validation.firstname_required"),
  lastname: Yup.string().required("employee.validation.lastname_required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, "employee.validation.phone_number_format")
    .required("employee.validation.phone_required"),
});

export const assignPatientToEmployeeValidationSchema = Yup.object({
  patientBodyId: Yup.string().required("employee.validation.patient_required"),
});
