import * as Yup from "yup";

export const addEditAppointmentValidationSchema = Yup.object({
  typeId: Yup.string().required("appointments.validation.type_id_required"),
  pricingId: Yup.string().required("appointments.validation.pricing_id_required"),
  description: Yup.string(),
  startDate: Yup.date().required("appointments.validation.start_date_required"),
  endDate: Yup.date().required("appointments.validation.end_date_required"),
  patientId: Yup.string().nullable(),
  repeatUntilDate: Yup.date().nullable(),
  repeatIntervalWeeks: Yup.number(),
});

export const deleteManyAppointmentsValidationSchema = Yup.object({
  startDate: Yup.date().required("appointments.validation.start_date_required"),
  endDate: Yup.date().required("appointments.validation.end_date_required"),
  patientId: Yup.string().nullable(),
});
