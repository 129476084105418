import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Background = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("/assets/images/illustrations/illustration_signin.webp");
  background-size: cover;
  backdrop-filter: blur(10px);
`;
