import { messaging } from "@config/firebaseConfig";
import { MessagePayload, onMessage } from "firebase/messaging";
import { useEffect } from "react";

type HandleMessageFunction = (payload: MessagePayload) => void;

const useNotificationMessage = (handleMessage: HandleMessageFunction) => {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      handleMessage(payload);
    });

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [handleMessage]);
};

export default useNotificationMessage;
