import { Theme, alpha } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";

export type ColorType = "primary" | "secondary" | "error" | "info" | "warning" | "success";

interface SeverityPillProps {
  id?: string;
  color?: ColorType;
  children?: React.ReactNode;
}

interface SeverityPillRootProps extends SeverityPillProps {
  theme: Theme;
}

const SeverityPillRoot = styled("span")<SeverityPillRootProps>(({ theme, color }) => ({
  alignItems: "center",
  backgroundColor: alpha(theme.palette[color || "primary"].main, 0.12),
  borderRadius: 12,
  color: theme.palette[color || "primary"].main,
  cursor: "default",
  display: "inline-flex",
  flexGrow: 0,
  flexShrink: 0,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 2,
  fontWeight: 600,
  justifyContent: "center",
  letterSpacing: 0.5,
  minWidth: 20,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textTransform: "uppercase",
  whiteSpace: "nowrap",
}));

export const SeverityPill: React.FC<SeverityPillProps> = ({ color = "primary", children, ...other }) => {
  const theme = useTheme();
  return (
    <SeverityPillRoot color={color} theme={theme} {...other}>
      {children}
    </SeverityPillRoot>
  );
};
