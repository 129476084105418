import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Container, Tab, Tabs, Typography } from "@mui/material";
import PageTitle from "@components/PageTitle/PageTitle";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import { AppRoutes } from "@utils/enums";
import EmployeeCharts from "./fragments/EmployeeCharts/EmployeeCharts";
import GeneralCharts from "./fragments/GeneralCharts/GeneralCharts";
import PatientCharts from "./fragments/PatientCharts/PatientCharts";
import { CustomTabPanel } from "@pages/Settings/Settings";
import { useUrlTab } from "@hooks/useUrlTab";

const Reports: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const { value, handleTabChange } = useUrlTab(0);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)) {
    navigate(AppRoutes.Dashboard);
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t("reports.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle title={t("reports.page_title")} gap={3} bottomContent={<Typography variant="subtitle2" color="text.secondary" />} />
        <Tabs value={value} onChange={handleTabChange} variant="scrollable">
          <Tab label={t("reports.tabs.general")} value={0} />
          <Tab label={t("reports.tabs.patients")} value={1} />
          <Tab label={t("reports.tabs.employees")} value={2} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <GeneralCharts snackbarShowMessage={snackbarShowMessage} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PatientCharts />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <EmployeeCharts />
        </CustomTabPanel>
      </Container>
    </>
  );
};

export default withSnackbar(Reports);
