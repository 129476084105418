import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IInvoiceData } from "invoiceData-types";

enum TagTypes {
  INVOICE_DATA = "INVOICE_DATA",
}

export const invoiceDataApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "invoiceDataApi",
  tagTypes: [TagTypes.INVOICE_DATA],
  endpoints: (build) => ({
    getAllInvoiceData: build.query<IInvoiceData[], { invoiceDataId?: string }>({
      query: ({ invoiceDataId }) => ({
        url: "settings/invoice-data",
        method: "GET",
        params: { invoiceDataId },
      }),
      providesTags: [TagTypes.INVOICE_DATA],
    }),
    deleteInvoiceData: build.mutation<void, { invoiceDataId: string }>({
      query: ({ invoiceDataId }) => ({
        method: "DELETE",
        url: `settings/invoice-data/${invoiceDataId}`,
      }),
      invalidatesTags: [TagTypes.INVOICE_DATA],
    }),
    addInvoiceData: build.mutation<IInvoiceData, Partial<IInvoiceData>>({
      query: (newInvoiceData) => ({
        method: "POST",
        url: `settings/invoice-data`,
        data: newInvoiceData,
      }),
      invalidatesTags: [TagTypes.INVOICE_DATA],
    }),
    editInvoiceData: build.mutation<IInvoiceData, { invoiceDataId: string; updatedInvoiceData: Partial<IInvoiceData> }>({
      query: ({ invoiceDataId, updatedInvoiceData }) => ({
        method: "PATCH",
        url: `settings/invoice-data/${invoiceDataId}`,
        data: updatedInvoiceData,
      }),
      invalidatesTags: [TagTypes.INVOICE_DATA],
    }),
  }),
});

export const { useGetAllInvoiceDataQuery, useDeleteInvoiceDataMutation, useAddInvoiceDataMutation, useEditInvoiceDataMutation } = invoiceDataApi;

export const invoiceDataApiReducer = {
  [invoiceDataApi.reducerPath]: invoiceDataApi.reducer,
};
