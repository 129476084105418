import { ClinicContext, IClinicContext } from "@context/ClinicProvider";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { styled } from "@mui/system";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import AppSidebar from "../components/AppSidebar/AppSidebar";
import Header from "../components/Header/Header";
import { motion } from "framer-motion";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Layout: React.FC = () => {
  const { userDetails } = useContext(UserContext) as IUserContextType;
  const { currentClinic } = useContext(ClinicContext) as IClinicContext;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledRoot>
      <Header {...{ userDetails }} onOpenNav={() => setOpen(true)} />

      <motion.div
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -300, opacity: 0 }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
      >
        <AppSidebar {...{ userDetails, currentClinic }} openNav={open} onCloseNav={() => setOpen(false)} />
      </motion.div>

      <Main>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} transition={{ duration: 0.5 }}>
          <Outlet />
        </motion.div>
      </Main>
    </StyledRoot>
  );
};

export default Layout;
