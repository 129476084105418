import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import { Button, Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useAddExpenseMutation,
  useCalculateExpensesQuery,
  useDeleteExpenseMutation,
  useGetExpensesQuery,
  useUpdateExpenseMutation,
} from "@store/services/expensesService";
import { AppRoutes } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { endOfMonth, getMonth, getYear, startOfMonth } from "date-fns";
import { IAddExpenseBody, ICalculateExpense, IExpense, IGetExpensesResponse } from "expenses.types";
import { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExpenseList from "./fragments/ExpensesList";
import AddExpenseModal from "./modals/AddExpenseModal";
import ExpensesStats from "./fragments/ExpensesStats";
import { formatDate } from "@utils/timeUtils";
import EditExpenseModal from "./modals/EditExpenseModal";

const Expenses: React.FC<SnackbarProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const theme = useTheme();

  const [selectedExpense, setSelectedExpense] = useState<IExpense | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [updateExpense, { isLoading: isEditExpenseLoading }] = useUpdateExpenseMutation();
  const [addExpense, { isLoading: isAddExpenseLoading }] = useAddExpenseMutation();
  const [deleteExpense, { isLoading: isDeleteExpenseLoading }] = useDeleteExpenseMutation();

  const handleCloseDeleteModal = () => handleCloseModal([setSelectedExpense], toggleDeleteExpenseModal);
  const handleCloseEditModal = () => handleCloseModal([setSelectedExpense], toggleEditExpenseModal);

  const [isEditExpenseModalOpen, toggleEditExpenseModal] = useModal();
  const [isAddExpenseModalOpen, toggleAddExpenseModal] = useModal();
  const [isDeleteExpenseModalOpen, toggleDeleteExpenseModal] = useModal();

  const { data: expenses = { fixed: [], oneTimeCost: [] } as IGetExpensesResponse } = useGetExpensesQuery({
    month: getMonth(selectedDate),
    year: getYear(selectedDate),
  });

  const { data: expensesStats = [] } = useCalculateExpensesQuery({
    month: getMonth(selectedDate),
    year: getYear(selectedDate),
  });

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleEditModalOpen = useCallback(
    (expense: IExpense) => {
      setSelectedExpense(expense);
      toggleEditExpenseModal();
    },
    [toggleEditExpenseModal]
  );

  const handleDeleteModalOpen = useCallback(
    (expense: IExpense) => {
      setSelectedExpense(expense);
      toggleDeleteExpenseModal();
    },
    [toggleDeleteExpenseModal]
  );

  const handleEditExpense = useCallback(
    (values: Partial<IAddExpenseBody>) => {
      if (!selectedExpense) return;

      updateExpense({ expenseId: selectedExpense._id, data: values })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("expenses.snackbar_message.edit.success"), "success");
          toggleEditExpenseModal();
        })
        .catch(() => {
          snackbarShowMessage(t("expenses.snackbar_message.edit.error"), "error");
        });
    },
    [selectedExpense, snackbarShowMessage, toggleEditExpenseModal]
  );

  const handleAddExpense = useCallback(
    (values: Partial<IAddExpenseBody>) => {
      addExpense(values)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("expense.snackbar_message.add.success"), "success");
          toggleAddExpenseModal();
        })
        .catch(() => {
          snackbarShowMessage(t("expense.snackbar_message.add.error"), "error");
        });
    },
    [snackbarShowMessage, toggleAddExpenseModal]
  );

  const handleDeleteExpense = useCallback(() => {
    if (!selectedExpense) return;

    deleteExpense({ expenseId: selectedExpense?._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("expense.snackbar_message.delete.success"), "success");
        toggleDeleteExpenseModal();
      })
      .catch(() => {
        snackbarShowMessage(t("expense.snackbar_message.delete.error"), "error");
      });
  }, [snackbarShowMessage, toggleDeleteExpenseModal, selectedExpense]);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_EXPENSES)) {
    navigate(AppRoutes.Dashboard);
  }

  return (
    <>
      <Helmet>
        <title>{t("expenses.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={t("expenses.title")}
          bottomContent={
            <Typography variant="subtitle2" color="text.secondary">
              {`${t("reports.period")} ${formatDate(startOfMonth(selectedDate), "dd.MM.yyyy")} - ${formatDate(
                endOfMonth(selectedDate),
                "dd.MM.yyyy"
              )}`}
            </Typography>
          }
          rightContent={
            <Stack direction="row" spacing={2} alignItems="center">
              <DatePicker
                views={["month", "year"]}
                label={t("expenses.month_picker")}
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { backgroundColor: theme.palette.background.paper },
                  },
                }}
              />
              <Button color="secondary" variant="contained" startIcon={<NoteAddRoundedIcon />} onClick={toggleAddExpenseModal}>
                {t("expenses.button.add")}
              </Button>
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ExpensesStats data={expensesStats as ICalculateExpense[]} />
          </Grid>
          <Grid item xs={12}>
            <ExpenseList expenses={expenses} handleDeleteModalOpen={handleDeleteModalOpen} handleOpenEditModal={handleEditModalOpen} />
          </Grid>
        </Grid>
      </Container>
      <EditExpenseModal
        open={isEditExpenseModalOpen}
        loading={isEditExpenseLoading}
        initialValues={selectedExpense}
        onClose={handleCloseEditModal}
        onSubmit={handleEditExpense}
      />
      <AddExpenseModal open={isAddExpenseModalOpen} loading={isAddExpenseLoading} onClose={toggleAddExpenseModal} onSubmit={handleAddExpense} />
      <ConfirmationModal
        open={isDeleteExpenseModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteExpense}
        title={t("expenses.delete_modal.title")}
        content={t("expenses.delete_modal.content_text")}
        loading={isDeleteExpenseLoading}
      />
    </>
  );
};

export default withSnackbar(Expenses);
