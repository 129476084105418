import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(3),
  boxSizing: "border-box",
  maxWidth: 550,
  width: "100%",
  [theme.breakpoints.down(550)]: {
    width: "calc(100% - 32px)",
  },
}));
