import { Avatar, Skeleton, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { stringAvatar } from "@utils/utils";
import React from "react";
import { useTranslation } from "react-i18next";

interface IUsernameAvatar {
  firstname: string;
  lastname: string;
  isFetching?: boolean;
  email?: string;
  avatar?: boolean;
  additionalInfo?: React.ReactNode;
  onClick?: () => void;
}

const UsernameAvatar: React.FC<IUsernameAvatar> = ({ firstname, lastname, email, isFetching, avatar, additionalInfo, onClick }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" spacing={2} onClick={onClick}>
      {avatar && <Avatar {...stringAvatar(`${firstname} ${lastname}`)} sx={{ width: 40, height: 40 }} />}
      <Box>
        {isFetching ? (
          <Skeleton variant="rounded" width={200} height={20} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="baseline">
            <Typography variant="subtitle1" noWrap sx={{ lineHeight: 2 }}>{`${firstname} ${lastname}`}</Typography>
            {additionalInfo}
          </Stack>
        )}
        {isFetching ? (
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={100} height={20} />
        ) : email ? (
          <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1, maxWidth: 150 }} noWrap>
            {email}
          </Typography>
        ) : (
          <Tooltip title={t("personal_data.no_email")}>
            <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1 }} noWrap>
              {t("personal_data.no_email")}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};

export default UsernameAvatar;
