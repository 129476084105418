import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";

interface IUpcomingAppointmentsMenuProps {
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  toggleEditAppointmentModal?: () => void;
  toggleAppointmentStatusModal?: () => void;
  toggleDeleteAppointmentModal?: () => void;
}

const UpcomingAppointmentsMenu: React.FC<IUpcomingAppointmentsMenuProps> = ({
  anchorElementMenu,
  handleCloseMenu,
  toggleEditAppointmentModal,
  toggleAppointmentStatusModal,
  toggleDeleteAppointmentModal,
}) => {
  const { t } = useTranslation();

  return (
    <Menu anchorEl={anchorElementMenu} open={!!anchorElementMenu} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 1 }}>
      <MenuItem onClick={toggleEditAppointmentModal}>
        <ListItemIcon>
          <EditRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("appointments.button.edit")}
        </Typography>
      </MenuItem>
      <MenuItem onClick={toggleAppointmentStatusModal}>
        <ListItemIcon>
          <CoPresentRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("appointments.button.change_status")}
        </Typography>
      </MenuItem>
      <Divider sx={{ borderStyle: "dashed" }} />
      <MenuItem onClick={toggleDeleteAppointmentModal}>
        <ListItemIcon>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="error">
          {t("appointments.button.delete")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default UpcomingAppointmentsMenu;
