import Iconify from "@components/Iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IChangePasswordForm {
  passwordMatch: boolean;
  formik: FormikProps<{ newPassword: string; password: string }>;
  isLoading: boolean;
}

const ChangePasswordForm: React.FC<IChangePasswordForm> = ({ passwordMatch, formik, isLoading }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="password"
            name="password"
            label={t("change_password.form.password_label")}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password ? t(formik.errors.password) : t("change_password.form.password_helper_text")
            }
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="newPassword"
            name="newPassword"
            label={t("change_password.form.confirm_password_label")}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={
              formik.touched.newPassword && formik.errors.newPassword
                ? t(formik.errors.newPassword)
                : t("change_password.form.confirm_password_helper_text")
            }
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton
          loading={isLoading}
          disabled={!passwordMatch}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ my: 2 }}
          color="secondary"
        >
          {t("change_password.form.reset_password_button")}
        </LoadingButton>
      </form>
    </>
  );
};

export default ChangePasswordForm;
