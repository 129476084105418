import * as Yup from "yup";

export const authValidationSchema = Yup.object({
  email: Yup.string().email("validation.valid_email").required("validation.email_required"),
  password: Yup.string().min(8, "validation.password_min_length").required("validation.password_required"),
});

export const registerValidationSchema = Yup.object({
  firstname: Yup.string().min(2, "validation.firstname_length").required("validation.firstname_required"),
  lastname: Yup.string().min(2, "validation.lastname_length").required("validation.lastname_required"),
  phoneNumber: Yup.string().matches(/^$|^\d{9}$/, "validation.phone_number_format"),
  email: Yup.string().email("validation.valid_email").required("validation.email_required"),
  password: Yup.string().min(8, "validation.password_min_length").required("validation.password_required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "validation.same_password")
    .required("validation.password_confirm_required"),
  accountType: Yup.string().required(),
});
export const sendResetPasswordEmailValidationSchema = Yup.object({
  email: Yup.string().email("validation.valid_email").required("validation.email_required"),
});

export const changePasswordValidationSchema = Yup.object({
  password: Yup.string().min(8, "change_password.validation.password_min_length").required("change_password.validation.password_required"),
  newPassword: Yup.string().min(8, "change_password.validation.password_min_length").required("change_password.validation.password_required"),
  token: Yup.string().required("change_password.validation.token_required"),
});
