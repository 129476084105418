import { useCallback, useState } from "react";
import axios from "axios";
import { apiUrl } from "@utils/urlsUtils";

type UseSendInvoiceReturnType = [
  (
    pdfBlob: Blob,
    fileName: string,
    email: string,
    invoiceNumber: string,
    value: string,
    publishDate: string,
    paymentDate: string,
    patientId: string
  ) => Promise<void>,
  { isLoading: boolean }
];

const useSendInvoice = (): UseSendInvoiceReturnType => {
  const [isLoading, setIsLoading] = useState(false);

  const sendInvoice = useCallback(
    async (
      pdfBlob: Blob,
      fileName: string,
      email: string,
      invoiceNumber: string,
      value: string,
      publishDate: string,
      paymentDate: string,
      patientId: string
    ) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", pdfBlob, fileName);
      formData.append("email", email);
      formData.append("invoiceNumber", invoiceNumber);
      formData.append("value", value);
      formData.append("publishDate", publishDate);
      formData.append("paymentDate", paymentDate);

      const token = JSON.parse(localStorage.getItem("token-data") || "{}").accessToken;
      const clinicId = localStorage.getItem("clinicId");
      const apiKey = import.meta.env.MODE !== "development" ? import.meta.env.VITE_API_KEY : import.meta.env.VITE_API_KEY_DEV;

      try {
        await axios.post(`${apiUrl}clinics/${clinicId}/patients/${patientId}/send-invoice`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": apiKey,
          },
        });
        console.log("Invoice sent successfully");
      } catch (error) {
        console.error("Error sending invoice:", error);
        return Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return [sendInvoice, { isLoading }];
};

export default useSendInvoice;
