import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import store from "@store/store";
import { Locale } from "date-fns";
import { enUS, pl } from "date-fns/locale";
import { useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import RBACProvider from "./auth/RBAC/RBACProvider";
import { UserProvider } from "./context/UserProvider";
import "./i18n";
import "./index.css";
import AppRouter from "./routes/index";
import ThemeProvider from "./theme/ThemeProvider";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const localeMap: Record<"en" | "pl", Locale> = {
    en: enUS,
    pl: pl,
  };

  const adapterLocale = useMemo(() => {
    return localeMap[i18n.language as keyof typeof localeMap] || localeMap["pl"];
  }, [i18n.language]);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
          <Provider store={store}>
            <BrowserRouter>
              <UserProvider>
                <RBACProvider>
                  <AppRouter />
                </RBACProvider>
              </UserProvider>
            </BrowserRouter>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
