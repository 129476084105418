import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import { Avatar, Box, Grid, Paper, Typography, alpha, useTheme } from "@mui/material";
import { formatCost } from "@utils/typographyUtils";
import { getInvoiceStatusText } from "@utils/utils";
import { IInvoiceStats } from "invoice-types";
import React from "react";
import { useTranslation } from "react-i18next";

export enum InvoiceStatus {
  PAID = "PAID",
  SENT = "SENT",
  OVERDUE = "OVERDUE",
  DRAFT = "DRAFT",
}

interface InvoiceStatsProps {
  data: IInvoiceStats[];
}

const iconMap: Record<string, React.ReactNode> = {
  Total: <ReceiptRoundedIcon color="primary" />,
  [InvoiceStatus.PAID]: <CheckCircleOutlineRoundedIcon color="success" />,
  [InvoiceStatus.SENT]: <AccessTimeRoundedIcon color="warning" />,
  [InvoiceStatus.OVERDUE]: <ErrorOutlineRoundedIcon color="error" />,
  [InvoiceStatus.DRAFT]: <DescriptionRoundedIcon color="info" />,
};

export const InvoiceStats: React.FC<InvoiceStatsProps> = ({ data }) => {
  const theme = useTheme();

  const colorMap: Record<string, string> = {
    Total: alpha(theme.palette.primary.main, 0.12),
    [InvoiceStatus.PAID]: alpha(theme.palette.success.main, 0.12),
    [InvoiceStatus.SENT]: alpha(theme.palette.warning.main, 0.12),
    [InvoiceStatus.OVERDUE]: alpha(theme.palette.error.main, 0.12),
    [InvoiceStatus.DRAFT]: alpha(theme.palette.info.main, 0.12),
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {data.map((status, index) => (
          <InvoiceStatusComponent
            key={index}
            status={getInvoiceStatusText(status.status)}
            invoiceCount={status.invoiceCount}
            grossTotalValue={status.grossTotalValue}
            icon={iconMap[status.status]}
            color={colorMap[status.status]}
          />
        ))}
      </Grid>
    </Paper>
  );
};

interface InvoiceStatusComponentProps {
  icon: React.ReactNode;
  status: string;
  invoiceCount: number;
  grossTotalValue: number;
  color: string;
}

const InvoiceStatusComponent: React.FC<InvoiceStatusComponentProps> = ({ icon, status, invoiceCount, grossTotalValue, color }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={4} md={2.4} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
      <Avatar sx={{ bgcolor: color, width: 56, height: 56, mb: 1 }}>{icon}</Avatar>
      <Box>
        <Typography variant="subtitle1">{status}</Typography>
        <Typography variant="body2" color="textSecondary">
          {invoiceCount} {t("invoice.stats.text_secondary")}
        </Typography>
        <Typography variant="subtitle1">{formatCost(grossTotalValue)}</Typography>
      </Box>
    </Grid>
  );
};

export default InvoiceStats;
