import Iconify from "@components/Iconify/Iconify";
import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { IEmployee } from "employees-types";
import { Field, FieldProps, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { addEmployeeValidationSchema } from "src/validations/employeeValidation";

interface IAddEmployeeModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<IEmployee>) => void;
}

const AddEmployeeModal: React.FC<IAddEmployeeModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    firstname: "",
    lastname: "",
    phoneNumber: null,
    email: "",
    password: "",
  } as Partial<IEmployee>;

  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validationSchema={addEmployeeValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, setFieldValue }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("employee.add_modal.title")}</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>{t("employee.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.email")}
                        helperText={touched.email && errors.email ? t(errors.email.toString()) : t("employee.add_modal.email_helper_text")}
                        error={touched.email && !!errors.email}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="password">
                    {({ field }: FieldProps) => (
                      <TextField
                        fullWidth
                        label={t("personal_data.password")}
                        type={showPassword ? "text" : "password"}
                        value={field.value}
                        onChange={(event) => setFieldValue("password", event.target.value)}
                        error={touched.password && Boolean(errors.password)}
                        helperText={
                          touched.password && errors.password ? t(errors.password.toString()) : t("employee.add_modal.password_helper_text")
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="firstname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.firstname")}
                        helperText={
                          touched.firstname && errors.firstname ? t(errors.firstname.toString()) : t("employee.add_modal.firstname_helper_text")
                        }
                        error={touched.firstname && !!errors.firstname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("personal_data.lastname")}
                        helperText={
                          touched.lastname && errors.lastname ? t(errors.lastname.toString()) : t("employee.add_modal.lastname_helper_text")
                        }
                        error={touched.lastname && !!errors.lastname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="phoneNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label={t("personal_data.phone_number")}
                        helperText={
                          touched.phoneNumber && errors.phoneNumber
                            ? t(errors.phoneNumber.toString())
                            : t("employee.add_modal.phone_number_helper_text")
                        }
                        error={touched.phoneNumber && !!errors.phoneNumber}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddEmployeeModal;
