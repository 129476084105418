import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ClinicState {
  clinicId: string | null;
}

export const clinicSlice = createSlice({
  name: "clinic",
  initialState: {
    clinicId: localStorage.getItem("clinicId") || null,
  } as ClinicState,
  reducers: {
    setClinicId: (state, action: PayloadAction<string>) => {
      state.clinicId = action.payload;
      localStorage.setItem("clinicId", action.payload);
    },
    removeClinicId: (state) => {
      state.clinicId = null;
      localStorage.removeItem("clinicId");
    },
  },
});

export const { setClinicId, removeClinicId } = clinicSlice.actions;

export default clinicSlice.reducer;

export const selectCurrentClinicId = (state: { clinic: ClinicState }) => state.clinic.clinicId;
