import { alpha, Theme } from "@mui/material/styles";

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
          "&:hover": {
            boxShadow: "none",
          },
          whiteSpace: "nowrap",
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          // @ts-expect-error
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.primary.main, 0.24)}`,
          "&:hover": {
            boxShadow: `0 8px 16px 0 ${alpha(theme.palette.primary.main, 0.32)}`,
          },
        },
        containedSecondary: {
          // @ts-expect-error
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
