import DropZoneField from "@components/DropZoneField/DropZoneField";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { Field, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "./CustomDialog";

interface IFileUploadModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<any>) => void;
}

const FileUploadModal: React.FC<IFileUploadModalProps> = ({ open, loading, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const initialValues = {
    files: [],
  } as any;

  return (
    <Formik initialValues={initialValues} validationSchema={null} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
        <CustomDialog open={open} onClose={onClose}>
          <Form onSubmit={handleSubmit}>
            <DialogTitle>{t("file.modal.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("file.modal.content_text")}</DialogContentText>
              <Box sx={{ my: 1 }}>
                <Field
                  name="files"
                  component={DropZoneField}
                  helperText={touched.files && errors.files ? t(errors.files.toString()) : t("file.modal.helper_text")}
                  error={touched.files && Boolean(errors.files)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={onClose} variant="outlined">
                {t("cancel")}
              </Button>
              <LoadingButton color="secondary" loading={loading} type="submit" variant="contained">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default FileUploadModal;
