import { combineReducers } from "@reduxjs/toolkit";
import { usersApiReducer } from "@store/services/usersService";
import authReducer from "../features/authSlice";
import clinicReducer from "../features/clinicSlice";
import { authApiReducer } from "@store/services/authService";
import { clinicsApiReducer } from "@store/services/clinicsService";
import { employeesApiReducer } from "@store/services/employeesService";
import { diagnosisApiReducer } from "@store/services/diagnosisService";
import { patientsApiReducer } from "@store/services/patientsService";
import { appointmentsApiReducer } from "@store/services/appointmentsService";
import { homeworksApiReducer } from "@store/services/homeworksService";
import { activityLogApiReducer } from "@store/services/activityLogService";
import { notificationsApiReducer } from "@store/services/notificationsService";
import { appointmentTypesApiReducer } from "@store/services/appointmentTypesService";
import { reportsApiReducer } from "@store/services/reportsService";
import { invoiceDataApiReducer } from "@store/services/InvoiceDataService";
import { invoiceApiReducer } from "@store/services/invoiceService";
import { appointmentStatusApiReducer } from "@store/services/appointmentStatusService";
import { expensesApiReducer } from "@store/services/expensesService";
import { clinicNotificationsApiReducer } from "@store/services/clinicNotificationsService";
import { reservationApiReducer } from "@store/services/reservationsService";
import { reservationSettingsApiReducer } from "@store/services/reservationSettingsService";
import { miscApiReducer } from "@store/services/miscService";

const reducer = combineReducers({
  auth: authReducer,
  clinic: clinicReducer,
  ...usersApiReducer,
  ...authApiReducer,
  ...clinicsApiReducer,
  ...employeesApiReducer,
  ...patientsApiReducer,
  ...appointmentsApiReducer,
  ...diagnosisApiReducer,
  ...homeworksApiReducer,
  ...activityLogApiReducer,
  ...notificationsApiReducer,
  ...appointmentTypesApiReducer,
  ...reportsApiReducer,
  ...invoiceDataApiReducer,
  ...invoiceApiReducer,
  ...appointmentStatusApiReducer,
  ...expensesApiReducer,
  ...clinicNotificationsApiReducer,
  ...reservationApiReducer,
  ...reservationSettingsApiReducer,
  ...miscApiReducer,
});

export default reducer;
