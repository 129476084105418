import React from "react";
import { Container, Typography, Stepper, Step, StepLabel, Button, Stack, Paper, Box } from "@mui/material";
import FactCheckRoundedIcon from "@mui/icons-material/FactCheckRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "@utils/enums";
import { useTranslation } from "react-i18next";

interface ICreateInvoiceStepsProps {
  hasPatients: boolean;
  hasInvoiceData: boolean;
}

const CreateInvoiceSteps: React.FC<ICreateInvoiceStepsProps> = ({ hasInvoiceData, hasPatients }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const steps = [
    {
      label: t("createInvoiceSteps.step1.label"),
      description: t("createInvoiceSteps.step1.description"),
      icon: <FactCheckRoundedIcon />,
      action: () => navigate(AppRoutes.MyClinic.replace(":id", "3")),
      completed: hasInvoiceData,
    },
    {
      label: t("createInvoiceSteps.step2.label"),
      description: t("createInvoiceSteps.step2.description"),
      icon: <PersonAddRoundedIcon />,
      action: () => navigate(AppRoutes.Patients),
      completed: hasPatients,
    },
  ];

  const initialStep = steps.findIndex((step) => !step.completed);
  const [activeStep, setActiveStep] = React.useState(initialStep === -1 ? steps.length : initialStep);

  const handleNext = () => {
    if (activeStep < steps.length) {
      steps[activeStep].action();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: "#fff" }}>
        <Typography variant="h4" gutterBottom align="center">
          {t("createInvoiceSteps.title")}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }} color="text.secondary">
          {t("createInvoiceSteps.subtitle")}
        </Typography>
        <Box sx={{ mt: 2, mb: 4 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={index} completed={step.completed}>
                <StepLabel
                  icon={React.cloneElement(step.icon, {
                    color: step.completed ? "primary" : "disabled",
                  })}
                >
                  {step.label}
                  <Typography variant="caption" display="block" color="text.secondary">
                    {step.description}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button color="secondary" variant="outlined" disabled={activeStep === 0} onClick={() => navigate(-1)}>
            {t("createInvoiceSteps.backButton")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            endIcon={activeStep < steps.length ? React.cloneElement(steps[activeStep].icon, { color: "inherit" }) : undefined}
            onClick={handleNext}
          >
            {activeStep === steps.length ? t("createInvoiceSteps.finishButton") : t("createInvoiceSteps.nextButton")}
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default CreateInvoiceSteps;
