import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Box, Stack, Typography } from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import React from "react";

interface ICustomHeaderProps {
  date: Date;
}

const CustomHeader: React.FC<ICustomHeaderProps> = ({ date }) => {
  const dayOfWeek = formatDate(date, "EEE");
  const dayOfMonth = formatDate(date, "d");

  return (
    <Stack alignItems="center" direction="column">
      <Box title={dayOfWeek} sx={{ mt: 1 }}>
        <SeverityPill>{dayOfWeek}</SeverityPill>
      </Box>
      <Typography variant="h3">{dayOfMonth}</Typography>
    </Stack>
  );
};

export default CustomHeader;
