import { SnackbarProps } from "@hoc/AlertPopover";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, DialogActions, Divider, Grid, TextField } from "@mui/material";
import { useEditUserDetailsMutation } from "@store/services/usersService";
import { AppRoutes } from "@utils/enums";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { userValidationSchema } from "src/validations/userValidation";
import { IUserDetails } from "user-types";

interface IAccountSectionProps extends SnackbarProps {
  clinicId: string;
  userDetails: IUserDetails;
}

interface FormValues extends Partial<IUserDetails> {}

const AccountSection: React.FC<IAccountSectionProps> = ({ clinicId, userDetails, snackbarShowMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!userDetails || !clinicId) return null;
  const [isEditMode, setIsEditMode] = useState(false);

  const [editUserInfo, { isLoading: isEditUserLoading }] = useEditUserDetailsMutation();

  const initialValues = {
    firstname: userDetails.firstname,
    lastname: userDetails.lastname,
    phoneNumber: userDetails.phoneNumber,
  } as Partial<IUserDetails>;

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleFormSubmit = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      editUserInfo({ clinicId, userId: userDetails._id, data: values })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("account.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("account.snackbar_message.edit.error"), "error");
        })
        .finally(() => actions.setSubmitting(false));

      if (isEditMode) toggleEditMode();
    },
    [editUserInfo, isEditMode, snackbarShowMessage, userDetails]
  );

  return (
    <Card>
      <CardHeader title={t("account.title")} subheader={t("account.subheader")} />
      <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={userValidationSchema} onSubmit={handleFormSubmit}>
          {({ errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12}>
                  <Field name="firstname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={!isEditMode}
                        label={t("personal_data.firstname")}
                        helperText={touched.firstname && errors.firstname ? t(errors.firstname.toString()) : t("account.firstname_helper_text")}
                        error={touched.firstname && !!errors.firstname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="lastname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={!isEditMode}
                        label={t("personal_data.lastname")}
                        helperText={touched.lastname && errors.lastname ? t(errors.lastname.toString()) : t("account.lastname_helper_text")}
                        error={touched.lastname && !!errors.lastname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="phoneNumber">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        disabled={!isEditMode}
                        fullWidth
                        label={t("personal_data.phone_number")}
                        helperText={
                          touched.phoneNumber && errors.phoneNumber ? t(errors.phoneNumber.toString()) : t("account.phone_number_helper_text")
                        }
                        error={touched.phoneNumber && !!errors.phoneNumber}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={!isEditMode} onClick={() => navigate(AppRoutes.PasswordReset)} variant="outlined" color="error">
                    {t("account.change_password")}
                  </Button>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={toggleEditMode} variant="outlined" color="secondary">
                  {isEditMode ? t("cancel") : t("edit")}
                </Button>
                {isEditMode && (
                  <LoadingButton loading={isEditUserLoading} type="submit" variant="contained" color="secondary">
                    {t("submit")}
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default AccountSection;
