import * as Yup from "yup";
import { Sex } from "@utils/enums";

export const addEditPatientValidationSchema = Yup.object({
  firstname: Yup.string().required("patient.validation.firstname_required"),
  lastname: Yup.string().required("patient.validation.lastname_required"),
  sex: Yup.mixed().oneOf(Object.values(Sex), "patient.validation.sex_invalid").required("patient.validation.sex_required"),
  dateOfBirth: Yup.date().required("patient.validation.date_of_birth").max(new Date(), "patient.validation.date_of_birth_invalid"),
  email: Yup.string().email("patient.validation.valid_email"),
  phoneNumber: Yup.string().matches(/^[0-9]{9}$/, "patient.validation.phone_number_invalid"),
});

export const assignEmployeeToPatientValidationSchema = Yup.object({
  employeeBodyId: Yup.string().required("patient.validation.employee_required"),
});

export const assignPatientToClientValidationSchema = Yup.object({
  email: Yup.string().email("patient.validation.valid_email").required("patient.validation.email_required"),
});

export const editPatientInvoiceDataValidationSchema = Yup.object({
  firstname: Yup.string().required("patient_details.invoice_data.validation.firstname_required"),
  lastname: Yup.string().required("patient_details.invoice_data.validation.lastname_required"),
  email: Yup.string().email("patient.validation.valid_email").required("patient_details.invoice_data.validation.email_required"),
});
