import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import { SortOrder } from "@utils/enums";
import React from "react";
import { useTranslation } from "react-i18next";
interface SortOrderButtonProps {
  sortOrder: SortOrder;
  onToggleSortOrder: () => void;
}

const SortOrderButton: React.FC<SortOrderButtonProps> = ({ sortOrder, onToggleSortOrder }) => {
  const { t } = useTranslation();
  return (
    <IconWithTooltip
      onClick={onToggleSortOrder}
      label={t("sort.toggle_order")}
      icon={sortOrder === SortOrder.ASC ? <KeyboardDoubleArrowUpRoundedIcon /> : <KeyboardDoubleArrowDownRoundedIcon />}
    />
  );
};

export default SortOrderButton;
