import { SnackbarProps } from "@hoc/AlertPopover";
import { Grid } from "@mui/material";
import { useGetAppointmentTypesQuery } from "@store/services/appointmentTypesService";
import React, { useCallback, useEffect, useState } from "react";
import AppointmentsTypesList from "./fragments/AppointmentsTypesList";
import AppointmentsTypesTable from "./fragments/AppointmentsTypesTable";
import { IAppointmentType } from "appointmentTypes.types";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";

interface IAppointmentsTypesConfigSectionProps extends SnackbarProps {}

const AppointmentsTypesConfigSection: React.FC<IAppointmentsTypesConfigSectionProps> = ({ snackbarShowMessage }) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<IAppointmentType>();

  const { appointmentTypes } = useGetAppointmentTypesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
    }
  );

  const { employees } = useGetAllEmployeesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
    }
  );

  const handleSelectAppointmentType = useCallback(
    (appointmentType: IAppointmentType) => {
      setSelectedAppointmentType(appointmentType);
    },
    [setSelectedAppointmentType]
  );

  useEffect(() => {
    if (appointmentTypes.length > 0 && !selectedAppointmentType) {
      setSelectedAppointmentType(appointmentTypes[0]);
    }
  }, [appointmentTypes, selectedAppointmentType]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={3}>
        <AppointmentsTypesList
          appointmentTypes={appointmentTypes}
          selectedAppointmentType={selectedAppointmentType}
          handleSelectAppointmentType={handleSelectAppointmentType}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <AppointmentsTypesTable appointmentType={selectedAppointmentType} employees={employees} snackbarShowMessage={snackbarShowMessage} />
      </Grid>
    </Grid>
  );
};

export default AppointmentsTypesConfigSection;
