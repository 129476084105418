import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAddAppointmentTypeMutation, useEditAppointmentTypeMutation, useGetAppointmentTypesQuery } from "@store/services/appointmentTypesService";
import { handleCloseModal } from "@utils/modalUtils";
import { formatCost, formatDuration } from "@utils/typographyUtils";
import { IAppointmentType } from "appointmentTypes.types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AddAppointmentTypeModal from "../modals/AppointmentTypes/AddAppointmentTypeModal";
import EditAppointmentTypeModal from "../modals/AppointmentTypes/EditAppointmentTypeModal";
import AppointmentsTypesMenu from "./AppointmentsTypesMenu";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";

interface IAppointmentTypesSectionProps extends SnackbarProps {}

const AppointmentTypesSection: React.FC<IAppointmentTypesSectionProps> = ({ snackbarShowMessage }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const [showArchived, setShowArchived] = useState(false);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<IAppointmentType>();
  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);

  const [isAddAppointmentTypeModalOpen, toggleAddAppointmentTypeModal] = useModal();
  const [isEditAppointmentTypeModalOpen, toggleEditAppointmentTypeModal] = useModal();
  const [isArchiveAppointmentTypeModalOpen, toggleArchiveAppointmentTypeModal] = useModal();

  const [addAppointmentType, { isLoading: isAddAppointmentTypeLoading }] = useAddAppointmentTypeMutation();
  const [editAppointmentType, { isLoading: isEditAppointmentTypeLoading }] = useEditAppointmentTypeMutation();
  const handleCloseEditApointmentTypeModal = () => handleCloseModal([setSelectedAppointmentType], toggleEditAppointmentTypeModal);
  const handleCloseDeleteApointmentTypeModal = () => handleCloseModal([setSelectedAppointmentType], toggleArchiveAppointmentTypeModal);

  const { appointmentTypes } = useGetAppointmentTypesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
    }
  );

  const filteredTypes = useMemo(() => {
    return appointmentTypes.filter((type) => (showArchived ? type.isArchive : !type.isArchive));
  }, [appointmentTypes, showArchived]);

  const handleChangeShowArchived = useCallback(() => {
    setShowArchived((prev) => !prev);
  }, [setShowArchived]);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElementMenu(event.currentTarget);
    },
    [setAnchorElementMenu]
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleOpenEditModal = useCallback(
    (type: IAppointmentType) => {
      setSelectedAppointmentType(type);
      toggleEditAppointmentTypeModal();
    },
    [selectedAppointmentType, toggleEditAppointmentTypeModal]
  );

  const handleOpenArchiveModal = useCallback(
    (type: IAppointmentType) => {
      toggleArchiveAppointmentTypeModal();
      setSelectedAppointmentType(type);
    },
    [selectedAppointmentType, toggleEditAppointmentTypeModal]
  );

  const handleAddAppointmentType = useCallback(
    (newAppointmentType: Partial<IAppointmentType>) => {
      addAppointmentType({ newAppointmentType })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointment_types.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointment_types.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddAppointmentTypeModal();
          handleCloseMenu();
        });
    },
    [addAppointmentType, snackbarShowMessage, toggleAddAppointmentTypeModal]
  );

  const handleEditAppointmentType = useCallback(
    (updatedAppointmentType: Partial<IAppointmentType>, archiveMode?: boolean) => {
      if (!selectedAppointmentType) return;

      editAppointmentType({ typeId: selectedAppointmentType._id, updatedAppointmentType })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointment_types.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointment_types.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          !archiveMode ? toggleEditAppointmentTypeModal() : toggleArchiveAppointmentTypeModal();
        });
    },
    [editAppointmentType, snackbarShowMessage, toggleEditAppointmentTypeModal, selectedAppointmentType]
  );

  const handleClick = (type: IAppointmentType) => {
    setOpen((prev) => ({ ...prev, [type._id]: !prev[type._id] }));
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Stack direction="row" spacing={1}>
              <Box>{t("appointment_types.types_title")}</Box>
              <SeverityPill id="appointment-types-header" color={showArchived ? "warning" : "success"}>
                {showArchived ? t("appointment_types.archived") : t("appointment_types.active")}
              </SeverityPill>
            </Stack>
          }
          subheader={t("appointment_types.helper_text")}
          action={
            <IconWithTooltip
              label={t("my_clinic.basic_info.menu.open")}
              icon={<MoreVertRoundedIcon />}
              onClick={handleOpenMenu}
              id="appointment-types-menu"
            />
          }
        />
        <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
        {filteredTypes && filteredTypes.length === 0 ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="body2" color="text.secondary" align="center">
              {t("appointment_types.no_data_title")}
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t("appointment_types.type_title")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTypes.map((type) => (
                  <React.Fragment key={type._id}>
                    <TableRow>
                      <TableCell sx={{ width: 2 }}>
                        <IconButton size="small" onClick={() => handleClick(type)}>
                          {open[type._id] ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="subtitle2">{type.name}</Typography>
                          <SeverityPill color={type.isArchive ? "warning" : "success"}>
                            {type.isArchive ? t("appointment_types.archived") : t("appointment_types.active")}
                          </SeverityPill>
                          <SeverityPill color="info">{type.pricingCount}</SeverityPill>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={0} justifyContent={"flex-end"} sx={{ mr: 1 }}>
                          {!type.isArchive ? (
                            <>
                              <IconButton onClick={() => handleOpenEditModal(type)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          ) : null}
                          <IconButton onClick={() => handleOpenArchiveModal(type)}>
                            {type.isArchive ? <UnarchiveRoundedIcon /> : <ArchiveRoundedIcon />}
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ p: 0 }}>
                        <Collapse in={open[type._id]} timeout="auto" unmountOnExit>
                          <Box>
                            <Typography variant="subtitle1" gutterBottom sx={{ ml: 2, mt: 2 }}>
                              {t("appointment_types.pricing_details")}
                            </Typography>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t("appointment_types.duration")}</TableCell>
                                  <TableCell>{t("appointment_types.cost")}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {type.pricing.map((price, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle2">{formatDuration(price.duration)} </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <SeverityPill color="info">{formatCost(price.cost)}</SeverityPill>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
      <AppointmentsTypesMenu
        {...{ showArchived, anchorElementMenu, handleCloseMenu, handleChangeShowArchived, handleAddAppointmentType, toggleAddAppointmentTypeModal }}
      />
      <AddAppointmentTypeModal
        open={isAddAppointmentTypeModalOpen}
        loading={isAddAppointmentTypeLoading}
        onClose={toggleAddAppointmentTypeModal}
        onSubmit={handleAddAppointmentType}
      />
      <EditAppointmentTypeModal
        open={isEditAppointmentTypeModalOpen}
        loading={isEditAppointmentTypeLoading}
        onClose={handleCloseEditApointmentTypeModal}
        onSubmit={(values) => handleEditAppointmentType(values, false)}
        initialValues={selectedAppointmentType}
      />
      <ConfirmationModal
        open={isArchiveAppointmentTypeModalOpen}
        loading={isEditAppointmentTypeLoading}
        title={
          !selectedAppointmentType?.isArchive ? t("appointment_types.archive_modal.archive.title") : t("appointment_types.archive_modal.active.title")
        }
        content={
          !selectedAppointmentType?.isArchive
            ? t("appointment_types.archive_modal.archive.content_text")
            : t("appointment_types.archive_modal.active.content_text")
        }
        onClose={handleCloseDeleteApointmentTypeModal}
        onConfirm={() => handleEditAppointmentType({ isArchive: selectedAppointmentType?.isArchive ? false : true }, true)}
      />
    </>
  );
};

export default AppointmentTypesSection;
