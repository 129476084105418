import PageTitle from "@components/PageTitle/PageTitle";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { Box, Container } from "@mui/material";
import { selectCurrentClinicId } from "@store/features/clinicSlice";
import React, { memo, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AccountSection from "./fragments/AccountSection";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel = memo(({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...other} sx={{ mt: 3 }}>
      {value === index && children}
    </Box>
  );
});

function Settings({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();

  const selectedClinic = useSelector(selectCurrentClinicId);
  const { userDetails } = useContext(UserContext) as IUserContextType;

  return (
    <>
      <Helmet>
        <title>{t("settings.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle title={t("settings.title")} gap={4} />
        <AccountSection userDetails={userDetails} clinicId={selectedClinic} {...{ snackbarShowMessage }} />
      </Container>
    </>
  );
}

export default withSnackbar(Settings);
