import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import CustomDialog from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  content: string;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, loading, title, content, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={onConfirm} variant="contained" autoFocus color="secondary">
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default ConfirmationModal;
