import { formatCost } from "@utils/typographyUtils";
import ApexCharts from "apexcharts";
import { t } from "i18next";
import i18n from "src/i18n";

interface IChartHeightConfig {
  itemCount: number;
  minHeight?: number;
  maxHeight?: number;
  heightPerItem?: number;
}

export const calculateDynamicChartHeight = ({ itemCount, minHeight = 300, maxHeight = 800, heightPerItem = 50 }: IChartHeightConfig): number => {
  return Math.min(maxHeight, Math.max(minHeight, itemCount * heightPerItem));
};

export const getCommonOptions = (theme: any): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: "bar",
      defaultLocale: i18n.language,
      locales: [
        {
          name: i18n.language,
          options: {
            toolbar: {
              exportToSVG: t("reports.export_to_svg"),
              exportToPNG: t("reports.export_to_png"),
              exportToCSV: t("reports.export_to_csv"),
            },
          },
        },
      ],
    },
    plotOptions: {
      bar: {
        borderRadiusApplication: "end",
        horizontal: true,
        borderRadius: 6,
        colors: {
          backgroundBarColors: [theme.palette.grey[200]],
          backgroundBarRadius: 6,
          ranges: [{ from: 0, to: 10000, color: theme.palette.primary.main }],
        },
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: true,
        color: theme.palette.grey[500],
      },
      textAnchor: "start",
      style: {
        ...theme.typography.subtitle2,
      },
      offsetY: 8,
      formatter: function (val: number) {
        return formatCost(val);
      },
    },
    tooltip: {
      enabled: false,
    },
    yaxis: {
      labels: {
        offsetY: 2,
        style: {
          ...theme.typography.subtitle2,
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
  };
};
