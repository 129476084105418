import { ListItemIcon, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import { useTranslation } from "react-i18next";
interface IPatientMenuProps {
  showArchived: boolean;
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  handleChangeShowArchived: () => void;
  toggleAddAppointmentTypeModal: () => void;
}

const AppointmentsTypesMenu: React.FC<IPatientMenuProps> = ({ showArchived, anchorElementMenu, handleCloseMenu, handleChangeShowArchived }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Menu anchorEl={anchorElementMenu} open={Boolean(anchorElementMenu)} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 100 }}>
      {!showArchived ? (
        <MenuItem onClick={handleChangeShowArchived}>
          <ListItemIcon>
            <ArchiveRoundedIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap sx={{ color: theme.palette.warning.main }}>
            {t("appointment_types.show_archived")}
          </Typography>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleChangeShowArchived}>
          <ListItemIcon>
            <UnarchiveRoundedIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap sx={{ color: theme.palette.warning.main }}>
            {t("appointment_types.show_not_archived")}
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

export default AppointmentsTypesMenu;
