import Logo from "@components/Logo/Logo";
import { Typography } from "@mui/material";
import { Background } from "@theme/styled-components/Background";
import { StyledBox } from "@theme/styled-components/StyledBox";
import React from "react";
import { Helmet } from "react-helmet-async";

interface NotificationWithActionProps {
  helmetTitle: string;
  title: string;
  message: string;
  buttonComponent?: React.ReactNode;
}

const NotificationWithAction: React.FC<NotificationWithActionProps> = ({ helmetTitle, title, message, buttonComponent }) => {
  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <Background />
      <Logo />
      <StyledBox>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
        <Typography color="text.secondary" sx={{ textAlign: "center", fontSize: 20 }}>
          {message}
        </Typography>
        {buttonComponent && buttonComponent}
      </StyledBox>
    </>
  );
};

export default NotificationWithAction;
