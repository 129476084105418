import { ReactNode } from "react";
import { motion } from "framer-motion";
import { Theme, useMediaQuery } from "@mui/material";

const useDisableAnimations = (): boolean => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return isMobile;
};

interface AnimatedWrapperProps {
  children: ReactNode;
  animation?: object;
}

const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({ children, animation }) => {
  const disableAnimations = useDisableAnimations();

  if (disableAnimations) {
    return <>{children}</>;
  }

  return <motion.div {...animation}>{children}</motion.div>;
};

export default AnimatedWrapper;
