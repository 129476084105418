import { alpha } from "@mui/material/styles";

const GREY = {
  0: "#FCFDFD",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#1C252E",
  900: "#141A21",
};

export const PRIMARY = {
  lighter: "#f8e4ff",
  light: "#e9a6ff",
  main: "#b901fe",
  dark: "#9107c2",
  darker: "#77079c",
  contrastText: GREY[100],
};

export const PRIMARY_GREEN = {
  lighter: "#C8FAD6",
  light: "#5BE49B",
  main: "#00A76F",
  dark: "#007867",
  darker: "#004B50",
  contrastText: GREY[100],
};

export const PRIMARY_BLUE = {
  lighter: "#CCF4FE",
  light: "#68CDF9",
  main: "#078DEE",
  dark: "#0351AB",
  darker: "#012972",
  contrastText: GREY[100],
};

export const PRIMARY_PURPLE = {
  lighter: "#EBD6FD",
  light: "#B985F4",
  main: "#7635dc",
  dark: "#431A9E",
  darker: "#200A69",
  contrastText: GREY[100],
};

export const PRIMARY_DARK_BLUE = {
  lighter: "#CDE9FD",
  light: "#6BB1F8",
  main: "#0C68E9",
  dark: "#063BA7",
  darker: "#021D6F",
  contrastText: GREY[100],
};

export const PRIMARY_RED = {
  lighter: "#FFE3D5",
  light: "#FFC1AC",
  main: "#FF3030",
  dark: "#B71833",
  darker: "#7A0930",
  contrastText: GREY[100],
};

export const primaryPalettes = {
  PRIMARY,
  PRIMARY_GREEN,
  PRIMARY_BLUE,
  PRIMARY_PURPLE,
  PRIMARY_DARK_BLUE,
  PRIMARY_RED,
};

const SECONDARY = {
  lighter: GREY[200],
  light: GREY[500],
  main: GREY[800],
  dark: GREY[700],
  darker: GREY[900],
  contrastText: GREY[100],
};

const INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#003768",
  darker: "#003768",
  contrastText: GREY[100],
};

const SUCCESS = {
  lighter: "#D3FCD2",
  light: "#77ED8B",
  main: "#22C55E",
  dark: "#118D57",
  darker: "#065E49",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: GREY[100],
};

export const palette = {
  common: { black: "#000", white: GREY[100] },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: "#fff",
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export type PrimaryPaletteKey = keyof typeof primaryPalettes;
