import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import reducer from "@store/reducer";
import { invoiceDataApi } from "./services/InvoiceDataService";
import { activityLogApi } from "./services/activityLogService";
import { appointmentStatusApi } from "./services/appointmentStatusService";
import { appointmentTypesApi } from "./services/appointmentTypesService";
import { appointmentsApi } from "./services/appointmentsService";
import { authApi } from "./services/authService";
import { clinicsApi } from "./services/clinicsService";
import { diagnosisApi } from "./services/diagnosisService";
import { employeesApi } from "./services/employeesService";
import { homeworksApi } from "./services/homeworksService";
import { invoiceApi } from "./services/invoiceService";
import { notificationsApi } from "./services/notificationsService";
import { patientsApi } from "./services/patientsService";
import { reportsApi } from "./services/reportsService";
import { usersApi } from "./services/usersService";
import { expensesApi } from "./services/expensesService";
import { clinicNotificationsApi } from "./services/clinicNotificationsService";
import { reservationApi } from "./services/reservationsService";
import { reservationSettingsApi } from "./services/reservationSettingsService";
import { miscApi } from "./services/miscService";

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware(),
    authApi.middleware,
    usersApi.middleware,
    clinicsApi.middleware,
    employeesApi.middleware,
    patientsApi.middleware,
    appointmentsApi.middleware,
    diagnosisApi.middleware,
    homeworksApi.middleware,
    activityLogApi.middleware,
    notificationsApi.middleware,
    appointmentTypesApi.middleware,
    reportsApi.middleware,
    invoiceDataApi.middleware,
    invoiceApi.middleware,
    appointmentStatusApi.middleware,
    expensesApi.middleware,
    clinicNotificationsApi.middleware,
    reservationApi.middleware,
    reservationSettingsApi.middleware,
    miscApi.middleware,
  ],
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;

export default store;
