import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Box, ListItemButton, Stack, Typography } from "@mui/material";
import { formatBankAccountNumber } from "@utils/typographyUtils";
import { IInvoiceData } from "invoiceData-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface InvoiceDataBoxProps {
  invoice: IInvoiceData;
  index: number;
  actions?: boolean;
  selectable?: boolean;
  onClick?: () => void;
  handleOpenEditModal?: (invoice: IInvoiceData) => void;
  handleOpenDeleteModal?: (id: string) => void;
}

const InvoiceDataBox: React.FC<InvoiceDataBoxProps> = ({
  invoice,
  index,
  actions = true,
  selectable = false,
  onClick,
  handleOpenEditModal,
  handleOpenDeleteModal,
}) => {
  const { t } = useTranslation();

  return (
    <ListItemButton
      key={invoice._id}
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onClick={selectable && onClick}
    >
      <Box sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <Stack direction="row" alignItems="center" justifyContent="start" gap={1}>
            <Typography variant="subtitle2">{invoice.bankName}</Typography>
            {index === 0 && <SeverityPill color="primary">{t("invoice_data.section.newest")}</SeverityPill>}
          </Stack>
          {actions && (
            <Stack direction="row" alignItems="center" justifyContent="start">
              <RBAC
                action={RBACActions.ACTION_EDIT_INVOICE_DATA}
                yes={() => (
                  <IconWithTooltip
                    label={t("invoice_data.section.button.edit")}
                    icon={<EditRoundedIcon />}
                    onClick={() => handleOpenEditModal(invoice)}
                  />
                )}
              />
              <RBAC
                action={RBACActions.ACTION_DELETE_INVOICE_DATA}
                yes={() => (
                  <IconWithTooltip
                    color="error"
                    label={t("invoice_data.section.button.delete")}
                    icon={<DeleteRoundedIcon />}
                    onClick={() => handleOpenDeleteModal(invoice._id)}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <Typography variant="body2" color="textSecondary">
          {invoice.address}, {invoice.city}, {invoice.postalCode}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("invoice_data.section.nip")} {invoice.NIP}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("invoice_data.section.regon")} {invoice.REGON}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("invoice_data.section.account_number")} {formatBankAccountNumber(invoice.bankAccountNumber)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${t("invoice_data.section.paymentDayOfMonth")} ${invoice.paymentDayOfMonth} ${t("invoice_data.section.dayOfMonth")}`}
        </Typography>
      </Box>
    </ListItemButton>
  );
};

export default InvoiceDataBox;
