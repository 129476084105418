import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPatientHomeworksMenuProps {
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  toggleEditPatientHomeworkModal?: () => void;
  toggleDeletePatientHomeworkModal?: () => void;
  toggleUploadHomeworkFileModal?: () => void;
}

const PatientHomeworkMenu: React.FC<IPatientHomeworksMenuProps> = ({
  anchorElementMenu,
  handleCloseMenu,
  toggleEditPatientHomeworkModal,
  toggleDeletePatientHomeworkModal,
  toggleUploadHomeworkFileModal,
}) => {
  const { t } = useTranslation();
  return (
    <Menu anchorEl={anchorElementMenu} open={!!anchorElementMenu} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 100 }}>
      <MenuItem onClick={toggleEditPatientHomeworkModal}>
        <ListItemIcon>
          <EditRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("homework.menu.edit")}
        </Typography>
      </MenuItem>
      <MenuItem onClick={toggleUploadHomeworkFileModal}>
        <ListItemIcon>
          <UploadFileRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("homework.menu.upload_file")}
        </Typography>
      </MenuItem>
      <Divider sx={{ borderStyle: "dashed" }} />
      <MenuItem onClick={toggleDeletePatientHomeworkModal}>
        <ListItemIcon>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="error">
          {t("homework.menu.delete")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default PatientHomeworkMenu;
