import Logo from "@components/Logo/Logo";
import { AlertColor, Link, Typography } from "@mui/material";
import LoginForm from "@pages/SignIn/fragments/SignInForm/SignInForm";
import { selectCurrentUserTokens, setCredentials } from "@store/features/authSlice";
import { useLoginMutation } from "@store/services/authService";
import { StyledCard } from "@theme/styled-components/AuthCard";
import { Background } from "@theme/styled-components/Background";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { withSnackbar } from "src/hoc/AlertPopover";
import { authValidationSchema } from "src/validations/authValidation";

const SignIn: React.FC = ({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) => {
  const { t } = useTranslation();

  const [login, { isLoading }] = useLoginMutation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (values) => {
      login({
        ...values,
      })
        .unwrap()
        .then((payload) => {
          dispatch(setCredentials({ ...payload }));
          navigate(AppRoutes.Dashboard, { replace: true });
        })
        .catch((error) => snackbarShowMessage(error.data.message, "error"));
    },
  });

  if (usersTokens) return <Navigate replace to={AppRoutes.Dashboard} />;

  return (
    <>
      <Helmet>
        <title>{t("sign_in.helmet")}</title>
      </Helmet>
      <Background />
      <Logo />
      <StyledCard>
        <Typography variant="h6" sx={{ mb: -0.5 }}>
          {t("sign_in.title")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }} color="text.secondary">
          {t("sign_in.dont_have_account")}{" "}
          <Link
            variant="subtitle2"
            href={AppRoutes.Register}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("sign_in.register")}
          </Link>
        </Typography>
        <LoginForm {...{ formik }} {...{ isLoading }} />
      </StyledCard>
    </>
  );
};
export default withSnackbar(SignIn);
