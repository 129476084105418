import Logo from "@components/Logo/Logo";
import { AlertColor, Typography } from "@mui/material";
import { useResetPasswordMutation } from "@store/services/authService";
import { StyledCard } from "@theme/styled-components/AuthCard";
import { Background } from "@theme/styled-components/Background";
import { AppRoutes } from "@utils/enums";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { withSnackbar } from "src/hoc/AlertPopover";
import { changePasswordValidationSchema } from "src/validations/authValidation";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword: React.FC = ({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [passwordMatch, setPasswordMatch] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      token: "",
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values) => {
      resetPassword({
        token: values.token,
        newPassword: values.newPassword,
      })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("change_password.snackbar_message.success"), "success");
          navigate(AppRoutes.ChangePasswordSuccess, { replace: true });
        })
        .catch(() => snackbarShowMessage(t("change_password.snackbar_message.error"), "error"));
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token") || "";
    formik.setFieldValue("token", token);
  }, []);

  useEffect(() => {
    const { password, newPassword } = formik.values;

    if (password && newPassword) {
      setPasswordMatch(password === newPassword);
    }
  }, [formik.values.password, formik.values.newPassword]);

  return (
    <>
      <Helmet>
        <title>{t("change_password.helmet")}</title>
      </Helmet>
      <Background />
      <Logo />
      <StyledCard>
        <Typography variant="h6" sx={{ mb: -0.5 }}>
          {t("change_password.title")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }} color="text.secondary">
          {t("change_password.instructions")}
        </Typography>
        <ChangePasswordForm {...{ formik }} {...{ isLoading }} {...{ passwordMatch }} />
      </StyledCard>
    </>
  );
};

export default withSnackbar(ChangePassword);
