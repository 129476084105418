import useResponsive from "@hooks/useResponsive";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";

interface IPageTitleProps {
  titleId?: string;
  gap?: number;
  title: string;
  avatarSrc?: string;
  bottomContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const PageTitle: React.FC<IPageTitleProps> = (props) => {
  const { title, bottomContent, rightContent, gap = 8 } = props;
  const isDesktop = useResponsive("up", "sm");

  return (
    <Stack spacing={3} sx={{ mb: isDesktop ? gap : 4 }}>
      <Stack direction={isDesktop ? "row" : "column"} justifyContent="space-between" spacing={isDesktop ? 4 : 2}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {props.avatarSrc && (
              <Stack direction="row">
                <Avatar
                  src={props.avatarSrc}
                  sx={{
                    width: 56,
                    height: 56,
                  }}
                />
              </Stack>
            )}
            <Typography variant="h4" id={props.titleId}>
              {title}
            </Typography>
          </Stack>
          {bottomContent}
        </Stack>
        <Box>{rightContent}</Box>
      </Stack>
    </Stack>
  );
};

export default PageTitle;
