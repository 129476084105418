import React, { useCallback, useMemo } from "react";
import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { ColorType, SeverityPill } from "@components/SeverityPill/SeverityPill";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { parseISO } from "date-fns";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useTranslation } from "react-i18next";
import { PatientAppointment } from "reports-types";

interface PatientAppointmentTableProps extends SnackbarProps {
  data?: PatientAppointment[];
}

const AppointmentTable: React.FC<PatientAppointmentTableProps> = ({ data = [], snackbarShowMessage }) => {
  const { t } = useTranslation();

  const formattedData = useMemo(
    () =>
      data.map((appointment) => ({
        ...appointment,
        appointmentCost: formatCost(appointment.appointmentCost),
      })),
    [data]
  );

  const csvConfig = useMemo(
    () =>
      mkConfig({
        filename: t("reports.patientAppointmentsTable.filename"),
        columnHeaders: [
          { key: "typeName", displayLabel: t("reports.appointment.type.title") },
          { key: "appointmentDate", displayLabel: t("reports.patientAppointmentsTable.date") },
          { key: "appointmentCost", displayLabel: t("reports.appointment.type.cost") },
        ],
      }),
    [t]
  );

  const csv = useMemo(() => generateCsv(csvConfig)(formattedData), [csvConfig, formattedData]);

  const handleDownload = useCallback(() => {
    download(csvConfig)(csv);
    snackbarShowMessage(t("reports.patientAppointmentsTable.downloaded"), "success");
  }, [csv, csvConfig, snackbarShowMessage, t]);

  if (!data || data.length === 0) {
    return (
      <Card>
        <CardHeader title={t("reports.patientAppointmentsTable.title")} subheader={t("reports.patientAppointmentsTable.subtitle")} />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent>
          <Typography variant="body2" color="text.secondary" align="center">
            {t("reports.no_data")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={t("reports.patientAppointmentsTable.title")}
        subheader={t("reports.patientAppointmentsTable.subtitle")}
        action={
          <RBAC
            action={RBACActions.ACTION_EXPORT_PATIENT_APPOINTMENTS_REPORTS}
            yes={() => <IconWithTooltip icon={<DownloadRoundedIcon />} label={t("reports.export_to_csv")} onClick={handleDownload} />}
          />
        }
      />
      <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("reports.appointment.type.title")}</TableCell>
                <TableCell>{t("reports.patientAppointmentsTable.date")}</TableCell>
                <TableCell>{t("reports.appointment.type.cost")}</TableCell>
                <TableCell align="right">{t("reports.appointment.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((appointment, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="subtitle2">{appointment.typeName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatDate(parseISO(appointment.appointmentDate), "d MMMM")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatCost(appointment.appointmentCost)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <SeverityPill color={getAppointmentStatusColor(appointment.appointmentStatus) as ColorType}>
                      {t(getAppointmentStatusText(appointment.appointmentStatus))}
                    </SeverityPill>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default withSnackbar(AppointmentTable);
