import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Container, Paper, SelectChangeEvent } from "@mui/material";
import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions, RBACRoles } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import { useAddPatientMutation, useGetAllPatientsQuery } from "@store/services/patientsService";
import { AppRoutes, PatientStatus, SortBy, SortOrder } from "@utils/enums";
import { IPatient } from "patients-types";
import FilterControls from "../../components/FilterControls/FilterControls";
import PatientFilterTabs, { PatientStatusFilter, PatientStatusFilterAll } from "../../components/PatientFilterTabs/PatientFilterTabs";
import PatientsTable from "./fragments/PatientsTable";
import AddPatientModal from "./modals/AddPatientModal";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";

function Patients({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [isAddPatientModalOpen, toggleAddPatientModal] = useModal();
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<PatientStatusFilter>(PatientStatusFilterAll.ALL);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.LAST_NAME);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
  const [searchText, setSearchText] = useState<string>("");
  const [patientCounts, setPatientCounts] = useState({ all: 0, active: 0, inactive: 0 });
  const [initialCountsSet, setInitialCountsSet] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 300);
  const patientStatusFilter = selectedTab === PatientStatusFilterAll.ALL ? undefined : selectedTab;

  const { patients, isFetching } = useGetAllPatientsQuery(
    { assignedEmployees: selectedEmployees, sortBy, sortOrder, patientStatus: patientStatusFilter, search: debouncedSearchText },
    {
      selectFromResult: ({ data, isFetching }) => ({
        patients: data || [],
        isFetching,
      }),
    }
  );

  const { employees } = useGetAllEmployeesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
      skip: userRole === RBACRoles.EMPLOYEE,
    }
  );

  const [addPatient, { isLoading: isAddPatientLoading }] = useAddPatientMutation();

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: PatientStatusFilter) => {
    setSelectedTab(newValue);
  }, []);

  const handleEmployeeChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedEmployees(event.target.value as string[]);
    },
    [setSelectedEmployees]
  );

  const handleAddPatient = useCallback(
    (patientData: Partial<IPatient>) => {
      addPatient(patientData)
        .unwrap()
        .then(() => snackbarShowMessage(t("patient.snackbar_message.add.success"), "success"))
        .catch(() => snackbarShowMessage(t("patient.snackbar_message.add.error"), "error"))
        .finally(() => {
          toggleAddPatientModal();
          setInitialCountsSet(false);
        });
    },
    [addPatient, snackbarShowMessage, toggleAddPatientModal, t]
  );

  useEffect(() => {
    if (patients.length > 0 && !initialCountsSet) {
      const allCount = patients.length;
      const activeCount = patients.filter((patient) => patient.patientStatus === PatientStatus.ACTIVE).length;
      const inactiveCount = patients.filter((patient) => patient.patientStatus === PatientStatus.INACTIVE).length;
      setPatientCounts({ all: allCount, active: activeCount, inactive: inactiveCount });
      setInitialCountsSet(true);
    }
  }, [patients, initialCountsSet]);

  useEffect(() => {
    if (!rbacCheck(userRole, RBACActions.MAIN_MENU_PATIENTS)) {
      navigate(AppRoutes.Dashboard);
    }
  }, [userRole, navigate]);

  return (
    <>
      <Helmet>
        <title>{t("patient.helmet")}</title>
      </Helmet>
      <Container maxWidth={false}>
        <PageTitle
          title={t("patient.title")}
          titleId="patients-page-title"
          rightContent={
            <Button
              color="secondary"
              variant="contained"
              startIcon={<AddRoundedIcon />}
              onClick={toggleAddPatientModal}
              id="patients-add-patient-button"
            >
              {t("patient.button.add_new")}
            </Button>
          }
        />
        <Paper>
          <PatientFilterTabs selectedTab={selectedTab} onTabChange={handleTabChange} counts={patientCounts} />
          <FilterControls
            employees={employees}
            selectedEmployees={selectedEmployees}
            searchText={searchText}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSearchText={setSearchText}
            setSortOrder={setSortOrder}
            handleEmployeeChange={handleEmployeeChange}
          />
          <PatientsTable {...{ patients, setInitialCountsSet, isFetching }} />
        </Paper>
        <AddPatientModal open={isAddPatientModalOpen} onClose={toggleAddPatientModal} onSubmit={handleAddPatient} loading={isAddPatientLoading} />
      </Container>
    </>
  );
}

export default withSnackbar(Patients);
