import { Box, IconButton, MenuItem, Popover, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { SyntheticEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const LANGS = [
  {
    value: "pl",
    label: "Polski",
    icon: "/assets/images/icons/ic_flag_pl.svg",
  },
  {
    value: "en",
    label: "English",
    icon: "/assets/images/icons/ic_flag_en.svg",
  },
];

const LanguagePopover: React.FC = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentLang = useMemo(() => LANGS.find((lang) => lang.value === i18n.language) || LANGS[0], [i18n.language]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(anchorEl && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={currentLang.icon} alt={currentLang.label} />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === i18n.language}
              onClick={() => {
                i18n.changeLanguage(option.value);
                handleClose();
              }}
            >
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default LanguagePopover;
