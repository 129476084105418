import { Theme } from "@mui/material";

export default function DialogActions(theme: Theme) {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    },
  };
}
