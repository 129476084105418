import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IEmployeeMenuProps {
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  handleOpenDeleteModal: () => void;
  handleOpenAssignPatientToEmployeeModal: () => void;
}

const EmployeeMenu: React.FC<IEmployeeMenuProps> = ({
  anchorElementMenu,
  handleCloseMenu,
  handleOpenDeleteModal,
  handleOpenAssignPatientToEmployeeModal,
}) => {
  const { t } = useTranslation();

  return (
    <Menu anchorEl={anchorElementMenu} open={!!anchorElementMenu} onClose={handleCloseMenu} sx={{ width: "20rem" }}>
      <MenuItem onClick={handleOpenAssignPatientToEmployeeModal}>
        <ListItemIcon>
          <AssignmentIndRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("employee.menu.assign_to_patient")}
        </Typography>
      </MenuItem>
      <Divider sx={{ borderStyle: "dashed" }} />
      <MenuItem onClick={handleOpenDeleteModal}>
        <ListItemIcon>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="error">
          {t("delete")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default EmployeeMenu;
