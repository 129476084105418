import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Button, Stack, Typography } from "@mui/material";
import { NavigateAction } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { useTranslation } from "react-i18next";
import { CalendarMode } from "../Appointments";
import { useMemo } from "react";

interface ICalendarToolbarProps {
  currentDate: Date;
  view: CalendarMode;
  startWeek: Date;
  endWeek: Date;
  handleNavigate: (type: NavigateAction) => void;
}

const CalendarToolbar: React.FC<ICalendarToolbarProps> = ({ view, currentDate, startWeek, endWeek, handleNavigate }) => {
  const { t } = useTranslation();

  const formatWeekRange = useMemo(() => {
    return view === CalendarMode.DAY
      ? formatDate(currentDate, "EEEE dd MMM yyyy")
      : formatDate(startWeek, "dd MMM") + " - " + formatDate(endWeek, "dd MMM yyyy");
  }, [view, currentDate, startWeek, endWeek]);

  return (
    <Stack sx={{ mb: 2 }} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">{formatWeekRange}</Typography>

      <Stack spacing={3} direction="row">
        <IconWithTooltip
          label={t(view === CalendarMode.DAY ? "appointments.calendar.previous_day_button" : "appointments.calendar.previous_button")}
          icon={<ArrowBackIosNewRoundedIcon />}
          onClick={() => handleNavigate(NavigateAction.PREV)}
        />
        <IconWithTooltip
          label={t(view === CalendarMode.DAY ? "appointments.calendar.next_day_button" : "appointments.calendar.next_button")}
          icon={<ArrowForwardIosRoundedIcon />}
          onClick={() => handleNavigate(NavigateAction.NEXT)}
        />
        <Button startIcon={<HomeRoundedIcon />} color="secondary" variant="contained" onClick={() => handleNavigate(NavigateAction.TODAY)}>
          {t("appointments.calendar.today_button")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CalendarToolbar;
