import { Avatar, Box, Stack, Typography } from "@mui/material";
import { formatCost } from "@utils/typographyUtils";

export interface IExpensesTypeWidgetProps {
  icon: React.ReactNode;
  type: string;
  value: number;
  color: string;
}

export const ExpensesTypeWidget: React.FC<IExpensesTypeWidgetProps> = ({ icon, type, value, color }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar sx={{ bgcolor: color, width: 56, height: 56 }}>{icon}</Avatar>
      <Box>
        <Typography variant="subtitle2" color="text.secondary" noWrap>
          {type}
        </Typography>
        <Typography variant="subtitle1" color={value ? value < 0 && "error" : "text.primary"}>
          {formatCost(value)}
        </Typography>
      </Box>
    </Stack>
  );
};
