import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { useTranslation } from "react-i18next";

const PasswordResetSuccess = () => {
  const { t } = useTranslation();

  return (
    <NotificationWithAction
      helmetTitle={t("password_reset.success.helmet")}
      title={t("password_reset.success.title")}
      message={t("password_reset.success.message")}
    />
  );
};

export default PasswordResetSuccess;
