import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { Tooltip } from "@mui/material";
import { IPatient } from "patients-types";
import { useTranslation } from "react-i18next";

const ClientAssignmentStatus = ({ patient }: { patient: IPatient }) => {
  const { t } = useTranslation();

  if (!patient) return null;

  const isAssigned = patient.clientId ? true : false;
  const tooltipTitle = t("patient.status.about", {
    firstname: patient.firstname,
    lastname: patient.lastname,
    status: isAssigned ? t("client.status.assigned") : t("client.status.not_assigned"),
  });

  return (
    <Tooltip title={tooltipTitle}>
      <span>
        <SeverityPill color={isAssigned ? "success" : "error"}>
          {isAssigned ? t("client.status.assigned") : t("client.status.not_assigned")}
        </SeverityPill>
      </span>
    </Tooltip>
  );
};
export default ClientAssignmentStatus;
