import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IAppointment } from "appointment.types";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";
import { ReservationStatus } from "@utils/enums";
type StatusToTranslationKeyMap = {
  [key in ReservationStatus]?: string;
};

interface ToggleReservationStatusModalProps {
  open: boolean;
  loading?: boolean;
  selectedReservation: Partial<IAppointment>;
  onSubmit: (status: ReservationStatus) => void;
  onClose: () => void;
}

const ToggleReservationStatusModal: React.FC<ToggleReservationStatusModalProps> = ({ open, loading, selectedReservation, onSubmit, onClose }) => {
  if (!selectedReservation) return null;
  const { t } = useTranslation();
  const [reservationStatus, setReservationStatus] = useState<ReservationStatus>(selectedReservation.reservationStatus);

  const dialogContentText = useMemo(() => {
    const statusToTranslationKey: StatusToTranslationKeyMap = {
      [ReservationStatus.ACCEPTED]: "reservations.calendar.status_modal.accepted_content",
      [ReservationStatus.REJECTED]: "reservations.calendar.status_modal.rejected_content",
      [ReservationStatus.PENDING]: "reservations.calendar.status_modal.pending_content",
    };

    return t(statusToTranslationKey[reservationStatus]);
  }, [reservationStatus, t]);

  useEffect(() => {
    setReservationStatus(selectedReservation.reservationStatus);
  }, [selectedReservation]);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("reservations.calendar.status_modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          exclusive
          value={reservationStatus}
          onChange={(event: React.MouseEvent<HTMLElement>, newStatus: ReservationStatus | null) => {
            if (newStatus !== null) setReservationStatus(newStatus);
          }}
          sx={{ mt: 3 }}
        >
          <ToggleButton value={ReservationStatus.ACCEPTED} color="success">
            {t("reservations.calendar.status.accepted")}
          </ToggleButton>
          <ToggleButton value={ReservationStatus.REJECTED} color="error">
            {t("reservations.calendar.status.rejected")}
          </ToggleButton>
          <ToggleButton value={ReservationStatus.PENDING} color="info">
            {t("reservations.calendar.status.pending")}
          </ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={() => onSubmit(reservationStatus)} variant="contained" autoFocus color="secondary">
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default ToggleReservationStatusModal;
