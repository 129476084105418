import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions, RBACRoles } from "@auth/rbac-rules";
import PatientDiagnosisWidget from "@components/PatientDiagnosisWidget/PatientDiagnosisWidget";
import PatientHomeworkWidget from "@components/PatientHomeworkWidget/PatientHomeworkWidget";
import { ClinicContext } from "@context/ClinicProvider";
import { SnackbarProps } from "@hoc/AlertPopover";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanelProps } from "@pages/Settings/Settings";
import { useGetAppointmentTypesQuery } from "@store/services/appointmentTypesService";
import { useGetAllAppointmentsQuery } from "@store/services/appointmentsService";
import { useGetDiagnosesByPatientQuery } from "@store/services/diagnosisService";
import { useGetHomeworksByPatientQuery } from "@store/services/homeworksService";
import { useGetPatientInvoiceDataQuery } from "@store/services/patientsService";
import { useGetPatientAppointmentsReportsQuery, useGetPatientPresenceReportsQuery } from "@store/services/reportsService";
import { AppointmentStatus } from "@utils/enums";
import { addDays, format, getMonth, parseISO } from "date-fns";
import { IPatient } from "patients-types";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPatientAppointmentsByStatus, IPatientIncomeDetails } from "reports-types";
import UpcomingAppointmentsWidget from "../../../components/UpcomingAppointmentsWidget/UpcomingAppointmentsWidget";
import ClinicDataWidget from "./fragments/ClinicDataWidget";
import PatientDataWidget from "./fragments/PatientDataWidget";
import PatientInvoiceData from "./fragments/PatientInvoiceData";
import PatientSummary from "./fragments/PatientSummary";
import { adjustAppointment } from "@utils/timeUtils";

interface IPatientDetailsContentProps extends SnackbarProps {
  patient: IPatient;
  patientId: string;
  isActive?: boolean;
}

const PatientDetailsContent: React.FC<IPatientDetailsContentProps> = ({ patient, patientId, isActive, snackbarShowMessage }) => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const { currentClinic } = useContext(ClinicContext);

  const { data: homeworks, refetch: refetchHomeworks } = useGetHomeworksByPatientQuery(patientId);
  const { data: diagnosis, refetch: refetchDiagnosis } = useGetDiagnosesByPatientQuery(patientId);
  const { data: patientInvoiceData } = useGetPatientInvoiceDataQuery(patientId, {
    skip: userRole === RBACRoles.EMPLOYEE || userRole === RBACRoles.CLIENT,
  });
  const [value, setValue] = useState(0);

  const { appointments } = useGetAllAppointmentsQuery(
    {
      start: format(new Date(), "yyyy-MM-dd"),
      from: format(addDays(new Date(), 30), "yyyy-MM-dd"),
      patientId: patientId,
      appointmentStatus: [AppointmentStatus.PRESENT, AppointmentStatus.NOT_EXCUSED, AppointmentStatus.EXCUSED, AppointmentStatus.PENDING],
    },
    {
      selectFromResult: ({ data }) => ({
        appointments: data || [],
      }),
    }
  );

  const adjustedAppointments = useMemo(() => appointments.map(adjustAppointment), [appointments]);

  const { appointmentTypes } = useGetAppointmentTypesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
      skip: !rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENT_TYPES),
    }
  );

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<AppointmentStatus[]>([
    AppointmentStatus.EXCUSED,
    AppointmentStatus.PRESENT,
    AppointmentStatus.NOT_EXCUSED,
    AppointmentStatus.PENDING,
  ]);

  const { presenceReports } = useGetPatientPresenceReportsQuery(
    {
      patientId: patientId,
      month: getMonth(selectedDate),
    },
    {
      selectFromResult: ({ data }) => ({
        presenceReports: data || ({} as IPatientAppointmentsByStatus),
      }),
    }
  );
  const { appointmentsReports } = useGetPatientAppointmentsReportsQuery(
    {
      patientId: patientId,
      month: getMonth(selectedDate),
      appointmentStatus: selectedStatuses,
    },
    {
      selectFromResult: ({ data }) => ({
        appointmentsReports: data || ({} as IPatientIncomeDetails),
      }),
    }
  );

  if (!presenceReports || !appointmentsReports) return null;

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const handleMonthChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        setSelectedDate(newDate);
      }
    },
    [setSelectedDate]
  );

  const latestDiagnosis = useMemo(() => {
    return diagnosis?.reduce((latest, current) => {
      const currentDate = parseISO(current.diagnosisDate);
      const latestDate = latest ? parseISO(latest.diagnosisDate) : new Date(0);

      return currentDate > latestDate ? current : latest;
    }, null);
  }, [diagnosis]);

  const CustomTabPanel = memo(({ children, value, index, ...other }: TabPanelProps) => {
    return (
      <Box role="tabpanel" hidden={value !== index} {...other} sx={{ mt: 3 }}>
        {value === index && children}
      </Box>
    );
  });
  if (!adjustedAppointments || !diagnosis || !homeworks || !currentClinic) return null;

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} variant="scrollable">
        <Tab label={t("patient_details.menu.diagnoses")} value={0} id="patient-details-content-diagnoses" />
        {rbacCheck(userRole, RBACActions.VIEW_PATIENT_DATA) && <Tab label={t("patient_details.menu.patient_data")} value={1} />}
        {rbacCheck(userRole, RBACActions.VIEV_CLINIC_DATA) && <Tab label={t("patient_details.menu.clinic_data")} value={2} />}
        <Tab label={t("patient_details.menu.homeworks")} value={3} id="patient-details-content-homework" />
        <Tab label={t("patient_details.menu.appointments")} value={4} />
        {rbacCheck(userRole, RBACActions.VIEW_PATIENT_INVOICE_DATA) && <Tab label={t("patient_details.menu.invoice_data")} value={5} />}
        <Tab label={t("patient_details.menu.summary")} value={6} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <PatientDiagnosisWidget
          isActive={isActive}
          diagnosis={latestDiagnosis}
          patientId={patientId}
          snackbarShowMessage={snackbarShowMessage}
          refetch={refetchDiagnosis}
          displayShowAll
        />
      </CustomTabPanel>
      {rbacCheck(userRole, RBACActions.VIEW_PATIENT_DATA) && (
        <CustomTabPanel value={value} index={1}>
          <PatientDataWidget patient={patient} isActive={isActive} />
        </CustomTabPanel>
      )}
      {rbacCheck(userRole, RBACActions.VIEV_CLINIC_DATA) && (
        <CustomTabPanel value={value} index={2}>
          <ClinicDataWidget currentClinic={currentClinic} patient={patient} />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={3}>
        <PatientHomeworkWidget
          isActive={isActive}
          homeworks={homeworks}
          patientId={patientId}
          snackbarShowMessage={snackbarShowMessage}
          refetch={refetchHomeworks}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <UpcomingAppointmentsWidget
          patients={[patient]}
          appointments={adjustedAppointments}
          appointmentTypes={appointmentTypes}
          snackbarShowMessage={snackbarShowMessage}
          isActive={isActive}
          isMonthScope
        />
      </CustomTabPanel>
      {rbacCheck(userRole, RBACActions.VIEW_PATIENT_INVOICE_DATA) && (
        <CustomTabPanel value={value} index={5}>
          <PatientInvoiceData patientId={patientId} patientInvoiceData={patientInvoiceData} />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={6}>
        <PatientSummary
          selectedDate={selectedDate}
          handleMonthChange={handleMonthChange}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          presenceReports={presenceReports}
          appointmentsReports={appointmentsReports}
          snackbarShowMessage={snackbarShowMessage}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default PatientDetailsContent;
