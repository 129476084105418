import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { Alert, AlertColor } from "@mui/material";

export interface SnackbarProps {
  snackbarShowMessage?: (message: string, severity?: AlertColor) => void;
}

export const withSnackbar = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P & SnackbarProps> => {
  const WithSnackbar: React.FC<P & SnackbarProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<AlertColor>("success");

    const showMessage = (message: string, severity: AlertColor) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide as React.ComponentType<TransitionProps>}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
  return WithSnackbar;
};
