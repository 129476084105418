import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useUrlTab = (defaultValue: number = 0) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const initialTab = tabParam ? parseInt(tabParam, 10) : defaultValue;
  const [value, setValue] = useState<number>(initialTab);

  useEffect(() => {
    const currentTab = searchParams.get("tab");
    if (currentTab && parseInt(currentTab, 10) !== value) {
      setValue(parseInt(currentTab, 10));
    }
  }, [searchParams, value]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      setSearchParams({ tab: newValue.toString() });
    },
    [setSearchParams]
  );

  return { value, setValue, handleTabChange };
};
