import CustomDialog from "@components/modals/CustomDialog";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import { IPatient } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface IChooseAcquirerDataModalProps {
  open: boolean;
  patients: IPatient[];
  searchText: string;
  onClose: () => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleAcquirerDataSelect: (invoiceData: IPatient) => void;
}

const ChooseAcquirerDataModal: React.FC<IChooseAcquirerDataModalProps> = ({
  open,
  patients,
  searchText,
  onClose,
  setSearchText,
  handleAcquirerDataSelect,
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("invoice.choose_acquirer_data_modal.title")}</DialogTitle>
      <DialogContent sx={{ maxHeight: "100%", position: "relative" }}>
        <Box sx={{ position: "sticky", top: 0, zIndex: 1, pb: 2, bgcolor: "background.paper" }}>
          <DialogContentText sx={{ mb: 2 }}>{t("invoice.choose_acquirer_data_modal.content_text")}</DialogContentText>
          <TextField
            fullWidth
            label={t("activity_log.filter_by_patient")}
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: <SearchRoundedIcon color="action" />,
            }}
          />
        </Box>
        <Grid container sx={{ mt: 1 }}>
          {patients.map((patient) => (
            <Grid item xs={12} key={patient._id}>
              <ListItemButton
                key={patient._id}
                sx={{
                  mb: 2,
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                onClick={() => handleAcquirerDataSelect(patient)}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography variant="body2" color="text.secondary" noWrap>{`Pacjent:`}</Typography>
                  <Typography variant="subtitle1" noWrap sx={{ mb: 2 }}>{`${patient.firstname} ${patient.lastname}`}</Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {t("invoice.choose_acquirer_data_modal.data")}
                  </Typography>
                  <Typography variant="subtitle1" noWrap>
                    {patient.patientInvoiceData?.firstname && patient.patientInvoiceData?.lastname
                      ? `${patient.patientInvoiceData.firstname} ${patient.patientInvoiceData.lastname}`
                      : t("invoice.choose_acquirer_data_modal.no_data")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {t("invoice.choose_acquirer_data_modal.email")}
                  </Typography>
                  <Typography variant="subtitle1" noWrap>
                    {patient.patientInvoiceData?.email || t("invoice.choose_acquirer_data_modal.no_data")}
                  </Typography>
                </Box>
              </ListItemButton>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ChooseAcquirerDataModal;
