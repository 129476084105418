import React from "react";
import { Tabs, Tab, Stack, Typography } from "@mui/material";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import { PatientStatus } from "@utils/enums";
import { useTranslation } from "react-i18next";

export enum PatientStatusFilterAll {
  ALL = "ALL",
}
export type PatientStatusFilter = PatientStatus | PatientStatusFilterAll;

interface IPatientFilterTabsProps {
  selectedTab: PatientStatusFilter;
  counts: { all: number; active: number; inactive: number };
  onTabChange: (event: React.SyntheticEvent, newValue: PatientStatusFilter) => void;
}

const PatientFilterTabs: React.FC<IPatientFilterTabsProps> = ({ selectedTab, counts, onTabChange }) => {
  const { t } = useTranslation();

  return (
    <Tabs value={selectedTab} onChange={onTabChange} variant="scrollable">
      <Tab
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2">{t("patient.filter_tabs.all")}</Typography>
            <SeverityPill color="primary">{counts.all}</SeverityPill>
          </Stack>
        }
        value={PatientStatusFilterAll.ALL}
        title={PatientStatusFilterAll.ALL}
      />
      <Tab
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2">{t("patient.filter_tabs.active")}</Typography>
            <SeverityPill color="success">{counts.active}</SeverityPill>
          </Stack>
        }
        value={PatientStatus.ACTIVE}
        title={PatientStatus.ACTIVE}
      />
      <Tab
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2">{t("patient.filter_tabs.inactive")}</Typography>
            <SeverityPill color="error">{counts.inactive}</SeverityPill>
          </Stack>
        }
        value={PatientStatus.INACTIVE}
        title={PatientStatus.INACTIVE}
      />
    </Tabs>
  );
};

export default PatientFilterTabs;
