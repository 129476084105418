import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IEmployee } from "employees-types";
import { SortBy, SortOrder } from "@utils/enums";

enum TagTypes {
  EMPLOYEES = "EMPLOYEES",
}

export const employeesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  reducerPath: "employeesApi",
  tagTypes: [TagTypes.EMPLOYEES],
  endpoints: (build) => ({
    getAllEmployees: build.query<IEmployee[], { sortBy?: SortBy; sortOrder?: SortOrder; search?: string }>({
      query: (queryParams) => ({
        method: "GET",
        url: "employees",
        params: queryParams,
      }),
      providesTags: [TagTypes.EMPLOYEES],
    }),
    addEmployee: build.mutation<IEmployee, Partial<IEmployee>>({
      query: (employee) => ({
        method: "POST",
        url: "employees",
        data: employee,
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
    deleteEmployee: build.mutation<void, { employeeId: string }>({
      query: ({ employeeId }) => ({
        method: "DELETE",
        url: `employees/${employeeId}`,
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
    assignPatientToEmployee: build.mutation<void, { employeeId: string; patientBodyId: string }>({
      query: ({ employeeId, patientBodyId }) => ({
        method: "POST",
        url: `employees/${employeeId}`,
        data: { patientBodyId },
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
    unassignPatientToEmployee: build.mutation<void, { employeeId: string; patientId: string }>({
      query: ({ employeeId, patientId }) => ({
        method: "DELETE",
        url: `employees/${employeeId}/${patientId}`,
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useAddEmployeeMutation,
  useDeleteEmployeeMutation,
  useAssignPatientToEmployeeMutation,
  useUnassignPatientToEmployeeMutation,
} = employeesApi;

export const employeesApiReducer = {
  [employeesApi.reducerPath]: employeesApi.reducer,
};
