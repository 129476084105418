import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import CustomDialog from "@components/modals/CustomDialog";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { IPatient } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IChooseAcquirerDataModalProps {
  open: boolean;
  patients: IPatient[];
  searchText: string;
  onClose: () => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleAcquirerDataSelect: (invoiceData: IPatient) => void;
}

const ChooseAcquirerDataModal: React.FC<IChooseAcquirerDataModalProps> = ({
  open,
  patients,
  searchText,
  onClose,
  setSearchText,
  handleAcquirerDataSelect,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("invoice.choose_acquirer_data_modal.title")}</DialogTitle>
      <DialogContent sx={{ maxHeight: "100%", position: "relative" }}>
        <Box sx={{ position: "sticky", top: 0, zIndex: 1, pb: 2, bgcolor: "background.paper" }}>
          <DialogContentText sx={{ mb: 2 }}>{t("invoice.choose_acquirer_data_modal.content_text")}</DialogContentText>
          <TextField
            fullWidth
            label={t("activity_log.filter_by_patient")}
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: <SearchRoundedIcon color="action" />,
            }}
          />
        </Box>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          {patients.map((patient) => {
            const hasInvoiceData = patient.patientInvoiceData?.firstname && patient.patientInvoiceData?.lastname;
            const hasEmail = patient.patientInvoiceData?.email;

            return (
              <Grid item xs={12} key={patient._id}>
                <Card key={patient._id}>
                  <CardActionArea onClick={() => handleAcquirerDataSelect(patient)}>
                    <CardContent>
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Typography variant="body2" color="text.secondary" noWrap>
                          {t(`invoice.choose_acquirer_data_modal.patient`)}
                        </Typography>
                        <Typography variant="subtitle1" noWrap sx={{ mb: 2 }}>
                          {`${patient.firstname} ${patient.lastname}`}
                        </Typography>
                        {!hasInvoiceData && !hasEmail && (
                          <Alert severity="warning" sx={{ mb: 2 }}>
                            {t("invoice.choose_acquirer_data_modal.no_added_invoice_data")}
                          </Alert>
                        )}
                        <Typography variant="body2" color="text.secondary" noWrap>
                          {t("invoice.choose_acquirer_data_modal.data")}
                        </Typography>
                        <Typography variant="subtitle1" noWrap>
                          {hasInvoiceData
                            ? `${patient.patientInvoiceData.firstname} ${patient.patientInvoiceData.lastname}`
                            : t("invoice.choose_acquirer_data_modal.no_data")}
                        </Typography>

                        <Typography variant="body2" color="text.secondary" noWrap>
                          {t("invoice.choose_acquirer_data_modal.email")}
                        </Typography>
                        <Typography variant="subtitle1" noWrap>
                          {hasEmail || t("invoice.choose_acquirer_data_modal.no_data")}
                        </Typography>
                        {(!hasInvoiceData || !hasEmail) && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <IconWithTooltip
                              icon={<AddRoundedIcon />}
                              label={t("invoice.choose_acquirer_data_modal.add_invoice_data_tooltip")}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(AppRoutes.PatientDetails.replace(":patientId", patient._id));
                              }}
                              color="secondary"
                            />
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ChooseAcquirerDataModal;
