import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPatientDiagnosisMenuProps {
  isPatientDiagnosisPage: boolean;
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  toggleAddPatientDiagnosisModal: () => void;
  toggleEditPatientDiagnosisModal: () => void;
  toggleDeletePatientDiagnosisModal: () => void;
  toggleUploadDiagnosisFileModal: () => void;
}

const PatientDiagnosisMenu: React.FC<IPatientDiagnosisMenuProps> = ({
  isPatientDiagnosisPage,
  anchorElementMenu,
  handleCloseMenu,
  toggleAddPatientDiagnosisModal,
  toggleEditPatientDiagnosisModal,
  toggleDeletePatientDiagnosisModal,
  toggleUploadDiagnosisFileModal,
}) => {
  const { t } = useTranslation();

  return (
    <Menu anchorEl={anchorElementMenu} open={!!anchorElementMenu} onClose={handleCloseMenu} sx={{ width: "20rem", zIndex: 100 }}>
      {isPatientDiagnosisPage && (
        <MenuItem onClick={toggleAddPatientDiagnosisModal}>
          <ListItemIcon>
            <AddRoundedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t("diagnosis.menu.add")}
          </Typography>
        </MenuItem>
      )}
      <MenuItem onClick={toggleEditPatientDiagnosisModal}>
        <ListItemIcon>
          <EditRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("diagnosis.menu.edit")}
        </Typography>
      </MenuItem>
      <MenuItem onClick={toggleUploadDiagnosisFileModal}>
        <ListItemIcon>
          <UploadFileRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("diagnosis.menu.upload_file")}
        </Typography>
      </MenuItem>
      <Divider sx={{ borderStyle: "dashed" }} />
      <MenuItem onClick={toggleDeletePatientDiagnosisModal}>
        <ListItemIcon>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap color="error">
          {t("diagnosis.menu.delete")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default PatientDiagnosisMenu;
