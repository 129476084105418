import { RBACRoles } from "@auth/rbac-rules";
import { Buffer } from "buffer";
import { Sex } from "./enums";

export const formatRoleString = (role: RBACRoles): string => {
  switch (role) {
    case RBACRoles.OWNER:
      return "roles.owner";
    case RBACRoles.EMPLOYEE:
      return "roles.employee";
    case RBACRoles.CLIENT:
      return "roles.client";
    case RBACRoles.ADMIN:
      return "roles.admin";
    default:
      return "";
  }
};

export const formatSexString = (sex: Sex): string => {
  switch (sex) {
    case Sex.MALE:
      return "gender.male";
    case Sex.FEMALE:
      return "gender.female";
    default:
      return "";
  }
};

export const capitalizeWord = (word: string): string => {
  if (!word) return "";

  return word.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const strPhoneNumber = String(phoneNumber);

  const cleaned = strPhoneNumber.replace(/\D/g, "");

  if (cleaned.length === 11) {
    return "+" + cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
  } else if (cleaned.length === 9) {
    return cleaned.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
  } else {
    return strPhoneNumber;
  }
};

export const formatCost = (cost: number) => {
  return new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(cost);
};

export const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let hoursText = "";
  if (hours > 0) {
    hoursText = `${hours} godzin${hours > 1 ? (hours < 5 ? "y" : "") : "a"}`;
  }

  let minutesText = "";
  if (remainingMinutes > 0) {
    minutesText = `${remainingMinutes} minut${remainingMinutes === 1 ? "a" : remainingMinutes < 5 && remainingMinutes > 1 ? "y" : ""}`;
  }

  return [hoursText, minutesText].filter(Boolean).join(" i ");
};

export const parseToUTF8 = (name: string) => {
  return Buffer.from(name, "latin1").toString("utf8");
};

export const formatBankAccountNumber = (accountNumber: string) => {
  return accountNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
};
