import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IAppointmentStatus } from "appointment.types";

enum TagTypes {
  APPOINTMENT_STATUS = "APPOINTMENT_STATUS",
}

export const appointmentStatusApi = createApi({
  reducerPath: "appointmentStatusApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [TagTypes.APPOINTMENT_STATUS],
  endpoints: (build) => ({
    getAppointmentStatus: build.query<IAppointmentStatus[], unknown>({
      query: () => ({
        method: "GET",
        url: `settings/appointment-status-share`,
      }),
      providesTags: [TagTypes.APPOINTMENT_STATUS],
    }),
    updateAppointmentStatusShare: build.mutation<IAppointmentStatus, { statusId: string; updatedValue: number }>({
      query: ({ statusId, updatedValue }) => ({
        method: "PATCH",
        url: `settings/appointment-status-share/${statusId}`,
        data: { value: updatedValue },
      }),
      invalidatesTags: [TagTypes.APPOINTMENT_STATUS],
    }),
  }),
});

export const { useGetAppointmentStatusQuery, useUpdateAppointmentStatusShareMutation } = appointmentStatusApi;

export const appointmentStatusApiReducer = {
  [appointmentStatusApi.reducerPath]: appointmentStatusApi.reducer,
};
