import { SnackbarProps } from "@hoc/AlertPopover";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useUpdateReservationSettingsMutation } from "@store/services/reservationSettingsService";
import { MaximumBookingLeadTime, MinimumBookingLeadTime } from "@utils/enums";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IReservationSettings } from "reservationSettings-types";
import { reservationSettingsValidationSchema } from "src/validations/reservationSettingsValidation";

const mapMinimumLeadTime = (value: number): MinimumBookingLeadTime => {
  switch (value) {
    case 0:
      return MinimumBookingLeadTime.NONE;
    case 720:
      return MinimumBookingLeadTime.TWELVE_HOURS;
    case 1440:
      return MinimumBookingLeadTime.ONE_DAY;
    case 2880:
      return MinimumBookingLeadTime.TWO_DAYS;
    default:
      return MinimumBookingLeadTime.NONE;
  }
};

const mapMaximumLeadTime = (value: number): MaximumBookingLeadTime => {
  if (Object.values(MaximumBookingLeadTime).includes(value)) {
    return value as MaximumBookingLeadTime;
  }
  return MaximumBookingLeadTime.ONE_WEEK;
};

interface ISettingsSectionProps extends SnackbarProps {
  reservationSettings: IReservationSettings;
}

interface FormValues extends Partial<IReservationSettings> {}

const SettingsSection: React.FC<ISettingsSectionProps> = ({ reservationSettings, snackbarShowMessage }) => {
  if (!reservationSettings) return null;

  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const [updateReservationSettings, { isLoading: isUpdating }] = useUpdateReservationSettingsMutation();

  const initialValues: FormValues = {
    autoAcceptReservation: reservationSettings?.autoAcceptReservation ?? false,
    breakBetweenAppointments: reservationSettings?.breakBetweenAppointments ?? 10,
    minimumBookingLeadTime: mapMinimumLeadTime(reservationSettings?.minimumBookingLeadTime ?? 0),
    maximumBookingLeadTime: mapMaximumLeadTime(reservationSettings?.maximumBookingLeadTime ?? 7),
  };

  const breakBetweenAppointmentsOptions = [0, 10, 15, 30, 45];

  const generateLeadTimeOptions = (enumObj: any, translationKey: string) => {
    return (
      Object.entries(enumObj)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, value]) => typeof value === "number")
        .map(([key, value]) => ({
          label: t(`reservations.settings.${translationKey}.${key}`),
          value: value as string,
        }))
    );
  };

  const minimumLeadTimeOptions = generateLeadTimeOptions(MinimumBookingLeadTime, "minimumBookingLeadTimeOptions");
  const maximumLeadTimeOptions = generateLeadTimeOptions(MaximumBookingLeadTime, "maximumBookingLeadTimeOptions");

  const handleFormSubmit = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      updateReservationSettings(values)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("reservations.settings.snackbar_message.edit.success"), "success");
          toggleEditMode();
        })
        .catch(() => {
          snackbarShowMessage(t("reservations.settings.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    [updateReservationSettings, snackbarShowMessage, t]
  );

  return (
    <Card>
      <CardHeader title={t("reservations.settings.title")} subheader={t("reservations.settings.subheader")} />
      <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={reservationSettingsValidationSchema} onSubmit={handleFormSubmit} enableReinitialize>
          {({ errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body1" color="text.secondary">
                    {t("reservations.settings.autoAcceptReservation.label")}
                  </Typography>
                  <Field name="autoAcceptReservation">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth>
                        <ToggleButtonGroup
                          color="primary"
                          exclusive
                          value={field.value ? "enabled" : "disabled"}
                          onChange={(_, value) => {
                            if (value !== null) {
                              form.setFieldValue(field.name, value === "enabled");
                            }
                          }}
                          disabled={!isEditMode}
                          fullWidth
                        >
                          <ToggleButton value="disabled">{t("reservations.settings.autoAcceptReservation.disabled")}</ToggleButton>
                          <ToggleButton value="enabled">{t("reservations.settings.autoAcceptReservation.enabled")}</ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="breakBetweenAppointments">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth disabled={!isEditMode}>
                        <InputLabel>{t("reservations.settings.breakBetweenAppointments")}</InputLabel>
                        <Select
                          {...field}
                          label={t("reservations.settings.breakBetweenAppointments")}
                          onChange={(event) => {
                            form.setFieldValue(field.name, event.target.value);
                          }}
                          value={field.value}
                        >
                          {breakBetweenAppointmentsOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} {t("reservations.settings.minutes")}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.breakBetweenAppointments && errors.breakBetweenAppointments && (
                          <Typography color="error" variant="caption">
                            {t(errors.breakBetweenAppointments.toString())}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="minimumBookingLeadTime">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth disabled={!isEditMode}>
                        <InputLabel>{t("reservations.settings.minimumBookingLeadTime")}</InputLabel>
                        <Select
                          {...field}
                          label={t("reservations.settings.minimumBookingLeadTime")}
                          onChange={(event) => {
                            form.setFieldValue(field.name, event.target.value);
                          }}
                          value={field.value}
                        >
                          {minimumLeadTimeOptions.map((option) => (
                            <MenuItem key={option.value as string} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.minimumBookingLeadTime && errors.minimumBookingLeadTime && (
                          <Typography color="error" variant="caption">
                            {t(errors.minimumBookingLeadTime.toString())}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="maximumBookingLeadTime">
                    {({ field, form }: FieldProps) => (
                      <FormControl fullWidth disabled={!isEditMode}>
                        <InputLabel>{t("reservations.settings.maximumBookingLeadTime")}</InputLabel>
                        <Select
                          {...field}
                          label={t("reservations.settings.maximumBookingLeadTime")}
                          onChange={(event) => {
                            form.setFieldValue(field.name, event.target.value);
                          }}
                          value={field.value}
                        >
                          {maximumLeadTimeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.maximumBookingLeadTime && errors.maximumBookingLeadTime && (
                          <Typography color="error" variant="caption">
                            {t(errors.maximumBookingLeadTime.toString())}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={toggleEditMode} variant="outlined" color="secondary">
                  {isEditMode ? t("cancel") : t("edit")}
                </Button>
                {isEditMode && (
                  <LoadingButton loading={isUpdating} type="submit" variant="contained" color="secondary">
                    {t("submit")}
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default SettingsSection;
