import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { Button } from "@mui/material";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";

const ConfirmRegistrationSuccess = () => {
  const { t } = useTranslation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);

  if (usersTokens) return <Navigate to={AppRoutes.Dashboard} />;
  return (
    <NotificationWithAction
      helmetTitle={t("confirm_registration.success.helmet")}
      title={t("confirm_registration.success.title")}
      message={t("confirm_registration.success.message.owner")}
      buttonComponent={
        <Button component={Link} color="secondary" to={AppRoutes.Login} variant="contained" size="large" sx={{ mt: 3, width: "50%" }}>
          {t("confirm_registration.success.go_to_login_button")}
        </Button>
      }
    />
  );
};

export default ConfirmRegistrationSuccess;
