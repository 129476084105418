import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { formatDate } from "@utils/timeUtils";
import { formatCost } from "@utils/typographyUtils";
import { getColorForStatus, getInvoiceStatusText } from "@utils/utils";
import { parseISO } from "date-fns";
import { IInvoice } from "invoice-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IInvoiceTableProps {
  invoices: IInvoice[];
  handleOpenMenu: (e: React.MouseEvent<HTMLElement>, invoice: IInvoice) => void;
}

const InvoiceTable: React.FC<IInvoiceTableProps> = ({ invoices, handleOpenMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{"Pacjent"}</TableCell>
            <TableCell align="right">{t("invoice.table.date")}</TableCell>
            <TableCell align="right">{t("invoice.table.date_of_payment")}</TableCell>
            <TableCell align="right">{t("invoice.table.gross_value")}</TableCell>
            <TableCell align="right">{t("invoice.table.stauts")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.length > 0 ? (
            invoices.map((invoice, index) => (
              <TableRow
                key={invoice._id}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(AppRoutes.PreviewInvoice.replace(":invoiceId", invoice._id))}
              >
                <TableCell>
                  <Typography variant="caption" color="text.secondary">
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack>
                    <Typography variant={"subtitle1"}>{`${invoice.patient?.firstname} ${invoice.patient?.lastname}`}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {invoice.invoiceNumber}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Stack>
                    <Typography variant={"subtitle2"}>{formatDate(new Date(invoice.publishDate), "dd MMM yyyy")}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(new Date(invoice.publishDate), "HH:mm")}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Stack>
                    <Typography variant={"subtitle2"}>{formatDate(new Date(invoice.paymentDate), "dd MMM yyyy")}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(parseISO(invoice.paymentDate), "HH:mm")}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Typography>{formatCost(invoice.grossValue)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <SeverityPill color={getColorForStatus(invoice.status)}>{getInvoiceStatusText(invoice.status)}</SeverityPill>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      handleOpenMenu(e, invoice);
                      e.stopPropagation();
                    }}
                  >
                    <MoreVertRoundedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="body2" color="text.secondary">
                  {t("invoice.table.no_invoices")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
