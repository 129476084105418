import { palette } from "@theme/palette";
import { IAppointment } from "appointment.types";
import { AppointmentStatus, InvoiceStatus, ReservationStatus } from "./enums";
import { ColorType } from "@components/SeverityPill/SeverityPill";
import { alpha } from "@mui/material";
import { t } from "i18next";

export const getEnumValues = (enumerator: Record<any, any>) => Object.values(enumerator);

export const getColorByFileExtension = (filename: string) => {
  const extension = filename.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return palette.warning.main;
    case "docx":
      return palette.secondary.main;
    case "jpg":
    case "jpeg":
    case "png":
      return palette.success.dark;
    default:
      return palette.grey[500];
  }
};

export const getEventBackgroundColor = (event: IAppointment) => {
  if (event.appointmentStatus === AppointmentStatus.PRESENT) {
    return alpha(palette.success.dark, 0.12);
  } else if (event.appointmentStatus === AppointmentStatus.PENDING) {
    return alpha(palette.info.dark, 0.12);
  } else if (event.appointmentStatus === AppointmentStatus.EXCUSED) {
    return alpha(palette.warning.dark, 0.12);
  } else if (event.appointmentStatus === AppointmentStatus.NOT_EXCUSED) {
    return alpha(palette.error.dark, 0.12);
  }
};

export const getEventFontColor = (event: IAppointment) => {
  if (event.appointmentStatus === AppointmentStatus.PRESENT) {
    return palette.success.main;
  } else if (event.appointmentStatus === AppointmentStatus.PENDING) {
    return palette.info.main;
  } else if (event.appointmentStatus === AppointmentStatus.EXCUSED) {
    return palette.warning.main;
  } else if (event.appointmentStatus === AppointmentStatus.NOT_EXCUSED) {
    return palette.error.main;
  }
};

export const getAppointmentStatusColor = (status: AppointmentStatus): ColorType => {
  switch (status) {
    case AppointmentStatus.PRESENT:
      return "success";
    case AppointmentStatus.PENDING:
      return "info";
    case AppointmentStatus.EXCUSED:
      return "warning";
    case AppointmentStatus.NOT_EXCUSED:
      return "error";
    default:
      return "primary";
  }
};

export const getAppointmentStatusText = (status: AppointmentStatus): string => {
  switch (status) {
    case AppointmentStatus.PRESENT:
      return "appointments.status.present";
    case AppointmentStatus.PENDING:
      return "appointments.status.pending";
    case AppointmentStatus.EXCUSED:
      return "appointments.status.excused";
    case AppointmentStatus.NOT_EXCUSED:
      return "appointments.status.not_excused";
    default:
      return "";
  }
};

export const getInvoiceStatusText = (status: InvoiceStatus | string): string => {
  switch (status) {
    case "Total":
      return t("status_invoice.all");
    case InvoiceStatus.PAID:
      return t("status_invoice.paid");
    case InvoiceStatus.SENT:
      return t("status_invoice.sent");
    case InvoiceStatus.OVERDUE:
      return t("status_invoice.overdue");
    case InvoiceStatus.DRAFT:
      return t("status_invoice.draft");
  }
};

export const getReservationStatusText = (status: ReservationStatus | string): string => {
  switch (status) {
    case "Total":
      return t("reservations.status.all");
    case ReservationStatus.ACCEPTED:
      return t("reservations.status.accepted");
    case ReservationStatus.REJECTED:
      return t("reservations.status.rejected");
    case ReservationStatus.PENDING:
      return t("reservations.status.pending");
  }
};

export const getColorForStatus = (status: InvoiceStatus): ColorType => {
  switch (status) {
    case InvoiceStatus.PAID:
      return "success";
    case InvoiceStatus.SENT:
      return "warning";
    case InvoiceStatus.OVERDUE:
      return "error";
    case InvoiceStatus.DRAFT:
      return "info";
  }
};
export const getColorForReservationStatus = (status: ReservationStatus): ColorType => {
  switch (status) {
    case ReservationStatus.ACCEPTED:
      return "success";
    case ReservationStatus.REJECTED:
      return "error";
    case ReservationStatus.PENDING:
      return "info";
  }
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;

    const pastelValue = Math.floor((value + 255) / 2);
    color += `00${pastelValue.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name: string, additionalSx = {}) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...additionalSx,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};
