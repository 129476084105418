import useResponsive from "@hooks/useResponsive";
import { Paper, TextField, List, ListItemButton, ListItemText, Typography, Divider, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { IEmployee } from "employees-types";
import { palette } from "@theme/palette";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";

interface IEmployeeListProps {
  searchTerm: string;
  employees: IEmployee[];
  selectedEmployee: IEmployee;
  handleSearchChange: (value: string) => void;
  setSelectedEmployee: (employeeId: IEmployee) => void;
}

const EmployeeList: React.FC<IEmployeeListProps> = ({ searchTerm, employees, selectedEmployee, setSelectedEmployee, handleSearchChange }) => {
  if (!selectedEmployee) return null;

  const { t } = useTranslation();

  const isDesktop = useResponsive("up", "sm");

  return (
    <Paper sx={{ p: 2, height: isDesktop ? 600 : 250 }}>
      <TextField
        fullWidth
        label={t("reservations.scheduler.find_specialist")}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        InputProps={{
          endAdornment: <SearchRoundedIcon color="action" />,
        }}
      />
      <List sx={{ overflowY: "auto", height: isDesktop ? 450 : 160 }}>
        {employees.length !== 0 ? (
          employees.map((employee, index) => (
            <React.Fragment key={employee._id}>
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setSelectedEmployee(employee)}
                selected={employee._id === selectedEmployee._id}
                sx={{
                  borderRadius: 1,
                  "&.Mui-selected": {
                    bgcolor: palette.grey[100],
                    "&:hover": {
                      bgcolor: palette.grey[200],
                    },
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1}>
                      <Typography variant="subtitle2" noWrap>
                        {`${employee.firstname} ${employee.lastname}`}
                      </Typography>
                      {/* @ts-expect-error: no isCurrentUser type in IEmployee */}
                      {employee.isCurrentUser && <SeverityPill color="primary">{t("reservations.scheduler.my_account")}</SeverityPill>}
                    </Stack>
                  }
                  secondary={
                    <Typography variant="body2" noWrap color="text.secondary">
                      {employee.email || t("personal_data.no_email")}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Divider variant="inset" component="li" />
              {index < employees.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary" align="center">
            {t("reservations.scheduler.no_specialist")}
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export default EmployeeList;
