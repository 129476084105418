import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { AppointmentStatus } from "@utils/enums";
import { getAppointmentStatusColor, getAppointmentStatusText } from "@utils/utils";
import { IEmployee } from "employees-types";
import { IPatient } from "patients-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICalendarFilterProps {
  showExcused: AppointmentStatus[];
  employees: IEmployee[];
  patients: IPatient[];
  selectedEmployees: string[];
  selectedPatients: string[];
  handlePatientChange: (event: SelectChangeEvent<string[]>) => void;
  handleEmployeeChange: (event: SelectChangeEvent<string[]>) => void;
  handleStatusChange: (event: SelectChangeEvent<AppointmentStatus[]>) => void;
}

const CalendarFilter: React.FC<ICalendarFilterProps> = ({
  employees,
  patients,
  showExcused,
  selectedPatients,
  selectedEmployees,
  handlePatientChange,
  handleEmployeeChange,
  handleStatusChange,
}) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ mb: 5 }}>
      <CardContent>
        <Grid container spacing={3}>
          <RBAC
            action={RBACActions.ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE}
            yes={() => (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>{t("filter.by_employee")}</InputLabel>
                  <Select
                    multiple
                    value={selectedEmployees}
                    onChange={handleEmployeeChange}
                    renderValue={(selected) => {
                      const selectedEmployeesNames = selected.map((employeeId) => {
                        const employee = employees.find((e) => e._id === employeeId);
                        return employee ? `${employee.firstname} ${employee.lastname}` : "";
                      });
                      return selectedEmployeesNames.join(", ");
                    }}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee._id}>
                        <Checkbox checked={selectedEmployees.indexOf(employee._id) > -1} sx={{ mr: 1 }} />
                        <ListItemText
                          primary={<Typography variant="subtitle1">{`${employee.firstname} ${employee.lastname}`}</Typography>}
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              {employee.email}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          />
          <RBAC
            action={RBACActions.ACTION_FILTER_APPOINTMENTS_BY_PATIENT}
            yes={() => (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>{t("filter.by_patient")}</InputLabel>
                  <Select
                    multiple
                    value={selectedPatients}
                    onChange={handlePatientChange}
                    renderValue={(selected) => {
                      const selectedPatientsNames = selected.map((patientId) => {
                        const patient = patients.find((e) => e._id === patientId);
                        return patient ? `${patient.firstname} ${patient.lastname}` : "";
                      });
                      return selectedPatientsNames.join(", ");
                    }}
                  >
                    {patients.map((patient) => (
                      <MenuItem key={patient._id} value={patient._id}>
                        <Checkbox checked={selectedPatients.indexOf(patient._id) > -1} sx={{ mr: 1 }} />
                        <ListItemText
                          primary={<Typography variant="subtitle1">{`${patient.firstname} ${patient.lastname}`}</Typography>}
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              {patient.assignedEmployees.length
                                ? `${t("appointments.add_modal.therapists")} ${patient.assignedEmployees
                                    .map((employee) => `${employee.firstname} ${employee.lastname}`)
                                    .join(", ")}`
                                : ""}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          />
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth>
              <InputLabel>{t("filter.by_status")}</InputLabel>
              <Select
                multiple
                value={showExcused}
                onChange={handleStatusChange}
                input={<OutlinedInput label={t("filter.by_status")} />}
                renderValue={(selected) => (
                  <Stack direction="row" spacing={1}>
                    {(selected as AppointmentStatus[]).map((value) => (
                      <SeverityPill key={value} color={getAppointmentStatusColor(value)}>
                        {t(getAppointmentStatusText(value))}
                      </SeverityPill>
                    ))}
                  </Stack>
                )}
              >
                {Object.values(AppointmentStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    <SeverityPill key={status} color={getAppointmentStatusColor(status)}>
                      {t(getAppointmentStatusText(status))}
                    </SeverityPill>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CalendarFilter;
