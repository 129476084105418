import { SnackbarProps } from "@hoc/AlertPopover";
import { Grid } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import EmployeeList from "./fragments/EmployeeList";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";
import useDebounce from "@hooks/useDebounce";
import EmployeeAvailabiltyScheduler from "./fragments/EmployeeAvailabiltyScheduler";
import { IEmployee } from "employees-types";
import { useGetAvailabilityQuery } from "@store/services/reservationsService";
import { IUserContextType, UserContext } from "@context/UserProvider";

interface IAvailabilityHoursConfigProps extends SnackbarProps {}

const AvailabilityHoursConfig: React.FC<IAvailabilityHoursConfigProps> = ({ snackbarShowMessage }) => {
  const { userDetails } = useContext(UserContext) as IUserContextType;

  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee>();
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 300);

  const { employees } = useGetAllEmployeesQuery(
    {
      search: debouncedSearchText,
    },
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
    }
  );

  const { availabilityData } = useGetAvailabilityQuery(
    { userId: selectedEmployee?._id },
    {
      selectFromResult: ({ data }) => ({
        availabilityData: data,
      }),
      skip: !selectedEmployee,
    }
  );

  const updatedEmployees = useMemo(() => {
    return [
      {
        ...userDetails,
        isCurrentUser: true,
      },
      ...employees,
    ] as IEmployee[];
  }, [userDetails, employees]);

  useEffect(() => {
    if (updatedEmployees.length) {
      setSelectedEmployee(updatedEmployees[0]);
    }
  }, [updatedEmployees]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={3}>
        <EmployeeList
          employees={updatedEmployees}
          searchTerm={searchText}
          handleSearchChange={setSearchText}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <EmployeeAvailabiltyScheduler
          availabilityData={availabilityData}
          selectedEmployee={selectedEmployee}
          snackbarShowMessage={snackbarShowMessage}
        />
      </Grid>
    </Grid>
  );
};

export default AvailabilityHoursConfig;
