import ClinicSelection from "@pages/ClinicSelection/ClinicSelection";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { selectCurrentClinicId } from "@store/features/clinicSlice";
import { useGetAllClinicsQuery } from "@store/services/clinicsService";
import { IClinic } from "clinics-types";
import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface IClinicContext {
  currentClinic: IClinic | null;
  setCurrentClinic: React.Dispatch<React.SetStateAction<IClinic | null>>;
}

export const ClinicContext = createContext<IClinicContext | null>(null);

export const ClinicProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentClinic, setCurrentClinic] = useState<IClinic | null>(null);

  const usersTokens = useSelector(selectCurrentUserTokens);
  const selectedClinic = useSelector(selectCurrentClinicId);

  const { clinics, isLoading, isSuccess } = useGetAllClinicsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      clinics: data || [],
      isLoading,
      isSuccess,
    }),
  });

  useEffect(() => {
    if (isSuccess && clinics && selectedClinic) {
      const clinicDetails = clinics.find((clinic) => clinic._id === selectedClinic);
      if (clinicDetails) {
        setCurrentClinic(clinicDetails);
      }
    }
  }, [isSuccess, clinics, selectedClinic]);

  if (!selectedClinic && !!usersTokens) {
    return <ClinicSelection clinics={clinics} isClinicsLoading={isLoading} isClinicsInitialized={isSuccess} />;
  }

  return <ClinicContext.Provider value={{ currentClinic, setCurrentClinic }}>{children}</ClinicContext.Provider>;
};
