import { Box, Typography, TextField, Button, Link, Stack } from "@mui/material";
import { t } from "i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";

const validationSchema = Yup.object({
  firstName: Yup.string().required(t("confirmation_form.first_name_required")),
  lastName: Yup.string().required(t("confirmation_form.last_name_required")),
  phoneNumber: Yup.string()
    .matches(/^\d{9}$/, t("confirmation_form.phone_number_invalid"))
    .required(t("confirmation_form.phone_number_required")),
  email: Yup.string().email(t("confirmation_form.email_invalid")).required(t("confirmation_form.email_required")),
  additionalInfo: Yup.string(),
});

interface IConfirmationFormSectionProps {
  setIsReservationConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setReservationDetails:
    | React.Dispatch<
        React.SetStateAction<{
          firstName: string;
          lastName: string;
          phoneNumber: number;
          email: string;
          additionalInfo?: string | undefined;
        } | null>
      >
    | undefined;
}

const ConfirmationFormSection: React.FC<IConfirmationFormSectionProps> = ({ setIsReservationConfirmed, setReservationDetails }) => {
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phoneNumber: null,
        email: "",
        additionalInfo: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { additionalInfo, ...rest } = values;

        const reservationData = {
          ...rest,
          ...(additionalInfo.trim() !== "" && { additionalInfo: additionalInfo.trim() }),
        };

        if (setReservationDetails) {
          setReservationDetails(reservationData);
        }
      }}
    >
      {({ errors, touched, isValid, dirty }) => (
        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
          <Box height="100%" display="flex" flexDirection="column" sx={{ p: 2 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              {t("confirmation_form.provide_contact_details")}
            </Typography>

            <Form>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mb: 2 }}>
                <Field
                  as={TextField}
                  name="firstName"
                  label={t("confirmation_form.first_name")}
                  variant="outlined"
                  fullWidth
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName ? <ErrorMessage name="firstName" /> : t("confirmation_form.first_name_helper")}
                />

                <Field
                  as={TextField}
                  name="lastName"
                  label={t("confirmation_form.last_name")}
                  variant="outlined"
                  fullWidth
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName ? <ErrorMessage name="lastName" /> : t("confirmation_form.last_name_helper")}
                />
              </Stack>

              <Field
                as={TextField}
                name="phoneNumber"
                label={t("confirmation_form.phone_number")}
                variant="outlined"
                fullWidth
                type="tel"
                sx={{ mb: 2 }}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={
                  touched.phoneNumber && errors.phoneNumber ? <ErrorMessage name="phoneNumber" /> : t("confirmation_form.phone_number_helper")
                }
              />

              <Field
                as={TextField}
                name="email"
                label={t("confirmation_form.email")}
                variant="outlined"
                fullWidth
                type="email"
                sx={{ mb: 2 }}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email ? <ErrorMessage name="email" /> : t("confirmation_form.email_helper")}
              />

              <Field
                as={TextField}
                rows={4}
                multiline
                name="additionalInfo"
                label={t("confirmation_form.additional_information")}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                helperText={t("confirmation_form.additional_information_helper")}
              />

              <Typography variant="body2" sx={{ mt: 2, color: "text.secondary" }}>
                {t("confirmation_form.terms_conditions_part1")}{" "}
                <Link href="https://www.logopedaplus.com/docs/regulamin.pdf" target="_blank" rel="noopener noreferrer">
                  {t("confirmation_form.terms_conditions_link")}
                </Link>{" "}
                {t("confirmation_form.terms_conditions_part2")}{" "}
                <Link href="https://www.logopedaplus.com/docs/polityka_prywatnosci.pdf" target="_blank" rel="noopener noreferrer">
                  {t("confirmation_form.privacy_policy_link")}
                </Link>
                .
              </Typography>

              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button type="submit" variant="contained" color="primary" sx={{ alignSelf: "flex-start" }} disabled={!isValid || !dirty}>
                  {t("confirmation_form.book_appointment")}
                </Button>
                <Button variant="outlined" color="secondary" sx={{ alignSelf: "flex-start" }} onClick={() => setIsReservationConfirmed(false)}>
                  {t("confirmation_form.go_back")}
                </Button>
              </Stack>
            </Form>
          </Box>
        </motion.div>
      )}
    </Formik>
  );
};

export default ConfirmationFormSection;
