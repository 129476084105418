export enum RBACActions {
  MAIN_MENU_DASHBOARD = "MAIN_MENU_DASHBOARD",
  MAIN_MENU_EMPLOYEES = "MAIN_MENU_EMPLOYEES",
  MAIN_MENU_PATIENTS = "MAIN_MENU_PATIENTS",
  MAIN_MENU_APPOINTMENTS = "MAIN_MENU_APPOINTMENTS",
  MAIN_MENU_ACTIVITY_LOG = "MAIN_MENU_ACTIVITY_LOG",
  MAIN_MENU_CLINICS = "MAIN_MENU_CLINICS",
  MAIN_MENU_SETTINGS = "MAIN_MENU_SETTINGS",
  MAIN_MENU_REPORTS = "MAIN_MENU_REPORTS",
  MAIN_MENU_INVOICE = "MAIN_MENU_INVOICE",
  MAIN_MENU_EXPENSES = "MAIN_MENU_EXPENSES",
  MAIN_MENU_RESERVATIONS = "MAIN_MENU_RESERVATIONS",

  MAIN_MENU_CLINIC_SECTION = "MAIN_MENU_CLINIC_SECTION",
  MAIN_MENU_PATIENT_SECTION = "MAIN_MENU_PATIENT_SECTION",
  MAIN_MENU_FINICIAL_SECTION = "MAIN_MENU_FINICIAL_SECTION",

  ACTION_GET_EMPLOYESS = "ACTION_GET_EMPLOYESS",

  ACTION_ADD_PATIENT = "ACTION_ADD_PATIENT",
  ACTION_EDIT_PATIENT = "ACTION_EDIT_PATIENT",
  ACTION_DELETE_PATIENT = "ACTION_DELETE_PATIENT",
  ACTION_CHANGE_PATIENT_STATUS = "ACTION_CHANGE_PATIENT_STATUS",
  ACTION_DELETE_PATIENT_FILE = "ACTION_DELETE_PATIENT_FILE",
  ACTION_VERIFY_ASSIGNMENT = "ACTION_VERIFY_ASSIGNMENT",
  ACTION_FILTER_PATIENTS_BY_EMPLOYEE = "ACTION_FILTER_PATIENTS_BY_EMPLOYEE",

  ACTION_ADD_DIAGNOSIS = "ACTION_ADD_DIAGNOSIS",
  ACTION_EDIT_DIAGNOSIS = "ACTION_EDIT_DIAGNOSIS",
  ACTION_DELETE_DIAGNOSIS = "ACTION_DELETE_DIAGNOSIS",

  ACTION_ADD_CLINIC = "ACTION_ADD_CLINIC",
  ACTION_ASSIGN_PATIENT_TO_CLIENT = "ACTION_ASSIGN_PATIENT_TO_CLIENT",
  ACTION_ASSIGN_PATIENT_TO_EMPLOYEE = "ACTION_ASSIGN_PATIENT_TO_EMPLOYEE",
  ACTION_UNASSIGN_PATIENT_FROM_EMPLOYEE = "ACTION_UNASSIGN_PATIENT_FROM_EMPLOYEE",

  ACTION_ADD_APPOINTMENT = "ACTION_ADD_APPOINTMENT",
  ACTION_EDIT_APPOINTMENT = "ACTION_EDIT_APPOINTMENT",
  ACTION_DELETE_APPOINTMENT = "ACTION_DELETE_APPOINTMENT",
  ACTION_TOGGLE_APPOINTMENT_STATUS = "ACTION_TOGGLE_APPOINTMENT_STATUS",
  ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE = "ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE",
  ACTION_FILTER_APPOINTMENTS_BY_PATIENT = "ACTION_FILTER_APPOINTMENTS_BY_PATIENT",

  ACTION_ADD_HOMEWORK = "ACTION_ADD_HOMEWORK",
  ACTION_EDIT_HOMEWORK = "ACTION_EDIT_HOMEWORK",
  ACTION_DELETE_HOMEWORK = "ACTION_DELETE_HOMEWORK",
  ACTION_TOGGLE_HOMEWORK_STATUS = "ACTION_TOGGLE_HOMEWORK_STATUS",

  ACTION_GET_APPOINTMENT_TYPES = "ACTION_GET_APPOINTMENT_TYPES",

  ACTION_GET_INVOICE_DATA = "ACTION_GET_INVOICE_DATA",
  ACTION_ADD_INVOICE_DATA = "ACTION_ADD_INVOICE_DATA",
  ACTION_EDIT_INVOICE_DATA = "ACTION_EDIT_INVOICE_DATA",
  ACTION_DELETE_INVOICE_DATA = "ACTION_DELETE_INVOICE_DATA",

  ACTION_EXPORT_PATIENT_APPOINTMENTS_REPORTS = "ACTION_EXPORT_PATIENT_APPOINTMENTS_REPORTS",

  ACTION_GET_APPOINTMENTS_STATUS = "ACTION_GET_APPOINTMENTS_STATUS",

  VIEW_PATIENT_DATA = "VIEW_PATIENT_DATA",
  VIEV_CLINIC_DATA = "VIEV_CLINIC_DATA",
  VIEW_PATIENT_INVOICE_DATA = "VIEW_PATIENT_INVOICE_DATA",

  ACTION_SEE_DEMO = "ACTION_SEE_DEMO",
  ACTION_GET_USER_NOTE = "ACTION_GET_USER_NOTE",

  ACTION_GET_RESERVATIONS = "ACTION_GET_RESERVATIONS",
  ACTION_ONLINE_RESERVATION_SETTINGS = "ACTION_SET_ONLINE_RESERVATION_SETTINGS",
}

export enum RBACRoles {
  OWNER = "OWNER",
  EMPLOYEE = "EMPLOYEE",
  CLIENT = "CLIENT",
  ADMIN = "ADMIN",
}

type RBACRules = Record<
  RBACRoles,
  {
    static?: RBACActions[];
  }
>;
const rules: RBACRules = {
  [RBACRoles.CLIENT]: {
    static: [
      RBACActions.MAIN_MENU_DASHBOARD,
      RBACActions.MAIN_MENU_SETTINGS,
      RBACActions.ACTION_TOGGLE_HOMEWORK_STATUS,
      RBACActions.ACTION_VERIFY_ASSIGNMENT,
      RBACActions.VIEV_CLINIC_DATA,
    ],
  },
  [RBACRoles.EMPLOYEE]: {
    static: [
      RBACActions.MAIN_MENU_DASHBOARD,
      RBACActions.MAIN_MENU_PATIENTS,
      RBACActions.MAIN_MENU_APPOINTMENTS,
      RBACActions.MAIN_MENU_ACTIVITY_LOG,
      RBACActions.MAIN_MENU_SETTINGS,
      RBACActions.MAIN_MENU_CLINIC_SECTION,
      RBACActions.MAIN_MENU_PATIENT_SECTION,
      RBACActions.ACTION_ADD_PATIENT,
      RBACActions.ACTION_EDIT_PATIENT,
      RBACActions.ACTION_CHANGE_PATIENT_STATUS,
      RBACActions.ACTION_DELETE_PATIENT_FILE,
      RBACActions.VIEW_PATIENT_DATA,
      RBACActions.ACTION_ADD_APPOINTMENT,
      RBACActions.ACTION_EDIT_APPOINTMENT,
      RBACActions.ACTION_DELETE_APPOINTMENT,
      RBACActions.ACTION_TOGGLE_APPOINTMENT_STATUS,
      RBACActions.ACTION_GET_APPOINTMENT_TYPES,
      RBACActions.ACTION_ADD_HOMEWORK,
      RBACActions.ACTION_EDIT_HOMEWORK,
      RBACActions.ACTION_DELETE_HOMEWORK,
      RBACActions.ACTION_ADD_DIAGNOSIS,
      RBACActions.ACTION_EDIT_DIAGNOSIS,
      RBACActions.ACTION_DELETE_DIAGNOSIS,
      RBACActions.ACTION_FILTER_APPOINTMENTS_BY_PATIENT,
      RBACActions.ACTION_GET_USER_NOTE,
      RBACActions.ACTION_GET_RESERVATIONS,
    ],
  },
  [RBACRoles.OWNER]: {
    static: [
      RBACActions.MAIN_MENU_DASHBOARD,
      RBACActions.MAIN_MENU_REPORTS,
      RBACActions.MAIN_MENU_PATIENTS,
      RBACActions.MAIN_MENU_CLINICS,
      RBACActions.MAIN_MENU_EMPLOYEES,
      RBACActions.MAIN_MENU_APPOINTMENTS,
      RBACActions.MAIN_MENU_ACTIVITY_LOG,
      RBACActions.MAIN_MENU_SETTINGS,
      RBACActions.MAIN_MENU_INVOICE,
      RBACActions.MAIN_MENU_EXPENSES,
      RBACActions.MAIN_MENU_CLINIC_SECTION,
      RBACActions.MAIN_MENU_PATIENT_SECTION,
      RBACActions.MAIN_MENU_FINICIAL_SECTION,
      RBACActions.MAIN_MENU_RESERVATIONS,
      RBACActions.ACTION_ADD_PATIENT,
      RBACActions.ACTION_EDIT_PATIENT,
      RBACActions.ACTION_DELETE_PATIENT,
      RBACActions.ACTION_CHANGE_PATIENT_STATUS,
      RBACActions.ACTION_DELETE_PATIENT_FILE,
      RBACActions.ACTION_ASSIGN_PATIENT_TO_EMPLOYEE,
      RBACActions.ACTION_UNASSIGN_PATIENT_FROM_EMPLOYEE,
      RBACActions.ACTION_ADD_CLINIC,
      RBACActions.ACTION_ADD_APPOINTMENT,
      RBACActions.ACTION_EDIT_APPOINTMENT,
      RBACActions.ACTION_DELETE_APPOINTMENT,
      RBACActions.ACTION_TOGGLE_APPOINTMENT_STATUS,
      RBACActions.ACTION_ADD_HOMEWORK,
      RBACActions.ACTION_EDIT_HOMEWORK,
      RBACActions.ACTION_DELETE_HOMEWORK,
      RBACActions.ACTION_ADD_DIAGNOSIS,
      RBACActions.ACTION_EDIT_DIAGNOSIS,
      RBACActions.ACTION_DELETE_DIAGNOSIS,
      RBACActions.ACTION_GET_APPOINTMENT_TYPES,
      RBACActions.ACTION_GET_INVOICE_DATA,
      RBACActions.ACTION_ADD_INVOICE_DATA,
      RBACActions.ACTION_EDIT_INVOICE_DATA,
      RBACActions.ACTION_DELETE_INVOICE_DATA,
      RBACActions.ACTION_GET_APPOINTMENTS_STATUS,
      RBACActions.VIEW_PATIENT_INVOICE_DATA,
      RBACActions.VIEW_PATIENT_DATA,
      RBACActions.ACTION_SEE_DEMO,
      RBACActions.ACTION_FILTER_PATIENTS_BY_EMPLOYEE,
      RBACActions.ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE,
      RBACActions.ACTION_FILTER_APPOINTMENTS_BY_PATIENT,
      RBACActions.ACTION_GET_USER_NOTE,
      RBACActions.ACTION_GET_RESERVATIONS,
      RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS,
      RBACActions.ACTION_GET_EMPLOYESS,
    ],
  },
  [RBACRoles.ADMIN]: {
    static: [
      RBACActions.MAIN_MENU_DASHBOARD,
      RBACActions.MAIN_MENU_REPORTS,
      RBACActions.MAIN_MENU_PATIENTS,
      RBACActions.MAIN_MENU_CLINICS,
      RBACActions.MAIN_MENU_EMPLOYEES,
      RBACActions.MAIN_MENU_APPOINTMENTS,
      RBACActions.MAIN_MENU_ACTIVITY_LOG,
      RBACActions.MAIN_MENU_RESERVATIONS,
      RBACActions.MAIN_MENU_SETTINGS,
      RBACActions.MAIN_MENU_INVOICE,
      RBACActions.MAIN_MENU_EXPENSES,
      RBACActions.MAIN_MENU_CLINIC_SECTION,
      RBACActions.MAIN_MENU_PATIENT_SECTION,
      RBACActions.MAIN_MENU_FINICIAL_SECTION,
      RBACActions.ACTION_ADD_PATIENT,
      RBACActions.ACTION_EDIT_PATIENT,
      RBACActions.ACTION_DELETE_PATIENT,
      RBACActions.ACTION_CHANGE_PATIENT_STATUS,
      RBACActions.ACTION_DELETE_PATIENT_FILE,
      RBACActions.ACTION_ASSIGN_PATIENT_TO_EMPLOYEE,
      RBACActions.ACTION_UNASSIGN_PATIENT_FROM_EMPLOYEE,
      RBACActions.ACTION_ADD_CLINIC,
      RBACActions.ACTION_ADD_APPOINTMENT,
      RBACActions.ACTION_EDIT_APPOINTMENT,
      RBACActions.ACTION_DELETE_APPOINTMENT,
      RBACActions.ACTION_TOGGLE_APPOINTMENT_STATUS,
      RBACActions.ACTION_ADD_HOMEWORK,
      RBACActions.ACTION_EDIT_HOMEWORK,
      RBACActions.ACTION_DELETE_HOMEWORK,
      RBACActions.ACTION_ADD_DIAGNOSIS,
      RBACActions.ACTION_EDIT_DIAGNOSIS,
      RBACActions.ACTION_DELETE_DIAGNOSIS,
      RBACActions.ACTION_GET_APPOINTMENT_TYPES,
      RBACActions.ACTION_EXPORT_PATIENT_APPOINTMENTS_REPORTS,
      RBACActions.ACTION_GET_INVOICE_DATA,
      RBACActions.ACTION_ADD_INVOICE_DATA,
      RBACActions.ACTION_EDIT_INVOICE_DATA,
      RBACActions.ACTION_DELETE_INVOICE_DATA,
      RBACActions.ACTION_GET_APPOINTMENTS_STATUS,
      RBACActions.VIEW_PATIENT_INVOICE_DATA,
      RBACActions.VIEW_PATIENT_DATA,
      RBACActions.ACTION_SEE_DEMO,
      RBACActions.ACTION_FILTER_PATIENTS_BY_EMPLOYEE,
      RBACActions.ACTION_FILTER_APPOINTMENTS_BY_EMPLOYEE,
      RBACActions.ACTION_FILTER_APPOINTMENTS_BY_PATIENT,
      RBACActions.ACTION_GET_USER_NOTE,
      RBACActions.ACTION_GET_RESERVATIONS,
      RBACActions.ACTION_ONLINE_RESERVATION_SETTINGS,
      RBACActions.ACTION_GET_EMPLOYESS,
    ],
  },
};

export default rules;
