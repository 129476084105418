import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
interface IPatientMenuProps {
  anchorElementMenu: HTMLElement;
  handleCloseMenu: () => void;
  handleEditModalOpen: () => void;
}

const ClinicMenu: React.FC<IPatientMenuProps> = ({ anchorElementMenu, handleCloseMenu, handleEditModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Menu anchorEl={anchorElementMenu} open={Boolean(anchorElementMenu)} onClose={handleCloseMenu} sx={{ width: "20rem" }}>
      <MenuItem onClick={handleEditModalOpen}>
        <ListItemIcon>
          <EditRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("my_clinic.basic_info.menu.edit")}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default ClinicMenu;
