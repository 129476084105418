import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { IconButton, SvgIcon, SvgIconProps, Tooltip } from "@mui/material";
import { t } from "i18next";
import React from "react";

interface IIconWithTooltipProps extends SvgIconProps {
  id?: string;
  label?: string;
  icon: React.ReactNode;
  buttonSize?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "action" | "error" | "disabled" | "info";
  onClick?: (e: React.SyntheticEvent) => void;
  locked?: {
    isLocked: boolean;
    title?: string;
  };
}

const IconWithTooltip: React.FC<IIconWithTooltipProps> = ({
  id,
  label = "Example action",
  icon,
  buttonSize = "medium",
  fontSize = "medium",
  color = "action",
  onClick,
  locked = {
    isLocked: false,
    title: t("icon_with_tooltip"),
  },
  ...rest
}) => (
  <Tooltip title={locked.isLocked ? locked.title : label}>
    <IconButton onClick={onClick} disabled={locked.isLocked} size={buttonSize} id={id}>
      <SvgIcon fontSize={fontSize} color={locked.isLocked ? "disabled" : color} {...rest}>
        {icon}
      </SvgIcon>

      {locked.isLocked && (
        <SvgIcon
          fontSize={"small"}
          {...rest}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "50%",
            height: "50%",
          }}
        >
          <LockRoundedIcon />
        </SvgIcon>
      )}
    </IconButton>
  </Tooltip>
);

export default IconWithTooltip;
