import { SnackbarProps } from "@hoc/AlertPopover";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, DialogActions, Divider, Grid, TextField } from "@mui/material";
import { useUpdatePatientInvoiceDataMutation } from "@store/services/patientsService";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { IPatientInvoiceData } from "patients-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { editPatientInvoiceDataValidationSchema } from "src/validations/patientValidation";

interface IPatientInvoiceDataProps extends SnackbarProps {
  patientId: string;
  patientInvoiceData: IPatientInvoiceData;
}

interface FormValues extends Partial<IPatientInvoiceData> {}

const PatientInvoiceData: React.FC<IPatientInvoiceDataProps> = ({ patientId, patientInvoiceData, snackbarShowMessage }) => {
  const { t } = useTranslation();
  if (!patientInvoiceData) return null;
  const [isEditMode, setIsEditMode] = useState(false);

  const [editPatientInvoiceData, { isLoading: isEditPatientInvoiceLoadign }] = useUpdatePatientInvoiceDataMutation();

  const initialValues = {
    firstname: patientInvoiceData.firstname,
    lastname: patientInvoiceData.lastname,
    email: patientInvoiceData.email,
  } as Partial<IPatientInvoiceData>;

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleFormSubmit = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      editPatientInvoiceData({ patientId: patientId, data: values })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.edit_patient_invoice_data.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.edit_patient_invoice_data.error"), "error");
        })
        .finally(() => actions.setSubmitting(false));

      if (isEditMode) toggleEditMode();
    },
    [editPatientInvoiceData, isEditMode, snackbarShowMessage]
  );

  return (
    <Card>
      <CardHeader title={t("patient_details.invoice_data.title")} subheader={t("patient_details.invoice_data.subtitle")} />
      <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={editPatientInvoiceDataValidationSchema} onSubmit={handleFormSubmit}>
          {({ errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12}>
                  <Field name="firstname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={!isEditMode}
                        label={t("personal_data.firstname")}
                        helperText={
                          touched.firstname && errors.firstname
                            ? t(errors.firstname.toString())
                            : t("patient_details.invoice_data.form.first_name_helper_text")
                        }
                        error={touched.firstname && !!errors.firstname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="lastname">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={!isEditMode}
                        label={t("personal_data.lastname")}
                        helperText={
                          touched.lastname && errors.lastname
                            ? t(errors.lastname.toString())
                            : t("patient_details.invoice_data.form.last_name_helper_text")
                        }
                        error={touched.lastname && !!errors.lastname}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        disabled={!isEditMode}
                        fullWidth
                        label={t("personal_data.email")}
                        helperText={
                          touched.email && errors.email ? t(errors.email.toString()) : t("patient_details.invoice_data.form.email_helper_text")
                        }
                        error={touched.email && !!errors.email}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={toggleEditMode} variant="outlined" color="secondary">
                  {isEditMode ? t("cancel") : t("edit")}
                </Button>
                {isEditMode && (
                  <LoadingButton loading={isEditPatientInvoiceLoadign} type="submit" variant="contained" color="secondary">
                    {t("submit")}
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default PatientInvoiceData;
