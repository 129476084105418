import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { IClinic } from "clinics-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ClinicBasicInfo: React.FC<{ currentClinic: IClinic; handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void }> = ({
  currentClinic,
  handleOpenMenu,
}) => {
  const { t } = useTranslation();

  const infoData = useMemo(
    () => [
      {
        label: t("my_clinic.basic_info.header"),
        value: currentClinic?.name,
        secondaryText: t("my_clinic.basic_info.secondary_text"),
        isComplex: true,
      },
      {
        label: t("my_clinic.basic_info.phone_number"),
        value: currentClinic?.contactNumber ?? t("my_clinic.basic_info.no_phone_number"),
        secondaryText: t("my_clinic.basic_info.phone_number_helper_text"),
        isComplex: true,
      },
      {
        label: t("my_clinic.basic_info.email"),
        value: currentClinic?.contactEmail ?? t("my_clinic.basic_info.no_email"),
        secondaryText: t("my_clinic.basic_info.email_helper_text"),
        isComplex: true,
      },
      {
        label: t("my_clinic.basic_info.status"),
        value: <SeverityPill color="success">{t("my_clinic.basic_info.status_type")}</SeverityPill>,
      },
    ],
    [currentClinic, t]
  );
  return (
    <Card>
      <CardHeader
        title={t("my_clinic.basic_info.header")}
        action={<IconWithTooltip label={t("my_clinic.basic_info.menu.open")} icon={<MoreVertRoundedIcon />} onClick={handleOpenMenu} />}
      />
      <Divider sx={{ mt: 2, borderStyle: "dashed" }} />
      <CardContent>
        <Grid container spacing={1}>
          {infoData.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={5}>
                <Typography variant="subtitle2">{item.label}</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {item.isComplex ? (
                  <Stack alignContent={"center"}>
                    <Typography variant="subtitle2">{item.value}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.secondaryText}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {item.value}
                  </Typography>
                )}
              </Grid>
              {index < infoData.length - 1 && <Divider sx={{ my: 2, borderStyle: "dashed" }} />}
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClinicBasicInfo;
